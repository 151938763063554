import { Directive, ElementRef, HostListener } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[trimWhitespaces]',
})
export class TrimWhitespacesDirective {
  /**
   * Creates an instance of the TrimWhitespacesDirective.
   * @param {ElementRef} el - The reference to the DOM element where this directive is applied.
   * @param {NgControl} control - The form control (AbstractControl) attached to this element.
   */
  constructor(private el: ElementRef, private control: NgControl) {}

  /**
   * Listens to the blur event on the host element and trims the whitespaces from the value.
   * @param {KeyboardEvent} event - The keyboard event when the input loses focus.
   */
  @HostListener('blur', ['$event'])
  onBlur(event: KeyboardEvent) {
    this.trimWhiteSpaces();
  }

  /**
   * Trims the leading and trailing whitespaces from the input element's value.
   * Updates the value directly on the DOM element and updates the form control's validity.
   */
  private trimWhiteSpaces() {
    const inputValue: string = this.el.nativeElement.value;
    const trimmedValue = inputValue.trim();

    // Set value directly in DOM
    this.el.nativeElement.value = trimmedValue;

    // If this is a form control, update the control value and validity
    if (this.control && this.control.control) {
      this.trimControlValue(this.control.control);
    }
  }

  /**
   * Will automatically trim whitespace from the form control on update-validity.
   * @param {AbstractControl} control - The form control to update and validate.
   */
  private trimControlValue(control: AbstractControl): void {
    const r = { startWhitespace: /^\s/, endWhitespace: /\s$/ };
    const value: string = control.value;
    if (!value || value.length === 0) return;

    if (r.startWhitespace.test(value) || r.endWhitespace.test(value)) {
      control.setValue(value.trim(), { emitEvent: false });
      control.updateValueAndValidity();
    }
  }
}
