import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { walletAccount } from 'src/app/shared/interface/interface';

@Component({
  selector: 'app-conncet-wallet',
  templateUrl: './conncet-wallet.component.html',
  styleUrls: ['./conncet-wallet.component.scss']
})
export class ConncetWalletComponent implements OnInit {
  public account: walletAccount;
  constructor(
    private accountService: AccountService,
    private router: Router,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    
    this.account = this.storageService.getItem('wagmi.store') === null ?
      { address: '', network: '', chainId: '', provider: '' } :
      JSON.parse(this.storageService.getItem('wagmi.store') as any);
    
     if(this.account.state.data.account != ''){
       this.router.navigate(['/dashboard']);
     }
  }


  /**
   * Connects wallet
   */
  public connectWallet() {
    this.ngOnInit()
    const data = {
      status: true,
    };
    this.accountService.openWallet(data);
  }
}
