import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search-component',
  templateUrl: './search-component.component.html',
  styleUrls: ['./search-component.component.scss']
})
export class SearchComponentComponent implements OnInit {
  @Input() loader:boolean;
  @Input() searchData;
  @Input() searchKey;
  public marketPlaceUrl = environment.MARKETPLACE_URL;
  public isRegulated: boolean;
  @Output() resetSearch = new EventEmitter()

  constructor(private storageService: StorageService){

  }

/**
   * on init component
   */
public ngOnInit(): void {
  this.isRegulated = JSON.parse(this.storageService.getItemSession('isRegulated'));
}
  /**
   * Clicks reset search
   */
  clickResetSearch(){
    this.resetSearch.emit();
  }
  
  /**
   * Bolds string
   * @param {string} fString 
   * @param {string} bString 
   * @returns  
   */
  boldString (fString:string, bString:string) {
    let regex = new RegExp(bString.toString().trim(), 'g');
    return fString.toString().trim().replace(regex, `<b>${bString.toString().trim()}</b>`);  
  }
}
