import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { initialUserDetails } from 'src/app/shared/const/const';
import { CopyDirective } from 'src/app/shared/directives/copy.directive';
import { IApiResponse, IuserDetails, walletAccount } from 'src/app/shared/interface/interface';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-nft-holding',
  templateUrl: './nft-holding.component.html',
  styleUrls: ['./nft-holding.component.scss']
})
export class NftHoldingComponent implements OnInit {
  public userId: string = '';
  public userData: IuserDetails;
  public nftDetails: any;
  public isLoadingData: boolean = false;
  public marketPlaceUrl = environment.MARKETPLACE_URL;
  public isRegulated: boolean;
  loader: boolean = false;
  userRouteData: IuserDetails; // This is using for menu navigation purpose
  public account: walletAccount;
  @ViewChild('content', { static: true }) contentElement!: ElementRef;
  i: any;
  disableInfiniteScroll: boolean = false;
  page: number = 1;
  limit: number = 9;
  scrollLoader = false;
  constructor(
    private route: ActivatedRoute,
    private userManagementService: UserManagementService,
    private copyDirective: CopyDirective,
    private storageService: StorageService,
    private initialUserDetails: initialUserDetails
  ) { }

  /**
   * on init component
   */
  public ngOnInit(): void {
    this.userData = this.initialUserDetails.userDetails;
    this.account = this.storageService.getItem('wagmi.store') === null ?
      { address: '', network: '', chainId: '', provider: '' } :
      JSON.parse(this.storageService.getItem('wagmi.store') as any);
    this.userId = this.route.snapshot.paramMap.get('address');
    this.isRegulated = JSON.parse(this.storageService.getItemSession('isRegulated'));
    this.getUserDetails()
  }

  /**
   * Gets user details
   */
  public getUserDetails() {
    this.loader = true;
    this.userManagementService.getUserByAddress(this.userId).subscribe((response: any) => {
      this.userData = response.data;
      this.userRouteData = response.data;
      this.loader = false;
      this.getUserNft();
    }, (_error) => {
      this.loader = false;
    })
  }

  /**
    * Copys content
    * @param content 
    */
  public copy(content: string) {
    this.copyDirective.copy(content)
  }

  /**
   * Gets user nft
   */
  public getUserNft() {
    if (this.page != null) {
      if (this.page === 1) {
        this.nftDetails = [];
        this.isLoadingData = true;
      } else {
        this.scrollLoader = true;
      }
      this.disableInfiniteScroll = true;
      this.userManagementService.getUserNft(this.userData.wallet_address, this.page, this.limit).subscribe({
        next: (response: IApiResponse) => {
          // this.nftDetails = response.data.items;
          if (!this.page || this.page === 1) this.nftDetails = [];
          response.data?.items ? this.nftDetails.push(...response.data.items) : this.nftDetails = [];
          this.page = response?.data.next_page;
          for (const nft of response.data.items) {
            nft.fileType = nft.preview_image ? nft?.preview_image.split('.')[nft?.preview_image.split('.').length - 1] : nft?.primary_media.split('.')[nft?.primary_media.split('.').length - 1]
          }
          this.disableInfiniteScroll = false;
          this.isLoadingData = false;
          this.scrollLoader = false;
        },
        error: (_error) => {
          this.disableInfiniteScroll = false;
          this.isLoadingData = false;
          this.scrollLoader = false;
        }
      })
    }
  }
  /**
     * Sets tooltip
     * @param {any} nft 
     */
  public setTooltip(nft: any) {
    setTimeout(() => {
      nft.forEach((_response: any, index: any) => {
        if ((<HTMLElement>document.getElementById(`tooltiptitle${index}`))?.scrollHeight > 77) {
          (<HTMLElement>document.getElementById(`tooltipdescription${index}`)).style.display = 'block';
          (<HTMLElement>document.getElementById(`tooltiphead${index}`)).classList.add('text-content');

        }
        else {
          if ((<HTMLElement>document.getElementById(`tooltipdescription${index}`))) (<HTMLElement>document.getElementById(`tooltipdescription${index}`)).style.display = 'none';
          if ((<HTMLElement>document.getElementById(`tooltiphead${index}`))) (<HTMLElement>document.getElementById(`tooltiphead${index}`)).classList.remove('text-content');
        }
      })
    }, 1000);
  }

  /**
   * Sets tooltip based on screen size
   * @param {number} index 
   */
  public setTooltipSize(index: number) {
    if ((<HTMLElement>document.getElementById(`tooltiptitle${index}`)).scrollHeight > 77) {
      (<HTMLElement>document.getElementById(`tooltipdescription${index}`)).style.display = 'block';
      (<HTMLElement>document.getElementById(`tooltiphead${index}`)).classList.add('text-content');

    }
    else {
      (<HTMLElement>document.getElementById(`tooltipdescription${index}`)).style.display = 'none';
      (<HTMLElement>document.getElementById(`tooltiphead${index}`)).classList.remove('text-content');
    }
  }

}
