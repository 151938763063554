<div class="sidemenu">
    <ul class="sidemenu-view">
        <li class="dropdown-header">Quick access.</li>
        <li class="navitems ">
            <a class="nav-link" [routerLink]="['/dashboard']" routerLinkActive="active">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                    <path d="M18 2V10M22 6H14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path
                        d="M2 6C2 4.59987 2 3.8998 2.27248 3.36502C2.51217 2.89462 2.89462 2.51217 3.36502 2.27248C3.8998 2 4.59987 2 6 2C7.40013 2 8.1002 2 8.63498 2.27248C9.10538 2.51217 9.48783 2.89462 9.72752 3.36502C10 3.8998 10 4.59987 10 6C10 7.40013 10 8.1002 9.72752 8.63498C9.48783 9.10538 9.10538 9.48783 8.63498 9.72752C8.1002 10 7.40013 10 6 10C4.59987 10 3.8998 10 3.36502 9.72752C2.89462 9.48783 2.51217 9.10538 2.27248 8.63498C2 8.1002 2 7.40013 2 6Z"
                        stroke="currentColor" stroke-width="1.5" />
                    <path
                        d="M2 18C2 16.5999 2 15.8998 2.27248 15.365C2.51217 14.8946 2.89462 14.5122 3.36502 14.2725C3.8998 14 4.59987 14 6 14C7.40013 14 8.1002 14 8.63498 14.2725C9.10538 14.5122 9.48783 14.8946 9.72752 15.365C10 15.8998 10 16.5999 10 18C10 19.4001 10 20.1002 9.72752 20.635C9.48783 21.1054 9.10538 21.4878 8.63498 21.7275C8.1002 22 7.40013 22 6 22C4.59987 22 3.8998 22 3.36502 21.7275C2.89462 21.4878 2.51217 21.1054 2.27248 20.635C2 20.1002 2 19.4001 2 18Z"
                        stroke="currentColor" stroke-width="1.5" />
                    <path
                        d="M14 18C14 16.5999 14 15.8998 14.2725 15.365C14.5122 14.8946 14.8946 14.5122 15.365 14.2725C15.8998 14 16.5999 14 18 14C19.4001 14 20.1002 14 20.635 14.2725C21.1054 14.5122 21.4878 14.8946 21.7275 15.365C22 15.8998 22 16.5999 22 18C22 19.4001 22 20.1002 21.7275 20.635C21.4878 21.1054 21.1054 21.4878 20.635 21.7275C20.1002 22 19.4001 22 18 22C16.5999 22 15.8998 22 15.365 21.7275C14.8946 21.4878 14.5122 21.1054 14.2725 20.635C14 20.1002 14 19.4001 14 18Z"
                        stroke="currentColor" stroke-width="1.5" />
                </svg>

                <div class="tooltipsview"><span class="nav_name">Dashboard. </span></div>
            </a>
        </li>
        <li class="navitems">
            <a class="nav-link" [routerLink]="['/manage-category']" routerLinkActive="active">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M3 6C3 4.34315 4.34315 3 6 3H7C8.65685 3 10 4.34315 10 6V7C10 8.65685 8.65685 10 7 10H6C4.34315 10 3 8.65685 3 7V6Z"
                        stroke="currentColor" stroke-width="1.5" />
                    <path
                        d="M14 6C14 4.34315 15.3431 3 17 3H18C19.6569 3 21 4.34315 21 6V7C21 8.65685 19.6569 10 18 10H17C15.3431 10 14 8.65685 14 7V6Z"
                        stroke="currentColor" stroke-width="1.5" />
                    <path
                        d="M14 17C14 15.3431 15.3431 14 17 14H18C19.6569 14 21 15.3431 21 17V18C21 19.6569 19.6569 21 18 21H17C15.3431 21 14 19.6569 14 18V17Z"
                        stroke="currentColor" stroke-width="1.5" />
                    <path
                        d="M3 17C3 15.3431 4.34315 14 6 14H7C8.65685 14 10 15.3431 10 17V18C10 19.6569 8.65685 21 7 21H6C4.34315 21 3 19.6569 3 18V17Z"
                        stroke="currentColor" stroke-width="1.5" />
                </svg>
                <div class="tooltipsview"><span class="nav_name">Manage Categories.</span> </div>
            </a>
        </li>
        <li class="navitems">
            <a class="nav-link" [routerLink]="['/manage-collection']" routerLinkActive="active">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                    <path d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2"
                        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                    <path
                        d="M4.64856 5.07876C4.78691 4.93211 4.92948 4.7895 5.0761 4.65111M7.94733 2.72939C8.12884 2.6478 8.31313 2.57128 8.5 2.5M2.73172 7.94192C2.64925 8.12518 2.57195 8.31127 2.5 8.5"
                        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12 8V16M16 12H8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
                <div class="tooltipsview"><span class="nav_name">Manage Collections.</span> </div>
            </a>
        </li>
        <li class="navitems">
            <a class="nav-link" [routerLink]="['/manage-nft']" routerLinkActive="active">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M7.5 9C8.32843 9 9 8.32843 9 7.5C9 6.67157 8.32843 6 7.5 6C6.67157 6 6 6.67157 6 7.5C6 8.32843 6.67157 9 7.5 9Z"
                        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
                        stroke="currentColor" stroke-width="1.5" />
                    <path d="M5 20.9999C9.37246 15.775 14.2741 8.88398 21.4975 13.5424" stroke="currentColor"
                        stroke-width="1.5" />
                </svg>
                <div class="tooltipsview"><span class="nav_name">Manage Items. </span></div>
            </a>
        </li>
        <li class="navitems">
            <a class="nav-link" [routerLink]="['/manage-user']" routerLinkActive="active">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M5.18007 15.2964C3.92249 16.0335 0.625212 17.5386 2.63348 19.422C3.6145 20.342 4.7071 21 6.08077 21H13.9192C15.2929 21 16.3855 20.342 17.3665 19.422C19.3748 17.5386 16.0775 16.0335 14.8199 15.2964C11.8709 13.5679 8.12906 13.5679 5.18007 15.2964Z"
                        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M14 7C14 9.20914 12.2091 11 10 11C7.79086 11 6 9.20914 6 7C6 4.79086 7.79086 3 10 3C12.2091 3 14 4.79086 14 7Z"
                        stroke="currentColor" stroke-width="1.5" />
                    <path
                        d="M19.6221 4.56564C19.8457 4.32342 19.9574 4.20232 20.0762 4.13168C20.3628 3.96123 20.7157 3.95593 21.0071 4.1177C21.1279 4.18474 21.2431 4.30244 21.4735 4.53783C21.7039 4.77322 21.8192 4.89092 21.8848 5.01428C22.0431 5.31194 22.038 5.67244 21.8711 5.96521C21.8019 6.08655 21.6834 6.20073 21.4463 6.4291L18.6252 9.14629C18.1759 9.57906 17.9512 9.79545 17.6704 9.90512C17.3896 10.0148 17.081 10.0067 16.4636 9.99057L16.3796 9.98838C16.1917 9.98346 16.0977 9.98101 16.0431 9.91901C15.9885 9.85702 15.9959 9.7613 16.0108 9.56985L16.0189 9.4659C16.0609 8.92706 16.0819 8.65765 16.1871 8.41547C16.2923 8.17328 16.4738 7.97664 16.8368 7.58335L19.6221 4.56564Z"
                        stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                </svg>
                <div class="tooltipsview"><span class="nav_name">Manage Users. </span></div>
            </a>
        </li>

        <li class="navitems">
            <a class="nav-link" [routerLink]="['/manage-delivery']" routerLinkActive="active">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M4 14.0039H6.39482C6.68897 14.0039 6.97908 14.0702 7.24217 14.1975L9.28415 15.1855C9.54724 15.3128 9.83735 15.379 10.1315 15.379H11.1741C12.1825 15.379 13 16.1701 13 17.1459C13 17.1853 12.973 17.22 12.9338 17.2308L10.3929 17.9334C9.93707 18.0594 9.449 18.0155 9.025 17.8103L6.84211 16.7542"
                        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M13 16.5039L17.5928 15.0928C18.407 14.8391 19.2871 15.1399 19.7971 15.8462C20.1659 16.3568 20.0157 17.0881 19.4785 17.3981L11.9629 21.7344C11.4849 22.0102 10.9209 22.0775 10.3952 21.9215L4 20.0238"
                        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M15 12.0039H13C11.1144 12.0039 10.1716 12.0039 9.58579 11.4181C9 10.8323 9 9.88953 9 8.00391V6.00391C9 4.11829 9 3.17548 9.58579 2.5897C10.1716 2.00391 11.1144 2.00391 13 2.00391H15C16.8856 2.00391 17.8284 2.00391 18.4142 2.5897C19 3.17548 19 4.11829 19 6.00391V8.00391C19 9.88953 19 10.8323 18.4142 11.4181C17.8284 12.0039 16.8856 12.0039 15 12.0039Z"
                        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M13 5.00391H15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
                <div class="tooltipsview"><span class="nav_name">Manage Delivery. </span></div>
            </a>
        </li>
        <li class="navitems">
            <a class="nav-link" [routerLink]="['/royalty']" routerLinkActive="active">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M9 22C9.35984 22 10.6908 21.3926 12.0494 20.1778M12.0494 20.1778C13.2078 19.1419 14.3863 17.6643 15 15.7452C16.3333 11.5753 8.33333 15.7452 11 19.2201C11.3281 19.6476 11.6815 19.9601 12.0494 20.1778ZM12.0494 20.1778C13.6521 21.1259 15.5311 20.274 16.8041 19.2944C17.1932 18.995 17.3877 18.8453 17.5038 18.8919C17.62 18.9385 17.6878 19.2064 17.8236 19.7422C18.2581 21.4569 19.5415 22.841 21 20.6105"
                        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M20 13V7.89072C20 6.17637 20 5.31919 19.732 4.63459C19.3013 3.53399 18.3902 2.66585 17.2352 2.25535C16.5168 2 15.6173 2 13.8182 2C10.6698 2 9.09563 2 7.83836 2.44686C5.81714 3.16523 4.22281 4.68448 3.46894 6.61052C3 7.80859 3 9.30864 3 12.3088V14.8859C3 17.9936 3 19.5474 3.8477 20.6265C4.09058 20.9356 4.37862 21.2101 4.70307 21.4416C5.07016 21.7034 5.48961 21.8804 6 22"
                        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M3 12C3 10.159 4.49238 8.66667 6.33333 8.66667C6.99912 8.66667 7.78404 8.78333 8.43137 8.60988C9.00652 8.45576 9.45576 8.00652 9.60988 7.43136C9.78333 6.78404 9.66667 5.99912 9.66667 5.33333C9.66667 3.49238 11.1591 2 13 2"
                        stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <div class="tooltipsview"><span class="nav_name">Royality Release. </span></div>
            </a>
        </li>
        <li class="navitems">
            <a class="nav-link" [routerLink]="['/announcement-detail']" routerLinkActive="active">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                    class="bi bi-megaphone" viewBox="0 0 16 16">
                    <path
                        d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 75 75 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0m-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233q.27.015.537.036c2.568.189 5.093.744 7.463 1.993zm-9 6.215v-4.13a95 95 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A61 61 0 0 1 4 10.065m-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68 68 0 0 0-1.722-.082z" />
                </svg>
                <div class="tooltipsview"><span class="nav_name">Announcement. </span></div>
            </a>
        </li>
    </ul>
    <hr>
    <div class="pt-2"></div>
    <ul class="sidemenu-view">
        <li class="dropdown-header">Quick access.</li>
        <li class="navitems ">
            <a class="nav-link" href="mailto:info@realworld.fi">
                <img loading="lazy" src="assets/images/icon/History.svg" alt="History" class="defualt">
                <img loading="lazy" src="assets/images/icon/HistoryWhite.svg" alt="History" class="active-deafult">
                <div class="tooltipsview"><span class="nav_name">History. </span></div>
            </a>
        </li>
        <li class="navitems ">
            <a class="nav-link" href="mailto:info@realworld.fi">
                <img loading="lazy" src="assets/images/icon/Chat.svg" alt="User chats" class="defualt">
                <img loading="lazy" src="assets/images/icon/ChatWhite.svg" alt="User chats" class="active-deafult">
                <div class="tooltipsview"><span class="nav_name">User chats. </span></div>
            </a>
        </li>
        <li class="navitems ">
            <a class="nav-link" href="mailto:info@realworld.fi">
                <img loading="lazy" src="assets/images/icon/Settings.svg" alt="Settings" class="defualt">
                <img loading="lazy" src="assets/images/icon/SettingsWhite.svg" alt="Settings" class="active-deafult">
                <div class="tooltipsview"><span class="nav_name">Settings. </span></div>
            </a>
        </li>
        <li class="navitems ">
            <a class="nav-link" href="mailto:info@realworld.fi">
                <img loading="lazy" src="assets/images/icon/Help.svg" alt="Help" class="defualt">
                <img loading="lazy" src="assets/images/icon/HelpWhite.svg" alt="Help" class="active-deafult">
                <div class="tooltipsview"><span class="nav_name">Help. </span></div>
            </a>
        </li>
    </ul>
    <hr>
    <div class="pt-3"></div>
</div>