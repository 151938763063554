<div class="main-wrap">
    <app-sidebar class="sidebarview"></app-sidebar>
    <section class="section">
        <div class="container-fluid">
            <div class="managecollection">
                <div class="row mb-4">
                    <div class="col-lg-6">
                        <ul class="manage-collection">
                            <li class="nav-item " [routerLink]="['/manage-user']">All user.</li>
                            <li class="nav-item" [routerLink]="['/manage-user/blocked-user']" routerLinkActive="menuactivities"
                                [routerLinkActiveOptions]="{exact: true}">Blocked user.</li>
                        </ul>
                    </div>
                </div>
                <!--start forms-->
                <div class="row mb-3  align-items-center">
                    <div class="col-lg-6 col-sm-12 mb-3">
                        <label for="label">Search user.</label>
                        <input type="text" class="form-control" placeholder="Enter a User name, Email address or User address" (keyup)="getAllUsers(1,this.currentPageLimit,this.isBlocked,$event.target['value']);">
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <th>User name.</th>
                                    <th>State.</th>
                                    <th>Email address.</th>
                                    <th>User address.</th>
                                    <th>Holdings.</th>
                                    <th *ngIf="isRegulated">KYC upload.</th>
                                    <th>Actions.</th>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="!isLoadingData">
                                    <tr *ngFor="let users of allUserDetails;trackBy: trackByFn" class="cursor-pointer">
                                        <td>
                                            <a [routerLink]="['/manage-user/user-profile', users.wallet_address]">{{users.name}}</a>
                                        </td>
                                        <td [routerLink]="['/manage-user/user-profile', users.wallet_address]"><button class="super-admin">User</button></td>
                                        <td>
                                            <span class="d-flex gap-2">
                                                <a class="user-email-text" href="mailto:{{users.email}}" [routerLink]="['/manage-user/user-profile', users.wallet_address]">{{users.email}}</a>
                                                <span><img loading="lazy" class="cursor-pointer" (click)="copy(users.email)" src="assets/images/copy.svg"></span>
                                            </span>
                                        </td>
                                        <td>
                                            <p class="walletaddress">
                                                <span [routerLink]="['/manage-user/user-profile', users.wallet_address]">
                                                {{users.wallet_address.slice(0,
                                                5)}}...{{users.wallet_address.slice(users.wallet_address.length - 5)}}
                                                </span>
                                                <span><img loading="lazy"  class="cursor-pointer" (click)="copy(users.wallet_address)" src="assets/images/copy.svg"></span>
                                            </p>
                                        </td>
                                        <td class="text-center" [routerLink]="['/manage-user/user-profile', users.wallet_address]">
                                            <p>{{users.nft_holdings}}</p>
                                        </td>
                                        <td *ngIf="isRegulated">
                                            <label class="switch"> <input type="checkbox" (change)="kycUploadStatus(users._id,$event.target['checked'])" [checked]="users.kyc_enable"><span class="slider round"></span></label>
                                        </td>
                                        <td>
                                            <div class="dropdown-end">
                                                <button class="verticalmenu dropdown-toggle" type="button"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="bi bi-three-dots-vertical"></i>
                                                </button>
                                                <ul class="dropdown-menu">
                                                    <li><a class="dropdown-item" [routerLink]="['/manage-user/user-edit', users._id]">Edit</a></li>
                                                    <li><a class="dropdown-item cursor-pointer" (click)="confirmAlert(users._id, users.wallet_address,true)">Unblock</a></li>
                                                    <li><a class="dropdown-item cursor-pointer" (click)="confirmAlert(users._id, users.wallet_address,false)">Delete.</a> </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                                    <tr *ngIf="isLoadingData">
                                        <td colspan="7" class="text-center">
                                            <div class="d-flex align-iems-center justify-content-center my-2">
                                                Loading. <span class="Loaders"></span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="!isLoadingData && (!isTableData || allUserDetailsPaginations?.totalDocs == 0)">
                                        <td colspan="7" class="text-center">
                                            <div class="data-not-found">
                                                <img src="assets/images/data-not-found.svg" alt="data not found" width="320" height="180" class="img-fluid mb-3">
                                                <h6>No data found.</h6>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="row">
                <div class="col-lg-12" *ngIf="!isLoadingData">
                    <div class="d-flex justify-content-end flex-wrap">
                        <div>
                            <button *ngIf="allUserDetailsPaginations?.page != 1" class="previous-btn" (click)="getAllUsers(allUserDetailsPaginations?.page - 1, currentPageLimit, isBlocked)">
                                <span class="me-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M10.5371 3.60462C10.3187 3.30815 9.90141 3.24483 9.60495 3.46317C9.37468 3.63277 9.15579 3.8024 8.96513 3.95118C8.58459 4.24819 8.07486 4.65707 7.56326 5.10131C7.05493 5.54267 6.52939 6.03202 6.12572 6.48888C5.92459 6.71652 5.73959 6.95185 5.60126 7.18132C5.47399 7.39245 5.33366 7.68259 5.33366 7.99999C5.33366 8.31739 5.47399 8.60759 5.60126 8.81872C5.73959 9.04819 5.92459 9.28345 6.12572 9.51112C6.52939 9.96799 7.05493 10.4573 7.56326 10.8987C8.07486 11.3429 8.58459 11.7518 8.96513 12.0489C9.15579 12.1976 9.37468 12.3673 9.60495 12.5369C9.90141 12.7552 10.3187 12.6919 10.5371 12.3954C10.6249 12.2762 10.6671 12.1375 10.667 12.0001L10.667 7.99999V3.99997C10.6671 3.8625 10.6249 3.72379 10.5371 3.60462Z" fill="currentColor"/>
                                    </svg>
                                </span>Prev
                            </button>
                            <button *ngIf="allUserDetailsPaginations?.page == 1" disabled class="previous-btn disble-btn">
                                <span class="me-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M10.5371 3.60462C10.3187 3.30815 9.90141 3.24483 9.60495 3.46317C9.37468 3.63277 9.15579 3.8024 8.96513 3.95118C8.58459 4.24819 8.07486 4.65707 7.56326 5.10131C7.05493 5.54267 6.52939 6.03202 6.12572 6.48888C5.92459 6.71652 5.73959 6.95185 5.60126 7.18132C5.47399 7.39245 5.33366 7.68259 5.33366 7.99999C5.33366 8.31739 5.47399 8.60759 5.60126 8.81872C5.73959 9.04819 5.92459 9.28345 6.12572 9.51112C6.52939 9.96799 7.05493 10.4573 7.56326 10.8987C8.07486 11.3429 8.58459 11.7518 8.96513 12.0489C9.15579 12.1976 9.37468 12.3673 9.60495 12.5369C9.90141 12.7552 10.3187 12.6919 10.5371 12.3954C10.6249 12.2762 10.6671 12.1375 10.667 12.0001L10.667 7.99999V3.99997C10.6671 3.8625 10.6249 3.72379 10.5371 3.60462Z" fill="currentColor"/>
                                    </svg>
                                </span>Prev
                            </button>
                        </div>
                        <div>
                            <nav>
                                <ul class="pagination">
                                    <li class="page-item cursor-pointer"
                                        *ngIf="allUserDetailsPaginations.prevPage != null  && (allUserDetailsPaginations.prevPage - 1) != 0 && (allUserDetailsPaginations.prevPage - 1) != allUserDetailsPaginations.page"
                                        (click)="getAllUsers(1, currentPageLimit, isBlocked)">
                                        <a class="page-link">1</a>
                                    </li>
                                    <li class="page-item cursor-pointer">
                                        <a class="page-link" *ngIf="allUserDetailsPaginations.prevPage > 0"
                                            (click)="getAllUsers(allUserDetailsPaginations.prevPage, currentPageLimit, isBlocked)">{{allUserDetailsPaginations.prevPage}}</a>
                                    </li>
                                    <li class="page-item cursor-pointer"><a class="page-link active">{{allUserDetailsPaginations.page}}</a></li>
                                    <li class="page-item cursor-pointer"><a class="page-link" *ngIf="allUserDetailsPaginations.nextPage > 0"
                                            (click)="getAllUsers(allUserDetailsPaginations.nextPage, currentPageLimit, isBlocked)">{{allUserDetailsPaginations.nextPage}}</a>
                                    </li>
                                    <li class="page-item"
                                        *ngIf="allUserDetailsPaginations.totalPages > allUserDetailsPaginations.nextPage && allUserDetailsPaginations.nextPage != null && (allUserDetailsPaginations.nextPage + 1) != allUserDetailsPaginations.totalPages">
                                        <a class="page-link">..</a>
                                    </li>
                                    <li class="page-item cursor-pointer"
                                        *ngIf="allUserDetailsPaginations.totalPages > allUserDetailsPaginations.nextPage && allUserDetailsPaginations.nextPage != null"
                                        (click)="getAllUsers(allUserDetailsPaginations.totalPages, currentPageLimit, isBlocked)">
                                        <a class="page-link">{{allUserDetailsPaginations.totalPages}}</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div>
                            <button *ngIf="allUserDetailsPaginations?.page != allUserDetailsPaginations?.totalPages" class="next-btn" (click)="getAllUsers(allUserDetailsPaginations?.page + 1, currentPageLimit, isBlocked)">Next 
                                <span class="ms-1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M5.46292 3.60462C5.68126 3.30815 6.09859 3.24483 6.39505 3.46317C6.62532 3.63277 6.84421 3.8024 7.03487 3.95118C7.41541 4.24819 7.92514 4.65707 8.43674 5.10131C8.94507 5.54267 9.47061 6.03202 9.87428 6.48888C10.0754 6.71652 10.2604 6.95185 10.3987 7.18132C10.526 7.39245 10.6663 7.68259 10.6663 7.99999C10.6663 8.31739 10.526 8.60759 10.3987 8.81872C10.2604 9.04819 10.0754 9.28345 9.87428 9.51112C9.47061 9.96799 8.94507 10.4573 8.43674 10.8987C7.92514 11.3429 7.41541 11.7518 7.03487 12.0489C6.84421 12.1976 6.62532 12.3673 6.39505 12.5369C6.09859 12.7552 5.68126 12.6919 5.46292 12.3954C5.37514 12.2762 5.33289 12.1375 5.33301 12.0001L5.33301 7.99999V3.99997C5.33289 3.8625 5.37514 3.72379 5.46292 3.60462Z" fill="currentColor"/>
                                  </svg></span>
                            </button>
                            <button *ngIf="allUserDetailsPaginations?.page == allUserDetailsPaginations?.totalPages" disabled class="next-btn disble-btn">Next 
                                <span class="ms-1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M5.46292 3.60462C5.68126 3.30815 6.09859 3.24483 6.39505 3.46317C6.62532 3.63277 6.84421 3.8024 7.03487 3.95118C7.41541 4.24819 7.92514 4.65707 8.43674 5.10131C8.94507 5.54267 9.47061 6.03202 9.87428 6.48888C10.0754 6.71652 10.2604 6.95185 10.3987 7.18132C10.526 7.39245 10.6663 7.68259 10.6663 7.99999C10.6663 8.31739 10.526 8.60759 10.3987 8.81872C10.2604 9.04819 10.0754 9.28345 9.87428 9.51112C9.47061 9.96799 8.94507 10.4573 8.43674 10.8987C7.92514 11.3429 7.41541 11.7518 7.03487 12.0489C6.84421 12.1976 6.62532 12.3673 6.39505 12.5369C6.09859 12.7552 5.68126 12.6919 5.46292 12.3954C5.37514 12.2762 5.33289 12.1375 5.33301 12.0001L5.33301 7.99999V3.99997C5.33289 3.8625 5.37514 3.72379 5.46292 3.60462Z" fill="currentColor"/>
                                  </svg></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>