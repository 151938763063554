<div class="main-wrap">
    <app-sidebar class="sidebarview"></app-sidebar>
    <section class="section">
        <div class="container-fluid">
            <div class="managecollection">
                <div class="row">
                    <div class="profile-view">
                        <div class="personal-details mb-4">
                            <h3>User Edit.</h3>
                            <div class="personal-info mt-4">
                                <form [formGroup]="editForm" (ngSubmit)="editFormSubmit()">
                                    <div class="row mb-lg-4 mb-sm-2">
                                        <div class="col-lg-5 mb-4">
                                            <label for="label">Name <span class="ms-2 text-danger">*</span></label>
                                            <input type="text" class="form-control" placeholder="Name"
                                                formControlName="name">
                                            <div *ngIf="editFormSubmitted && editFormControls.name.errors"
                                                class="invalid-feedback-custom">
                                                <small class="text-danger"
                                                    *ngIf="editFormControls.name.errors.required">Please enter
                                                    name.</small>
                                            </div>
                                        </div>

                                        <div class="col-lg-5 mb-4">
                                            <label for="label">Email <span class="ms-2 text-danger">*</span></label>
                                            <input type="email" class="form-control" placeholder="Email"
                                                formControlName="email">
                                            <div *ngIf="editFormSubmitted && editFormControls.email.errors"
                                                class="invalid-feedback-custom">
                                                <small class="text-danger"
                                                    *ngIf="editFormControls.email.errors.required">Please enter
                                                    email.</small>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 my-2">
                                            <span style="visibility: hidden;">jhj</span>
                                        <button class="submit-btn" type="submit" [disabled]="editFormLoader || editForm.invalid">
                                            <span *ngIf="!editFormLoader">Update.</span>
                                            <span *ngIf="editFormLoader">Processing...</span>
                                        </button>
                                    </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>