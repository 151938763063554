import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TagInputComponent } from 'ngx-chips';
import { Editor, Toolbar } from 'ngx-editor';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { CommonContractService } from 'src/app/services/common-contract.service';
import { LogDebugsService } from 'src/app/services/log-debugs.service';
import { NftManagementService } from 'src/app/services/nft-management.service';
import { RoyaltyManagementService } from 'src/app/services/royalty-management.service';
import { StorageService } from 'src/app/services/storage.service';
import { CopyDirective } from 'src/app/shared/directives/copy.directive';
import { ErrorHandlerDirective } from 'src/app/shared/directives/error-handler.directive';
import { ManageTransactionsDirective } from 'src/app/shared/directives/manage-transactions.directive';
import { walletAccount } from 'src/app/shared/interface/interface';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';

const ethereum = window['ethereum'] as any
let web3 = new Web3(window['ethereum'] as any);
const apiUrl = environment.API_BASE_URL;

@Component({
    selector: 'app-create-collection',
    templateUrl: './create-collection.component.html',
    styleUrls: ['./create-collection.component.scss']
})
export class CreateCollectionComponent {
    public collectionForm: FormGroup;
    @ViewChild('collectionSuccessModal') collectionSuccessModal: { show: () => void; hide: () => void; };
    public collectionFormSubmitted: boolean = false;
    public createCollectionLoader: boolean = false;
    public account: walletAccount;
    public splitPattern = /[,; ]/;
    public collectionName: string = "";
    public collectionAddress: string = "";
    public transHash: string = '';
    public transhUrl: string = '';
    public urlAddress: string = '';
    public chipData: Array<object>;
    public collectionDetails: Array<object>;
    public isLazyMint: boolean = false;
    public transMintHash: string = '';
    public locationDetails: Array<any>;
    categoryDetails: Array<any>;
    categoryData: string = '';
    editor: Editor;
    html = '';
    toolbar: Toolbar = [
        ['bold', 'italic'],
        ['underline'],
        ['ordered_list', 'bullet_list'],
        [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
        ['align_left', 'align_center', 'align_right', 'align_justify'],
    ];
    @ViewChild('tagInput') tagInput: TagInputComponent;


    /**
     * Creates an instance of collections component.
     */
    constructor(
        private nftManagementService: NftManagementService,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private storageService: StorageService,
        private accountService: AccountService,
        private route: ActivatedRoute,
        private router: Router,
        private royaltyService: RoyaltyManagementService,
        private copyDirective: CopyDirective,
        private commonservice: CommonContractService,
        private manageTransactionsDirective: ManageTransactionsDirective,
        private logDebgsService: LogDebugsService,
        private errorHandler: ErrorHandlerDirective
    ) { }

    /**
     * on init
     */
    public ngOnInit(): void {
        this.editor = new Editor();
        this.commonservice.closeModalsObservable.subscribe((response: boolean) => {
            if (response) {
                this.collectionSuccessModal.hide();
            }
        });

        this.collectionForm = this.formBuilder.group({
            collectionType: ['', [Validators.required]],
            royaltyFee: ['', [Validators.required]],
            name: ['', [Validators.required]],
            symbol: ['', [Validators.required]],
            baseUrl: ['', [Validators.required]],
            clientAddress: ['', [Validators.required]],
            attribute: [''],
            description: [''],
            lazyMintNftDetails: [''],
            location: [''],
            category: [''],
        });
        this.urlAddress = this.route.snapshot.paramMap.get('address') as any;
        this.accountService.accountObserve.subscribe((response) => {
            if (response) {
                // this.account = response;
            }
        });

        this.account = this.storageService.getItem('wagmi.store') === null ?
            { address: '', network: '', chainId: '', provider: '' } :
            JSON.parse(this.storageService.getItem('wagmi.store') as any);

        if (this.account.state.data.account) {
            this.transhUrl = environment[this.account.state.data.chain.id].EXPLORER;

            this.checkWhitelist();

        }

        this.getNftTriats();
        this.collectionForm.patchValue({
            collectionType: 'ERC721',
            baseUrl: environment.BASE_URL,
        });
        if (this.urlAddress) {
            this.checkCollectionAddress();
            this.nftManagementService.getCollectionName(this.urlAddress).then((collectionAddressResponse) => {
                this.collectionForm.patchValue({
                    name: collectionAddressResponse,
                });
            });
            this.nftManagementService.getSymbol(this.urlAddress).then((collectionSymbolResponse) => {
                this.collectionForm.patchValue({
                    symbol: collectionSymbolResponse,
                });
            });
            this.royaltyService.getSplitterContractAddress(this.urlAddress).then(async (paymentSplitterAddress: any) => {
                const clientAddress = await this.royaltyService.payee(paymentSplitterAddress, 1) as any;
                this.clientAddressChange(clientAddress);
                this.collectionForm.patchValue({
                    clientAddress: clientAddress,
                });
            });
        }

        this.accountService.accountObserve.subscribe((response) => {
            if (response) {
                this.account = this.storageService.getItem('wagmi.store') === null ?
                    { address: '', network: '', chainId: '', provider: '' } :
                    JSON.parse(this.storageService.getItem('wagmi.store') as any);
                this.transhUrl = environment[this.account.state.data.chain.id].EXPLORER;
                this.checkWhitelist();
                if (this.urlAddress) {
                    this.checkCollectionAddress();
                    this.nftManagementService.getCollectionName(this.urlAddress).then((collectionAddressResponse) => {
                        this.collectionForm.patchValue({
                            name: collectionAddressResponse,
                        });
                    });
                    this.nftManagementService.getSymbol(this.urlAddress).then((collectionSymbolResponse) => {
                        this.collectionForm.patchValue({
                            symbol: collectionSymbolResponse,
                        });
                    });
                    this.royaltyService.getSplitterContractAddress(this.urlAddress).then(async (paymentSplitterAddress: any) => {
                        const clientAddress = await this.royaltyService.payee(paymentSplitterAddress, 1) as any;
                        this.clientAddressChange(clientAddress);
                        this.collectionForm.patchValue({
                            clientAddress: clientAddress,
                        });
                    });
                }
            }
        });

    }

    /**
     * Checks whitelist
     */
    public checkWhitelist() {
        this.nftManagementService.whiteListed(this.account.state.data.account).then((isWhitlisted => {
            if (!isWhitlisted) {
                this.toastr.error("Your address is not whitelisted. Please contact your admin.");
                this.router.navigate(['/']);
                return;
            }
        })).catch((err) => console.log("error : ", err));
    }

    /**
     * Gets mandatory attributes
     */
    public async getNftTriats() {
        this.nftManagementService.getNftTriats('', false).subscribe({
            next: async (data: any) => {
                let attributesArray: any[] = [];
                let attributes = data.data
                const mandatoryAttributes = attributes.map(object => object.attribute);
                this.locationDetails = data.data.filter((object) => object.attribute === "Location");
                this.categoryDetails = data.data.filter((object) => object.attribute === "Category");
                this.collectionForm.patchValue({
                    location: 'London'
                })
                this.chipData = [];
                let param;
                if (this.urlAddress) {
                    await this.nftManagementService.getCollectionAttributes(this.urlAddress).then(async (collectionAttributesResponse) => {
                        attributesArray = await collectionAttributesResponse;
                    });
                    attributesArray.forEach(element => {
                        if (mandatoryAttributes.includes(element)) {
                            param = { display: element, value: element, readonly: true }
                            this.chipData.push(param);
                        } else {
                            param = { display: element, value: element, readonly: false }
                            this.chipData.push(param);
                        }
                    });
                    this.nftManagementService.getCollectionDb(this.urlAddress).subscribe((response: any) => {
                        this.collectionDetails = response;
                        this.collectionForm.patchValue({
                            description: (this.collectionDetails as any)['data'].description,
                        })
                        this.logDebgsService.debugs(`${apiUrl}admin/collection-by-address?address=${this.urlAddress}`, response, 'get', 'success');
                        if (this.collectionDetails['data'].isLazyMint) {
                            this.toastr.error('Edit option not available for lazy mint collections');
                            this.router.navigate(['/manage-collection']);
                        }
                    },
                        (error: any) => {
                            this.logDebgsService.debugs(`${apiUrl}admin/collection-by-address?address=${this.urlAddress}`, error, 'get', 'error');
                            this.router.navigate(['/manage-collection']);
                            this.toastr.error(error.error.message);
                            return;
                        });

                } else {
                    attributes.forEach((element: { attribute: any; }) => {
                        param = { display: element.attribute, value: element.attribute, readonly: true }
                        this.chipData.push(param);
                    });
                }
                this.collectionForm.patchValue({ attribute: this.chipData });
            },
            error: (error: any) => {
                this.toastr.error(error.error.message);
            },
        });
    }

    /**
     * Updates lazy mint
     */
    async updateLazyMint() {
        this.isLazyMint = !this.isLazyMint
        if (!this.isLazyMint) {
            this.collectionForm.get('location').clearValidators();
            this.collectionForm.get('category').clearValidators();
            this.collectionForm.get('lazyMintNftDetails').clearValidators();
            this.collectionForm.get('location').updateValueAndValidity();
            this.collectionForm.get('category').updateValueAndValidity();
            this.collectionForm.get('lazyMintNftDetails').updateValueAndValidity();
            this.getNftTriats();

        }
        else {
            this.collectionForm.get('location').setValidators([Validators.required]);
            this.collectionForm.get('category').setValidators([Validators.required]);
            this.collectionForm.get('lazyMintNftDetails').setValidators([Validators.required]);
            this.collectionForm.get('location').updateValueAndValidity();
            this.collectionForm.get('category').updateValueAndValidity();
            this.collectionForm.get('lazyMintNftDetails').updateValueAndValidity();
        }
    }
    /**
    * Creates collections submit
    */
    public async createCollectionsSubmit() {
        this.collectionFormSubmitted = true;
        if (this.collectionForm.invalid) {
            return;
        }
        this.createCollectionLoader = true;
        let addressTrim = await this.collectionForm.controls['clientAddress'].value.trim()

        // Validation to check if connected address and client address are not same
        // TODO - Need to remove this validation after contract side change
        if (web3.utils.toChecksumAddress(addressTrim) === this.account.state.data.account) {
            this.toastr.error('Unable to use your wallet address as client address.');
            this.createCollectionLoader = false;
            return
        }

        let attributes: any[] = [];
        await this.collectionForm.controls['attribute'].value.forEach((element: { value: any; display?: any; }) => {
            if (element.value == undefined || element.value == '' || element.value == null) {
                element = { display: element, value: element }
            }
            attributes.push(element.value);
        });

        try {
            // creating
            if (!this.urlAddress) {

                await this.validateCollection(this.collectionForm.controls.name.value, this.collectionForm.controls.symbol.value);

            }
            // editing
            if (this.urlAddress) {
                if (this.collectionForm.controls['description'].value !== this.collectionDetails['data'].description) {
                    await this.updateDescription(this.collectionForm.controls['description'].value, attributes)
                }
                else {
                    this.setAttributes(this.urlAddress, attributes)
                }
            }
            else {
                const { name, symbol, clientAddress, description } = this.collectionForm.controls;
                this.collectionName = name.value;
                const data = {
                    tokenName: name.value,
                    tokenSymbol: symbol.value,
                    receiver: clientAddress.value,
                    description: description.value,
                    attributes: attributes
                };
                try {

                    const { createCollectionAbi, requiredGas } = await this.nftManagementService.creatCollection(data, this.account.state.data.account, this.isLazyMint);
                    const params = {
                        account: this.account.state.data.account,
                        to: environment[this.account.state.data.chain.id].FACTORY_ADDRESS,
                        data: createCollectionAbi,
                        gasPrice: await web3.utils.toHex(Number(await web3.eth.getGasPrice()) * 2),
                        gas: Number(requiredGas) * 2,
                    };
                    this.manageTransactionsDirective.makeTransactions(params)
                        .then(async (receipt: { [x: string]: { address: any; }; }) => {
                            this.collectionAddress = web3.utils.toChecksumAddress(receipt['data']['logs'][2].address);
                            this.transMintHash = receipt['data']['transactionHash'];
                            // if (!this.isLazyMint) {
                            //   this.setAttributes(this.collectionAddress, attributes);
                            // }
                            // else {
                            const { name, symbol, clientAddress, collectionType, royaltyFee, baseUrl, lazyMintNftDetails, location, category } = this.collectionForm.controls;
                            let minting_fee: any
                            await this.nftManagementService.collectionPlatformFee(this.collectionAddress).then((platformFeeResponse: any) => {
                                minting_fee = (platformFeeResponse.toString() / 100)
                            });
                            let data = {
                                collection_type: collectionType.value,
                                collection_address: this.collectionAddress,
                                royalty_fee: royaltyFee.value,
                                name: name.value,
                                symbol: symbol.value,
                                base_url: baseUrl.value,
                                owner_address: clientAddress.value,
                                collection_owner_address: this.account.state.data.account,
                                attributes: attributes,
                                isLazyMint: this.isLazyMint,
                                lazyMintNftDetails: lazyMintNftDetails.value,
                                minting_fee: minting_fee,
                                description: description.value,
                            }
                            if (this.isLazyMint) {
                                delete data.attributes;
                                data['location'] = location.value;
                                data['category'] = category.value;
                            }
                            this.createCollectionApiProcess(data, receipt['data']);
                            // }
                        })
                        .catch((error: any) => {
                            this.createCollectionLoader = false;
                        });
                } catch (error) {
                    console.log(error);

                }
            }
        } catch (error) {
            this.errorHandler.handleError(error);
            this.createCollectionLoader = false;
        }
    }

    /**
     * Updates description
     * @param {description} description
     * @param {array} attributes
     */
    public async updateDescription(description: string, attributes: any[]) {
        try {
            const { createAttributesAbi, requiredGas } = await this.nftManagementService.updateCollectionDescription(this.account.state.data.account, this.urlAddress, description);
            const message = {
                to: this.urlAddress,
                data: createAttributesAbi,
                gasPrice: await web3.utils.toHex(Number(await web3.eth.getGasPrice()) * 2),
                gasLimit: await web3.utils.toHex(Number(requiredGas) * 2)
            };
            this.manageTransactionsDirective.makeTransactions(message)
                .then(async (_response: any) => {
                    this.setAttributes(this.urlAddress, attributes)
                })
                .catch((error: { code: any; }) => {
                    this.createCollectionLoader = false;

                });
        }
        catch (error) {
            this.errorHandler.handleError(error);
            this.createCollectionLoader = false;

        }

    }
    /**
     * Sets attributes
     * @param {string} collectionAddress
     * @param {array} attributes
     */
    public async setAttributes(collectionAddress: string, attributes: any[]) {
        try {

            const { createAttributesAbi, requiredGas } = await this.nftManagementService.setCollectionAttributes(this.account.state.data.account, collectionAddress, attributes);
            const message = {
                to: environment[this.account.state.data.chain.id].FACTORY_ADDRESS,
                data: createAttributesAbi,
                gasPrice: await web3.utils.toHex(Number(await web3.eth.getGasPrice()) * 2),
                gasLimit: await web3.utils.toHex(Number(requiredGas) * 2)
            };
            this.manageTransactionsDirective.makeTransactions(message)
                .then(async (receipt: any) => {
                    const { name, symbol, clientAddress, collectionType, royaltyFee, baseUrl, lazyMintNftDetails, description } = this.collectionForm.controls;
                    let minting_fee: any
                    await this.nftManagementService.collectionPlatformFee(collectionAddress).then((platformFeeResponse: any) => {
                        minting_fee = (platformFeeResponse.toString() / 100)
                    });
                    // backend accepts only description and attributes while editing
                    let data = {
                        // collection_type: collectionType.value,
                        // collection_address: collectionAddress,
                        // royalty_fee: royaltyFee.value,
                        // name: name.value,
                        // symbol: symbol.value,
                        // base_url: baseUrl.value,
                        // owner_address: clientAddress.value,
                        // collection_owner_address: this.account.state.data.account,
                        attributes: attributes,
                        // isLazyMint: this.isLazyMint,
                        // lazyMintNftDetails: lazyMintNftDetails.value,
                        // minting_fee: minting_fee,
                        description: description.value
                    }
                    this.createCollectionApiProcess(data, receipt['data']);
                })
                .catch((error: { code: any; }) => {

                    this.createCollectionLoader = false;

                });
        }
        catch (error) {
            this.errorHandler.handleError(error);
            this.createCollectionLoader = false;

        }
    }

    /**
     * Creates collection api process
     * @param {object} data
     * @param {any} receipt
     */
    public createCollectionApiProcess(data: object, receipt: any) {
        let collectionSubscribe;
        let apiEndPoint;
        let apiMethod;
        if (!this.urlAddress) {
            data['transaction_hash'] = receipt['transactionHash']
            collectionSubscribe = this.nftManagementService.createCollectionDb(data);
            apiEndPoint = `${apiUrl}admin/collection`;
            apiMethod = "post";
        }
        else {
            collectionSubscribe = this.nftManagementService.updateCollectionDb(data, this.collectionDetails['data']._id);
            apiEndPoint = `${apiUrl}admin/collection?id=${this.collectionDetails['data']._id}`;
            apiMethod = "patch";
        }
        collectionSubscribe.subscribe((response: any) => {
            if (!this.urlAddress) {
                this.tagInput.inputForm.input.nativeElement.value = '';
                this.collectionForm.reset();
            }
            this.collectionForm.patchValue({
                collectionType: 'ERC721',
                baseUrl: environment.BASE_URL,
            });
            this.transHash = receipt.transactionHash;
            this.storageService.setItem('collectionAddress', data['collection_address'])
            this.collectionSuccessModal.show();
            this.createCollectionLoader = false;

            this.logDebgsService.debugs(apiEndPoint, response, apiMethod, 'success', data);
        },
            (error: any) => {
                this.createCollectionLoader = false;
                this.errorHandler.handleError(error);
                this.logDebgsService.debugs(apiEndPoint, error, apiMethod, 'error', data);
            }
        )
    }
    /**
     * Collection FormGet
     */
    get collectionFormGet() {
        return this.collectionForm.controls;
    }

    /**
     * Copys collection address
     * @param {string} address
     */
    public copyCollectionAddress(address: string) {
        this.copyDirective.copy(address)
    }

    /**
     * Checks collection address
     */
    public checkCollectionAddress() {
        this.nftManagementService.getUserCreatedCollections(this.account.state.data.account).then(async (response) => {
            let web3 = new Web3(window['ethereum'] as any || environment[ethereum as any].chainId.PROVIDER);
            const collectionAddressList = await response;
            const inputAddress = this.urlAddress.trim();
            let collectionAddress = web3.utils.toChecksumAddress(inputAddress);
            this.nftManagementService.isCollectionDestroyed(collectionAddress).then((response => {
                if (response) {
                    this.toastr.error("Collection destroyed");
                    this.router.navigate(['/manage-collection']);
                    return;
                }
                else {
                    if (!collectionAddressList.includes(collectionAddress)) {
                        this.toastr.error("Collection address not found.");
                        this.router.navigate(['/manage-collection']);
                        return;
                    }
                }
            }))

        }).
            catch((error: any) => {
                if ((error.toString()).includes('is not a valid Ethereum address')) {
                    this.toastr.error("Collection address not found.");
                    this.router.navigate(['/manage-collection']);
                }
            })
    }

    /**
     * Closes collection modal
     */
    public closeCollectionModal() {
        if (!this.urlAddress) {
            this.collectionFormSubmitted = false;
            this.collectionForm.patchValue({
                name: '',
                symbol: '',
                clientAddress: '',
                attribute: this.chipData,
                description: ''
            });
        }
        this.collectionSuccessModal.hide();
    }

    /**
     * Connects wallet
     */
    public connectWallet() {
        this.ngOnInit()
        const data = {
            status: true,
        };
        this.accountService.openWallet(data);
    }


    /**
     * Client address change event
     * - gets the royalty fee for client address
     * - patch royaltyFee value in collectionForm
     *
     * @param {string} clientAddress
     */
    public clientAddressChange(clientAddress: string) {
        this.nftManagementService.royaltyFee(web3.utils.toChecksumAddress(clientAddress)).then((feeResponse) => {
            this.collectionForm.patchValue({
                royaltyFee: feeResponse['royaltyFee'] / 100,
            });
        });
    }

    /**
     * Clears form
     */
    public clearForm() {
        this.tagInput.inputForm.input.nativeElement.value = '';
        if (!this.urlAddress) {
            this.collectionForm.patchValue({
                name: '',
                symbol: '',
                clientAddress: '',
                royaltyFee: '',
                description: ''
            });
        }
        this.collectionForm.patchValue({ attribute: this.chipData });
        this.collectionFormSubmitted = false;
    }

    /**
     * Create nft url redirections
     * @param {string} collectionAddress
     */
    public createNft(collectionAddress: string) {
        this.collectionSuccessModal.hide()
        this.router.navigate(['/manage-nft/create-nft', collectionAddress]);
    }

    /**
   * Validates csv file
   * @param {string} csvUrl
   * @param {string} name
   * @param {string} symbol
   */
    public validateCollection(name: string, symbol: string, csvUrl?: string) {
        return new Promise((resolve, reject) => {
            const data = {
                url: csvUrl,
                collection_name: name,
                collection_symbol: symbol
            }
            this.nftManagementService.validateCollection(data).subscribe({
                next: (response) => {
                    resolve({ status: true, data: response })
                    this.logDebgsService.debugs(`${apiUrl}admin/validate-api`, response, 'post', 'success', data);
                },
                error: ((error) => {
                    this.createCollectionLoader = false;
                    reject({ status: false, data: error?.error })
                    this.logDebgsService.debugs(`${apiUrl}admin/validate-api`, error, 'post', 'success', data);
                })
            });
        });
    }
}
