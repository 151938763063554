import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage.service';
import { AccountService } from 'src/app/services/account.service';
import { RoyaltyManagementService } from 'src/app/services/royalty-management.service';
import { ToastrService } from 'ngx-toastr';
import Web3 from 'web3';
import { NftManagementService } from 'src/app/services/nft-management.service';
import { ManageTransactionsDirective } from 'src/app/shared/directives/manage-transactions.directive'
import { walletAccount } from 'src/app/shared/interface/interface';
import { ErrorHandlerDirective } from 'src/app/shared/directives/error-handler.directive';

let web3 = new Web3(window['ethereum'] as any);

@Component({
  selector: 'app-royalty',
  templateUrl: './royalty.component.html',
  styleUrls: ['./royalty.component.scss']
})
export class RoyaltyComponent implements OnInit {

  public royaltyForm: UntypedFormGroup;
  @ViewChild('collectionSuccessModal') collectionSuccessModal;
  public royaltyFormSubmitted: boolean = false;
  public searchCollectionLoader: boolean = false;
  public account: walletAccount;
  public attribute: any = [];
  public currencyData: any = [];
  public splitPattern = /[,; ]/;
  public collectionName: string = "";
  public collectionAddress: string = "";
  public transhUrl: any;
  public urlAddress: string = '';
  public shareInPercentage: number = 0;
  public releaseTokenRoyaltyCurrentIndex: number = 0;
  public isShareHolder: boolean = false;
  public disableAll: boolean = false;
  public amountInNative: any;
  public splitterContractAddress: any;
  public releaseRoyaltyTriggered: boolean = false;
  public transactionHash: string = '';
  public claimedAmount: any;
  public currentRoyalty: any;
  public userTokenRoyaltyData: any;
  public releaseStatus: boolean = false;
  public releaseTokenRoyaltyProcessing: boolean = false
  collectionsAddressList: any = [];


  /**
   * Creates an instance of collections component.
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    private storageService: StorageService,
    private accountService: AccountService,
    private royaltyService: RoyaltyManagementService,
    private nftManagementService: NftManagementService,
    private toastr: ToastrService,
    private manageTransactionsDirective:ManageTransactionsDirective,
    private errorHandler: ErrorHandlerDirective
  ) { }

  /**
   * on init
   */
  public async ngOnInit() {
    this.account = this.storageService.getItem('wagmi.store') === null ?
      { address: '', network: '', chainId: '', provider: '' } :
      JSON.parse(this.storageService.getItem('wagmi.store') as any);;
    if (this.account.state.data.account) {
      this.transhUrl = environment[this.account.state.data.chain.id].EXPLORER
    }
    this.royaltyForm = this.formBuilder.group({
      collectionAddress: ['', [Validators.required]],
    });
    this.getCurrencyData();
    this.getCollections();

    this.accountService.accountObserve.subscribe((response) => {
      if (response) {
        this.account = this.storageService.getItem('wagmi.store') === null ?
          { address: '', network: '', chainId: '', provider: '' } :
          JSON.parse(this.storageService.getItem('wagmi.store') as any);
        this.getCurrencyData();
        this.getCollections();
        if (this.royaltyForm.controls['collectionAddress'].value != '') {
          this.royaltyReleaseSubmit(this.royaltyForm.controls['collectionAddress'].value);
        }
      }
    });

  }

  /**
   * Gets collections
   */
  getCollections(){
    this.nftManagementService.getCollections(this.account.state.data.account).subscribe((response:any) => {
      this.collectionsAddressList = response.data;
    }
  )
  }

  /**
   * Gets currency data
   */
  public getCurrencyData() {
    this.royaltyService.getCurrencies().subscribe({
      next: (res: any) => {
        this.currencyData = res.data.filter((currencyData) =>  currencyData.chain_id === this.account.state.data.chain.id );
      },
      error: (err) => {
        console.log(err)
      }
    })
  }

   

  /**
  * Creates collections submit
  */
  public async royaltyReleaseSubmit(address:string) {
    if(address){
    this.searchCollectionLoader = true;
    this.collectionAddress = address;
    const isValidColection = await this.nftManagementService.isValidCollection(this.collectionAddress) as any;
    await this.getCollectionDetailsFromDB(this.collectionAddress);
    if (web3.utils.toChecksumAddress(isValidColection) != web3.utils.toChecksumAddress(environment[this.account.state.data.chain.id].FACTORY_ADDRESS)) {
      this.toastr.error("Collection Address not found.");
      this.searchCollectionLoader = false;
      return;
    }
    const collectionAddress = web3.utils.toChecksumAddress(this.collectionAddress);
    this.nftManagementService.isCollectionDestroyed(collectionAddress).then((destroyedResponse) => {
      if (destroyedResponse) {
        this.toastr.error("Collection destroyed.");
        this.searchCollectionLoader = false;
        return;
      }
    });
    this.splitterContractAddress = await this.royaltyService.getSplitterContractAddress(this.collectionAddress);
    for (let index = 0; index < 2; index++) {
      const getShareAddress = await this.royaltyService.payee(this.splitterContractAddress, index) as any;
      if (getShareAddress == this.account.state.data.account) {
        this.isShareHolder = true;
      }
    }
    if (!this.isShareHolder) {
      this.toastr.error('You are not a royalty share holder.');
      this.searchCollectionLoader = false;
      return;
    }
    this.shareInPercentage = await this.royaltyService.shares(this.splitterContractAddress, this.account.state.data.account) as any;
    this.claimedAmount = await this.royaltyService.claimedRoyalty(this.splitterContractAddress, this.account.state.data.account);
    this.currentRoyalty = await this.royaltyService.releaseableAmount(this.splitterContractAddress, this.account.state.data.account);
    await this.getCurrencyData()
    this.userTokenRoyaltyData = await this.royaltyService.userTokenRoyalty(this.splitterContractAddress, this.account.state.data.account, this.currencyData);
    if (this.currentRoyalty > 0) {
      this.releaseStatus = true;
    } else {
      this.releaseStatus = false;
    }
    this.searchCollectionLoader = false;
    this.royaltyFormSubmitted = true;
  }
  else{
    this.royaltyFormSubmitted = false;
  }
  }

  /**
   * Releases royalty
   */
  public async releaseRoyalty() {
    try{
    this.releaseRoyaltyTriggered = true;
    this.disableAll = true;
    const { releaseAbi, requiredGas } = await this.royaltyService.release(this.splitterContractAddress, this.account.state.data.account);
    const message = {
      method: 'eth_sendTransaction',
      from: this.account.state.data.account,
      to: this.splitterContractAddress,
      data: releaseAbi,
      chainId: this.account.state.data.chain.id,
      gasPrice: await web3.utils.toHex(Number(await web3.eth.getGasPrice()) * 2),
      gasLimit: await web3.utils.toHex(Number(requiredGas) * 2)
    };
    this.manageTransactionsDirective.makeTransactions(message)
      .then(async (receipt) => {
        this.shareInPercentage = await this.royaltyService.shares(this.splitterContractAddress, this.account.state.data.account) as any;
        this.claimedAmount = await this.royaltyService.claimedRoyalty(this.splitterContractAddress, this.account.state.data.account);
        this.currentRoyalty = await this.royaltyService.releaseableAmount(this.splitterContractAddress, this.account.state.data.account);
        this.releaseRoyaltyTriggered = false;
        this.disableAll = false;
        if (this.currentRoyalty > 0) {
          this.releaseStatus = true;
        } else {
          this.releaseStatus = false;
        }
        this.transactionHash = receipt['transactionHash'];
        this.toastr.success("Royalty Released Successfully");
      })
      .catch((_error) => {
        this.releaseRoyaltyTriggered = false;
        this.disableAll = false;
      });
    }
    catch(error){
      this.errorHandler.handleError(error);
      this.releaseRoyaltyTriggered = false;
      this.disableAll = false;
      
    }
  }

  /**
   * Releases token royalty
   * @param {string} tokenAddress 
   * @param {number} index 
   */
  public async releaseTokenRoyalty(tokenAddress: string, index: number) {
    try {
      this.releaseTokenRoyaltyProcessing = true
      this.releaseTokenRoyaltyCurrentIndex = index
      this.disableAll = true;
      const { releaseAbi, requiredGas } = await this.royaltyService.releaseTokenRoyalty(this.splitterContractAddress, this.account.state.data.account, tokenAddress);
      const message = {
        method: 'eth_sendTransaction',
        from: this.account.state.data.account,
        to: this.splitterContractAddress,
        data: releaseAbi,
        chainId: this.account.state.data.chain.id,
        gasPrice: await web3.utils.toHex(Number(await web3.eth.getGasPrice()) * 2),
        gasLimit: await web3.utils.toHex(Number(requiredGas) * 2)
      };
      this.manageTransactionsDirective.makeTransactions(message)
        .then(async (receipt) => {
          await this.getCurrencyData()
          this.userTokenRoyaltyData = await this.royaltyService.userTokenRoyalty(this.splitterContractAddress, this.account.state.data.account, this.currencyData);
          this.releaseTokenRoyaltyProcessing = false
          this.disableAll = false;
          this.transactionHash = receipt['transactionHash'];
          this.toastr.success("Royalty released successfully.");
        })
        .catch((_error) => {
          this.releaseTokenRoyaltyProcessing = false
          this.disableAll = false;
        });
    }
    catch (error) {
      this.errorHandler.handleError(error);
      this.releaseRoyaltyTriggered = false;
      this.disableAll = false;
      
    }
  }

  /**
   * Backs to search
   * @returns  
   */
  public backToSearch() {
    this.royaltyFormSubmitted = false;
    return;
  }

  /**
   * Collection FormGet
   */
  get royaltyFormGet() {
    return this.royaltyForm.controls;
  }

  /**
   * Connects wallet
   */
  public connectWallet() {
    this.ngOnInit()
    const data = {
      status: true,
    };
    this.accountService.openWallet(data);
  }

  /**
   * Gets collection details from db
   * @param {string} collectionAddress 
   * @returns  
   */
  public getCollectionDetailsFromDB(collectionAddress:string){
    return new Promise((resolve, reject) => {
    this.nftManagementService.royaltyReleaseSearch(collectionAddress,this.account.state.data.account).subscribe({
      next: (response: any) => {
        resolve({ status: true, data: response });
      },
      error: (error: any) => {
            this.searchCollectionLoader = false;
            this.toastr.error(error.error.message);
        reject({ status: false, data: error?.error });
      }
    })
  });
  }
}
