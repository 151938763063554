import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { CommonContractService } from 'src/app/services/common-contract.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { CopyDirective } from 'src/app/shared/directives/copy.directive';
import { ErrorHandlerDirective } from 'src/app/shared/directives/error-handler.directive';
import { ManageTransactionsDirective } from 'src/app/shared/directives/manage-transactions.directive';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import Web3 from 'web3';
import { allUsers, IPagination, walletAccount } from '../../shared/interface/interface';

let web3 = new Web3(window['ethereum'] as any);

@Component({
    selector: 'app-manage-user',
    templateUrl: './manage-user.component.html',
    styleUrls: ['./manage-user.component.scss']
})
export class ManageUserComponent implements OnInit {
    public allUserDetails: any;
    public searchValue: string;
    public isTableData: boolean = true;
    public currentPageLimit: number = 10;
    public allUserDetailsPaginations: IPagination;
    public isBlocked: boolean = true;
    public account: walletAccount;
    public isLoadingData: boolean = false;
    public isRegulated: any;

    constructor(
        private userManagementService: UserManagementService,
        private accountService: AccountService,
        private copyDirective: CopyDirective,
        private storageService: StorageService,
        public commonservices: CommonContractService,
        private manageTransactionsDirective: ManageTransactionsDirective,
        private errorHandler: ErrorHandlerDirective
    ) { }

    /**
     * on init
     */
    ngOnInit(): void {
        this.isRegulated = JSON.parse(this.storageService.getItemSession('isRegulated'));
        this.commonservices.closeModalsObservable.subscribe((response: boolean) => {
            if (response) {
                Swal.close()
            }
        });
        this.account = this.storageService.getItem('wagmi.store') === null ?
            { address: '', network: '', chainId: '', provider: '' } :
            JSON.parse(this.storageService.getItem('wagmi.store') as any);
        this.getAllUsers(1, this.currentPageLimit, this.isBlocked);

        this.accountService.searchPipeObserve.subscribe((response: any) => {
            if (this.searchValue == '' || this.searchValue == undefined) this.isTableData = true;

            if (this.searchValue != '' && this.searchValue != undefined) {
                this.isTableData = response;
                if (response) {
                    this.isTableData = response;
                    return
                }
            }

        })

        this.accountService.accountObserve.subscribe((response) => {
            if (response) {
                this.account = this.storageService.getItem('wagmi.store') === null ?
                    { address: '', network: '', chainId: '', provider: '' } :
                    JSON.parse(this.storageService.getItem('wagmi.store') as any);
                this.getAllUsers(1, this.currentPageLimit, this.isBlocked);
            }
        });

    }


    /**
     * Gets all users
     * @param {number} page
     * @param {number} limit
     * @param {boolean} type
     */
    public getAllUsers(page: number, limit: number, isBlocked: boolean, item?: string) {
        let searchKey = '';
        if (item) {
            searchKey = `&item=${item}`;
        }
        this.isLoadingData = true;
        this.userManagementService.getAllUsers(page, limit, isBlocked, searchKey).subscribe((response: allUsers) => {
            this.allUserDetails = response['data'].docs;
            this.allUserDetailsPaginations = response['data'];
            this.isLoadingData = false;

        })
    }

    /**
       * Track by function for ngFor loops
       *
       * @param index
       * @param item
       */
    public trackByFn(index: number, item: any): any {
        return item._id || index;
    }


    /**
   * Changes paginations
   * @param event
   */
    public changePaginations(event: any) {
        this.currentPageLimit = event.target.value;
        this.getAllUsers(1, event.target.value, this.isBlocked);

    }

    /**
       * Copys content
       * @param content
       */
    public copy(content: string) {
        this.copyDirective.copy(content)
    }


    /**
     * Confirms alert
     * @param {string} id
     * @param {string} address
     * @param {boolean} isUnBlock
     */
    public confirmAlert(id: string, address: string, isUnBlock: boolean) {
        Swal.fire({
            title: `Are you sure want to ${isUnBlock ? 'Unblock' : 'Delete'} this user?`,
            text: `Click OK to  ${isUnBlock ? 'Unblock' : 'Delete'} this user.`,
            type: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'OK.',
            cancelButtonText: 'Cancel.',
        }).then((willDelete) => {
            Swal.fire({
                onBeforeOpen: () => {
                    Swal.showLoading()
                },
                allowOutsideClick: false,
                text: 'Processing, Please wait...',
            });
            if (willDelete.dismiss) {
                Swal.fire({ title: '', text: `Not ${isUnBlock ? 'Unblock' : 'Delete'} this user.`, icon: 'error', type: 'error', confirmButtonText: 'OK.' });
            } else {
                if (isUnBlock) this.unBlockUser(id, address);
                else this.deleteUser(id);

            }
        });
    }

    /**
     * Deletes user
     * @param id
     */
    deleteUser(id: string) {
        try {
            this.userManagementService.deleteUser(id, this.account.state.data.account).subscribe({
                next: (_response) => {
                    Swal.fire({ title: '', text: 'Deleted Successfully.', icon: 'success', type: 'success', confirmButtonText: 'OK.' });
                    this.getAllUsers(1, this.currentPageLimit, this.isBlocked);
                },
                error:
                    ((error: any) => {
                        Swal.fire({ title: '', text: error.error.message ? error.error.message + '.' : 'Something went to wrong!. Please try again later.', icon: 'error', type: 'error', confirmButtonText: 'OK.' });
                    })
            })
        }
        catch (error) {
            Swal.fire({ title: '', text: error.error.message ? error.error.message + '.' : 'Something went to wrong!. Please try again later.', icon: 'error', type: 'error', confirmButtonText: 'OK.' });

        }
    }

    /**
     * UnBlocks user
     * @param {string} id
     * @param {string} address
     */
    public async unBlockUser(id: string, address: string) {
        try {
            const { blockUnblockAbi, requiredGas } = await this.userManagementService.blockUnblockUser(address, false, this.account.state.data.account);
            const message = {
                to: environment[this.account.state.data.chain.id].ACCESS_CONTROL_TOKEN,
                data: blockUnblockAbi,
                chainId: this.account.state.data.chain.id,
                gasPrice: await web3.utils.toHex(Number(await web3.eth.getGasPrice()) * 2),
                gasLimit: await web3.utils.toHex(Number(requiredGas) * 2)
            };
            this.manageTransactionsDirective.makeTransactions(message)
                .then(async (_receipt) => {
                    this.userManagementService.blockUser(id, false).subscribe((_response: any) => {
                        Swal.fire({ title: '', text: 'Unblocked Successfully.', icon: 'success', type: 'success', confirmButtonText: 'OK.' });
                        this.getAllUsers(1, this.currentPageLimit, this.isBlocked);
                    },
                        (_error: any) => {
                            Swal.fire({ title: '', text: `Something went to wrong!. Please try again later.`, type: 'error', icon: 'error', confirmButtonText: 'OK.' });

                        }
                    )
                })
                .catch((_error) => {
                    Swal.close()
                });
        }
        catch (error) {
            this.errorHandler.handleError(error);
            Swal.close()

        }
    }

    /**
     * Confirms alert
     * @param {string} id
     * @param {boolean} status
     */
    public kycUploadStatus(id: string, status: boolean) {
        let name = status ? 'enable' : 'disable';
        Swal.fire({
            title: `Are you sure you want to ${name} identity verification for this user?`,
            text: `This user will ${status ? '' : 'not'} be redirected to identity validation next time he tries to initiate a transaction.`,
            type: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'OK.',
            cancelButtonText: 'Cancel.',

        }).then((willDelete) => {
            Swal.fire({
                onBeforeOpen: () => {
                    Swal.showLoading()
                },
                allowOutsideClick: false,
                text: 'Processing, Please wait...',
            });
            if (willDelete.dismiss) {
                Swal.fire({ title: '', text: `Not ${name} this user.`, type: 'error', icon: 'error', confirmButtonText: 'OK.' });

                this.getAllUsers(1, this.currentPageLimit, this.isBlocked);
            } else {
                this.kycUploadStatusProcess(id, status);
            }
        });

    }

    /**
     * Kycs upload status process
     * @param {string} id
     * @param {string} status
     * @param {number} index
     */
    kycUploadStatusProcess(id: string, status: boolean) {
        let name = status ? 'Enabled' : 'Disabled';
        const data = {
            status: status
        }
        this.userManagementService.updateKycStatus(id, data).subscribe((response) => {
            Swal.fire({ title: '', text: `${name} successfully.`, icon: 'success', type: 'success', confirmButtonText: 'OK.' });
            this.getAllUsers(1, this.currentPageLimit, this.isBlocked);
        },
            (error) => {
                Swal.fire({ title: '', text: `Not ${name} this user.`, type: 'error', icon: 'error', confirmButtonText: 'OK.' });
                this.getAllUsers(1, this.currentPageLimit, this.isBlocked);
            }
        )
    }

}

