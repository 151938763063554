import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lastSeen'
})
export class LastseenPipe implements PipeTransform {

  transform(value: any): string {
    const now = new Date();
    const lastSeen = new Date(value);
    const seconds = Math.floor((now.getTime() - lastSeen.getTime()) / 1000);

    if (seconds < 60) {
      return 'just now';
    } else if (seconds < 3600) {
      return `${Math.floor(seconds / 60)} minutes ago`;
    } else if (seconds < 86400) {
      return `${Math.floor(seconds / 3600)} hours ago`;
    } else {
      return `${Math.floor(seconds / 86400)} days ago`;
    }
  }

}
