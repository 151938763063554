<footer class="base_footer">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-4 col-sm-12">
                <div class="footer-text">
                    <p>Email: <a href="mailto:info@realworld.fi">info@realworld.fi</a></p>
                    <ul class="social-links">
                        <li ><a  href="https://discord.com/invite/s2knd8GCzt" target="_blank"><i  class="bi bi-discord fs-4"></i></a></li>
                        <li ><a  href="https://twitter.com/realworld_fi" target="_blank"><i  class="bi bi-twitter fs-4"></i></a></li>
                        <li ><a  href="https://www.linkedin.com/company/real-world-finance/" target="_blank"><i  class="bi bi-linkedin fs-4"></i></a></li></ul>
                </div>
            </div>
            <div class="col-lg-8 col-sm-12">
                <p class="text-right">RealWorld International Ventures Ltd.</p>
                <p class="text-right">Reg. Number: C 57659. Hamilton Development, unit B, Charlestown, Nevis, West Indies.</p>
            </div>
            
        </div>
    </div>
</footer>

