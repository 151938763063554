<div class="main-wrap">
    <app-sidebar class="sidebarview"></app-sidebar>
    <section class="section">
        <div class="container-fluid">
            <div class="managecollection">
                <div class="row mb-4">
                    <div class="col-lg-6">
                        <ul class="manage-collection">
                            <li class="nav-item " [routerLink]="['/manage-collection']">Create collection.</li>
                            <li class="nav-item" [routerLink]="['/manage-collection/edit-collection']" routerLinkActive="menuactivities"
                                [routerLinkActiveOptions]="{exact: true}">Edit collection.</li>
                        </ul>
                    </div>
                </div>
                <!--start forms-->
                <form [formGroup]="collectionSearchForm" (ngSubmit)="collectionSearch()">
                    <div class="row mb-2  align-items-center">
                        <div class="col-lg-9 col-sm-12 mb-3">
                            <label for="label">Collection search.</label>
                            <input type="text" class="form-control" placeholder="Enter collection address"
                                formControlName="address" (keyup)="collectionChange()">
                            <div *ngIf="collectionFormSubmitted && collectionSearchFormGet.address.errors"
                                class="invalid-feedback-custom">
                                <small class="text-danger"
                                    *ngIf="collectionSearchFormGet.address.errors.required">Please enter collection
                                    address.</small>
                            </div>
                            <div class="text-danger mt-2">{{collectionError}}</div>
                        </div>

                        <div class="col-lg-3 col-sm-4 mt-sm-2 mt-md-3">
                            <div class="text-center" *ngIf="account.state.data.account != ''">
                                <button class="submit-btn"
                                    [disabled]="collectionLoader || collectionSearchForm.invalid">
                                    <span *ngIf="collectionLoader"> Processing...</span>
                                    <span *ngIf="!collectionLoader"> Search. </span>
                                </button>
                            </div>

                            <div class="d-flex align-items-center gap-4" *ngIf="account.state.data.account == ''">
                                <button class="submit-btn wallet-btn" type="button" (click)="connectWallet()">Connect
                                    Wallet.</button>

                            </div>
                        </div>

                    </div>
                </form>
            </div>

        </div>
    </section>
</div>