import { Component } from '@angular/core';
import { LogsService } from 'src/app/services/logs.service';
import { SocketIoService } from 'src/app/services/socket-io.service';
import { StorageService } from 'src/app/services/storage.service';
import { transhLogs } from 'src/app/shared/interface/interface';
import { environment } from 'src/environments/environment';
import { CopyDirective } from 'src/app/shared/directives/copy.directive';

@Component({
  selector: 'app-user-logs-details',
  templateUrl: './user-logs-details.component.html',
  styleUrls: ['./user-logs-details.component.scss']
})
export class UserLogsDetailsComponent {
  public alluserActivityDetailsPaginations;
  public alluserActivityDetails: transhLogs[] = [];
  public currentPageLimit: number = 10;
  public isRegulated: boolean;
  public isLoadingUserLogs:boolean = false;
  public transhUrl: string = '';

  constructor(
    private socketIoService:SocketIoService,
    private storageService: StorageService,
    private logsService:LogsService,
    private copyDirective:CopyDirective,
  ){
    
  }
 
  /**
   * on init
   */
  public ngOnInit(): void {
    this.isRegulated = JSON.parse(this.storageService.getItemSession('isRegulated'));
    const account = this.storageService.getItem('wagmi.store') === null ?
    { address: '', network: '', chainId: '', provider: '' } :
    JSON.parse(this.storageService.getItem('wagmi.store') as any);
    this.socketIoService.getUserActivityLogs();
    this.socketIoService.userActivityLogs$.subscribe((response) => {
      if(response.custodial == this.isRegulated) { 
        if(this.alluserActivityDetails.length >= this.currentPageLimit){
          this.alluserActivityDetails.pop();  
        }
        this.alluserActivityDetails.unshift(response.message);
      }
    });
    this.getUserActivityLogs(1,this.currentPageLimit);
    this.transhUrl = environment[account.state.data.chain.id].EXPLORER
  }

/**
   * Gets user activity logs
   * @param {number} page 
   * @param {number} limit 
   */
public getUserActivityLogs(page:number,limit:number){
  this.isLoadingUserLogs = true;
  this.logsService.getUserActivityLogs(page,limit).subscribe((response) => {
    this.alluserActivityDetails = response['data']['docs'];
    this.alluserActivityDetailsPaginations = response['data'];
    this.isLoadingUserLogs = false;
  },
  (error) => {
    this.isLoadingUserLogs = false;
  }
  )
}

/**
     * Track by function for ngFor loops
     * @param index
     * @param item
     */
public trackByFn(index: number, item: any): any {
  return item._id || index;
}

 /**
   * Copys content
   * @param content 
   */
 public copy(content:string){
  this.copyDirective.copy(content)
}
}
