<div class="main-wrap">
    <app-sidebar class="sidebarview"></app-sidebar>
    <section class="section">
      <div class="container-fluid">
        <div class="managecollection">
          <div class="row mb-4">
            <app-profile-header [loader]="loader" [userData]="userData"></app-profile-header>
          </div>
          <div class="row">
            <div class="col-lg-4 mb-md-4">
              <app-profile [loader]="loader" [userData]="userData"></app-profile>
            </div>
            <div class="col-lg-8 mt-3 mt-md-0 mt-lg-0">
              <div class="profile-view" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="100"
                [infiniteScrollDisabled]="disableInfiniteScroll" (scrolled)="getUserNft()">
                <app-profile-nav [userData]="userRouteData"></app-profile-nav>
                <div class="personal-details mb-4">
                  <span class="d-flex justify-content-between">
                    <h5>Items details.</h5>
                    <span class="cursor-pointer" (click)="getUserNft()">
                      <svg fill="#006736" height="16" width="16" version="1.1" id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 383.748 383.748" xml:space="preserve" stroke="#006736">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                          <g>
                            <path
                              d="M62.772,95.042C90.904,54.899,137.496,30,187.343,30c83.743,0,151.874,68.13,151.874,151.874h30 C369.217,81.588,287.629,0,187.343,0c-35.038,0-69.061,9.989-98.391,28.888C70.368,40.862,54.245,56.032,41.221,73.593 L2.081,34.641v113.365h113.91L62.772,95.042z">
                            </path>
                            <path
                              d="M381.667,235.742h-113.91l53.219,52.965c-28.132,40.142-74.724,65.042-124.571,65.042 c-83.744,0-151.874-68.13-151.874-151.874h-30c0,100.286,81.588,181.874,181.874,181.874c35.038,0,69.062-9.989,98.391-28.888 c18.584-11.975,34.707-27.145,47.731-44.706l39.139,38.952V235.742z">
                            </path>
                          </g>
                        </g>
                      </svg>
                    </span>
                  </span>
  
                  <div class="personal-info mt-4" *ngIf="isLoadingData">
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-sm-6 mb-4">
                        <div class="nft-view-wrap">
                          <ngx-skeleton-loader
                            [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '100%', height: '150px','border-radius':'5px','margin-bottom':'5px'}"></ngx-skeleton-loader>
                          <ngx-skeleton-loader
                            [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '100%', height: '20px','border-radius':'5px'}"></ngx-skeleton-loader>
                        </div>
  
                      </div>
                    </div>
                  </div>
                  <div class="personal-info mt-4" *ngIf="nftDetails && nftDetails.length > 0 && !isLoadingData">
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-sm-6 mb-4" *ngFor="let nft of nftDetails">
  
                        <a [href]="nft.lazy_mint ? marketPlaceUrl+'lazy-mint/'+nft._id+'?regulated='+isRegulated : marketPlaceUrl+'nft-detail/'+nft?.collections?.collection_address+'/'+nft.token_id+'?regulated='+isRegulated"
                          target="_blank" rel="noopener noreferrer">
  
                          <div class="nft-view-wrap">
                            <img loading="lazy" *ngIf="nft?.fileType != 'html'" src="{{nft.primary_media}}"
                              onerror="this.src='./assets/images/default-nft.svg';" alt="holding" class="img-fluid">
  
                            <div class="gemsView" *ngIf="nft?.fileType === 'html'">
                              <img [src]="nft?.preview_image ? nft?.preview_image : nft?.secondary_media[0]"
                                class="img-fluid" onerror="this.src='./assets/images/default-nft.svg';">
                            </div>
  
                            <div [ngClass]="nft.on_loan || nft.on_sale || nft.for_loan ?'d-flex gap-2 ':'w-100'">
                              <div class="position-relative">
                                <div #content class="truncate" id="tooltiphead{{i}}">
                                  <h6 (window:resize)="setTooltipSize(i)" id="tooltiptitle{{i}}">{{nft.name}}
                                    #{{nft.token_id}}</h6>
                                </div>
                                <h5 *ngIf="nft.collections?.name">{{nft.collections?.name}}</h5>
                                <!-- <h5 *ngIf="!nft.collections?.name">-</h5> -->
                                <div class="tooltip" id="tooltipdescription{{i}}">{{nft?.name}}</div>
                              </div>
                              <div>
                                <span class="position-relative">
                                  <img src="assets/images/for-loan.svg" width="24" height="24" alt="loan"
                                    *ngIf="nft.for_loan" class="forloan">
                                  <span class="loan-details-tooltip for-loan-tooltip"
                                    *ngIf="nft.owner != this.account.state.data.account">Collateral for Loan - This item
                                    is currently being offered as collateral for loan. If you are interested in lending
                                    against this item, please go to the Lending page on the site menu.</span>
                                  <span class="loan-details-tooltip for-loan-tooltip"
                                    *ngIf="nft.owner == this.account.state.data.account">Collateral for Loan - You've
                                    offered this item as collateral for loan. You are now waiting for other parties to
                                    accept or recounter.</span>
                                </span>
                                <span class="position-relative">
                                  <img src="assets/images/on-loan.svg" width="24" height="24" alt="loan"
                                    *ngIf="nft.on_loan" class="forloan">
                                  <span class="loan-details-tooltip">In live loan.</span>
                                </span>
                                <span class="position-relative">
                                  <img src="assets/images/for-sale.svg" width="24" height="24" alt="loan"
                                    *ngIf="nft.on_sale" class="forloan">
                                  <span class="loan-details-tooltip">For sale.</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="loadingview-wrap">
                    <div class="loadingview" *ngIf="scrollLoader">
                      <h6>Loading.</h6>
                      <span class="Loaders"></span>
                    </div>
                  </div>
                  <div class="personal-info mt-4" *ngIf="nftDetails && nftDetails.length === 0 && !isLoadingData">
                    No data found
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>