import { Component, Input } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { CommonContractService } from 'src/app/services/common-contract.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { ManageTransactionsDirective } from 'src/app/shared/directives/manage-transactions.directive';
import { IuserDetails, walletAccount } from 'src/app/shared/interface/interface';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import Web3 from 'web3';

import { ErrorHandlerDirective } from 'src/app/shared/directives/error-handler.directive';

let web3 = new Web3(window['ethereum'] as any);

@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.scss']
})
export class ProfileHeaderComponent{
  @Input('userData') userData:IuserDetails;
  @Input('loader') loader:boolean = false;

  public account: walletAccount;
  public isLoading:boolean = false;

  constructor(
    private userManagementService: UserManagementService,
    private storageService: StorageService,
    private accountService:AccountService,
    private manageTransactionsDirective:ManageTransactionsDirective,
    private commonservices:CommonContractService,
    private errorHandler: ErrorHandlerDirective
  ) { }

  /**
   * on init
   */
  public ngOnInit(): void {
    this.commonservices.closeModalsObservable.subscribe((response:boolean) => {
      if(response){
        Swal.close()
      }
    });
    this.account = this.storageService.getItem('wagmi.store') === null ?
      { address: '', network: '', chainId: '', provider: '' } :
      JSON.parse(this.storageService.getItem('wagmi.store') as any);
    this.accountService.accountObserve.subscribe((response) => {
      if (response) {
        this.account = this.storageService.getItem('wagmi.store') === null ?
          { address: '', network: '', chainId: '', provider: '' } :
          JSON.parse(this.storageService.getItem('wagmi.store') as any);
      }
    });


  }

  /**
 * Confirms alert
 * @param {string} id
 * @param {string} address
 */
public confirmAlert(status:boolean,address:string) {
  let name;
  if(status){
    name = 'Block';
  }
  else{
    name = 'Unblock';
  }
  Swal.fire({
    title: `Are you sure want to ${name} this user?`,
    text: `Click OK to ${name} this user.`,
    type: 'warning',
    confirmButtonText: 'OK.',
    cancelButtonText: 'Cancel.',
    showCloseButton: true,
    showCancelButton: true,
  }).then((willDelete) => {
    Swal.fire({
      onBeforeOpen: () => {
        Swal.showLoading()
    },
      allowOutsideClick: false,
      text: 'Processing, Please wait...',
  });
    this.isLoading = true;
    if (willDelete.dismiss) {
      this.isLoading = false;
      Swal.fire({title:'',text: `Not ${name} this user.`,type: 'error',icon:'error',confirmButtonText:'OK.'});
    } else {
      this.blockUnblock(status,address);
    }
  });

}

  /**
   * Blocks unblock
   * @param {boolean} status
   * @param {string} address
   */
  public async blockUnblock(status:boolean,address:string){
try{
    const { blockUnblockAbi, requiredGas } = await this.userManagementService.blockUnblockUser(address, status, this.account.state.data.account);
    const message = {
      to: environment[this.account.state.data.chain.id].ACCESS_CONTROL_TOKEN,
      data: blockUnblockAbi,
      chainId: this.account.state.data.chain.id,
      gasPrice: await web3.utils.toHex(Number(await web3.eth.getGasPrice()) * 2),
      gasLimit: await web3.utils.toHex(Number(requiredGas) * 2)
    };
    this.manageTransactionsDirective.makeTransactions(message)
      .then(async (_receipt) => {
        this.userManagementService.blockUser(this.userData._id,status).subscribe((_response:any) => {
          this.userManagementService.updateBlockStatus(true);
          if(status){
            Swal.fire({title:'', text:'Blocked Successfully.',icon: 'success',type:'success',confirmButtonText:'OK.'});

          }
          else{
            Swal.fire({title:'', text:'Unblocked Successfully.',icon: 'success',type:'success',confirmButtonText:'OK.'});

          }
          this.isLoading = false;
        },
        (_error:any) => {
          Swal.fire({title:'',text: `Something went to wrong!. Please try again later.`,type: 'error',icon:'error',confirmButtonText:'OK.'})
          this.isLoading = false;
        }
        )

      })
      .catch((_error) => {
        Swal.close()
        this.isLoading = false;
      });

    }
    catch(error){
      Swal.close()
      this.errorHandler.handleError(error);

    }
  }


}
