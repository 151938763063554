<div class="row mb-4">
    <div class="col-lg-12">
        <div class="d-flex align-items-center justify-content-between flex-wrap gap-3">
            <div class="d-flex flex-wrap align-items-center justify-content-between gap-4">
                <a class="back-btn" [routerLink]="['/manage-user']"><img loading="lazy"  src="assets/images/back-btn.svg"
                        class="backbtn"></a>
                <a><ngx-skeleton-loader *ngIf="loader" [theme]="{background: 'rgba(0, 103, 54,.15)',width:'200px',height:'23px'}" ></ngx-skeleton-loader> <span *ngIf="!loader">{{userData.name}}</span></a>
                
            </div>
            <div>
                <button class="blockuser" [disabled]="isLoading" *ngIf="!userData.is_blocked"
                    (click)="confirmAlert(true, userData.wallet_address)">
                    <span *ngIf="!isLoading">Block User.</span>
                    <span *ngIf="isLoading">Processing...</span>
                </button>
                <button class="blockuser" [disabled]="isLoading" *ngIf="userData.is_blocked"
                    (click)="confirmAlert(false, userData.wallet_address)">
                    <span *ngIf="!isLoading">Unblock User.</span>
                    <span *ngIf="isLoading">Processing...</span>
                </button>
            </div>
        </div>
    </div>
</div>