<div class="main-wrap">
    <app-sidebar class="sidebarview"></app-sidebar>
    <section class="section">
        <div class="container-fluid">
            <div class="managecollection">
                <div class="row mb-4">
                    <div class="col-lg-6">
                        <ul class="manage-collection">
                            <li class="nav-item " [routerLink]="['/royalty']" routerLinkActive="menuactivities"
                                [routerLinkActiveOptions]="{exact: true}">Royalty Release.</li>

                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <label for="label" class="search-collection-label">Choose the collection to royalty
                            release.</label>
                            <ng-select (change)="royaltyReleaseSubmit($event)" placeholder="Choose a collection">
                                <ng-option *ngFor="let data of collectionsAddressList"
                                    [value]="data.collection_address">{{data.name +' - '+data.collection_address
                                    }}</ng-option>
                            </ng-select>
                    </div>
                </div>
                <div class="loading-notfound" *ngIf="searchCollectionLoader">
                    <div class="loader"></div>
                    <p>Please wait while we load your items. </p>&nbsp;
                </div>
                <div class="row" *ngIf="royaltyFormSubmitted && !searchCollectionLoader">
                    <div class="col-lg-12">
                        <div class="walletview">
                            <div class="walletview-pos">
                                <h1>Your royalty {{shareInPercentage}}%</h1>
                            </div>
                            <h3>Wallet Address: <span class="ms-2">{{account.state.data.account}}</span></h3>
                            <h3>Collection Address: <span class="ms-2">{{collectionAddress}}</span></h3>
                        </div>
                        <div class="dataviewtable">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <th>Currency / token.</th>
                                        <th>Your current royalty.</th>
                                        <th>Your total royalty earnings.</th>
                                        <th>Action.</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <a class="btn-currency">{{this.account?.state.chains[0].nativeCurrency.name}}</a>
                                            </td>
                                            <td>
                                                <p>{{currentRoyalty}}</p>
                                            </td>
                                            <td>
                                                <p>{{claimedAmount}}</p>
                                            </td>
                                            <td><button class="release" [disabled]="!releaseStatus || disableAll"
                                                    (click)="releaseRoyalty()">
                                                    <span *ngIf="!releaseRoyaltyTriggered">Release.</span>
                                                    <span *ngIf="releaseRoyaltyTriggered">Processing...</span>
                                                </button></td>
                                        </tr>
                                        <tr *ngFor="let userTokenRoyalty of userTokenRoyaltyData, index as i">
                                            <td>
                                                <a class="btn-currency">{{userTokenRoyalty.symbol}}</a>
                                            </td>
                                            <td>
                                                <p>{{userTokenRoyalty.releasable}}</p>
                                            </td>
                                            <td>
                                                <p>{{userTokenRoyalty.released}}</p>
                                            </td>
                                            <td><button class="release"
                                                    [disabled]="userTokenRoyalty.releasable == 0 || disableAll"
                                                    (click)="releaseTokenRoyalty(userTokenRoyalty.address,i)">
                                                    <span
                                                        *ngIf="releaseTokenRoyaltyCurrentIndex != i || !releaseTokenRoyaltyProcessing">Release.</span>
                                                    <span class="d-flex justify-content-between"
                                                        *ngIf="releaseTokenRoyaltyCurrentIndex == i && releaseTokenRoyaltyProcessing"><span
                                                            class="spinner-border spinner-border-sm mr-2"
                                                            role="status"></span>Processing...</span>
                                                </button></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <a *ngIf="transactionHash != ''" href="{{transhUrl}}/tx/{{transactionHash}}" target="_blank"
                    class="d-block text-center mt-4">Click to view transaction.</a>
            </div>
        </div>
    </section>
</div>