import { Pipe, PipeTransform } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
   @Pipe({
     name: 'search'
   })
   export class SearchPipe implements PipeTransform {

    constructor(public accountService:AccountService){

    }

    transform(value: any, args?: any): any {
      
      if (!args) {
        this.accountService.searchPipeUpdate(true);
          return value;
        }
        let searchStatus = false;
        return value.filter((val,index) => {
          let rVal = (val.name.toLocaleLowerCase().includes(args.toLocaleLowerCase())) || (val.email.toLocaleLowerCase().includes(args.toLocaleLowerCase())) || (val.wallet_address.includes(args.toLocaleLowerCase()));
          if(rVal){
            searchStatus = rVal;
          }
          if(index + 1 == value.length){
             this.accountService.searchPipeUpdate(searchStatus);
          }
          return rVal;
        })
    
      }
    }