import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { NftManagementService } from 'src/app/services/nft-management.service';
import { StorageService } from 'src/app/services/storage.service';
import Web3 from 'web3';
import { environment } from 'src/environments/environment';
import { walletAccount } from 'src/app/shared/interface/interface';
import { ErrorHandlerDirective } from 'src/app/shared/directives/error-handler.directive';
@Component({
  selector: 'app-editcollection',
  templateUrl: './editcollection.component.html',
  styleUrls: ['./editcollection.component.scss']
})
/**
 * Collection Search
 */
export class EditcollectionComponent implements OnInit {
  public collectionSearchForm: UntypedFormGroup;
  public collectionLoader: boolean = false;
  public collectionFormSubmitted: boolean = false;
  public account: walletAccount;
  public collectionError:string = '';

  /**
   * Creates an instance of search collections component.
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    private nftManagementService: NftManagementService,
    private accountService: AccountService,
    private storageService: StorageService,
    private toastr: ToastrService,
    private router: Router,
    private errorHandler: ErrorHandlerDirective
  ) { }

  /**
   * on init
   */
  public ngOnInit(): void {
    this.account = this.storageService.getItem('wagmi.store') === null ?
      { address: '', network: '', chainId: '', provider: '' } :
      JSON.parse(this.storageService.getItem('wagmi.store') as any);;
    this.collectionSearchForm = this.formBuilder.group({
      address: ['', Validators.required]
    });
    this.accountService.accountObserve.subscribe((response) => {
      if (response) {
        this.account = this.storageService.getItem('wagmi.store') === null ?
          { address: '', network: '', chainId: '', provider: '' } :
          JSON.parse(this.storageService.getItem('wagmi.store') as any);
        if (this.collectionSearchForm.controls['address'].value != '') {
          this.collectionSearch();
        }
      }
    });
  }

  /**
   * Collections search
   */
  public collectionSearch() {
    this.collectionError = '';
    this.collectionFormSubmitted = true;
    if (this.collectionSearchForm.invalid) {
      return;
    }
    try {
      this.collectionLoader = true;
      let web3 = new Web3(window['ethereum'] as any || environment[this.account.state.data.chain.id].PROVIDER);
      const inputAddress = this.collectionSearchForm.controls['address'].value.trim();
      let collectionAddress = inputAddress;
      this.nftManagementService.getCollectionOwner(collectionAddress).then(async (response:any) => {
        const collectionOwner = await web3.utils.toChecksumAddress(response);
        this.nftManagementService.isCollectionDestroyed(collectionAddress).then(async (response) => {
          if (response) {
            this.toastr.error("Collection Destroyed");
            this.collectionLoader = false;
            return;
          }
          else {
            if (collectionOwner === await web3.utils.toChecksumAddress(this.account.state.data.account)) {
              this.checkCollectionAddress(collectionAddress)
            }
            else {
              this.collectionLoader = false;
              this.toastr.error("You are not a owner of the collection.")
            }
          }
        })

      }).
        catch((error: any) => {
          this.collectionLoader = false;
          if ((error.toString()).includes('is not a valid Ethereum address')) {
            this.toastr.error("Invalid collection address.")
          }
          else if((error.toString()).includes('InvalidAddressError')){
            this.toastr.error("Invalid collection address.")
          }
          else if((error.toString()).includes('returned no data')){
            this.toastr.error("Collection address not found.")
          }
        })
    }
    catch (error: any) {
      this.errorHandler.handleError(error);
      this.collectionLoader = false;
    }
  }

  /**
   * Gets get nft burn form
   */
  public get collectionSearchFormGet() {
    return this.collectionSearchForm.controls;
  }

  /**
   * Connects wallet
   */
  public connectWallet() {
    this.ngOnInit()
    const data = {
      status: true,
    };
    this.accountService.openWallet(data);
  }

  /**
   * Checks collection address
   * @param {string} collectionAddress 
   */
  public checkCollectionAddress(collectionAddress:string){
    this.nftManagementService.getCollectionDb(collectionAddress).subscribe((response: any) => {
      if(response['data'].isLazyMint){
        this.collectionLoader = false;
        this.collectionError = "Edit option not available for lazy mint collections.";
        return
      }
      else{
        this.collectionLoader = false;
        this.router.navigate(['/manage-collection/edit-collection', collectionAddress]);
      }
    },
    (error:any) => {
      this.collectionLoader = false;
      this.toastr.error(error.error.message);
      return;
    });
  }

  /**
   * Collections change
   */
  public collectionChange(){
    this.collectionError = "";
  }
}

