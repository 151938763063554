
import { Injectable } from '@angular/core';
import {  BehaviorSubject, Observable, } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonContractService {
  
  
  private signin=new  BehaviorSubject(false);
  signinpageObservable= this.signin.asObservable()

  private closeModals = new BehaviorSubject(false);
  closeModalsObservable = this.closeModals.asObservable();

   /** wallet conncet page header
    * 
    */
   sethederhide(type:boolean){
    this.signin.next(type)
   }

   /**
    * Closes all modals
    * @param {boolean} close 
    */
   public closeAllModals(close: boolean){
    this.closeModals.next(close)
  }
}
