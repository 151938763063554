<div class="main-wrap">
    <app-sidebar class="sidebarview"></app-sidebar>
    <section class="section">
        <div class="container-fluid">
            <div class="managecollection mb-4">
                <div class="row mb-2">
                    <div class="col-lg-12">
                        <h4>Announcement</h4>
                    </div>
                </div>
                <div class="row">
                    <form [formGroup]="announcements" (ngSubmit)="announcementSubmit()">
                        <div class="row">
                          <div class="col-lg-3 mb-3" >
                            <div class="form-group">
                              <label for="bg">Background color.</label>
                              <input type="color" class="form-control"  formControlName="background_color" id="bg" />
                                  <div class="text-danger" *ngIf="(f['background_color'].touched && f['background_color'].errors?.['required'])">
                                      *Required background color.
                                  </div>
                            </div>
                          </div>
                          <div class="col-lg-2 mb-3" >
                            <div class="form-group">
                              <label for="text">Text color.</label>
                              <input type="color" class="form-control" value="#ffffff" formControlName="font_color" id="text"/>
                                  <div class="text-danger" *ngIf="(f['font_color'].touched ) && f['font_color'].errors?.['required']">
                                    *Required text color.
                                  </div>
                            </div>
                          </div>
                          <div class="col-lg-7 mb-3">
                            <label for="">Description.</label>
                            <div class="NgxEditor__Wrapper">
                              <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
                              <ngx-editor [editor]="editor"
                                [disabled]="false"
                                [placeholder]="'Type here...'"
                                formControlName="announcement_title"></ngx-editor>
                              <div class="text-danger" *ngIf="(f['announcement_title'].touched) && f['announcement_title'].errors?.['required']">
                                *Required announcement details.
                              </div>
                            </div>
                          </div> 
                          <div class="col-lg-12">
                            <div class="float-right">
                              <button type="submit" class="btn create-btn" [disabled]="announcements.invalid">Submit.</button>
                            </div>
                          </div>
                        </div>
                      </form>
                </div>
            </div>
            <div class="managecollection mb-4">
              <table class="table table-bordered">
                    <thead>
                        <th>#</th>
                        <th>Name.</th>
                        <th>Status.</th>
                        <th>Action.</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of getAnnouncementData; let i=index; ">
                            <td>{{i+1}}</td>
                            <td>
                              <div class="announce" [style.background-color]="data.background_color" [style.color]="data.font_color">
                                <div [innerHTML]="data.announcement_title |safeHtml "></div>
                              </div>
                            </td>
                            <td>
                              <label class="switch">
                                <input type="checkbox" (change)="setAnnouncement(data)" [checked]="data.enabled">
                                <span class="slider round"></span>
                              </label>
                            </td>
                            <td>
                                <div  class="d-flex gap-2">
                                    <button  type="button" class="btn btn-primary" (click)="editAnnouncemnet(data)">Edit.</button>
                                    <button  type="button" class="btn btn-danger" (click)="deleteAnnounce(data._id)">Delete.</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
              </table>
            </div>
        </div>
    </section>
</div>