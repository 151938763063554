<div class="main-wrap">
    <app-sidebar class="sidebarview"></app-sidebar>
    <section class="section">
        <div class="container-fluid">
            <div class="managecollection">
                <div class="row mb-4">
                    <div class="col-lg-6">
                        <ul class="manage-collection">
                            <li class="nav-item " [routerLink]="['/manage-nft']">Create NFT.</li>
                            <li class="nav-item" [routerLink]="['/manage-nft/edit-nft']">Edit NFT.</li>
                            <li class="nav-item" [routerLink]="['/manage-nft/burn-nft']" routerLinkActive="menuactivities"
                                [routerLinkActiveOptions]="{exact: true}">Burn NFT.</li>
                        </ul>
                    </div>
                </div>
                <!--start forms-->
                <form [formGroup]="nftBurnForm" (ngSubmit)="nftBurnSubmit()">
                    <div class="row mb-sm-2 mb-lg-0  align-items-center">
                        <div class="col-lg-3 col-sm-12 mb-3">
                            <label for="label">Token ID.</label>
                            <input type="text" class="form-control" placeholder="Enter Token ID"
                                formControlName="tokenId">
                            <div *ngIf="burnNftFormSubmitted && nftBurnFormGet.tokenId.errors"
                                class="invalid-feedback-custom">
                                <small class="text-danger" *ngIf="nftBurnFormGet.tokenId.errors.required">Please enter
                                    token ID.</small>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12 mb-3">
                            <label for="label">Collection search.</label>
                            <input type="text" class="form-control" placeholder="Enter collection address"
                                formControlName="address">
                            <div *ngIf="burnNftFormSubmitted && nftBurnFormGet.address.errors"
                                class="invalid-feedback-custom">
                                <small class="text-danger" *ngIf="nftBurnFormGet.address.errors.required">Please enter
                                    collection address.</small>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-4 mt-sm-2 mt-md-3">
                            <div class="text-center">
                                <button class="submit-btn" [disabled]="nftBurnForm.invalid || burnNftLoader"
                                    *ngIf="account.state.data.account != ''" type="submit">
                                    <span *ngIf="!burnNftLoader"> Burn NFT </span>
                                    <span *ngIf="burnNftLoader">Processing...</span>
                                </button>

                                <button class="submit-btn wallet-btn" type="button" *ngIf="account.state.data.account == ''"
                                    (click)="connectWallet()">Connect Wallet</button>
                            </div>
                        </div>
                    </div>
                </form>
                <a *ngIf="transactionHash != ''" href="{{transhUrl}}/tx/{{transactionHash}}" target="_blank"
                    class="d-block text-center">Click to view transaction.</a>
            </div>
        </div>

    </section>
</div>