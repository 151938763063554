import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnnouncementDetailComponent } from './module/announcement-detail/announcement-detail.component';
import { ConncetWalletComponent } from './module/conncet-wallet/conncet-wallet.component';
import { DashboardComponent } from './module/dashboard/dashboard.component';
import { TransacationDetailComponent } from './module/dashboard/transacation-detail/transacation-detail.component';
import { TransactionsLogsListComponent } from './module/dashboard/transactions-logs-list/transactions-logs-list.component';
import { UserLogsDetailsComponent } from './module/dashboard/user-logs-details/user-logs-details.component';
import { DeliveryDetailsComponent } from './module/delivery/delivery-details/delivery-details.component';
import { DeliveryHistoryComponent } from './module/delivery/delivery-history/delivery-history.component';
import { ManageDeliveryComponent } from './module/delivery/manage-delivery/manage-delivery.component';
import { ManageCategoryComponent } from './module/manage-category/manage-category.component';
import { CreateCollectionComponent } from './module/manage-collection/create-collection/create-collection.component';
import { CreateLazymintCollectionComponent } from './module/manage-collection/create-lazymint-collection/create-lazymint-collection.component';
import { ManageCollectionComponent } from './module/manage-collection/manage-collection.component';
import { BurnnftComponent } from './module/manage-nft/burnnft/burnnft.component';
import { CreatenftComponent } from './module/manage-nft/createnft/createnft.component';
import { EditnftComponent } from './module/manage-nft/editnft/editnft.component';
import { ManageNftComponent } from './module/manage-nft/manage-nft.component';
import { AllUserComponent } from './module/manage-user/all-user/all-user.component';
import { EditUserComponent } from './module/manage-user/edit-user/edit-user.component';
import { ManageUserComponent } from './module/manage-user/manage-user.component';
import { NftHoldingComponent } from './module/manage-user/profileuser/nft-holding/nft-holding.component';
import { NftTransacationComponent } from './module/manage-user/profileuser/nft-transacation/nft-transacation.component';
import { ProfileuserComponent } from './module/manage-user/profileuser/profileuser.component';
import { RoyaltyComponent } from './module/royalty/royalty.component';
import { AuthService } from './services/auth.service';


const routes: Routes = [
    {
        path: '',
        component: ConncetWalletComponent
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthService]
    },
    {
        path: 'manage-category',
        component: ManageCategoryComponent,
        canActivate: [AuthService]
    },
    {
        path: 'manage-collection',
        component: ManageCollectionComponent,
        canActivate: [AuthService]
    },
    {
        path: 'manage-collection/create',
        component: CreateCollectionComponent,
        canActivate: [AuthService]
    },
    {
        path: 'manage-collection/create-lazymint',
        component: CreateLazymintCollectionComponent,
        canActivate: [AuthService]
    },
    {
        path: 'manage-collection/:address',
        component: ManageCollectionComponent,
        canActivate: [AuthService]
    },
    {
        path: 'manage-collection/edit/:address',
        component: CreateCollectionComponent,
        canActivate: [AuthService]
    },
    {
        path: 'manage-collection/edit-lazymint/:address',
        component: CreateLazymintCollectionComponent,
        canActivate: [AuthService]
    },
    {
        path: 'manage-nft',
        component: ManageNftComponent,
        canActivate: [AuthService]
    },
    {
        path: 'manage-nft/edit-nft/:address/:tokenId',
        component: CreatenftComponent,
        canActivate: [AuthService]
    },
    {
        path: 'manage-nft/create-nft/:address',
        component: CreatenftComponent,
        canActivate: [AuthService]
    },
    {
        path: 'manage-nft/edit-nft',
        component: EditnftComponent,
        canActivate: [AuthService]
    },
    {
        path: 'manage-nft/burn-nft',
        component: BurnnftComponent,
        canActivate: [AuthService]
    },
    {
        path: 'manage-user/blocked-user',
        component: ManageUserComponent,
        canActivate: [AuthService]
    },
    {
        path: 'manage-user',
        component: AllUserComponent,
        canActivate: [AuthService]
    },
    {
        path: 'manage-user/user-profile/:address',
        component: ProfileuserComponent,
        canActivate: [AuthService]
    },
    {
        path: 'manage-user/user-edit/:id',
        component: EditUserComponent,
        canActivate: [AuthService]
    },
    {
        path: 'manage-user/holding/:address',
        component: NftHoldingComponent,
        canActivate: [AuthService]
    },
    {
        path: 'manage-user/user-transaction/:id/:address',
        component: NftTransacationComponent,
        canActivate: [AuthService]
    },
    {
        path: 'dashboard/transaction-detail/:id',
        component: TransacationDetailComponent,
        canActivate: [AuthService]
    },
    {
        path: 'royalty',
        component: RoyaltyComponent,
        canActivate: [AuthService]
    },
    {
        path: 'dashboard/view-all-logs',
        component: TransactionsLogsListComponent,
        canActivate: [AuthService]
    },
    {
        path: 'dashboard/view-all-user-logs',
        component: UserLogsDetailsComponent,
        canActivate: [AuthService]
    },
    {
        path: 'manage-delivery',
        component: ManageDeliveryComponent,
        canActivate: [AuthService]
    },
    {
        path: 'manage-delivery/delivery-history',
        component: DeliveryHistoryComponent,
        canActivate: [AuthService]
    },
    {
        path: 'manage-delivery/delivery-order-detail/:id',
        component: DeliveryDetailsComponent,
        canActivate: [AuthService]
    },
    {
        path: 'announcement-detail',
        component: AnnouncementDetailComponent,
        canActivate: [AuthService]
    },



];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
})
/**
 * Export Class
 */
export class AppRoutingModule { }
