<div class="main-wrap">
  <app-sidebar class="sidebarview"></app-sidebar>
  <section class="section">
    <div class="container-fluid">
      <div class="managecollection">
        <div class="row mb-4">
          <div class="col-lg-6">
            <ul class="manage-collection">
              <li class="nav-item " [routerLink]="['/manage-delivery']" routerLinkActive="menuactivities"
                [routerLinkActiveOptions]="{exact: true}">Manage Delivery.</li>
              <li class="nav-item" [routerLink]="['/manage-delivery/delivery-history']">Delivery History.</li>
            </ul>
          </div>
        </div>
        <div class="row mb-4 align-items-center">
          <div class="col-lg-4 col-md-6 custom-mb">
            <label>Search.</label>
            <input type="search" class="form-control" [formControl]="searchControl"
              placeholder="Enter Asset Name, User, Address...">
          </div>
          <div class="col-lg-8 col-md-6">
            <div class="d-flex align-items-center justify-content-end flex-wrap gap-14">
              <div>
                <label for="">Status.</label>
                <div class="dropdown">
                  <a class="btn btn-status status{{filterStatus['status']}}" role="button" id="status"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    {{filterStatus['name']}} <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17"
                      viewBox="0 0 16 17" fill="none">
                      <path
                        d="M3.39287 6.2499C3.49741 6.00366 3.73902 5.84375 4.00652 5.84375H12.0066C12.274 5.84375 12.5156 6.00366 12.6202 6.2499C12.7248 6.49614 12.672 6.78102 12.4862 6.97347L8.93902 10.6474C8.84482 10.7451 8.73315 10.8609 8.62402 10.9471C8.49328 11.0505 8.28722 11.1771 8.00655 11.1771C7.72582 11.1771 7.51975 11.0505 7.38902 10.9471C7.27995 10.8609 7.16822 10.7451 7.07402 10.6474L3.52692 6.97347C3.34111 6.78102 3.28833 6.49614 3.39287 6.2499Z"
                        fill="currentColor" fill-opacity="1" />
                    </svg>
                  </a>
                  <ul class="dropdown-menu allstatus">
                    <li class="dropdown-item" (click)="changeFilterStatus('All Status',0,'')">All Status</li>
                    <li class="dropdown-item" *ngFor="let data of deliveryStatus"
                      (click)="changeFilterStatus(data.name,data.status,data._id)">{{data.name}}</li>
                  </ul>
                </div>
              </div>
              <div>
                <label for="">Show.</label>
                <div class="dropdown">
                  <a (click)="toggleDropdown($event)" class="btn btn-status" role="button" id="status"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    {{currentPageLimit}} Entry <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17"
                      viewBox="0 0 16 17" fill="none">
                      <path
                        d="M3.39287 6.2499C3.49741 6.00366 3.73902 5.84375 4.00652 5.84375H12.0066C12.274 5.84375 12.5156 6.00366 12.6202 6.2499C12.7248 6.49614 12.672 6.78102 12.4862 6.97347L8.93902 10.6474C8.84482 10.7451 8.73315 10.8609 8.62402 10.9471C8.49328 11.0505 8.28722 11.1771 8.00655 11.1771C7.72582 11.1771 7.51975 11.0505 7.38902 10.9471C7.27995 10.8609 7.16822 10.7451 7.07402 10.6474L3.52692 6.97347C3.34111 6.78102 3.28833 6.49614 3.39287 6.2499Z"
                        fill="currentColor" fill-opacity="1" />
                    </svg>
                  </a>
                  <ul class="dropdown-menu allstatus" aria-labelledby="status" aria-labelledby="status" id="status">
                    <li class="dropdown-item" (click)="changePageLimit(10)">10 Entry</li>
                    <li class="dropdown-item" (click)="changePageLimit(20)">20 Entry</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <th>Order ID.</th>
                  <th>Item.</th>
                  <th>User ID.</th>
                  <!-- <th>Quantity.</th> -->
                  <th>Location.</th>
                  <th>Initiated.</th>
                  <th>Status.</th>
                </thead>
                <tbody>
                  <ng-container *ngIf="!loader">
                    <tr class="cursor-pointer" *ngFor="let data of deliveryList;trackBy: trackByFn;let i= index;">
                      <td [routerLink]="['/manage-delivery/delivery-order-detail',data._id]">
                        <p class="trash">{{data.order_id}}</p>
                      </td>
                      <td [routerLink]="['/manage-delivery/delivery-order-detail',data._id]">
                        <div class="d-flex gap-2">
                          <img *ngIf="data?.nft_id['fileType'] != 'html'" src="{{data.nft_id['primary_media']}}"
                            alt="nft img" width="48" height="35" onerror="this.src='assets/images/create-nft.png'">
                          <div class="gemsView" *ngIf="data?.nft_id['fileType'] === 'html'">
                            <iframe
                              [src]="data.nft_id['preview_image'] ? data.nft_id['preview_image'] : data.nft_id['primary_media'] | safe"
                              class="img-fluid" frameborder="0" scrolling="no"></iframe>
                          </div>
                          <h6>{{data.nft_id['name']}}</h6>
                        </div>
                      </td>
                      <td [routerLink]="['/manage-user/user-profile/',data.user_id['wallet_address']]">
                        <div class="d-flex gap-2">
                          <p class="trash">{{data.user_id['name']}}</p>
                          <a class="cursor-pointer"><img src="assets/images/external-delivery.svg" alt="external"></a>
                        </div>
                      </td>
                      <!-- <td [routerLink]="['/manage-delivery/delivery-order-detail',data._id]">
                                                <p class="text-center">{{data.quantity}}</p>
                                            </td> -->
                      <td [routerLink]="['/manage-delivery/delivery-order-detail',data._id]">
                        <p>{{data.country}}</p>
                      </td>
                      <td [routerLink]="['/manage-delivery/delivery-order-detail',data._id]">
                        <p class="datetime">{{data.createdAt | date:"dd/MM/YYYY" }} <span class="">{{data.createdAt |
                            date:"hh:mm:ss a" }}</span></p>
                      </td>
                      <td align="center">
                        <select class="form-select btn status{{data.status['status']}}"
                          aria-label="Default select example" id="status-update{{i+1}}"
                          (change)="confirmAlert(data['_id'],i,data._id,data.status['status'])">
                          <option *ngFor="let deliveryData of deliveryStatus"
                            [selected]="deliveryData.status == data.status['status']"
                            value={{deliveryData.status}},{{deliveryData._id}}>{{deliveryData.name}}</option>
                        </select>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="loader">
                    <tr class="text-center">
                      <td colspan="6">
                        <div class="d-flex justify-content-center my-2">
                          <span>Loading.</span> <span class="Loaders"></span>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="!loader && deliveryList?.length == 0">
                    <tr class="text-center">
                      <td colspan="6">
                        <div class="data-not-found">
                          <img src="assets/images/data-not-found.svg" alt="data not found" width="320" height="180"
                            class="img-fluid mb-3">
                          <h6>Manage delivery not found.</h6>
                        </div>
                      </td>
                    </tr>
                  </ng-container>

                </tbody>
              </table>
            </div>

          </div>

        </div>
      </div>
      <div class="row">
        <div class="col-lg-12" *ngIf="!loader && deliveryList.length != 0">
          <div class="d-flex justify-content-end flex-wrap">
            <div>
              <button *ngIf="deliveryListPagination?.page != 1" class="previous-btn"
                (click)="deliveryDetails(deliveryListPagination?.page - 1, currentPageLimit)">
                <span class="me-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M10.5371 3.60462C10.3187 3.30815 9.90141 3.24483 9.60495 3.46317C9.37468 3.63277 9.15579 3.8024 8.96513 3.95118C8.58459 4.24819 8.07486 4.65707 7.56326 5.10131C7.05493 5.54267 6.52939 6.03202 6.12572 6.48888C5.92459 6.71652 5.73959 6.95185 5.60126 7.18132C5.47399 7.39245 5.33366 7.68259 5.33366 7.99999C5.33366 8.31739 5.47399 8.60759 5.60126 8.81872C5.73959 9.04819 5.92459 9.28345 6.12572 9.51112C6.52939 9.96799 7.05493 10.4573 7.56326 10.8987C8.07486 11.3429 8.58459 11.7518 8.96513 12.0489C9.15579 12.1976 9.37468 12.3673 9.60495 12.5369C9.90141 12.7552 10.3187 12.6919 10.5371 12.3954C10.6249 12.2762 10.6671 12.1375 10.667 12.0001L10.667 7.99999V3.99997C10.6671 3.8625 10.6249 3.72379 10.5371 3.60462Z"
                      fill="currentColor" />
                  </svg>
                </span>Prev
              </button>
              <button *ngIf="deliveryListPagination?.page == 1" disabled class="previous-btn disble-btn">
                <span class="me-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M10.5371 3.60462C10.3187 3.30815 9.90141 3.24483 9.60495 3.46317C9.37468 3.63277 9.15579 3.8024 8.96513 3.95118C8.58459 4.24819 8.07486 4.65707 7.56326 5.10131C7.05493 5.54267 6.52939 6.03202 6.12572 6.48888C5.92459 6.71652 5.73959 6.95185 5.60126 7.18132C5.47399 7.39245 5.33366 7.68259 5.33366 7.99999C5.33366 8.31739 5.47399 8.60759 5.60126 8.81872C5.73959 9.04819 5.92459 9.28345 6.12572 9.51112C6.52939 9.96799 7.05493 10.4573 7.56326 10.8987C8.07486 11.3429 8.58459 11.7518 8.96513 12.0489C9.15579 12.1976 9.37468 12.3673 9.60495 12.5369C9.90141 12.7552 10.3187 12.6919 10.5371 12.3954C10.6249 12.2762 10.6671 12.1375 10.667 12.0001L10.667 7.99999V3.99997C10.6671 3.8625 10.6249 3.72379 10.5371 3.60462Z"
                      fill="currentColor" />
                  </svg>
                </span>Prev
              </button>
            </div>
            <div>
              <nav>
                <ul class="pagination">
                  <li class="page-item cursor-pointer"
                    *ngIf="deliveryListPagination.prevPage != null  && (deliveryListPagination.prevPage - 1) != 0 && (deliveryListPagination.prevPage - 1) != deliveryListPagination.page"
                    (click)="deliveryDetails(1, currentPageLimit)">
                    <a class="page-link">1</a>
                  </li>
                  <li class="page-item cursor-pointer">
                    <a class="page-link" *ngIf="deliveryListPagination.prevPage > 0"
                      (click)="deliveryDetails(deliveryListPagination.prevPage, currentPageLimit)">{{deliveryListPagination.prevPage}}</a>
                  </li>
                  <li class="page-item cursor-pointer"><a class="page-link active">{{deliveryListPagination.page}}</a>
                  </li>
                  <li class="page-item cursor-pointer"><a class="page-link" *ngIf="deliveryListPagination.nextPage > 0"
                      (click)="deliveryDetails(deliveryListPagination.nextPage, currentPageLimit)">{{deliveryListPagination.nextPage}}</a>
                  </li>
                  <li class="page-item"
                    *ngIf="deliveryListPagination.totalPages > deliveryListPagination.nextPage && deliveryListPagination.nextPage != null && (deliveryListPagination.nextPage + 1) != deliveryListPagination.totalPages">
                    <a class="page-link">..</a>
                  </li>
                  <li class="page-item cursor-pointer"
                    *ngIf="deliveryListPagination.totalPages > deliveryListPagination.nextPage && deliveryListPagination.nextPage != null"
                    (click)="deliveryDetails(deliveryListPagination.totalPages, currentPageLimit)">
                    <a class="page-link">{{deliveryListPagination.totalPages}}</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div>
              <button *ngIf="deliveryListPagination?.page != deliveryListPagination?.totalPages" class="next-btn"
                (click)="deliveryDetails(deliveryListPagination?.page + 1, currentPageLimit)">Next
                <span class="ms-1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                    fill="none">
                    <path
                      d="M5.46292 3.60462C5.68126 3.30815 6.09859 3.24483 6.39505 3.46317C6.62532 3.63277 6.84421 3.8024 7.03487 3.95118C7.41541 4.24819 7.92514 4.65707 8.43674 5.10131C8.94507 5.54267 9.47061 6.03202 9.87428 6.48888C10.0754 6.71652 10.2604 6.95185 10.3987 7.18132C10.526 7.39245 10.6663 7.68259 10.6663 7.99999C10.6663 8.31739 10.526 8.60759 10.3987 8.81872C10.2604 9.04819 10.0754 9.28345 9.87428 9.51112C9.47061 9.96799 8.94507 10.4573 8.43674 10.8987C7.92514 11.3429 7.41541 11.7518 7.03487 12.0489C6.84421 12.1976 6.62532 12.3673 6.39505 12.5369C6.09859 12.7552 5.68126 12.6919 5.46292 12.3954C5.37514 12.2762 5.33289 12.1375 5.33301 12.0001L5.33301 7.99999V3.99997C5.33289 3.8625 5.37514 3.72379 5.46292 3.60462Z"
                      fill="currentColor" />
                  </svg></span>
              </button>
              <button *ngIf="deliveryListPagination?.page == deliveryListPagination?.totalPages" disabled
                class="next-btn disble-btn">Next
                <span class="ms-1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                    fill="none">
                    <path
                      d="M5.46292 3.60462C5.68126 3.30815 6.09859 3.24483 6.39505 3.46317C6.62532 3.63277 6.84421 3.8024 7.03487 3.95118C7.41541 4.24819 7.92514 4.65707 8.43674 5.10131C8.94507 5.54267 9.47061 6.03202 9.87428 6.48888C10.0754 6.71652 10.2604 6.95185 10.3987 7.18132C10.526 7.39245 10.6663 7.68259 10.6663 7.99999C10.6663 8.31739 10.526 8.60759 10.3987 8.81872C10.2604 9.04819 10.0754 9.28345 9.87428 9.51112C9.47061 9.96799 8.94507 10.4573 8.43674 10.8987C7.92514 11.3429 7.41541 11.7518 7.03487 12.0489C6.84421 12.1976 6.62532 12.3673 6.39505 12.5369C6.09859 12.7552 5.68126 12.6919 5.46292 12.3954C5.37514 12.2762 5.33289 12.1375 5.33301 12.0001L5.33301 7.99999V3.99997C5.33289 3.8625 5.37514 3.72379 5.46292 3.60462Z"
                      fill="currentColor" />
                  </svg></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>