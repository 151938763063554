import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const apiUrl = environment.API_BASE_URL;

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

  constructor(private http: HttpClient) { }

  /**
   * Gets delivery details
   * @param {string} address 
   * @param {number} page 
   * @param {number} limit 
   * @param {string} status 
   * @param {string} search 
   * @returns  
   */
  getDeliveryDetails(address:string,page:number,limit:number,status?:string,search?:string){
    let statusFilter = status ? `&status=${status}` : '';
    let searchFilter = search ? `&search=${search}` : '';
    return this.http.get(`${apiUrl}admin/list-delivery-request?address=${address}&page=${page}&limit=${limit}${statusFilter}${searchFilter}`);
  }

  /**
   * Gets delivery status
   * @returns  
   */
  getDeliveryStatus(){
    return this.http.get(`${apiUrl}admin/delivery-status`);
  }

  /**
   * Updates delivery details
   * @param {string} id 
   * @param {object} data 
   * @returns  
   */
  updateDeliveryDetails(id:string,data:object){
    return this.http.patch(`${apiUrl}admin/delivery-request?id=${id}`,data);
  }

  /**
   * Get delivery history details
   * @param {string} address 
   * @param {number} page 
   * @param {number} limit 
   * @param {string} status 
   * @param {string} search
   * @returns  
   */
  getDeliveryHistoryDetails(address:string,page: number, limit: number, status?: string, search?: string) {
    let statusFilter = status ? `&status=${status}` : '';
    let searchFilter = search ? `&search=${search}` : '';
    return this.http.get(`${apiUrl}admin/list-delivered-cancelled-request?address=${address}&page=${page}&limit=${limit}${statusFilter}${searchFilter}`);
  }

  /**
   * Gets delivery details by id
   * @param {string} id 
   * @returns  
   */
  getDeliveryDetailsById(id:string){
    return this.http.get(`${apiUrl}admin/delivery-request?id=${id}`);
  }

  /**
   * Gets delivery history by id
   * @param {string} id 
   * @returns  
   */
  getDeliveryHistoryById(id:string){
    return this.http.get(`${apiUrl}admin/delivery-history?id=${id}`);
  }
}
