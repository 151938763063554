<div class="showdrop">
    <div class="loading-notfound" *ngIf="loader">
        <hr class="custom-hr">
        <div class="loader"></div> 
        <p>Please wait while we load your items. </p>&nbsp;
    </div>
    <div class="loading-notfound mb-3" *ngIf="!loader && searchData.data.log.length == 0 && searchData.data.collections.length == 0 && searchData.data.nfts.length == 0 && searchData.data.users.length == 0 && searchData.data.user_holdings.length == 0">
        <img src="https://static.zohocdn.com/chat/source/officechat/images/default/emptystate/search_empty.svg" alt="" width="100" height="75" class="mb-3">
        <div>
            <p>No results</p>
            <p>Tinker with a word or two and try again.</p>
        </div>
    </div>
    <div *ngIf="!loader">
        <ng-container *ngIf="searchData.data.log.length > 0">
            <h3>Dashboard.</h3>
            <ng-container *ngFor="let data of searchData.data.log">
                <div class="common-space cursor-pointer" (click)="clickResetSearch()" [routerLink]="data.method != 'Signup' && 'User blocked' && 'User unblocked' && 'Update user' && 'Apple auth' && 'Google auth' ? ['/dashboard/transaction-detail', data._id] : ['/dashboard/view-all-user-logs']">

                    <div class="common-wrap mb-14">
                        <div class="common-img">
                            <img src="assets/images/icon/Dashboard.svg" alt="wallet" width="18" height="18">
                        </div>
                            <h4 [innerHTML]="boldString(data.matching_field['value'], searchKey)"></h4>
                        </div>
                        <div>
                            <a ><img src="assets/images/external-search.svg" alt=""></a>
                        </div>
                    </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="searchData.data.collections.length > 0">
            <hr>
            <h3>Manage collections.</h3>
            <ng-container *ngFor="let data of searchData.data.collections">
                <div class="common-space mb-14 cursor-pointer" (click)="clickResetSearch()" [routerLink]="['/manage-collection', data.collection_address]">
                    <div class="common-wrap">
                        <div class="common-img">
                            <img src="assets/images/icon/Manageassets.svg" alt="wallet" width="18" height="18">
                        </div>
                        <h4 [innerHTML]="boldString(data.matching_field['value'], searchKey)"></h4>
                    </div>
                    <div>
                        <a><img src="assets/images/external-search.svg" alt=""></a>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="searchData.data.nfts.length > 0">
            <hr>
            <h3>Manage NFT.</h3>
            <ng-container *ngFor="let data of searchData.data.nfts">
                <div class="common-space mb-14 cursor-pointer" (click)="clickResetSearch()" [routerLink]="['/manage-nft/edit-nft',data.collections.collection_address, data.token_id]">
                    <div class="common-wrap">
                        <div class="common-img">
                            <img src="assets/images/icon/ManageNft.svg" alt="wallet" width="18" height="18">
                        </div>
                        <h4 [innerHTML]="boldString(data.matching_field['value'], searchKey)"></h4>
                    </div>
                    <div> 
                        <a><img src="assets/images/external-search.svg" alt=""></a>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="searchData.data.users.length > 0">
            <hr>
            <h3>Manage users.</h3>
            <ng-container  *ngFor="let data of searchData.data.users">
                <div class="common-space mb-14 cursor-pointer" (click)="clickResetSearch()" [routerLink]="['/user-profile', data.wallet_address]">
                    <div class="common-wrap">
                        <div class="common-img">
                            <img src="assets/images/icon/Manageusers.svg" alt="wallet" width="18" height="18">
                        </div>
                        <h4 [innerHTML]="boldString(data.matching_field['value'], searchKey)"></h4>
                    </div>
                    <div>
                        <a><img src="assets/images/external-search.svg" alt=""></a>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="searchData.data.user_holdings.length > 0">
            <hr>
            <h3>Users holdings.</h3>
            <ng-container *ngFor="let data of searchData.data.user_holdings">
                <a class="common-space mb-14 cursor-pointer" (click)="clickResetSearch()" [href]="data.lazy_mint ? marketPlaceUrl+'lazy-mint/'+data._id+'?regulated='+isRegulated : marketPlaceUrl+'nft-detail/'+data.collections.collection_address+'/'+data.token_id+'?regulated='+isRegulated" target="_blank">
                    <div class="common-wrap">
                        <div class="common-img">
                            <img src="assets/images/icon/Manageusers.svg" alt="wallet" width="18" height="18">
                        </div>
                        <h4 [innerHTML]="boldString(data.matching_field['value'], searchKey)"></h4>
                    </div>
                    <div>
                        <a><img src="assets/images/external-search.svg" alt=""></a>
                    </div>
                </a>
            </ng-container>
        </ng-container>
    </div>
</div>