import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { DeliveryService } from 'src/app/services/delivery.service';
import { StorageService } from 'src/app/services/storage.service';
import { deliveryResponse, deliveryStatus, IPagination, walletAccount } from 'src/app/shared/interface/interface';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-manage-delivery',
    templateUrl: './manage-delivery.component.html',
    styleUrls: ['./manage-delivery.component.scss']
})
export class ManageDeliveryComponent implements OnInit {
    currentPageLimit: number = 10;
    currentPage: number = 1;
    loader: boolean = false;
    deliveryList: deliveryResponse[];
    isDropdownOpen = false;
    deliveryListPagination: IPagination;
    deliveryStatus: deliveryStatus[];
    filterStatus: object = { name: 'All Status', status: 0 };
    searchControl = new FormControl();
    unsubScribeSearch$ = new Subject<void>();
    public account: walletAccount;
    filterId: string;


    constructor(
        private deliveryService: DeliveryService,
        private el: ElementRef,
        private accountService: AccountService,
        private storageService: StorageService,
    ) { }

    /**
     * on init
     */
    ngOnInit(): void {

        this.account = this.storageService.getItem('wagmi.store') === null ?
            { address: '', network: '', chainId: '', provider: '' } :
            JSON.parse(this.storageService.getItem('wagmi.store') as any);

        this.accountService.accountObserve.subscribe((response) => {
            if (response) {
                this.account = this.storageService.getItem('wagmi.store') === null ?
                    { address: '', network: '', chainId: '', provider: '' } :
                    JSON.parse(this.storageService.getItem('wagmi.store') as any);
                this.deliveryDetails(this.currentPage, this.currentPageLimit);
            }
        });
        this.deliveryDetails(this.currentPage, this.currentPageLimit);
        this.getDeliveryStatus();
        this.searchControl.valueChanges.pipe(
            debounceTime(1000),
            takeUntil(this.unsubScribeSearch$)
        ).subscribe((keyword: string) => {
            this.deliveryDetails(this.currentPage, this.currentPageLimit, null, keyword);
        })
    }


    /**
   * Track by function for ngFor loops
   * @param index
   * @param item
   */
    trackByFn(index: number, item: any): any {
        return item._id || index;
    }

    /**
     * Get deliverys details
     * @param {number} page
     * @param {number} limit
     * @param {string} status
     * @param {string} search
     */
    deliveryDetails(page: number, limit: number, status?: string, search?: string) {
        this.loader = true;
        this.deliveryService.getDeliveryDetails(this.account.state.data.account, page, limit, status, search).subscribe((response: any) => {
            this.deliveryList = response.data.docs;
            for (const nft of response.data.docs) {
                nft.nft_id['fileType'] = nft.nft_id['preview_image'] ? nft.nft_id['preview_image'].split('.')[nft.nft_id['preview_image'].split('.').length - 1] : nft.nft_id['primary_media'].split('.')[nft.nft_id['primary_media'].split('.').length - 1]
            }
            this.deliveryListPagination = response.data;
            this.loader = false;
        },
            (_error) => {
                this.loader = false;
            })
    }
    /**
     * Changes page limit
     * @param {number} limit
     */
    changePageLimit(limit: number) {
        this.currentPageLimit = limit;
        this.deliveryDetails(this.currentPage, this.currentPageLimit, this.filterId);
    }

    /**
     * Changes filter status
     * @param {string} name
     * @param {number} status
     * @param {string} id
     */
    changeFilterStatus(name: string, status: number, id: string) {
        this.filterStatus = { name: name, status: status };
        this.filterId = id;
        this.deliveryDetails(this.currentPage, this.currentPageLimit, id);
    }


    /**
     * Toggles dropdown
     * @param {Event} event
     */
    toggleDropdown(event: Event) {
        event.stopPropagation();
        this.isDropdownOpen = !this.isDropdownOpen;
    }


    /**
     * Gets delivery status
     */
    getDeliveryStatus() {
        this.deliveryService.getDeliveryStatus().subscribe((response) => {
            this.deliveryStatus = response['data'];
            this.deliveryStatus = this.deliveryStatus.filter(item => { if (item.name != 'Cancelled' && item.name != 'Burned') return item; });
        })
    }

    /**
     * Confirms alert
     * @param {number} index
     * @param {string} id
     * @param {number} currentStatus
     */
    confirmAlert(deliveryId: string, index: number, id: string, currentStatus: number) {
        let selectElement = document.getElementById(`status-update${index + 1}`) as HTMLSelectElement;
        let originalValue = selectElement.value;

        Swal.fire({
            title: `Are you sure want to change status?`,
            text: `Click OK to change status.`,
            type: 'warning',
            confirmButtonText: 'OK.',
            cancelButtonText: 'Cancel.',
            showCloseButton: true,
            showCancelButton: true,
        }).then((result) => {
            if (result.dismiss) {
                // Find the option with the matching status and select it
                let options = Array.from(selectElement.options);
                let optionToSelect = options.find(option => option.value.startsWith(currentStatus.toString()));

                if (optionToSelect) {
                    optionToSelect.selected = true;
                } else {
                    console.error("No matching option found for status:", currentStatus);
                }
                Swal.fire({ title: '', text: `Status not changed.`, type: 'error', icon: 'error', confirmButtonText: 'OK.' });

            } else if (result.isConfirmed) {
                // User clicked OK
                Swal.fire({
                    onBeforeOpen: () => {
                        Swal.showLoading()
                    },
                    allowOutsideClick: false,
                    text: 'Processing, Please wait...',
                });

                let newStatus = selectElement.value.split(',')[0];
                let newStatusId = selectElement.value.split(',')[1];
                this.updateDeliveryStatus(index, id, newStatus, currentStatus, newStatusId);
            }
        });
    }
    /**
     * Updates delivery status
     * @param {number} index
     * @param {string} id
     * @param {string} string
     * @param {number} currentStatus
     */
    updateDeliveryStatus(index: number, id: string, status: string, currentStatus: number, statusId: string) {

        (document.getElementById(`status-update${index + 1}`) as HTMLElement).className = `form-select btn status${status}`;
        const data = {
            status: statusId
        }
        this.deliveryService.updateDeliveryDetails(id, data).subscribe((response) => {
            Swal.fire({ title: '', text: `Status changed successfully.`, type: 'success', icon: 'success', confirmButtonText: 'OK.' });
            this.deliveryDetails(this.currentPage, this.currentPageLimit, this.filterStatus['status']);
            this.searchControl.patchValue('');
        }, (error) => {
            (document.getElementById(`status-update${index + 1}`) as HTMLInputElement).value = currentStatus.toString();
            Swal.close();
        })
    }

}
