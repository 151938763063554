import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LogDebugsService {

  constructor(
    private router: Router
  ) { }

  /**
   * Debugs log debugs service
   * @param {string} apiEndPoint 
   * @param {any} response 
   * @param {string} type 
   */
  debugs(apiEndPoint,response,type,status_type,payload?){
  const objectData = {
      apiEndPoint:apiEndPoint,
      url:this.router.url,
      response:response,
      type:type,
      status_type:status_type,
      payload:payload
    }
  }

}
