import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { NftManagementService } from 'src/app/services/nft-management.service';
import { StorageService } from 'src/app/services/storage.service';
import { IPagination, walletAccount } from 'src/app/shared/interface/interface';

@Component({
    selector: 'app-manage-collection',
    templateUrl: './manage-collection.component.html',
    styleUrls: ['./manage-collection.component.scss']
})
export class ManageCollectionComponent implements OnInit {
    public collections = [];
    public account: walletAccount;
    public loader: boolean = true;
    public currentPageLimit: number = 10;
    public collectionsPaginations: IPagination;
    collectionAddress: string = '';
    /**
     * Creates an instance of collections component.
     */
    constructor(
        private router: Router,
        private nftManagementService: NftManagementService,
        private storageService: StorageService,
        private clipboardservice: ClipboardService,
        private toastr: ToastrService,
        private accountService: AccountService,
        private route: ActivatedRoute,
    ) { }

    /**
     * on init
     */
    public ngOnInit(): void {
        this.account = this.storageService.getItem('wagmi.store') === null ?
            { address: '', network: '', chainId: '', provider: '' } :
            JSON.parse(this.storageService.getItem('wagmi.store') as any);
        this.collectionAddress = this.route.snapshot.paramMap.get('address') as any;
        this.getCollections(1, this.currentPageLimit, this.collectionAddress);
        this.accountService.accountObserve.subscribe((response) => {
            if (response) {
                this.account = this.storageService.getItem('wagmi.store') === null ?
                    { address: '', network: '', chainId: '', provider: '' } :
                    JSON.parse(this.storageService.getItem('wagmi.store') as any);
                this.getCollections(1, this.currentPageLimit, this.collectionAddress);
            }
        });
    }

    /**
     * Gets collections
     * @param {number} page
     * @param {number} limit
     */
    getCollections(page: number, limit: number, collectionAddress?: string) {
        this.loader = true;
        collectionAddress = collectionAddress ? collectionAddress : '';
        this.nftManagementService.getCollectionsByAddress(page, limit, this.account.state.data.account, collectionAddress).subscribe((response: any) => {
            this.collections = response.data.collections || [];
            this.collectionsPaginations = response.data;
            this.loader = false;
        }, (_error) => {
            this.loader = false;
        }
        )
    }

    /**
     * Track by function for ngFor loops
     * @param index
     * @param item
     */
    public trackByFn(index: number, item: any): any {
        return item._id || index;
    }

    /**
     * Creates nft
     * @param {string} collectionAddress
     */
    createNft(collectionAddress: string) {
        this.router.navigate(['/manage-nft/create-nft', collectionAddress])
    }


    /**
     * Copys address
     * @param {string} content
     */
    copyAddress(content: string) {
        this.clipboardservice.copyFromContent(content);
        this.toastr.success('Copied address');
    }

}
