import { Component, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { DeliveryService } from 'src/app/services/delivery.service';
import { NftManagementService } from 'src/app/services/nft-management.service';
import { StorageService } from 'src/app/services/storage.service';
import { ManageTransactionsDirective } from 'src/app/shared/directives/manage-transactions.directive';
import { deliveryResponse, deliveryStatus, IPagination, walletAccount } from 'src/app/shared/interface/interface';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import Web3 from 'web3';

let web3 = new Web3(window['ethereum'] as any);
const apiUrl = environment.API_BASE_URL;

@Component({
    selector: 'app-delivery-history',
    templateUrl: './delivery-history.component.html',
    styleUrls: ['./delivery-history.component.scss']
})
export class DeliveryHistoryComponent {
    selectedItem: any;
    currentPageLimit: number = 10;
    currentPage: number = 1;
    loader: boolean = false;
    deliveryList: deliveryResponse[];
    isDropdownOpen = false;
    deliveryListPagination: IPagination;
    deliveryStatus: deliveryStatus[];
    filterDeliveryStatus: deliveryStatus[];
    filterStatus: object = { name: 'All Status', status: 0 };
    searchControl = new FormControl();
    unsubScribeSearch$ = new Subject<void>();
    public account: walletAccount;
    filterId: string;

    constructor(
        private deliveryService: DeliveryService,
        private el: ElementRef,
        private manageTransactionsDirective: ManageTransactionsDirective,
        private nftManagementService: NftManagementService,
        private accountService: AccountService,
        private storageService: StorageService,
    ) { }

    /**
   * on init
   */
    ngOnInit(): void {
        this.account = this.storageService.getItem('wagmi.store') === null ?
            { address: '', network: '', chainId: '', provider: '' } :
            JSON.parse(this.storageService.getItem('wagmi.store') as any);

        this.accountService.accountObserve.subscribe((response) => {
            if (response) {
                this.account = this.storageService.getItem('wagmi.store') === null ?
                    { address: '', network: '', chainId: '', provider: '' } :
                    JSON.parse(this.storageService.getItem('wagmi.store') as any);
            }
        });

        this.deliveryHistoryDetails(this.currentPage, this.currentPageLimit);
        this.getDeliveryStatus();
        this.searchControl.valueChanges.pipe(
            debounceTime(1000),
            takeUntil(this.unsubScribeSearch$)
        ).subscribe((keyword: string) => {
            this.deliveryHistoryDetails(this.currentPage, this.currentPageLimit, this.filterId ? this.filterId : null, keyword);
        })

    }

    /**
  * Track by function for ngFor loops
  * @param index
  * @param item
  */
    trackByFn(index: number, item: any): any {
        return item._id || index;
    }

    /**
     * Get deliverys details
     * @param {number} page
     * @param {number} limit
     * @param {string} status
     * @param {number} search
     */
    deliveryHistoryDetails(page: number, limit: number, status?: string, search?: string) {
        this.loader = true;
        this.deliveryService.getDeliveryHistoryDetails(this.account.state.data.account, page, limit, status, search).subscribe((response: any) => {
            this.deliveryList = response.data.nfts;
            for (const nft of response.data.nfts) {
                nft.nft_id['fileType'] = nft.nft_id['preview_image'] ? nft.nft_id['preview_image'].split('.')[nft.nft_id['preview_image'].split('.').length - 1] : nft.nft_id['primary_media'].split('.')[nft.nft_id['primary_media'].split('.').length - 1]
            }
            this.selectedItem = this.deliveryList.map((item, i) => { return { status: item.status, count: i } })
            this.deliveryListPagination = response.data;
            this.loader = false;
        },
            (_error) => {
                this.loader = false;
            })
    }
    /**
     * Changes page limit
     * @param {number} limit
     */
    changePageLimit(limit: number) {
        this.currentPageLimit = limit;
        this.deliveryHistoryDetails(this.currentPage, this.currentPageLimit, this.filterId);
    }

    /**
     * Changes filter status
     * @param {string} name
     * @param {number} status
     * @param {string} id
     */
    changeFilterStatus(name: string, status: number, id: string) {
        this.filterStatus = { name: name, status: status };
        this.filterId = id;
        this.deliveryHistoryDetails(this.currentPage, this.currentPageLimit, id);
    }

    /**
     * Toggles dropdown
     * @param {Event} event
     */
    toggleDropdown(event: Event) {
        event.stopPropagation();
        this.isDropdownOpen = !this.isDropdownOpen;
    }


    /**
     * Gets delivery status
     */
    getDeliveryStatus() {
        this.deliveryService.getDeliveryStatus().subscribe((response) => {
            this.deliveryStatus = response['data'];
            // this property is for filter menu
            this.filterDeliveryStatus = this.deliveryStatus.filter(item => {
                return item.name === 'Burned' || item.name === 'Cancelled' || item.name === 'Order delivered';
            });
            this.deliveryStatus = this.deliveryStatus.filter(item => { if (item.name != 'Burned') return item; });


        })
    }

    // NFT Burn process

    /**
   * Confirms alert
   * @param {string} tokenId
   * @param {string} deliveryId
   * @param {string} address
   * @param {string} name
   */
    public confirmAlert(deliveryId: string, tokenId: string, address: string, name: string) {
        Swal.fire({
            title: `Are you sure want to burn to ${name} item?`,
            text: `Click OK to burn to ${name} item.`,
            type: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'OK.',
            cancelButtonText: 'Cancel.',
        }).then((willDelete) => {
            Swal.fire({
                onBeforeOpen: () => {
                    Swal.showLoading()
                },
                allowOutsideClick: false,
                text: 'Processing, Please wait...',
            });
            if (willDelete.dismiss) {
                Swal.fire({ title: '', text: `Not burn ${name} item.`, type: 'error', icon: 'error', confirmButtonText: 'OK.' });

            }
            else {
                this.burnProcess(deliveryId, tokenId, address);
            }


        });
    }


    /**
     * Burns process
     * @param {string} tokenId
     * @param {string} address
     */
    public async burnProcess(deliveryId, tokenId, address) {
        try {
            const { burnAbi, requiredGas } = await this.nftManagementService.burnNft(tokenId, address, this.account.state.data.account);
            const message = {
                to: address,
                data: burnAbi,
                gasPrice: await web3.utils.toHex(Number(await web3.eth.getGasPrice()) * 2),
                gasLimit: await web3.utils.toHex(Number(requiredGas) * 2)
            };
            this.manageTransactionsDirective.makeTransactions(message)
                .then(async (receipt) => {
                    this.deleteNft(deliveryId, tokenId, address);
                }).
                catch((error) => {
                    Swal.close();
                });
        }
        catch (error: any) {
            console.log("error", error);
            Swal.fire({ title: '', text: `${error?.data?.shortMessage ? error.data.shortMessage + '.' : 'Something went wrong.'}`, type: 'error', icon: 'error', confirmButtonText: 'OK.' });

        }
    }


    /**
     * Deletes nft
     * @param {string} tokenId
     * @param {string} collectionAddress
     */
    public deleteNft(deliveryId: string, tokenId: string, collectionAddress: string) {
        this.nftManagementService.deleteNft(deliveryId, tokenId, collectionAddress, this.account.state.data.account).subscribe((response) => {
            Swal.fire({ title: '', text: `Item burned successfully.`, type: 'success', icon: 'success', confirmButtonText: 'OK.' });
            this.deliveryHistoryDetails(this.currentPage, this.currentPageLimit);
            this.searchControl.patchValue('');

        },
            (error) => {
                Swal.fire({ title: '', text: `${error.error.message ? error.error.message + '.' : 'Something went wrong.'}`, type: 'error', icon: 'error', confirmButtonText: 'OK.' });

            })
    }

    /**
   * Confirms alert
   * @param {number} index
   * @param {string} id
   * @param {number} currentStatus
   */
    changeStatus(deliveryId: string, index: number, id: string, currentStatus: number) {
        let selectElement = document.getElementById(`status-update${index + 1}`) as HTMLSelectElement;
        let originalValue = selectElement.value;

        Swal.fire({
            title: `Are you sure want to change status?`,
            text: `Click OK to change status.`,
            type: 'warning',
            confirmButtonText: 'OK.',
            cancelButtonText: 'Cancel.',
            showCloseButton: true,
            showCancelButton: true,
        }).then((result) => {
            if (result.dismiss) {
                // Find the option with the matching status and select it
                let options = Array.from(selectElement.options);
                let optionToSelect = options.find(option => option.value.startsWith(currentStatus.toString()));

                if (optionToSelect) {
                    optionToSelect.selected = true;
                } else {
                    console.error("No matching option found for status:", currentStatus);
                }
                Swal.fire({ title: '', text: `Status not changed.`, type: 'error', icon: 'error', confirmButtonText: 'OK.' });

            } else if (result.isConfirmed) {
                // User clicked OK
                Swal.fire({
                    onBeforeOpen: () => {
                        Swal.showLoading()
                    },
                    allowOutsideClick: false,
                    text: 'Processing, Please wait...',
                });

                let newStatus = selectElement.value.split(',')[0];
                let newStatusId = selectElement.value.split(',')[1];
                this.updateDeliveryStatus(index, id, newStatus, currentStatus, newStatusId);
            }
        });
    }

    /**
   * Updates delivery status
   * @param {number} index
   * @param {string} id
   * @param {string} string
   * @param {number} currentStatus
   */
    updateDeliveryStatus(index: number, id: string, status: string, currentStatus: number, statusId: string) {

        (document.getElementById(`status-update${index + 1}`) as HTMLElement).className = `form-select btn status${status}`;
        const data = {
            status: statusId
        }
        this.deliveryService.updateDeliveryDetails(id, data).subscribe((response) => {
            Swal.fire({ title: '', text: `Status changed successfully.`, type: 'success', icon: 'success', confirmButtonText: 'OK.' });
            this.deliveryHistoryDetails(this.currentPage, this.currentPageLimit, this.filterStatus['status']);
            this.searchControl.patchValue('');
        }, (error) => {
            (document.getElementById(`status-update${index + 1}`) as HTMLInputElement).value = currentStatus.toString();
            Swal.close();
        })
    }

}
