import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const apiUrl = environment.API_BASE_URL;

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  constructor(private http: HttpClient) {

  }

  /**
   * Get logs
   * @param {number} page
   * @param {number} limit
   * @returns
   */
  public getLogs(page:number,limit:number){
    return this.http.get(`${apiUrl}logs/list-logs?page=${page}&limit=${limit}`);
  }

  /**
   * Gets user activity logs
   * @param {number} page
   * @param {number} limit
   * @returns
   */
  public getUserActivityLogs(page:number,limit:number){
    return this.http.get(`${apiUrl}logs/users-activity-logs?page=${page}&limit=${limit}`);
  }

  /**
   * Gets logs by id
   * @param {string} userId
   * @returns
   */
  public getLogsById(userId:string){
    return this.http.get(`${apiUrl}logs?id=${userId}`);
  }

  /**
   * Gets logs by address
   * @param {string} walletAddress
   * @returns
   */
  public getLogsByAddress(walletAddress:string){
    return this.http.get(`${apiUrl}user/get-user?wallet_address=${walletAddress}`);
  }

  /**
   * Gets user logs
   * @param {number} page
   * @param {number} limit
   * @param {string} walletAddress
   * @returns
   */
  public getUserLogs(page:number,limit:number,walletAddress:string){
    return this.http.get(`${apiUrl}logs/list-user-logs?wallet_address=${walletAddress}&page=${page}&limit=${limit}`);
  }


}
