import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const apiUrl = environment.API_BASE_URL;

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private http: HttpClient) { }


  /**
   * Searchs items
   * @param {string} keyWord 
   * @param {string} owner_address 
   * @returns  
   */
  public searchItems(keyWord:string,owner_address:string){
    return this.http.get(`${apiUrl}admin/search-item?owner_address=${owner_address}&search=${keyWord}`);
  }

}
