import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeliveryService } from 'src/app/services/delivery.service';
import { StorageService } from 'src/app/services/storage.service';
import { CopyDirective } from 'src/app/shared/directives/copy.directive';
import { deliveryResponse, deliveryStatus } from 'src/app/shared/interface/interface';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-delivery-details',
  templateUrl: './delivery-details.component.html',
  styleUrls: ['./delivery-details.component.scss']
})
export class DeliveryDetailsComponent implements OnInit {
  selectedItem: string = 'New';
  userId = '';
  deliveryDetails: deliveryResponse;
  loader:boolean=false;
  deliveryStatus: deliveryStatus[];
  deliveryHistory: any;
  public marketPlaceUrl = environment.MARKETPLACE_URL;
  public isRegulated: boolean;
  shippingLoader = false;
  selectElement: any;

  constructor(
    private _location: Location,
    private deliveryService: DeliveryService,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private copyDirective: CopyDirective,
  ) {
  }

  /**
 * on init
 */
  ngOnInit(): void {
    this.userId = this.route.snapshot.paramMap.get('id');
    this.getDeliveryDetails();
    this.getDeliveryStatus();
    this.getDeliveryHistory();
    this.isRegulated = JSON.parse(this.storageService.getItemSession('isRegulated'));
  }
  /**
   * Go back
   */
  goBack() {
    this._location.back();
  }

  /**
   * Gets delivery details
   */

  getDeliveryDetails() {
    this.loader=true;
    this.deliveryService.getDeliveryDetailsById(this.userId).subscribe((response) => {
      this.deliveryDetails = response['data'];
       setTimeout(() => {
        this.loader=false;
       }, 2000);

      // for (const nft of response['data']) {
        response['data'].nft_id['fileType'] = response['data'].nft_id['preview_image'] ? response['data'].nft_id['preview_image'].split('.')[response['data'].nft_id['preview_image'].split('.').length - 1] : response['data'].nft_id['primary_media'].split('.')[response['data'].nft_id['primary_media'].split('.').length - 1]
      // }
      // for (const nft of response['data']) {
      //   nft.nft_id['fileType'] = nft.nft_id['preview_image'] ? nft.nft_id['preview_image'].split('.')[nft.nft_id['preview_image'].split('.').length - 1] : nft.nft_id['primary_media'].split('.')[nft.nft_id['primary_media'].split('.').length - 1]
      // }
    }, (error) => {
      this.loader = false;
    })
  }

  /**
 * Gets delivery status
 */
  getDeliveryStatus() {
    this.deliveryService.getDeliveryStatus().subscribe((response) => {
      this.deliveryStatus = response['data'];
      this.deliveryStatus = this.deliveryStatus.filter(item => item.name != 'Cancelled' && item.name != 'Burned');
    })
  }

  /**
   * Confirms alert
   * @param {number} index
   * @param {string} id
   * @param {number} currentStatus
   */
  confirmAlert(id: string, currentStatus: number) {
    const itemId = this.deliveryStatus.filter((item) => { if(item.status == this.deliveryDetails.status['status']) return item._id; })
    let deliveryValue = (document.getElementById(`status-update`) as HTMLInputElement).value;
    let split = deliveryValue.split(',');
    let status = split[0];
    let statusId = split[1];
    Swal.fire({
      title: `Are you sure want to change status?`,
      text: `Click OK to change status.`,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'OK.',
      cancelButtonText: 'Cancel.',
    }).then((willDelete) => {
      Swal.fire({
        onBeforeOpen: () => {
          Swal.showLoading()
        },
        allowOutsideClick: false,
        text: 'Processing, Please wait...',
      });
      if (willDelete.dismiss) {
        Swal.fire({title:'',text: `Status not changed.`,type: 'error',icon:'error',confirmButtonText:'OK.'});
        (document.getElementById(`status-update`) as HTMLInputElement).value = this.deliveryDetails.status['status']+','+itemId[0]._id;
      } else {
        this.updateDeliveryStatus(id, status, currentStatus, statusId);
      }
    });
  }


  /**
  * Updates delivery status
  * @param {number} index
  * @param {string} id
  * @param {string} string
  * @param {number} currentStatus
  */
  updateDeliveryStatus(id: string, status: string, currentStatus: number, statusId: string) {

    (document.getElementById(`status-update`) as HTMLElement).className = `form-select btn status${status}`;
    const data = {
      status: statusId
    }
    this.deliveryService.updateDeliveryDetails(id, data).subscribe((response) => {
      this.getDeliveryHistory();
      this.getDeliveryDetails();
      Swal.fire({title:'',text: `Status changed successfully.`,type: 'success',icon:'success',confirmButtonText:'OK.'});
    }, (error) => {
      (document.getElementById(`status-update`) as HTMLInputElement).value = currentStatus.toString();
      Swal.close();
    })
  }

  /**
   * Gets delivery history
   */
  getDeliveryHistory() {
    this.shippingLoader = true;
    this.deliveryService.getDeliveryHistoryById(this.userId).subscribe((response) => {
      this.deliveryHistory = response['data']['historyDetails'];
      setTimeout(()=>{
        this.shippingLoader = false;
      }, 2000)
    },
  (error) => {
    this.shippingLoader = false;
   })
  }

  /**
   * Copys content
   * @param content
  */
  public copy(content: string) {
    this.copyDirective.copy(content)
  }

}
