import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const apiUrl = environment.API_BASE_URL;

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }


  /**
   * Signin
   * @param {data} data 
   * @returns  
   */
  public signin(data:object){
    return this.http.post(`${apiUrl}admin/signup-signin-sub-admin`,data);
  }

}
