<div class="main-wrap">
    <app-sidebar class="sidebarview"></app-sidebar>
    <section class="section">
        <div class="container-fluid">
            <div class="managecollection" id="nameScrollEdit">
                <!-- Tab : Select Items -->
                <div class="row mb-4">
                    <div class="col-lg-6">
                        <ul class="manage-collection">
                            <li class="nav-item  cursor-pointer menuactivities">Select
                                Items.</li>
                        </ul>
                    </div>
                </div>

                <div class="row  align-items-center">
                    <!-- DropDown : Choose Category -->
                    <div class="col-lg-6 col-md-6  col-sm-12 mb-4">
                        <label for="label">
                            Choose category to select items for displaying in the first page of Marketplace.</label>
                        <ng-select
                            (change)="selectedNftsRetrieved = false; getNfts($event,sortType,currentPage,currentPageLimit)"
                            placeholder="Choose category">
                            <ng-option *ngFor="let data of categories" [value]="data">
                                {{data}}</ng-option>
                        </ng-select>
                    </div>
                </div>

                <!-- Table Title: Selected Items for Selected Category -->
                <div class="col-lg-6 col-md-6 col-sm-12 mb-4"
                    *ngIf="selectedCategoryName && showTableView['selectedNft']">
                    <label for="label">
                        Selected Items for {{selectedCategoryName}} Category.</label>

                    <div *ngIf="selectedCategoryName && !isLoading['selectedNft']">
                        <p *ngIf="selectedNftsByCategory.length == 0">Note: Please select five items.</p>
                        <p
                            *ngIf="selectedNftsByCategory.length > 0 && selectedNftsByCategory.length < totalNftsToBeSelected">
                            Note: Currently
                            {{selectedNftsByCategory.length}}
                            items have been selected.Please select five items in total.</p>
                    </div>
                </div>

                <!-- Table: Selected Items for Selected Category -->
                <div class="table-responsive">
                    <table class="table table-hover" *ngIf="showTableView['selectedNft']">
                        <thead>
                            <th>S.no.</th>
                            <th></th>
                            <th>Name.</th>
                            <th>Image.</th>
                            <th>Token id.</th>
                        </thead>


                        <tbody>
                            <tr *ngFor="let data of selectedNftsByCategory; trackBy: trackByFn;let i = index;"
                                (click)="clickRedirect(data)" class="cursor-pointer">
                                <td>
                                    {{i + 1}}
                                </td>
                                <td>
                                    <input type="checkbox" [disabled]="false"
                                        (change)="toggleNftItem($any($event.target).checked,data)"
                                        [checked]="isCheckedNftItem(data._id)" (click)="$event.stopPropagation()">
                                </td>
                                <td>
                                    {{data.name}}
                                </td>
                                <td>
                                    <img *ngIf="data?.['fileType'] != 'html'" src="{{data['primary_media']}}"
                                        alt="nft img" width="50" height="50"
                                        onerror="this.src='assets/images/create-nft.png'">
                                    <img *ngIf="data?.['fileType'] == 'html'"
                                        [src]="data['preview_image'] ? data['preview_image'] : data['secondary_media'][0]"
                                        alt="nft img" width="50" height="50"
                                        onerror="this.src='assets/images/create-nft.png'">
                                </td>
                                <td>{{data.token_id}}</td>

                            </tr>
                            <!-- No Data Found: Selected Items for Selected Category  -->
                            <ng-container *ngIf="!isLoading['selectedNft']">
                                <tr *ngIf="selectedNftsByCategory?.length == 0" class="text-center">
                                    <td colspan="5">
                                        <div class="data-not-found">
                                            <img src="assets/images/data-not-found.svg" alt="data not found" width="320"
                                                height="180" class="img-fluid mb-3">
                                            <h6>No data found.
                                            </h6>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>

                    </table>
                </div>

                <!-- Button: Submit (Selected Nft Items) -->
                <div class="col-lg-12 col-md-12 col-sm-12 mx-10" *ngIf="selectedNftsByCategory?.length > 0 && !isLoading['selectedNft']">
                    <div class="d-flex justify-content-end pb-lg-3">
                        <button [disabled]="selectedNftsByCategory?.length<totalNftsToBeSelected" class="create-btn"
                            (click)="storeSelectedNfts()">{{submitLoader
                            ?'Processing...':'Submit.'}}</button>
                    </div>
                </div>

                <div class="loading-notfound" *ngIf="isLoading['selectedNft']">
                    <div class="loader"></div>
                    <p>Please wait while we load your items. </p>&nbsp;
                </div>
            </div>
        </div>
    </section>

    <section class="section" *ngIf="selectedCategoryName">
        <div class="container-fluid">
            <div class="managecollection" id="nameScrollEdit">

                <div class="row align-items-center">
                    <!-- Table Title: All NFT Items for Selected Category List -->
                    <div class="col-xl-0.5 col-lg-1 col-md-3 col-sm-12 my-4">
                        <label for="label">{{selectedCategoryName}} List.</label>
                    </div>

                    <!-- DropDown : Filter -->
                    <div class="col-xl-0.5 col-lg-1 col-md-3 col-sm-12 mb-3" *ngIf="!isLoading['allNft']">
                        <div class="ml-4">
                            <div class="d-block">
                                <button class="btn-light dropdown-toggle" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    {{sortName}}
                                </button>
                                <ul class="dropdown-menu sorting dropdown-menu-end">
                                    <li><a class="dropdown-item" (click)="sortNft('All items.',_nftSortBy.ALL_ITEMS)">All items.</a></li>
                                    <li><a class="dropdown-item" (click)="sortNft('Normal mint item.',_nftSortBy.NORMAL_MINT_ITEM)">Normal
                                            mint item.</a></li>
                                    <li><a class="dropdown-item" (click)="sortNft('Lazymint item.',_nftSortBy.LAZY_MINT_ITEM)">Lazymint
                                            item.</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Table: All NFT Items for Selected Category List  -->
                <div class="table-responsive">
                    <table class="table table-hover" *ngIf="!isLoading['allNft'] && showTableView['allNft']">
                        <thead>
                            <th>S.no.</th>
                            <th></th>
                            <th>Name.</th>
                            <th>Image.</th>
                            <th>Token id.</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of nftList; trackBy: trackByFn;let i = index;"
                                (click)="clickRedirect(data)" class="cursor-pointer">
                                <td>
                                    {{i + 1}}
                                </td>
                                <td class="px-3">
                                    <input type="checkbox" [disabled]="isDisabledNftItem(data._id)"
                                        (change)="toggleNftItem($any($event.target).checked,data)"
                                        [attr.data-toggle]="isDisabledNftItem(data._id) ? 'tooltip' : null"
                                        [attr.title]="isDisabledNftItem(data._id) ? 'Five selections are only allowed.' : null"
                                        [checked]="isCheckedNftItem(data?._id)" (click)="$event.stopPropagation()">
                                </td>
                                <td>
                                    {{data.name}}
                                </td>
                                <td>
                                    <img *ngIf="data?.['fileType'] != 'html'" src="{{data['primary_media']}}"
                                        alt="nft img" width="50" height="50"
                                        onerror="this.src='assets/images/create-nft.png'">
                                    <img *ngIf="data?.['fileType'] == 'html'"
                                        [src]="data['preview_image'] ? data['preview_image'] : data['secondary_media'][0]"
                                        alt="nft img" width="50" height="50"
                                        onerror="this.src='assets/images/create-nft.png'">
                                </td>
                                <td>{{data.token_id}}</td>

                            </tr>
                            <!-- No Data Found: All NFT Items for Selected Category List  -->
                            <ng-container *ngIf="!isLoading['allNft']">
                                <tr *ngIf="nftList?.length == 0" class="text-center">
                                    <td colspan="5">
                                        <div class="data-not-found">
                                            <img src="assets/images/data-not-found.svg" alt="data not found" width="320"
                                                height="180" class="img-fluid mb-3">
                                            <h6>No data found.
                                            </h6>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>

                    </table>

                </div>

                <div class="loading-notfound" *ngIf="isLoading['allNft']">
                    <div class="loader"></div>
                    <p>Please wait while we load your items. </p>&nbsp;
                </div>

            </div>

            <!-- Paginations -->
            <div class="row">
                <div class="col-lg-12" *ngIf="!isLoading['allNft'] && nftList?.length != 0">
                    <div class="d-flex justify-content-end flex-wrap">
                        <div>
                            <button *ngIf="nftListPagination?.page != 1" class="previous-btn"
                                (click)="getNfts(selectedCategoryName,sortType,nftListPagination?.page - 1, currentPageLimit)">
                                <span class="me-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                        fill="none">
                                        <path
                                            d="M10.5371 3.60462C10.3187 3.30815 9.90141 3.24483 9.60495 3.46317C9.37468 3.63277 9.15579 3.8024 8.96513 3.95118C8.58459 4.24819 8.07486 4.65707 7.56326 5.10131C7.05493 5.54267 6.52939 6.03202 6.12572 6.48888C5.92459 6.71652 5.73959 6.95185 5.60126 7.18132C5.47399 7.39245 5.33366 7.68259 5.33366 7.99999C5.33366 8.31739 5.47399 8.60759 5.60126 8.81872C5.73959 9.04819 5.92459 9.28345 6.12572 9.51112C6.52939 9.96799 7.05493 10.4573 7.56326 10.8987C8.07486 11.3429 8.58459 11.7518 8.96513 12.0489C9.15579 12.1976 9.37468 12.3673 9.60495 12.5369C9.90141 12.7552 10.3187 12.6919 10.5371 12.3954C10.6249 12.2762 10.6671 12.1375 10.667 12.0001L10.667 7.99999V3.99997C10.6671 3.8625 10.6249 3.72379 10.5371 3.60462Z"
                                            fill="currentColor" />
                                    </svg>
                                </span>Prev
                            </button>
                            <button *ngIf="nftListPagination?.page == 1" disabled class="previous-btn disble-btn">
                                <span class="me-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                        fill="none">
                                        <path
                                            d="M10.5371 3.60462C10.3187 3.30815 9.90141 3.24483 9.60495 3.46317C9.37468 3.63277 9.15579 3.8024 8.96513 3.95118C8.58459 4.24819 8.07486 4.65707 7.56326 5.10131C7.05493 5.54267 6.52939 6.03202 6.12572 6.48888C5.92459 6.71652 5.73959 6.95185 5.60126 7.18132C5.47399 7.39245 5.33366 7.68259 5.33366 7.99999C5.33366 8.31739 5.47399 8.60759 5.60126 8.81872C5.73959 9.04819 5.92459 9.28345 6.12572 9.51112C6.52939 9.96799 7.05493 10.4573 7.56326 10.8987C8.07486 11.3429 8.58459 11.7518 8.96513 12.0489C9.15579 12.1976 9.37468 12.3673 9.60495 12.5369C9.90141 12.7552 10.3187 12.6919 10.5371 12.3954C10.6249 12.2762 10.6671 12.1375 10.667 12.0001L10.667 7.99999V3.99997C10.6671 3.8625 10.6249 3.72379 10.5371 3.60462Z"
                                            fill="currentColor" />
                                    </svg>
                                </span>Prev
                            </button>
                        </div>
                        <div>
                            <nav>
                                <ul class="pagination">
                                    <li class="page-item cursor-pointer"
                                        *ngIf="nftListPagination.prevPage != null  && (nftListPagination.prevPage - 1) != 0 && (nftListPagination.prevPage - 1) != nftListPagination.page"
                                        (click)="getNfts(selectedCategoryName,sortType,1, currentPageLimit)">
                                        <a class="page-link">1</a>
                                    </li>
                                    <li class="page-item cursor-pointer">
                                        <a class="page-link" *ngIf="nftListPagination.prevPage > 0"
                                            (click)="getNfts(selectedCategoryName,sortType,nftListPagination.prevPage, currentPageLimit)">{{nftListPagination.prevPage}}</a>
                                    </li>
                                    <li class="page-item cursor-pointer"><a
                                            class="page-link active">{{nftListPagination.page}}</a></li>
                                    <li class="page-item cursor-pointer"><a class="page-link"
                                            *ngIf="nftListPagination.nextPage > 0"
                                            (click)="getNfts(selectedCategoryName,sortType,nftListPagination.nextPage, currentPageLimit)">{{nftListPagination.nextPage}}</a>
                                    </li>
                                    <li class="page-item"
                                        *ngIf="nftListPagination.totalPages > nftListPagination.nextPage && nftListPagination.nextPage != null && (nftListPagination.nextPage + 1) != nftListPagination.totalPages">
                                        <a class="page-link">..</a>
                                    </li>
                                    <li class="page-item cursor-pointer"
                                        *ngIf="nftListPagination.totalPages > nftListPagination.nextPage && nftListPagination.nextPage != null"
                                        (click)="getNfts(selectedCategoryName,sortType,nftListPagination.totalPages, currentPageLimit)">
                                        <a class="page-link">{{nftListPagination.totalPages}}</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div>
                            <button *ngIf="nftListPagination?.page != nftListPagination?.totalPages" class="next-btn"
                                (click)="getNfts(selectedCategoryName,sortType,nftListPagination?.page + 1, currentPageLimit)">Next
                                <span class="ms-1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        viewBox="0 0 16 16" fill="none">
                                        <path
                                            d="M5.46292 3.60462C5.68126 3.30815 6.09859 3.24483 6.39505 3.46317C6.62532 3.63277 6.84421 3.8024 7.03487 3.95118C7.41541 4.24819 7.92514 4.65707 8.43674 5.10131C8.94507 5.54267 9.47061 6.03202 9.87428 6.48888C10.0754 6.71652 10.2604 6.95185 10.3987 7.18132C10.526 7.39245 10.6663 7.68259 10.6663 7.99999C10.6663 8.31739 10.526 8.60759 10.3987 8.81872C10.2604 9.04819 10.0754 9.28345 9.87428 9.51112C9.47061 9.96799 8.94507 10.4573 8.43674 10.8987C7.92514 11.3429 7.41541 11.7518 7.03487 12.0489C6.84421 12.1976 6.62532 12.3673 6.39505 12.5369C6.09859 12.7552 5.68126 12.6919 5.46292 12.3954C5.37514 12.2762 5.33289 12.1375 5.33301 12.0001L5.33301 7.99999V3.99997C5.33289 3.8625 5.37514 3.72379 5.46292 3.60462Z"
                                            fill="currentColor" />
                                    </svg></span>
                            </button>
                            <button *ngIf="nftListPagination?.page == nftListPagination?.totalPages" disabled
                                class="next-btn disble-btn">Next
                                <span class="ms-1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        viewBox="0 0 16 16" fill="none">
                                        <path
                                            d="M5.46292 3.60462C5.68126 3.30815 6.09859 3.24483 6.39505 3.46317C6.62532 3.63277 6.84421 3.8024 7.03487 3.95118C7.41541 4.24819 7.92514 4.65707 8.43674 5.10131C8.94507 5.54267 9.47061 6.03202 9.87428 6.48888C10.0754 6.71652 10.2604 6.95185 10.3987 7.18132C10.526 7.39245 10.6663 7.68259 10.6663 7.99999C10.6663 8.31739 10.526 8.60759 10.3987 8.81872C10.2604 9.04819 10.0754 9.28345 9.87428 9.51112C9.47061 9.96799 8.94507 10.4573 8.43674 10.8987C7.92514 11.3429 7.41541 11.7518 7.03487 12.0489C6.84421 12.1976 6.62532 12.3673 6.39505 12.5369C6.09859 12.7552 5.68126 12.6919 5.46292 12.3954C5.37514 12.2762 5.33289 12.1375 5.33301 12.0001L5.33301 7.99999V3.99997C5.33289 3.8625 5.37514 3.72379 5.46292 3.60462Z"
                                            fill="currentColor" />
                                    </svg></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>