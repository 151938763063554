import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { CommonContractService } from 'src/app/services/common-contract.service';
import { NftManagementService } from 'src/app/services/nft-management.service';
import { RoyaltyManagementService } from 'src/app/services/royalty-management.service';
import { StorageService } from 'src/app/services/storage.service';
import { Const } from 'src/app/shared/const/const';
import { ErrorHandlerDirective } from 'src/app/shared/directives/error-handler.directive';
import { ManageTransactionsDirective } from 'src/app/shared/directives/manage-transactions.directive';
import { IPagination, walletAccount } from 'src/app/shared/interface/interface';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import Web3 from 'web3';

const ethereum = window['ethereum'] as any
let web3 = new Web3(window['ethereum'] as any);
const apiUrl = environment.API_BASE_URL;

@Component({
    selector: 'app-manage-nft',
    templateUrl: './manage-nft.component.html',
    styleUrls: ['./manage-nft.component.scss']
})
export class ManageNftComponent implements OnInit {

    public collectionLoader: boolean = false;
    public collectionFormSubmitted: boolean = false;
    public account: walletAccount;
    public displayNFT: boolean = false;
    public attributes: any = [];
    public attributeValue: any = [];
    public nftForm: FormGroup;
    public createNftLoader: boolean = false;
    public nftFormSubmitted: boolean = false;
    public nft: any;
    public attribute: any = [];
    public collectionName: string = '';
    public nftName: string = '';
    public baseUrl = environment.BASE_URL;
    public transactionHash: string = '';
    public ipfsNftHash: string = '';
    public IpfsHash: string = '';
    public transhUrl: any
    public fileErrorMsg: string = '';
    public multipleFileErrorMsg: string = '';
    public collectionAddress: string = '';
    @ViewChild('nftSuccessModal') nftSuccessModal;
    public secondaryImageLength: string;
    public IpfsHashSecSecImg: any = [];
    public primaryExtension: string = '';
    public isPreview: boolean = false;
    public previewImage: any;
    public previewImageName: string = '';
    public fileErrorMsgPreview: string = '';
    public IpfsPreviewHash: string = '';
    public tokenId: string = '';
    public nftDetails: any = [];
    public animationUrlLink: string = '';
    public imageUrl: string = '';
    public secondaryImagesUrl: string = '';
    public isAttributeSet: boolean = false;
    public formNotChanged: boolean = false;
    public clientAddress: any;
    public attributesArray: any = [];
    public attributeValues: any = [];
    public selectedAttributeValues: any;
    public tokenURI: string = '';
    public collectionDetails: any;
    public IpfsHashSecSecImgVal: any[];
    @ViewChild('primaryMedia') primaryMedia!: ElementRef;
    @ViewChild('previewImageTag') previewImageTag!: ElementRef;
    @ViewChild('secondaryImage') secondaryImage!: ElementRef;
    public nftAllDetails: Object;
    public attributeOriginalValue: any = [];
    updateFormError: boolean = false;
    previewImageUrl: string = '';
    currencyData: any;
    currencyError: string = '';
    collectionsAddressList: any = [];
    public marketPlaceUrl = environment.MARKETPLACE_URL;
    public isRegulated: boolean;
    public nftDetailsPagination: IPagination;
    public currentPageLimit: number = 10;
    collectionId: string = '';
    lazyMint: string = '';
    sortName = 'All items.';

    /**
     * Creates an instance of search collections component.
     */
    constructor(
        private nftManagementService: NftManagementService,
        private accountService: AccountService,
        private storageService: StorageService,
        private toastr: ToastrService,
        private constVal: Const,
        private zone: NgZone,
        private router: Router,
        private royaltyService: RoyaltyManagementService,
        private commonservice: CommonContractService,
        private errorHandler: ErrorHandlerDirective,
        private manageTransactionsDirective: ManageTransactionsDirective,
    ) { }

    /**
     * on init
     */
    public ngOnInit(): void {
        this.isRegulated = JSON.parse(this.storageService.getItemSession('isRegulated'));
        this.commonservice.closeModalsObservable.subscribe((response: boolean) => {
            if (response) {
                this.nftSuccessModal.hide();
            }
        });

        this.account = this.storageService.getItem('wagmi.store') === null ?
            { address: '', network: '', chainId: '', provider: '' } :
            JSON.parse(this.storageService.getItem('wagmi.store') as any);
        this.getCollections();
        this.accountService.accountChange(false);
        this.accountService.accountObserve.subscribe((response) => {
            if (response) {
                this.account = this.storageService.getItem('wagmi.store') === null ?
                    { address: '', network: '', chainId: '', provider: '' } :
                    JSON.parse(this.storageService.getItem('wagmi.store') as any);
                this.getCollections();
            }
        });

    }

    /**
     * Track by function for ngFor loops
     * @param index
     * @param item
     */
    public trackByFn(index: number, item: any): any {
        return item._id || index;
    }

    /**
     * Gets nft
     * @param {string} collectionId
     * @param {number} page
     * @param {number} limit
     * @param {string} lazyMint
     */
    getNft(collectionId: string, lazyMint: string, page: number, limit: number) {
        if (collectionId) {
            this.collectionLoader = true;
            this.collectionId = collectionId;
            this.nftManagementService.getNftbyCollections(collectionId, lazyMint, page, limit).subscribe({
                next: (response: any) => {
                    this.displayNFT = true;
                    this.nftDetails = response.data.docs;
                    for (const nft of this.nftDetails) {
                        nft['fileType'] = nft['preview_image'] ? nft['preview_image'].split('.')[nft['preview_image'].split('.').length - 1] : nft['primary_media'].split('.')[nft['primary_media'].split('.').length - 1]
                    }
                    this.nftDetailsPagination = response.data;
                    this.collectionLoader = false;
                },
                error: (error: any) => {
                    this.displayNFT = false;
                    this.collectionLoader = false;
                    this.toastr.error(error.error.message);
                }
            })
        }
        else {
            this.displayNFT = false;
            this.nftDetails = [];
        }
    }


    /**
     * Checks collection address
     * @param inputAddress
     */
    public async checkCollectionAddress(inputAddress: string) {
        let status;
        await this.nftManagementService.getUserCreatedCollections(this.account.state.data.account).then(async (response) => {
            let web3 = new Web3(window['ethereum'] as any || environment[ethereum as any].chainId.PROVIDER);
            const collectionAddressList = await response;
            let collectionAddress = web3.utils.toChecksumAddress(inputAddress);
            await this.nftManagementService.isCollectionDestroyed(collectionAddress).then((response => {
                if (response) {
                    this.toastr.error("Collection destroyed.");
                    this.displayNFT = false;
                    status = false;
                }
                else {
                    if (!collectionAddressList.includes(collectionAddress)) {
                        this.toastr.error("Collection address not found.");
                        this.displayNFT = false;
                        status = false;
                        this.collectionLoader = false;
                    }
                    else {
                        status = true;
                        this.displayNFT = true;
                    }
                }
            }))
            return status;
        }).
            catch((error: any) => {
                status = false;
                this.displayNFT = false;
                this.collectionLoader = false;
                if ((error.toString()).includes('is not a valid Ethereum address')) {
                    this.toastr.error("Collection address not found.");
                }
            })
        return status;
    }

    /**
    * Gets client address
    */
    public async getClientAddress() {
        const spliterContractAddress = await this.royaltyService.getSplitterContractAddress(this.collectionAddress) as any;
        this.clientAddress = await this.royaltyService.payee(spliterContractAddress, 1);
    }


    /**
     * Gets collection details from db
     * @param {string} collectionAddress
     * @returns
     */
    public getCollectionDetailsFromDB(collectionAddress: string) {
        return new Promise((resolve, reject) => {
            this.nftManagementService.getCollectionDb(collectionAddress).subscribe({
                next: (response: any) => {
                    this.collectionDetails = response.data;
                    this.collectionLoader = false;
                    resolve({ status: true, data: response });
                },
                error: (error: any) => {
                    this.displayNFT = false;
                    this.collectionLoader = false;
                    this.toastr.error(error.error.message);
                    reject({ status: false, data: error?.error });
                }
            })
        });
    }
    /**
     * Validate First letter start with Space
     * @param {string} event
    */
    public space(event) {
        if (event.target.selectionStart === 0 && event.code === this.constVal.CONST_VAR.space) {
            event.preventDefault();
        }
    }

    /**
     * Check Network
     */
    public checkNetwork() {
        if (this.account.state.data.chain.id && this.account.state.data.chain.id != environment[this.account.state.data.chain.id].CHAIN_NETWORK && this.account.state.data.chain.id != environment[this.account.state.data.chain.id].CHAINID) {
            this.toastr.error('Wrong network.');
        } else {
            this.zone.run(() => {
                this.router.navigate(['/nft-management']);
            });
        }
    }


    /**
     * Converts edit platform fee component
     * @param {any} n
     * @returns
     */
    public convert(n) {
        var sign = +n < 0 ? "-" : "",
            toStr = n.toString();
        if (!/e/i.test(toStr)) {
            return n;
        }
        var [lead, decimal, pow] = n.toString()
            .replace(/^-/, "")
            .replace(/^([0-9]+)(e.*)/, "$1.$2")
            .split(/e|\./);
        return +pow < 0
            ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
            : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
    }

    /**
     * Connects wallet
     */
    public connectWallet() {
        this.ngOnInit()
        const data = {
            status: true,
        };
        this.accountService.openWallet(data);
    }


    /**
     * Gets collections
     */
    getCollections() {
        this.nftManagementService.getCollections(this.account.state.data.account).subscribe((response: any) => {
            this.collectionsAddressList = response.data;
        }
        )
    }



    // NFT Burn process

    /**
   * Confirms alert
   * @param {string} tokenId
   * @param {string} address
   * @param {string} name
   */
    public confirmAlert(deliveryId: String, tokenId: string, address: string, name: string) {
        Swal.fire({
            title: `Are you sure want to burn to ${name} item?`,
            text: `Click OK to burn to ${name} item.`,
            type: 'warning',
            confirmButtonText: 'OK.',
            cancelButtonText: 'Cancel.',
            showCloseButton: true,
            showCancelButton: true,
        }).then((willDelete) => {
            Swal.fire({
                onBeforeOpen: () => {
                    Swal.showLoading()
                },
                allowOutsideClick: false,
                text: 'Processing, Please wait...',
            });
            if (willDelete.dismiss) {
                Swal.fire({ title: '', text: `Not burn ${name} item.`, type: 'error', icon: 'error', confirmButtonText: 'OK.' });

            }
            else {
                this.burnProcess(deliveryId, tokenId, address);
            }


        });
    }

    /**
     * Burns process
     * @param {string} tokenId
     * @param {string} address
     */
    public async burnProcess(deliveryId, tokenId, address) {
        try {
            const { burnAbi, requiredGas } = await this.nftManagementService.burnNft(tokenId, address, this.account.state.data.account);
            const message = {
                to: address,
                data: burnAbi,
                gasPrice: await web3.utils.toHex(Number(await web3.eth.getGasPrice()) * 2),
                gasLimit: await web3.utils.toHex(Number(requiredGas) * 2)
            };
            this.manageTransactionsDirective.makeTransactions(message)
                .then(async (receipt) => {
                    this.deleteNft(deliveryId, tokenId, address);
                }).
                catch((error) => {
                    Swal.close();
                });
        }
        catch (error: any) {
            Swal.fire({ title: '', text: `${error?.data?.shortMessage ? error?.data?.shortMessage + '.' : 'Some thing went to wrong.'}`, type: 'error', icon: 'error', confirmButtonText: 'OK.' });

        }
    }


    /**
     * Deletes nft
     * @param {string} tokenId
     * @param {string} collectionAddress
     */
    public deleteNft(deliveryId, tokenId: string, collectionAddress: string) {
        this.nftManagementService.deleteNft(deliveryId, tokenId, collectionAddress, this.account.state.data.account).subscribe((response) => {
            Swal.fire({ title: '', text: `Item burned successfully.`, type: 'success', icon: 'success', confirmButtonText: 'OK.' });
            this.getNft(this.collectionId, this.lazyMint, 1, this.currentPageLimit)
        },
            (error) => {
                Swal.fire({ title: '', text: `${error.error.message ? error.error.message + '.' : 'Some thing went to wrong.'}`, icon: 'error', confirmButtonText: 'OK.' });

            })
    }

    /**
     * Clicks redirect
     * @param {object} data
     */
    clickRedirect(data: object) {
        window.open(data['lazy_mint'] ? this.marketPlaceUrl + 'lazy-mint/' + data['_id'] + '?regulated=' + this.isRegulated : this.marketPlaceUrl + 'nft-detail/' + data['collections'].collection_address + '/' + data['token_id'] + '?regulated=' + this.isRegulated, '_blank');
    }

    /**
     * Sorts nft
     * @param {string} sortName
     * @param {string} type
     */
    sortNft(sortName: string, type: string) {
        this.sortName = sortName;
        this.lazyMint = type;
        this.getNft(this.collectionId, type, 1, this.currentPageLimit);
    }
}
