<div class="main-wrap">
  <app-sidebar class="sidebarview"></app-sidebar>
  <section class="section">
    <div class="container-fluid">
      <div class="managecollection">
        <div class="row mb-4">
          <div class="col-sm-6">
            <ul class="manage-collection">
              <li class="nav-item menuactivities">Manage collections.</li>
            </ul>
          </div>
          <div class="col-sm-6 ">
            <div class="d-flex justify-content-end">
              <a class="create-btn" [routerLink]="['/manage-collection/create']">Create collection.</a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <th>S.no.</th>
                  <th>Address.</th>
                  <th>Name.</th>
                  <th>Client address.</th>
                  <th>Collection creator.</th>
                  <th>Collection owner.</th>
                  <th>Total<br>items.</th>
                  <th>Actions.</th>
                </thead>
                <tbody>
                  <ng-container *ngIf="!loader">
                    <tr *ngFor="let data of collections; trackBy: trackByFn;let i = index;">
                      <td>{{i + 1}}</td>
                      <td>
                        <div class="d-flex gap-2 align-items-center" title="{{data.collection_address}}">
                          {{data.collection_address | contentSlice :0:5}}
                          <a class="onboard_copy" (click)="copyAddress(data.collection_address)"> <svg
                              viewBox="0 0 17 16" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"
                              xmlns:anim="http://www.w3.org/2000/anim" anim="" anim:transform-origin="50% 50%"
                              anim:duration="1" anim:ease="ease-in-out">
                              <g id="Frame">
                                <path id="Vector"
                                  d="M6.60156 10C6.60156 8.1144 6.60156 7.1716 7.18735 6.58579C7.77316 6 8.71596 6 10.6016 6H11.2682C13.1538 6 14.0966 6 14.6824 6.58579C15.2682 7.1716 15.2682 8.1144 15.2682 10V10.6667C15.2682 12.5523 15.2682 13.4951 14.6824 14.0809C14.0966 14.6667 13.1538 14.6667 11.2682 14.6667H10.6016C8.71596 14.6667 7.77316 14.6667 7.18735 14.0809C6.60156 13.4951 6.60156 12.5523 6.60156 10.6667V10Z"
                                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path id="Vector_2"
                                  d="M11.9374 6.00016C11.9358 4.02877 11.906 3.00764 11.3322 2.30845C11.2214 2.17342 11.0976 2.04962 10.9626 1.9388C10.225 1.3335 9.12917 1.3335 6.9375 1.3335C4.74585 1.3335 3.65002 1.3335 2.91245 1.9388C2.77743 2.04961 2.65362 2.17342 2.54281 2.30845C1.9375 3.04602 1.9375 4.14184 1.9375 6.3335C1.9375 8.52516 1.9375 9.62096 2.54281 10.3586C2.65361 10.4936 2.77743 10.6174 2.91245 10.7282C3.61164 11.302 4.63277 11.3318 6.60417 11.3334"
                                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                              </g>
                            </svg></a>
                        </div>
                      </td>
                      <td>{{data.name}}</td>
                      <td>
                        <div class="d-flex gap-2 align-items-center" title="{{data.owner_address}}">
                          {{data.owner_address | contentSlice :0:5}}
                          <a class="onboard_copy" (click)="copyAddress(data.owner_address)"> <svg viewBox="0 0 17 16"
                              width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"
                              xmlns:anim="http://www.w3.org/2000/anim" anim="" anim:transform-origin="50% 50%"
                              anim:duration="1" anim:ease="ease-in-out">
                              <g id="Frame">
                                <path id="Vector"
                                  d="M6.60156 10C6.60156 8.1144 6.60156 7.1716 7.18735 6.58579C7.77316 6 8.71596 6 10.6016 6H11.2682C13.1538 6 14.0966 6 14.6824 6.58579C15.2682 7.1716 15.2682 8.1144 15.2682 10V10.6667C15.2682 12.5523 15.2682 13.4951 14.6824 14.0809C14.0966 14.6667 13.1538 14.6667 11.2682 14.6667H10.6016C8.71596 14.6667 7.77316 14.6667 7.18735 14.0809C6.60156 13.4951 6.60156 12.5523 6.60156 10.6667V10Z"
                                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path id="Vector_2"
                                  d="M11.9374 6.00016C11.9358 4.02877 11.906 3.00764 11.3322 2.30845C11.2214 2.17342 11.0976 2.04962 10.9626 1.9388C10.225 1.3335 9.12917 1.3335 6.9375 1.3335C4.74585 1.3335 3.65002 1.3335 2.91245 1.9388C2.77743 2.04961 2.65362 2.17342 2.54281 2.30845C1.9375 3.04602 1.9375 4.14184 1.9375 6.3335C1.9375 8.52516 1.9375 9.62096 2.54281 10.3586C2.65361 10.4936 2.77743 10.6174 2.91245 10.7282C3.61164 11.302 4.63277 11.3318 6.60417 11.3334"
                                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                              </g>
                            </svg></a>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex gap-2 align-items-center" title="{{data.collection_owner_address}}">
                          {{data.collection_owner_address | contentSlice :0:5}}
                          <a class="onboard_copy" (click)="copyAddress(data.collection_owner_address)"> <svg
                              viewBox="0 0 17 16" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"
                              xmlns:anim="http://www.w3.org/2000/anim" anim="" anim:transform-origin="50% 50%"
                              anim:duration="1" anim:ease="ease-in-out">
                              <g id="Frame">
                                <path id="Vector"
                                  d="M6.60156 10C6.60156 8.1144 6.60156 7.1716 7.18735 6.58579C7.77316 6 8.71596 6 10.6016 6H11.2682C13.1538 6 14.0966 6 14.6824 6.58579C15.2682 7.1716 15.2682 8.1144 15.2682 10V10.6667C15.2682 12.5523 15.2682 13.4951 14.6824 14.0809C14.0966 14.6667 13.1538 14.6667 11.2682 14.6667H10.6016C8.71596 14.6667 7.77316 14.6667 7.18735 14.0809C6.60156 13.4951 6.60156 12.5523 6.60156 10.6667V10Z"
                                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path id="Vector_2"
                                  d="M11.9374 6.00016C11.9358 4.02877 11.906 3.00764 11.3322 2.30845C11.2214 2.17342 11.0976 2.04962 10.9626 1.9388C10.225 1.3335 9.12917 1.3335 6.9375 1.3335C4.74585 1.3335 3.65002 1.3335 2.91245 1.9388C2.77743 2.04961 2.65362 2.17342 2.54281 2.30845C1.9375 3.04602 1.9375 4.14184 1.9375 6.3335C1.9375 8.52516 1.9375 9.62096 2.54281 10.3586C2.65361 10.4936 2.77743 10.6174 2.91245 10.7282C3.61164 11.302 4.63277 11.3318 6.60417 11.3334"
                                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                              </g>
                            </svg></a>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex gap-2 align-items-center" title="{{data.collection_owner_address}}">
                          {{data.collection_owner_address | contentSlice :0:5}}
                          <a class="onboard_copy" (click)="copyAddress(data.collection_owner_address)"> <svg
                              viewBox="0 0 17 16" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"
                              xmlns:anim="http://www.w3.org/2000/anim" anim="" anim:transform-origin="50% 50%"
                              anim:duration="1" anim:ease="ease-in-out">
                              <g id="Frame">
                                <path id="Vector"
                                  d="M6.60156 10C6.60156 8.1144 6.60156 7.1716 7.18735 6.58579C7.77316 6 8.71596 6 10.6016 6H11.2682C13.1538 6 14.0966 6 14.6824 6.58579C15.2682 7.1716 15.2682 8.1144 15.2682 10V10.6667C15.2682 12.5523 15.2682 13.4951 14.6824 14.0809C14.0966 14.6667 13.1538 14.6667 11.2682 14.6667H10.6016C8.71596 14.6667 7.77316 14.6667 7.18735 14.0809C6.60156 13.4951 6.60156 12.5523 6.60156 10.6667V10Z"
                                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                                <path id="Vector_2"
                                  d="M11.9374 6.00016C11.9358 4.02877 11.906 3.00764 11.3322 2.30845C11.2214 2.17342 11.0976 2.04962 10.9626 1.9388C10.225 1.3335 9.12917 1.3335 6.9375 1.3335C4.74585 1.3335 3.65002 1.3335 2.91245 1.9388C2.77743 2.04961 2.65362 2.17342 2.54281 2.30845C1.9375 3.04602 1.9375 4.14184 1.9375 6.3335C1.9375 8.52516 1.9375 9.62096 2.54281 10.3586C2.65361 10.4936 2.77743 10.6174 2.91245 10.7282C3.61164 11.302 4.63277 11.3318 6.60417 11.3334"
                                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path>
                              </g>
                            </svg></a>
                        </div>
                      </td>
                      <td>{{data['nftCount']}}</td>
                      <td>
                        <div class="d-flex gap-2">
                          <ng-container *ngIf="!data.isLazyMint">
                            <button type="button" class="btn btn-green action-btn "
                              (click)="createNft(data.collection_address)">Create Item.</button>
                            <button [routerLink]="['/manage-collection/edit/', data.collection_address]" type="button"
                              class="btn btn-green action-btn">Edit.</button>
                          </ng-container>
                          <ng-container *ngIf="data.isLazyMint">
                            <ng-container *ngIf="data.category == 'Gold'; else enableBtn">
                              <button type="button" class="btn btn-green action-btn"
                                (click)="createNft(data.collection_address)">Create Item.</button>
                              <button [routerLink]="['/manage-collection/edit-lazymint/', data.collection_address]"
                                type="button" class="btn btn-green action-btn">Edit.</button>
                            </ng-container>
                            <ng-template #enableBtn>
                              <button type="button" class="btn btn-green action-btn" disabled>Lazymint.</button>
                              <button [routerLink]="['/manage-collection/edit-lazymint/', data.collection_address]"
                                type="button" class="btn btn-green action-btn">Edit.</button>
                            </ng-template>
                          </ng-container>
                        </div>

                      </td>

                    </tr>
                    <tr *ngIf="collections.length == 0" class="text-center">
                      <td colspan="8">
                        <div class="data-not-found">
                          <img src="assets/images/data-not-found.svg" alt="data not found" width="320" height="180"
                            class="img-fluid mb-3">
                          <h6>You do not have any collections. to begin, click the 'Create Collection' button to start a
                            new collection.</h6>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="loader">
                    <tr class="text-center">
                      <td colspan="8">
                        <div class="d-flex align-iems-center justify-content-center my-2">
                          Loading. <span class="Loaders"></span>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-lg-12" *ngIf="!loader && collections.length != 0">
          <div class="d-flex justify-content-end flex-wrap">
            <div>
              <button *ngIf="collectionsPaginations?.page != 1" class="previous-btn"
                (click)="getCollections(collectionsPaginations?.page - 1, currentPageLimit)">
                <span class="me-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M10.5371 3.60462C10.3187 3.30815 9.90141 3.24483 9.60495 3.46317C9.37468 3.63277 9.15579 3.8024 8.96513 3.95118C8.58459 4.24819 8.07486 4.65707 7.56326 5.10131C7.05493 5.54267 6.52939 6.03202 6.12572 6.48888C5.92459 6.71652 5.73959 6.95185 5.60126 7.18132C5.47399 7.39245 5.33366 7.68259 5.33366 7.99999C5.33366 8.31739 5.47399 8.60759 5.60126 8.81872C5.73959 9.04819 5.92459 9.28345 6.12572 9.51112C6.52939 9.96799 7.05493 10.4573 7.56326 10.8987C8.07486 11.3429 8.58459 11.7518 8.96513 12.0489C9.15579 12.1976 9.37468 12.3673 9.60495 12.5369C9.90141 12.7552 10.3187 12.6919 10.5371 12.3954C10.6249 12.2762 10.6671 12.1375 10.667 12.0001L10.667 7.99999V3.99997C10.6671 3.8625 10.6249 3.72379 10.5371 3.60462Z"
                      fill="currentColor" />
                  </svg>
                </span>Prev
              </button>
              <button *ngIf="collectionsPaginations?.page == 1" disabled class="previous-btn disble-btn">
                <span class="me-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M10.5371 3.60462C10.3187 3.30815 9.90141 3.24483 9.60495 3.46317C9.37468 3.63277 9.15579 3.8024 8.96513 3.95118C8.58459 4.24819 8.07486 4.65707 7.56326 5.10131C7.05493 5.54267 6.52939 6.03202 6.12572 6.48888C5.92459 6.71652 5.73959 6.95185 5.60126 7.18132C5.47399 7.39245 5.33366 7.68259 5.33366 7.99999C5.33366 8.31739 5.47399 8.60759 5.60126 8.81872C5.73959 9.04819 5.92459 9.28345 6.12572 9.51112C6.52939 9.96799 7.05493 10.4573 7.56326 10.8987C8.07486 11.3429 8.58459 11.7518 8.96513 12.0489C9.15579 12.1976 9.37468 12.3673 9.60495 12.5369C9.90141 12.7552 10.3187 12.6919 10.5371 12.3954C10.6249 12.2762 10.6671 12.1375 10.667 12.0001L10.667 7.99999V3.99997C10.6671 3.8625 10.6249 3.72379 10.5371 3.60462Z"
                      fill="currentColor" />
                  </svg>
                </span>Prev
              </button>
            </div>
            <div>
              <nav>
                <ul class="pagination">
                  <li class="page-item cursor-pointer"
                    *ngIf="collectionsPaginations.prevPage != null  && (collectionsPaginations.prevPage - 1) != 0 && (collectionsPaginations.prevPage - 1) != collectionsPaginations.page"
                    (click)="getCollections(1, currentPageLimit)">
                    <a class="page-link">1</a>
                  </li>
                  <li class="page-item cursor-pointer">
                    <a class="page-link" *ngIf="collectionsPaginations.prevPage > 0"
                      (click)="getCollections(collectionsPaginations.prevPage, currentPageLimit)">{{collectionsPaginations.prevPage}}</a>
                  </li>
                  <li class="page-item cursor-pointer"><a class="page-link active">{{collectionsPaginations.page}}</a>
                  </li>
                  <li class="page-item cursor-pointer"><a class="page-link" *ngIf="collectionsPaginations.nextPage > 0"
                      (click)="getCollections(collectionsPaginations.nextPage, currentPageLimit)">{{collectionsPaginations.nextPage}}</a>
                  </li>
                  <li class="page-item"
                    *ngIf="collectionsPaginations.totalPages > collectionsPaginations.nextPage && collectionsPaginations.nextPage != null && (collectionsPaginations.nextPage + 1) != collectionsPaginations.totalPages">
                    <a class="page-link">..</a>
                  </li>
                  <li class="page-item cursor-pointer"
                    *ngIf="collectionsPaginations.totalPages > collectionsPaginations.nextPage && collectionsPaginations.nextPage != null"
                    (click)="getCollections(collectionsPaginations.totalPages, currentPageLimit)">
                    <a class="page-link">{{collectionsPaginations.totalPages}}</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div>
              <button *ngIf="collectionsPaginations?.page != collectionsPaginations?.totalPages" class="next-btn"
                (click)="getCollections(collectionsPaginations?.page + 1, currentPageLimit)">Next
                <span class="ms-1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                    fill="none">
                    <path
                      d="M5.46292 3.60462C5.68126 3.30815 6.09859 3.24483 6.39505 3.46317C6.62532 3.63277 6.84421 3.8024 7.03487 3.95118C7.41541 4.24819 7.92514 4.65707 8.43674 5.10131C8.94507 5.54267 9.47061 6.03202 9.87428 6.48888C10.0754 6.71652 10.2604 6.95185 10.3987 7.18132C10.526 7.39245 10.6663 7.68259 10.6663 7.99999C10.6663 8.31739 10.526 8.60759 10.3987 8.81872C10.2604 9.04819 10.0754 9.28345 9.87428 9.51112C9.47061 9.96799 8.94507 10.4573 8.43674 10.8987C7.92514 11.3429 7.41541 11.7518 7.03487 12.0489C6.84421 12.1976 6.62532 12.3673 6.39505 12.5369C6.09859 12.7552 5.68126 12.6919 5.46292 12.3954C5.37514 12.2762 5.33289 12.1375 5.33301 12.0001L5.33301 7.99999V3.99997C5.33289 3.8625 5.37514 3.72379 5.46292 3.60462Z"
                      fill="currentColor" />
                  </svg></span>
              </button>
              <button *ngIf="collectionsPaginations?.page == collectionsPaginations?.totalPages" disabled
                class="next-btn disble-btn">Next
                <span class="ms-1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                    fill="none">
                    <path
                      d="M5.46292 3.60462C5.68126 3.30815 6.09859 3.24483 6.39505 3.46317C6.62532 3.63277 6.84421 3.8024 7.03487 3.95118C7.41541 4.24819 7.92514 4.65707 8.43674 5.10131C8.94507 5.54267 9.47061 6.03202 9.87428 6.48888C10.0754 6.71652 10.2604 6.95185 10.3987 7.18132C10.526 7.39245 10.6663 7.68259 10.6663 7.99999C10.6663 8.31739 10.526 8.60759 10.3987 8.81872C10.2604 9.04819 10.0754 9.28345 9.87428 9.51112C9.47061 9.96799 8.94507 10.4573 8.43674 10.8987C7.92514 11.3429 7.41541 11.7518 7.03487 12.0489C6.84421 12.1976 6.62532 12.3673 6.39505 12.5369C6.09859 12.7552 5.68126 12.6919 5.46292 12.3954C5.37514 12.2762 5.33289 12.1375 5.33301 12.0001L5.33301 7.99999V3.99997C5.33289 3.8625 5.37514 3.72379 5.46292 3.60462Z"
                      fill="currentColor" />
                  </svg></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
