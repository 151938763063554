<div class="main-wrap">
    <app-sidebar class="sidebarview"></app-sidebar>
    <section class="section">
        <div class="container-fluid">
            <div class="managecollection">
                <app-profile-header [loader]="loader"  [userData]="userData"></app-profile-header>
                <div class="row">
                    <div class="col-lg-4 mb-md-4">
                        <app-profile [loader]="loader" [userData]="userData"></app-profile>
                    </div>
                    <div class="col-lg-8 mt-3 mt-md-0 mt-lg-0">
                        <div class="profile-view">
                            <app-profile-nav [userData]="userRouteData"></app-profile-nav>
                                <div class="personal-details mb-4">
                                        <h3>Personal info.</h3>
                                    <div class="personal-info mt-4">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-12">
                                                <h4>Full Name.</h4>
                                                <h5 *ngIf="!loader">{{userData.name}}</h5>
                                                <ngx-skeleton-loader *ngIf="loader" [theme]="{background: 'rgba(0, 103, 54,.15)',width:'100%',height:'22px'}"> </ngx-skeleton-loader>

                                            </div>
                                            <div class="col-lg-6 col-md-12">
                                                <h4>Email Address.</h4>
                                                <h5>
                                                    <a *ngIf="!loader" href="mailto:{{userData.email}}">{{userData.email}}</a>
                                                    <ngx-skeleton-loader *ngIf="loader" [theme]="{background: 'rgba(0, 103, 54,.15)',width:'100%',height:'22px'}"> </ngx-skeleton-loader>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="personal-details mb-4">
                                    <h3>Balance info.</h3>
                                    <div class="personal-info mt-4">
                                        <div class="row">
                                            <div class="col-lg-12" >
                                                <div class="row" *ngIf="loader">
                                                    <div class="col-md-6">
                                                        <div class="user-wallet-amount" >
                                                            <div class="shimmer-load">
                                                                <div style="width: 32px; height: 32px;">
                                                                    <ngx-skeleton-loader [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '100%', height: '100%', 'border-radius': '100%' }"></ngx-skeleton-loader>
                                                                </div>
                                                                <div style="width:calc(100% - 42px); height: 15px;">
                                                                <ngx-skeleton-loader [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '100%', height: '100%' }"></ngx-skeleton-loader>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="user-wallet-amount" >
                                                            <div class="shimmer-load">
                                                                <div style="width: 32px; height: 32px;">
                                                                    <ngx-skeleton-loader [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '100%', height: '100%', 'border-radius': '100%' }"></ngx-skeleton-loader>
                                                                </div>
                                                                <div style="width:calc(100% - 42px); height: 15px;">
                                                                <ngx-skeleton-loader [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '100%', height: '100%' }"></ngx-skeleton-loader>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row" *ngIf="!loader">
                                                    <div class="col-md-6" *ngFor="let currency of currencies">
                                                        <div class="user-wallet-amount" >
                                                            <div class="d-flex gap-8 align-items-center">
                                                                <img  src="{{currency['image']}}" onerror="this.src = 'assets/images/bitcoin.png'" alt="currency">
                                                            <div>
                                                                <h4>{{currency.symbol}}</h4>
                                                                <p *ngIf="currency.usdValue > 0">${{(currency.balance * currency.usdValue).toFixed(5) || 0}} USD</p>
                                                            </div>
                                                            </div>
                                                            <h3>{{currency.balance || 0}} {{currency.symbol}}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="user-wallet-amount">
                                                    <div class="d-flex gap-8 align-items-center">
                                                    <img src="assets/images/polygon.png" alt="">
                                                    <div>
                                                        <h4>Polygon</h4>
                                                        <p *ngIf="maticPrice > 0">${{(regulatedBalance * maticPrice).toFixed(5)}} USD</p>
                                                    </div>
                                                    </div>
                                                    <h3>{{regulatedBalance.toFixed(5)}} MATIC</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="personal-details" *ngIf="isRegulated">
                                    <h3>KYC details.</h3>
                                    <div class="personal-info mt-4">
                                        <div class="row">
                                            <div *ngIf="loader">
                                                <ngx-skeleton-loader [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '100%', height: '20px'}"></ngx-skeleton-loader>
                                            </div>
                                            <div class="d-flex justify-content-between" *ngIf="!loader">
                                                <div>KYC upload status. : {{kycStatus ? 'Enabled' : 'Disabled'}}.</div>
                                                <div>
                                                    <label class="switch"> <input type="checkbox" (change)="kycUploadStatus(userData._id,$event.target['checked'])"  [checked]="kycStatus"><span class="slider round"></span></label>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        <!-- <div class="d-flex justify-content-center" *ngIf="loader">
                            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                        </div> -->
                        </div>

                    </div>
                    <!-- <div class="loading-section" *ngIf="loader"> -->

                        <!-- <div class="loader"></div>
                        <p>Please wait while we load your items. </p>&nbsp;
                    </div> -->
                </div>

            </div>

        </div>
    </section>
</div>
