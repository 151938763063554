import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import pinataClient, { PinataPinOptions } from '@pinata/sdk';
import { readContract } from '@wagmi/core';
import { firstValueFrom, Observable } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { encodeFunctionData, getAddress } from 'viem';
import Web3 from 'web3';
import { IApiResponse, walletAccount } from '../shared/interface/interface';
import { publicClientConfig } from './publicClientConfig.service';

const abi = require('../common/abis/factory.json');
const accessAbi = require('../common/abis/access-token.json');
const nftAbi = require('../common/abis/nftAbi.json');
const pinata = new pinataClient(environment.PINATA_KEY, environment.PINATA_SECRET);

const apiUrl = environment.API_BASE_URL;
const apiUrlV2 = environment.API_BASE_URL_V2;


const options: PinataPinOptions = {
    pinataMetadata: {
        name: 'Metadata',
    },
    pinataOptions: {
        cidVersion: 0,
    },
};

@Injectable({
    providedIn: 'root'
})
export class NftManagementService {
    public account: walletAccount;
    public contract: any
    public accessContract: any
    public isRegulated: string = '';
    public chainNetwork = (environment as any).CHAIN_ID;
    public web3: any
    private publicClient: any;

    /**
     * Creates an instance of nft management service.
     */
    constructor(
        private http: HttpClient,
        private storageService: StorageService,
        public handler: HttpBackend,
        public publicClientConfig: publicClientConfig

    ) {
        this.onInit()
    }

    /**
     * init on
     */
    public onInit() {
        this.web3 = new Web3(window['ethereum'] as any);
        setTimeout(() => {
            this.accountConfig();
        }, 1000);

    }

    /**
     * Accounts config
     */
    accountConfig() {
        this.account = this.storageService.getItem('wagmi.store') === null ?
            { address: '', network: '', chainId: '', provider: '' } :
            JSON.parse(this.storageService.getItem('wagmi.store') as any);
        this.isRegulated = JSON.parse(this.storageService.getItemSession('isRegulated')!);
        if (this.account.state.data.chain) {
            this.publicClient = this.publicClientConfig.publicClient(this.account.state.data.chain.id);
            this.contract = new this.web3.eth.Contract(abi, environment[this.account.state.data.chain.id].FACTORY_ADDRESS);
            this.accessContract = new this.web3.eth.Contract(accessAbi, environment[this.account.state.data.chain.id].ACCESS_CONTROL_TOKEN);
        }
    }

    /**
    * Creats collection
    * @param {any} data
    */
    public async creatCollection(data: any, walletAddress: string, isLazyMint: boolean) {
        let requiredGas;
        let createCollectionAbi;
        if (this.isRegulated) {
            if (isLazyMint) {
                const contractParams = {
                    address: environment[this.account.state.data.chain.id].FACTORY_ADDRESS,
                    abi: abi,
                    functionName: 'createLazyMintERC721CollectionRegulated',
                    args: [data['tokenName'], data['tokenSymbol'], data['description'], data['receiver'], data['attributes'], data['lazyMintUris']],
                }

                requiredGas = await this.publicClient.estimateContractGas({
                    address: environment[this.account.state.data.chain.id].FACTORY_ADDRESS,
                    account: walletAddress,
                    ...contractParams
                });


                createCollectionAbi = await encodeFunctionData({
                    ...contractParams
                })
            } else {
                const contractParams = {
                    address: environment[this.account.state.data.chain.id].FACTORY_ADDRESS,
                    abi: abi,
                    functionName: 'createERC721CollectionRegulated',
                    args: [data['tokenName'], data['tokenSymbol'], data['description'], data['receiver'], data['attributes']],
                }

                requiredGas = await this.publicClient.estimateContractGas({
                    address: environment[this.account.state.data.chain.id].FACTORY_ADDRESS,
                    account: walletAddress,
                    ...contractParams
                });
                createCollectionAbi = await encodeFunctionData({
                    ...contractParams
                })
            }
        }
        if (!this.isRegulated) {
            if (isLazyMint) {

                const contractParams = {
                    address: environment[this.account.state.data.chain.id].FACTORY_ADDRESS,
                    abi: abi,
                    functionName: 'createLazyMintERC721CollectionUnregulated',
                    args: [data['tokenName'], data['tokenSymbol'], data['description'], data['receiver'], data['attributes'], data['lazyMintUris']],
                }
                requiredGas = await this.publicClient.estimateContractGas({
                    address: environment[this.account.state.data.chain.id].FACTORY_ADDRESS,
                    account: walletAddress,
                    ...contractParams
                });

                createCollectionAbi = await encodeFunctionData({
                    ...contractParams
                })
            }
            else {


                const contractParams = {
                    address: environment[this.account.state.data.chain.id].FACTORY_ADDRESS,
                    abi: abi,
                    functionName: 'createERC721CollectionUnregulated',
                    args: [data['tokenName'], data['tokenSymbol'], data['description'], data['receiver'], data['attributes']],
                }
                requiredGas = await this.publicClient.estimateContractGas({
                    address: environment[this.account.state.data.chain.id].FACTORY_ADDRESS,
                    account: walletAddress,
                    ...contractParams
                });

                createCollectionAbi = await encodeFunctionData({
                    ...contractParams
                })


            }
        }
        requiredGas = requiredGas.toString();
        return { createCollectionAbi, requiredGas }
    }


    /**
     * Sets collection attributes
     * @param {string} accountAddress
     * @param {string} collectionAddress
     * @param {array} attributes
     * @returns
     */
    public async setCollectionAttributes(accountAddress: string, collectionAddress: string, attributes: Array<any>) {
        const contractParams = {
            address: environment[this.account.state.data.chain.id].FACTORY_ADDRESS,
            abi: abi,
            functionName: 'setCollectionAttributes',
            args: [collectionAddress, attributes],
        }
        const createAttributesAbi = await encodeFunctionData({
            ...contractParams
        })

        let requiredGas = await this.publicClient.estimateContractGas({
            address: environment[this.account.state.data.chain.id].FACTORY_ADDRESS,
            account: accountAddress,
            ...contractParams
        });

        requiredGas = requiredGas.toString();

        return { createAttributesAbi, requiredGas }
    }

    /**
     * Gets user created collections
     * @param {string} accountAddress
     * @returns
     */
    public async getUserCreatedCollections(accountAddress: string) {
        const chainId = this.account.state.data.chain.id;
        return await readContract({
            address: environment[this.account.state.data.chain.id].FACTORY_ADDRESS,
            abi: abi,
            chainId,
            functionName: 'getUserCreatedCollections',
            args: [accountAddress]
        });
    }


    /**
     * Gets creator
     * @param {number} tokenId
     * @param {string} collectionAddress
     * @returns
     */
    public async getCreator(tokenId: number, collectionAddress: string) {
        const chainId = this.account.state.data.chain.id;
        return await readContract({
            address: await getAddress(collectionAddress),
            abi: nftAbi,
            chainId,
            functionName: 'creator',
            args: [tokenId]
        });
    }

    getCategories() {
        return this.http.get(`${environment.API_BASE_URL}admin/nfttraits/categories`)
    }

    /**
     * Gets mandatory attributes
     * @returns
     */
    public getNftTriats(category: string = '', isLazyMint: boolean = false) {
        return this.http.get(`${(environment as any).API_BASE_URL}admin/nfttraits?category=${category}&lazy_mint=${isLazyMint}`);
    }

    /**
     * Gets collection attributes
     * @param {string} collectionAddress
     * @returns
     */
    public async getCollectionAttributes(collectionAddress: string) {
        const chainId = this.account.state.data.chain.id;
        return await readContract({
            address: environment[this.account.state.data.chain.id].FACTORY_ADDRESS,
            abi: abi,
            chainId,
            functionName: 'getCollectionAttributes',
            args: [collectionAddress]
        });
        // return await this.contract.methods.getCollectionAttributes(collectionAddress).call();
    }

    /**
     * Whites listed
     * @param {string} accountAddress
     * @returns
     */
    public async whiteListed(accountAddress: string) {
        await this.accountConfig();
        const chainId = this.account.state.data.chain.id;
        return await readContract({
            address: environment[this.account?.state?.data?.chain?.id].ACCESS_CONTROL_TOKEN,
            abi: accessAbi,
            chainId,
            functionName: 'whitelisted',
            args: [accountAddress]
        });

    }

    /**
    * Gets user created collections
    * @param {string} accountAddress
    * @returns
    */
    public async getCollectionName(collectionAddress: string) {
        const chainId = this.account.state.data.chain.id;
        return await readContract({
            address: await getAddress(collectionAddress),
            abi: nftAbi,
            chainId,
            functionName: 'name',
            args: []
        });
    }

    /**
     * To check the collection is valid and created from our factory
     * @param {string} accountAddress
     * @returns
     */
    public async isValidCollection(collectionAddress: string) {
        const chainId = this.account.state.data.chain.id;
        try {
            return await readContract({
                address: await getAddress(collectionAddress),
                abi: nftAbi,
                chainId,
                functionName: 'factory',
                args: []
            });
        } catch (e) {
            return '0x0000000000000000000000000000000000000000';
        }
    }

    /**
     * Gets user created collections
     * @param {string} accountAddress
     * @returns
     */
    public async getSymbol(collectionAddress: string) {
        const chainId = this.account.state.data.chain.id;
        return await readContract({
            address: await getAddress(collectionAddress),
            abi: nftAbi,
            chainId,
            functionName: 'symbol',
            args: []
        });
    }

    /**
    * Create NFT
    * @param {string} toAddress
    * @param {string} uri
    */
    public async createNft(collectionAddress: string, toAddress: string, uri: string, walletAddress: string, platformFee) {
        const urlAddress = (environment as any).BASE_URL + uri;

        const contractParams = {
            address: await getAddress(collectionAddress),
            abi: nftAbi,
            functionName: 'safeMint',
            args: [toAddress, urlAddress],
            value: platformFee
        }
        const createNftAbi = await encodeFunctionData({
            ...contractParams
        })

        let requiredGas = await this.publicClient.estimateContractGas({
            address: await getAddress(collectionAddress),
            account: walletAddress,
            ...contractParams
        });

        requiredGas = requiredGas.toString();
        return { createNftAbi, requiredGas }
    }

    /**
      * Update NFT
      * @param {string} tokenId
      * @param {string} uri
      * @param {array} tokenId
      */
    public async updateNft(collectionAddress: string, uri: string, tokenId: any, walletAddress: string) {
        const urlAddress = (environment as any).BASE_URL + uri;
        const contractParams = {
            address: await getAddress(collectionAddress),
            abi: nftAbi,
            functionName: 'updateTokenUri',
            args: [tokenId, urlAddress],
        }

        const createNftAbi = await encodeFunctionData({
            ...contractParams
        })

        let requiredGas = await this.publicClient.estimateContractGas({
            address: await getAddress(collectionAddress),
            account: walletAddress,
            ...contractParams
        });

        requiredGas = requiredGas.toString();

        return { createNftAbi, requiredGas }
    }

    /**
       * Uploads file
       * @param {file} file
       * @return {httpresponse}
       */
    public uploadFile(file: File) {
        const formData = new FormData();
        formData.append('file', file);
        const httpClientNew = new HttpClient(this.handler);
        return httpClientNew.post('https://api.pinata.cloud/pinning/pinFileToIPFS', formData, {
            headers: {
                'pinata_api_key': environment.PINATA_KEY,
                'pinata_secret_api_key': environment.PINATA_SECRET
            }
        });
    }

    /**
      * unpin file
      * @param cid
      * @returns
      */
    public unPinFile(cid: string) {
        const httpClientNew = new HttpClient(this.handler);
        return httpClientNew.delete(`https://api.pinata.cloud/pinning/unpin/${cid}`, {
            headers: {
                'pinata_api_key': environment.PINATA_KEY,
                'pinata_secret_api_key': environment.PINATA_SECRET
            }
        });
    }
    /**
       * Upload Json
       * @param {object} data
       * @return {Observable}
       */
    public uploadJson(data) {
        return pinata.pinJSONToIPFS(data, options);
    }

    /**
      * Burns nft
      * @param {string} id
      * @param {string} address
      * @param {string} walletAddress
      */
    public async burnNft(id: string, address: string, walletAddress: string) {
        const contractParams = {
            address: await getAddress(address),
            abi: nftAbi,
            functionName: 'burn',
            args: [id],
        }
        console.log("contractParams", contractParams);

        const burnAbi = await encodeFunctionData({
            ...contractParams
        })
        console.log("burnAbi", burnAbi);
        let requiredGas = await this.publicClient.estimateContractGas({
            address: await getAddress(address),
            account: walletAddress,
            ...contractParams
        });

        console.log("requiredGas", requiredGas);
        return { burnAbi, requiredGas }
    }

    /**
       * Owners contract integrations service
       */
    public async owner() {
        const chainId = this.account.state.data.chain.id;
        return await readContract({
            address: environment[this.account.state.data.chain.id].FACTORY_ADDRESS,
            abi: abi,
            chainId,
            functionName: 'owner',
            args: []
        });

    }


    /**
     * Gets nftowner
     * @param {string} tokenId
     * @param {string} address
     * @returns
     */
    public async getNftowner(tokenId: string, address: string) {
        const chainId = this.account.state.data.chain.id;
        return await readContract({
            address: await getAddress(address),
            abi: nftAbi,
            chainId,
            functionName: 'ownerOf',
            args: [tokenId]
        });
    }

    /**
     * Gets next mintable token
     * @param {string} address
     * @returns
     */
    public async getNextMintableToken(address: string) {
        const chainId = this.account.state.data.chain.id;
        return await readContract({
            address: await getAddress(address),
            abi: nftAbi,
            chainId,
            functionName: 'getNextMintableToken',
            args: []
        });
    }

    /**
       * Gets nft details
       * @param {string} url
       * @returns
       */
    public getNftDetails(url: string) {
        const httpClientNew = new HttpClient(this.handler);
        return httpClientNew.get(url);
    }


    /**
       * Gets nft
       * @param {string} address
       * @param {string} id
       * @returns
       */
    public async getNft(address: string, id: string) {
        const chainId = this.account.state.data.chain.id;
        return await readContract({
            address: await getAddress(address),
            abi: nftAbi,
            chainId,
            functionName: 'tokenURI',
            args: [id]
        });
    }

    /**
       * Platforms fee
       */
    public async platformFee() {
        const chainId = this.account.state.data.chain.id;
        return await readContract({
            address: environment[this.account.state.data.chain.id].FACTORY_ADDRESS,
            abi: abi,
            chainId,
            functionName: 'platformFee',
            args: []
        });
    }

    /**
       * Collections platform fee
       * @param {string} collectionAddress
       */
    public async collectionPlatformFee(collectionAddress: string) {
        const chainId = this.account.state.data.chain.id;
        return await readContract({
            address: await getAddress(collectionAddress),
            abi: nftAbi,
            chainId,
            functionName: 'platformFee',
            args: []
        });
    }

    /**
       * Collection Destroyed
       * @param {string} collectionAddress
       */
    public async isCollectionDestroyed(collectionAddress: string) {
        const chainId = this.account.state.data.chain.id;
        return await readContract({
            address: environment[this.account.state.data.chain.id].FACTORY_ADDRESS,
            abi: abi,
            chainId,
            functionName: 'isCollectionDestroyed',
            args: [collectionAddress]
        });

    }

    /**
      * Get Royalty fee
      * @param {string} clientAddress
      * @returns
      */
    public async royaltyFee(clientAddress: string) {
        const chainId = this.account.state.data.chain.id;
        return await readContract({
            address: environment[this.account.state.data.chain.id].FACTORY_ADDRESS,
            abi: abi,
            chainId,
            functionName: 'getFees',
            args: [clientAddress, this.isRegulated]
        });
    }

    /**
       * Royaltys fee
       * @param {string} collectionAddress
       * @returns
       */
    public async collectionRoyaltyFee(collectionAddress: string) {
        const chainId = this.account.state.data.chain.id;
        return await readContract({
            address: await getAddress(collectionAddress),
            abi: nftAbi,
            chainId,
            functionName: 'royaltyFee',
            args: []
        });
    }

    /**
      * Get total supply
      * @param {string} collectionAddress
      * @returns
      */
    public async getTotalSupply(collectionAddress: string) {
        const chainId = this.account.state.data.chain.id;
        return await readContract({
            address: await getAddress(collectionAddress),
            abi: nftAbi,
            chainId,
            functionName: 'totalSupply',
            args: []
        });
    }

    /**
       * Get collection owner
       * @param {string} collectionAddress
       * @returns
       */
    public async getCollectionOwner(collectionAddress: string) {
        const chainId = this.account.state.data.chain.id;
        return await readContract({
            address: await getAddress(collectionAddress),
            abi: nftAbi,
            chainId,
            functionName: 'owner',
            args: []
        });
    }

    /**
       * Creates collection in DB
       * @param {object} data
       * @returns
       */
    public createCollectionDb(data: object) {
        return this.http.post(`${apiUrl}admin/collection`, data);
    }

    /**
     * Updates collection db
     * @param {object} data
     * @param {string} id
     * @returns
     */
    public updateCollectionDb(data: object, id: string) {
        return this.http.patch(`${apiUrl}admin/collection?id=${id}`, data);
    }


    /**
     * Gets collection from db
     * @param {string} adress
     * @returns
     */
    public getCollectionDb(adress: string) {
        return this.http.get(`${apiUrl}admin/collection-by-address?address=${adress}`);
    }
    /**
     * Gets collection by address
     * @param {string} adress
     * @returns
     */
    public getCollectionByAddress(adress: string) {
        return this.http.get(`${apiUrl}admin/get-collection?address=${adress}`);
    }

    /**
     * Royaltys release search
     * @param {string} adress
     * @param {string} wallet_address
     * @returns
     */
    public royaltyReleaseSearch(adress: string, wallet_address: string) {
        return this.http.get(`${apiUrl}admin/search-royalty-release-collection?collection_address=${adress}&wallet_address=${wallet_address}`);
    }


    /**
     * Creates NFT in DB
     * @param {object} data
     * @returns
     */
    public createNftDb(data: object) {
        return this.http.post(`${apiUrl}admin/nft`, data);
    }

    /**
     * Updates nft db
     * @param {object} data
     * @param {string} token_id
     * @param {string} collection_address
     * @returns
     */
    public updateNftDb(data: object, token_id: string, collection_address: string) {
        return this.http.patch(`${apiUrl}admin/nft/update-by-token-id?token_id=${token_id}&collection_address=${collection_address}`, data);
    }

    /**
     * Delete NFT
     * @param {string} token_id
     * @param {string} collection_address
     * @param {string} wallet_address
     */
    public deleteNft(deliveryId: string, token_id: string, collection_address: string, wallet_address: string) {
        return this.http.delete(`${apiUrl}admin/nft/delete-by-token-id?token_id=${token_id}&collection_address=${collection_address}&wallet_address=${wallet_address}&delivery_id=${deliveryId}`);
    }

    /**
      * Validates csv file
      * @param {object} data
      */
    public validateCollection(data: object) {
        return this.http.post(`${apiUrl}admin/validate-api`, data);
    }

    /**
       * Update collection description
       * @param {string} accountAddress
       * @param {string} collectionAddress
       * @param {string} description
       * @returns
       */
    public async updateCollectionDescription(accountAddress: string, collectionAddress: string, description: string) {
        const nftContract = new this.web3.eth.Contract(nftAbi, collectionAddress);

        const contractParams = {
            address: await getAddress(collectionAddress),
            abi: nftAbi,
            functionName: 'updateCollectionDescription',
            args: [description],
        }
        const createAttributesAbi = await encodeFunctionData({
            ...contractParams
        })

        let requiredGas = await this.publicClient.estimateContractGas({
            address: await getAddress(collectionAddress),
            account: accountAddress,
            ...contractParams
        });
        requiredGas = requiredGas.toString();

        return { createAttributesAbi, requiredGas }
    }

    /**
       * Gets admin
       * @param {string} collectionAddress
       * @returns
       */
    public async getAdmin(collectionAddress: string) {
        const chainId = this.account.state.data.chain.id;
        return await readContract({
            address: await getAddress(collectionAddress),
            abi: nftAbi,
            chainId,
            functionName: 'admin',
            args: []
        });

    }

    /**
     * Checks burn nft
     * @param tokenId
     * @param collectionAddress
     * @returns
     */
    public checkBurnNft(collectionAddress, tokenId) {
        return this.http.get(`${apiUrl}admin/check-burn-nft-status?address=${collectionAddress}&token_id=${tokenId}`);
    }

    /**
     * Gets collections by address
     * @param {number} page
     * @param {number} limit
     * @param {string} walletAddress
     * @param {string} collectionAddress
     */
    getCollectionsByAddress(page: number, limit: number, walletAddress: string, collectionAddress?: string) {
        return this.http.get(`${apiUrl}admin/collection-list/wallet?address=${walletAddress}&search=${collectionAddress}&page=${page}&limit=${limit}`);
    }

    /**
     * Gets collections
     * @param {string} address
     * @returns
     */
    getCollections(address: string) {
        return this.http.get(`${apiUrl}admin/collection-list/owner?address=${address}`);
    }

    /**
     * Gets nftby collections
     * @param {string} collectionId
     * @param {string} lazy_mint
     * @param {number} page
     * @param {number} limit
     * @returns
     */
    getNftbyCollections(collectionId: string, lazy_mint: string, page: number, limit: number) {
        return this.http.get(`${apiUrl}admin/nft-list-by-collection?id=${collectionId}&lazy_mint=${lazy_mint}&page=${page}&limit=${limit}`);
    }

    /**
     * Gets nfts by category
     * @param {string} categoryName
     * @param {string} lazyMint
     * @param {number} page
     * @param {number} limit
     * @return{Observable<IApiResponse>}
     */
    getNftsbyCategory(categoryName: string, lazyMint: string = '', page: number, limit: number): Observable<IApiResponse> {
        return this.http.get<IApiResponse>(`${apiUrlV2}admin/nft-list-by-catgeory?category=${categoryName}&lazy_mint=${lazyMint}&page=${page}&limit=${limit}`);
    }

    /**
     * stores selected nfts under category which  will be listed in market palce by default
     * @param {string[]} selectedNftIds
     * @param {string} categoryName
     * @return{Observable<IApiResponse>}
     */
    public storeDefaultNftsinCategory(selectedNftIds: string[], categoryName: string): Observable<IApiResponse> {
        let payload = { nftIds: selectedNftIds, category: categoryName };
        return this.http.patch<IApiResponse>(`${apiUrlV2}admin/update-selected-nfts`, payload);
    }

    /**
     * removes selected nfts under category which is listed in market palce by default
     * @param {string[]} selectedNftIds
     * @return{Observable<IApiResponse>}
     */
    public removeDefaultNftsinCategory(selectedNftIds: string[]): Observable<IApiResponse> {
        let payload = { nftIds: selectedNftIds }
        return this.http.patch<IApiResponse>(`${apiUrlV2}admin/remove-selected-nfts`, payload);
    }

    /**
     * Gets selected nfts under specific category
     * @param {string}categoryName
     * @returns
     */
    getSelectedNftsInCategory(categoryName: string, lazyMint: string = '', page: number, limit: number): Observable<IApiResponse> {
        return this.http.get<IApiResponse>(`${apiUrlV2}admin/selected-nft-list?category=${categoryName}&lazy_mint=${lazyMint}&page=${page}&limit=${limit}`);
    }

    /**
     * Gets single nft
     * @param collectionAddress
     * @param tokenId
     * @returns
     */
    getSingleNft(collectionAddress: string, tokenId: string) {
        return this.http.get(`${apiUrl}admin/management/nft?collection_address=${collectionAddress}&token_id=${tokenId}`);
    }

    /**
     * Gets gold value
     * @param {object} data
     * @returns
     */
    public getGoldValue(data: object) {
        return firstValueFrom(this.http.post(`${(environment as any).API_BASE_URL}admin/get-gold-value`, data));
    }

    /**
     *
     * @param{string} media
     * @return{Promise}
     */
    async getImage(media: string) {
        return fetch(media)
            .then(response => {
                return response.blob().then(blob => {
                    return {
                        contentType: response.headers.get("Content-Type"),
                        raw: blob
                    }
                })
            })
            .catch(error => console.log('fetch image'))
    }

}
