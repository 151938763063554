// auth.service.ts

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {

  constructor(
      private router: Router,
      private storageService: StorageService,
    ) { }

  /**
   * Determines whether allow or not
   * @returns  
   */
  public canActivate(){

    // Authentication logic
    const isAuthenticated = this.checkIfUserIsAuthenticated();

    if (isAuthenticated) {
      return true;
    } else {
      // Navigate to the home page if not authenticated
      this.router.navigate(['/']);
      return false;
    }
  }

  /**
   * Checks if user is authenticated
   * @returns true if if user is authenticated 
   */
  private checkIfUserIsAuthenticated(): boolean {
    const account = this.storageService.getItem('wagmi.store') === null ?
      { address: '', network: '', chainId: '', provider: '' } :
      JSON.parse(this.storageService.getItem('wagmi.store'));
      if(account.state.data.account != '' && account.state.data.account != undefined){
        return true;
      }
      else{
        return false;
      }
    
  }
}
