<div class="profile-view">
    <div class="mb-3 profile-view-img d-flex justify-content-center position-relative"
        [ngClass]="profileLoader ? 'profile-view-img-processing' : ''" data-toggle="tooltip" title="support formats png, jpeg, jpg" >

        <img src="{{userData.profile_image ? userData.profile_image : 'assets/images/default-user.jpeg'}}"
            onerror="'assets/images/default-user.jpeg'" class="img-fluid">
        
        <div class="middle">
            <div class="text input-file-button">
                <label class="cursor-pointer" *ngIf="!profileLoader">Upload</label>
                <label class="cursor-pointer" *ngIf="profileLoader"> Uploading...</label>
                <input type="file" class="cursor-pointer" (change)="profileImageChange($event)">
            </div>
        </div>
        
    </div>
    <div class="profile-user-details mt-3">
        <h3 *ngIf="userData.name == userData.wallet_address && !loader">
            {{userData.name.slice(0, 7)}}...{{userData.name.slice(userData.name.length - 7)}}
        </h3>
        
        <h3 *ngIf="userData.name != userData.wallet_address && !loader">{{userData.name}}</h3>
        <ngx-skeleton-loader *ngIf="loader" [theme]="{background: 'rgba(0, 103, 54,.15)',width:'100%',height:'20px'}"> </ngx-skeleton-loader>
        <h4>User</h4>
        <div class="my-4 text-center" *ngIf="isRegulated">
            <button class="user-active" *ngIf="!loader">{{userData.kyc_verified ? 'Active' : 'Pending'}}</button>
            <ngx-skeleton-loader *ngIf="loader" [theme]="{background: 'rgba(0, 103, 54,.15)',width:'100%',height:'20px'}"> </ngx-skeleton-loader>
        </div>
        <div class="my-4">
            <hr>
        </div>
        <div class="my-2">
            <h5 class="mb-1">Wallet address.</h5>
            <div class="d-flex align-items-center">
                <ng-container *ngIf="!loader">
                    <p>{{userData.wallet_address.slice(0,
                    15)}}...{{userData.wallet_address.slice(userData.wallet_address.length - 15)}}</p>
                    <span class="ms-2"><img loading="lazy" class="cursor-pointer" src="assets/images/copy.svg"
                        (click)="copy(userData.wallet_address)"></span>
                </ng-container>
            </div>
            <ngx-skeleton-loader *ngIf="loader" [theme]="{background: 'rgba(0, 103, 54,.15)',width:'100%',height:'15px'}"> </ngx-skeleton-loader>
        </div>
        <div class="my-2 last-p" >
            <h5>Last active.</h5>
            <ngx-skeleton-loader *ngIf="userData.last_online && loader" [theme]="{background: 'rgba(0, 103, 54,.15)',width:'100%',height:'15px'}"> </ngx-skeleton-loader>
            <p *ngIf="userData.last_online && !loader">{{userData.last_online | lastSeen}}</p>
        </div>
        <div class="my-2 last-p">
            <h5>Account type.</h5>
            <ngx-skeleton-loader *ngIf="userData && loader"
              [theme]="{background: 'rgba(0, 103, 54,.15)',width:'100%',height:'15px'}"> </ngx-skeleton-loader>
            <p *ngIf="userData && !loader">{{userData?.google_signIn ? 'Google Account.' : userData?.apple_signin
              ? 'Apple Account.' :'Regular Signup.' }}</p>
          </div>
          <div class="my-2 last-p">
            <h5>Registration Time.</h5>
            <ngx-skeleton-loader *ngIf="userData.createdAt && loader"
              [theme]="{background: 'rgba(0, 103, 54,.15)',width:'100%',height:'15px'}"> </ngx-skeleton-loader>
            <p *ngIf="userData?.createdAt && !loader">{{userData?.createdAt | utcConverter | date:"dd/MM/yyyy h:mm:ss a"}}</p>
          </div>
    </div>

</div>