import { Directive } from '@angular/core';
import { sendTransaction, waitForTransaction } from '@wagmi/core';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: '[appManageTransactions]'
})
export class ManageTransactionsDirective {

  constructor(
    private toastr:ToastrService,
  ) { }

  public makeTransactions(params:any){
    return new Promise(async (resolve, reject) => {
      await sendTransaction(params).
          then(async (receipt: any) => {
              await waitForTransaction({ hash: receipt.hash })
                  .then((response: any) => {
                      resolve({ status: true, data: response });
                  }).catch((error: any) => {
                    reject({ status: false, data: error?.error });
                  });
                  resolve({ status: true, data: receipt });
                }).catch((error: any) => {
            reject({ status: false, data: error?.error });
            console.log("error log",error);

            if((error?.toString())?.includes('User rejected the request') || (error?.data?.toString())?.includes('User rejected the request')){
              this.toastr.error("User declined the transaction.");
            }
            else{
              this.toastr.error("Something went to wrong");
            }
          })
  });

  }
}
