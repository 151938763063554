<div class="main-wrap">
    <app-sidebar class="sidebarview"></app-sidebar>
    <section class="section">
        <div class="container-fluid">
            <div class="managecollection">
                <div class="row mb-4">
                    <div class="col-lg-6">
                        <ul class="manage-collection">
                            <li class="nav-item cursor-pointer" [routerLink]="['/manage-collection/create']">Create
                                collection.</li>
                            <li class="nav-item menuactivities cursor-pointer">{{
                                urlAddress != '' && urlAddress != null ? 'Edit' :
                                'Create' }} lazymint collection.</li>

                        </ul>
                    </div>
                </div>
                <!--start forms-->
                <form [formGroup]="collectionForm" (ngSubmit)="createCollectionsSubmit()">

                    <div class="row">
                        <div class="col-lg-6 mb-4">
                            <label for="label">Select category. <span class="ms-2 text-danger">*</span></label>
                            <ng-select [clearable]=false placeholder="Choose a category" [readonly]="isEditing"
                                [class]="isDisplayCollectionForm ? '' : 'is-not-selected'" formControlName="category"
                                (change)="changeCategory($event)">
                                <ng-option *ngFor="let data of categories" [value]="data">{{data}}</ng-option>
                            </ng-select>
                        </div>



                    </div>
                    <!-- <div class="row mb-lg-4 mb-sm-2" *ngIf="isDisplayCollectionForm">

                    </div> -->
                    <div class="row mb-lg-4 mb-sm-2" *ngIf="isDisplayCollectionForm">
                        <div class="col-lg-6 mb-4">
                            <label for="label">Collection Type. <span class="ms-2 text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="" readonly
                                formControlName="collectionType">
                        </div>
                        <div class="col-lg-6 mb-4">
                            <label for="label">Base url.<span class="ms-2 text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="" formControlName="baseUrl" readonly>
                            <div *ngIf="collectionFormSubmitted && collectionFormGet.baseUrl.errors"
                                class="invalid-feedback-custom">
                                <small class="text-danger" *ngIf="collectionFormGet.baseUrl.errors.required">Please
                                    enter base url.</small>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-4">
                            <label for="label">Name. <span class="ms-2 text-danger">*</span></label>
                            <input type="text" class="form-control" [readonly]="urlAddress != '' && urlAddress != null"
                                placeholder="Name." formControlName="name">
                            <div *ngIf="collectionFormSubmitted && collectionFormGet.name.errors"
                                class="invalid-feedback-custom">
                                <small class="text-danger" *ngIf="collectionFormGet.name.errors.required">Please enter
                                    collection Name.</small>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-4">
                            <label for="label">Symbol. <span class="ms-2 text-danger">*</span></label>
                            <input type="text" class="form-control" [readonly]="urlAddress != '' && urlAddress != null"
                                placeholder="Symbol." formControlName="symbol">
                            <div *ngIf="collectionFormSubmitted && collectionFormGet.symbol.errors"
                                class="invalid-feedback-custom">
                                <small class="text-danger" *ngIf="collectionFormGet.symbol.errors.required">Please enter
                                    symbol.</small>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-3">
                            <label for="label">Client Address.<span class="ms-2 text-danger">*</span></label>
                            <input type="text" class="form-control"
                                (change)="clientAddressChange($any($event.target).value)"
                                [readonly]="urlAddress != '' && urlAddress != null" formControlName="clientAddress"
                                placeholder="Client Address.">
                            <div *ngIf="collectionFormSubmitted && collectionFormGet.clientAddress.errors"
                                class="invalid-feedback-custom">
                                <small class="text-danger"
                                    *ngIf="collectionFormGet.clientAddress.errors.required">Please enter client
                                    address
                                    url.</small>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-4">
                            <label for="label">Royalty Fee(%).<span class="ms-2 text-danger">*</span> <span
                                    class="ms-2 warning-text">Add client address can get Royalty Fee.</span> </label>
                            <input type="text" class="form-control" placeholder="" value="2.5" readonly
                                formControlName="royaltyFee">
                        </div>
                        <div class="col-lg-6 mb-4">
                            <label for="label">Email.
                                <a class="onaboard" (click)="secondayMail()" [ngClass]="ccMail?'d-none':''">Add CC</a>
                                <a class="closemail" (click)="closeMail()" [ngClass]="ccMail?'':'d-none'">Close CC</a>
                                <span class="ms-2 warning-text">Type email and hit ENTER.</span></label>
                            <tag-input [(ngModel)]="chips1" class="tag-chips" [theme]="'minimal-danger'"
                                [addOnPaste]="true" [validators]="validators" name="attribute" formControlName="email"
                                [errorMessages]="errorMessages" placeholder="Enter email address"
                                secondaryPlaceholder="Enter email address"></tag-input>
                        </div>
                        <div class="col-lg-6 mb-4" *ngIf="ccMail">
                            <div class="form-group">
                                <label>Secondary mail. <span class="ms-2 warning-text">Type email and hit
                                        ENTER.</span></label>
                                <tag-input [(ngModel)]="chips2" class="tag-chips" [theme]="'minimal-danger'"
                                    [addOnPaste]="true" name="attribute" formControlName="cc_email"
                                    [validators]="validators" [errorMessages]="errorMessages"
                                    placeholder="Enter email address"
                                    secondaryPlaceholder="Enter email address"></tag-input>
                                <div *ngIf="emailError" class="warning-text">The Email and Secondary mail should be
                                    different.</div>
                            </div>
                        </div>

                        <div class="col-lg-6 mb-3">
                            <label for="label">Description.</label>
                            <div class="NgxEditor__Wrapper">
                                <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
                                <ngx-editor formControlName="description" [ngModel]="html" [editor]="editor"
                                    [placeholder]="'Description.'"></ngx-editor>
                            </div>
                        </div>
                        <!-- Unit Type(Singular) -->
                        <div class="col-lg-6 mb-4">
                            <label for="label">Unit Type(Singular).</label>
                            <input type="text" class="form-control" placeholder="Unit(Singular)." trimWhitespaces [required]="!!collectionFormGet.unit_type_plural.value" formControlName="unit">
                            <div *ngIf="collectionFormGet.unit_type_plural.touched && collectionFormGet.unit.errors"
                            class="invalid-feedback-custom">
                            <small class="text-danger"
                                *ngIf="collectionFormGet.unit.errors.required">Please enter unit type(singular).</small>
                            </div>
                        </div>

                        <!-- Unit Type(Plural) -->
                        <div class="col-lg-6 mb-4">
                            <label for="label">Unit Type(Plural).</label>
                            <input type="text" class="form-control" placeholder="Unit(Plural)." trimWhitespaces [required]="!!collectionFormGet.unit.value" formControlName="unit_type_plural">
                            <div *ngIf="collectionFormGet.unit.touched && collectionFormGet.unit_type_plural.errors"
                            class="invalid-feedback-custom">
                            <small class="text-danger"
                                *ngIf="collectionFormGet.unit_type_plural.errors.required">Please enter unit type(plural).</small>
                            </div>
                        </div>


                        <!-- <div class="col-lg-6 mb-4">
                            <label for="label">Name. <span class="ms-2 text-danger">*</span></label>
                            <input type="text" class="form-control" [readonly]="urlAddress != '' && urlAddress != null"
                                placeholder="Name." formControlName="name">
                            <div *ngIf="collectionFormSubmitted && collectionFormGet.name.errors"
                                class="invalid-feedback-custom">
                                <small class="text-danger" *ngIf="collectionFormGet.name.errors.required">Please enter
                                    collection Name.</small>
                            </div>
                        </div> -->
                        <!-- <div class="col-lg-6 mb-4">
                            <label for="label">Email.</label>
                            <input type="text" class="form-control" placeholder="Email." formControlName="email">
                        </div> -->
                        <div class="col-lg-6 mb-4" *ngIf="hasLocation">
                            <label for="label">Location. <span class="ms-2 text-danger">*</span></label>
                            <ng-select formControlName="location">
                                <ng-option *ngFor="let data of locationDetails" [value]="data">{{data}}</ng-option>
                            </ng-select>
                        </div>
                        <div class="col-lg-6 mb-4" *ngIf="chipData?.length > 0">
                            <div class="form-group">
                                <label>Attributes. <span class="text-danger"> * </span></label>
                                <tag-input class="tag-chips" [theme]="'minimal-danger'" [addOnPaste]="true"
                                    name="attribute" formControlName="attribute"
                                    placeholder="Enter collection attributes"></tag-input>
                                <div *ngIf="collectionFormSubmitted && collectionFormGet.attribute.errors"
                                    class="invalid-feedback-custom">
                                    <small class="text-danger"
                                        *ngIf="collectionFormGet.attribute.errors.required">Please enter
                                        attributes.</small>
                                </div>
                            </div>

                        </div>


                        <div class="" *ngIf="currentCategory != 'Gold' && (!isEditing || lazyMintData?.length)">
                            <div class="col-lg-12 mb-3">
                                <label for="label">Enter lazymint item details.<span class="ms-2 text-danger">*</span>
                                    <span class="ms-2 warning-text"></span> </label>
                                <tag-input *ngIf="!isEditing" class="tag-chips" [theme]="'minimal-danger'"
                                    [addOnPaste]="true" name="attribute" formControlName="lazyMintNftDetails"
                                    placeholder="Enter a new tag"></tag-input>
                                <div *ngIf="isEditing" class="lazyminteditmaildetail">
                                    <div *ngFor="let data of lazyMintData">{{data}}</div>
                                </div>
                                <div class="py-2 warning-text" *ngIf="!isEditing">Type the value and hit enter.</div>
                            </div>
                        </div>
                    </div>
                    <!--end-->
                    <div class="row mt-4 mb-2" *ngIf="isDisplayCollectionForm">
                        <div class="col-lg-12">
                            <div class="d-flex align-items-center gap-4" *ngIf="account.state.data.account != ''">
                                <button class="submit-btn" type="submit" *ngIf="!createCollectionLoader"
                                    [disabled]="!collectionForm.valid">
                                    <span *ngIf="urlAddress != '' && urlAddress != null">Update.</span>
                                    <span *ngIf="urlAddress == '' || urlAddress == null">Submit.</span>
                                </button>
                                <button class="submit-btn" *ngIf="createCollectionLoader" disabled>
                                    Processing...
                                </button>

                                <button class="clear-btn" type="button" (click)="clearForm()"
                                    [disabled]="createCollectionLoader">Clear.</button>
                            </div>
                            <div class="d-flex align-items-center gap-4" *ngIf="account.state.data.account == ''">
                                <button class="submit-btn" type="button" (click)="connectWallet()">Connect
                                    Wallet.</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <app-ui-modal #collectionSuccessModal [hideHeader]="true" [hideFooter]="true">
            <div class="app-modal-body">
                <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
                    (click)="closeCollectionModal()"><span aria-hidden="true">&times;</span></button>
                <div class="modal-dialog" *ngIf="!urlAddress">
                    <h5 class="text-green text-center collection-success-title">Collection created successfully.</h5>
                    <div class="row">
                        <div class="col-12 d-flex gap-3 flex-wrap justify-content-center">
                            <div class="collection-name">
                                {{collectionName}}
                            </div>
                            <div class="collection-address" title="copy to clipboard">
                                <div class="d-flex gap-2">
                                    {{collectionAddress.slice(0,
                                    7)}}...{{collectionAddress.slice(account.state.data.account.length
                                    - 7)}}
                                    <img loading="lazy" src="assets/images/copy-icon.png" class="copy-icon"
                                        alt="copy-icon" (click)="copyCollectionAddress(collectionAddress)">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 view-link d-block">
                            <span class="d-flex justify-content-between"> ERC721 Collection. :
                                <a target="_blank" href="{{transhUrl}}/tx/{{transMintHash}}" target="_blank"> Click to
                                    view transactions. </a>
                            </span>
                        </div>
                        <div class="col-12 view-link" *ngIf="currentCategory == 'Gold'">
                            <button class="btn btn-green" (click)="createNft(collectionAddress)">Create Item.</button>
                        </div>
                    </div>
                </div>
            </div>
        </app-ui-modal>
    </section>
</div>