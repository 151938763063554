import { Component,  OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LogDebugsService } from 'src/app/services/log-debugs.service';
import { LogsService } from 'src/app/services/logs.service';
import { SocketIoService } from 'src/app/services/socket-io.service';
import { StorageService } from 'src/app/services/storage.service';
import { CopyDirective } from 'src/app/shared/directives/copy.directive';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';
let web3 = new Web3(window['ethereum'] as any);
const apiUrl = environment.API_BASE_URL;

@Component({
  selector: 'app-transacation-detail',
  templateUrl: './transacation-detail.component.html',
  styleUrls: ['./transacation-detail.component.scss']
})
export class TransacationDetailComponent implements OnInit{
  public allLogsDetails:any;
  public logsDetails:any;
  public reciverDetails;
  public transhUrl: string = '';
  public transhId: string = '';
  public isLoadingData:boolean = false;
  public currentPageLimit: number = 10;

  constructor(
    private socketIoService:SocketIoService,
    private logsService:LogsService,
    private copyDirective:CopyDirective,
    private route: ActivatedRoute,
    private logDebgsService:LogDebugsService,
    private storageService:StorageService
  ) { }
 
  /**
   * on init
   */
  public ngOnInit(): void {
    this.socketIoService.isConnected();
    this.transhId = this.route.snapshot.paramMap.get('id');

    this.socketIoService.getAllLogs$.subscribe((_response) => {
    });
    this.getAllLogs(1,this.currentPageLimit);
    this.getLogsById(this.transhId);

    const account = this.storageService.getItem('wagmi.store') === null ?
    { address: '', network: '', chainId: '', provider: '' } :
    JSON.parse(this.storageService.getItem('wagmi.store') as any);
    this.transhUrl = environment[account.state.data.chain.id].EXPLORER
  }

  
  /**
   * Gets all logs
   * @param {number} page 
   * @param {number} limit 
   */
  public getAllLogs(page:number,limit:number){
    this.isLoadingData = true;
    this.logsService.getLogs(page,limit).subscribe((response) => {
      this.allLogsDetails = response['data']['docs'];
      this.isLoadingData = false;
      this.logDebgsService.debugs(`${apiUrl}logs/list-logs?page=${page}&limit=${limit}`,response,'get','success');
    },
    (error) => {
      this.isLoadingData = false;
      this.logDebgsService.debugs(`${apiUrl}logs/list-logs?page=${page}&limit=${limit}`,error,'get','error');
    })
  }

  /**
   * Gets logs by id
   * @param {string} id 
   */
  public getLogsById(id:string){
    this.logsService.getLogsById(id).subscribe((response) => {
      this.logsDetails = response['data'];
      this.getLogsByAddress(this.logsDetails['to']);
    },
    (error) => {
    })
  }

  /**
   * Gets logs by address
   * @param {string} walletAddress 
   */
  public getLogsByAddress(walletAddress:string){
    this.logsService.getLogsByAddress(walletAddress).subscribe((response) => {
      this.reciverDetails = response['data'];
    },
    (error) => {
    })
  }

  /**
     * Track by function for ngFor loops
     * @param index
     * @param item
     */
  public trackByFn(index: number, item: any): any {
    return item._id || index;
  }

  /**
   * Copys content
   * @param content 
   */
public copy(content:string){
  this.copyDirective.copy(content)
}


/**
 * From wei convertion for gas fee
 * @param {number} value 
 * @returns  
 */
public fromWeiConvertion(value){
  const gasFee = +web3.utils.fromWei(value.toString(),'Gwei');
  return gasFee.toFixed(6)
}

 
}
