import { Component } from '@angular/core';
import { NgxBugatlasService } from 'ngx-bugatlas';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'RealWorld.fi - Management application';
    data = {
        api_key: environment.BUG_ATLAS_API_KEY,
        secret_key: environment.BUG_ATLAS_SECRET_KEY,
    }
    constructor(
        private ngxBugatlasService: NgxBugatlasService,
    ) {
        this.ngxBugatlasService.seConfigKey(this.data)
    }
}
