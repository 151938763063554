<section class="connect-wallet">
    <div class="container">
        <div class="row ">
            <div class="col-lg-12">
                <header>
                    <div class="d-flex align-items-center justify-content-between flex-wrap gap-3">
                        <img loading="lazy"  src="assets/images/logo.png" class="img-fluid" width="250" alt="Realworld logo">  
                        <h4>Email: <a href="mailto:info@realworld.fi">info@realworld.fi</a></h4>
                    </div>
                </header>
                
            </div>
            
        </div>
        <div class="row mentor align-items-center">
            <div class="col-lg-5 col-sm-7 mx-auto">
                <div class="walletconnect">
                    <h3>Connect Wallet</h3>
                    <p>Choose a wallet to connect</p>
                    <div class="d-flex justify-content-center">
                        <w3m-button balance='hide'></w3m-button>
                        <w3m-network-switch></w3m-network-switch>
                    </div>
               </div>
            </div>
        </div>
    </div>
</section>