import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TagInputModule } from 'ngx-chips';
import { ClipboardModule } from 'ngx-clipboard';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpInterceptorService } from './interceptors/http-interceptor.service';
import { ConncetWalletComponent } from './module/conncet-wallet/conncet-wallet.component';
import { DashboardComponent } from './module/dashboard/dashboard.component';
import { TransacationDetailComponent } from './module/dashboard/transacation-detail/transacation-detail.component';
import { TransactionsLogsListComponent } from './module/dashboard/transactions-logs-list/transactions-logs-list.component';
import { UserLogsDetailsComponent } from './module/dashboard/user-logs-details/user-logs-details.component';
import { CreateCollectionComponent } from './module/manage-collection/create-collection/create-collection.component';
import { CreateLazymintCollectionComponent } from './module/manage-collection/create-lazymint-collection/create-lazymint-collection.component';
import { EditcollectionComponent } from './module/manage-collection/editcollection/editcollection.component';
import { ManageCollectionComponent } from './module/manage-collection/manage-collection.component';
import { BurnnftComponent } from './module/manage-nft/burnnft/burnnft.component';
import { CreatenftComponent } from './module/manage-nft/createnft/createnft.component';
import { EditnftComponent } from './module/manage-nft/editnft/editnft.component';
import { ManageNftComponent } from './module/manage-nft/manage-nft.component';
import { AllUserComponent } from './module/manage-user/all-user/all-user.component';
import { EditUserComponent } from './module/manage-user/edit-user/edit-user.component';
import { ManageUserComponent } from './module/manage-user/manage-user.component';
import { NftHoldingComponent } from './module/manage-user/profileuser/nft-holding/nft-holding.component';
import { NftTransacationComponent } from './module/manage-user/profileuser/nft-transacation/nft-transacation.component';
import { ProfileHeaderComponent } from './module/manage-user/profileuser/profile-header/profile-header.component';
import { ProfileNavComponent } from './module/manage-user/profileuser/profile-nav/profile-nav.component';
import { ProfileComponent } from './module/manage-user/profileuser/profile/profile.component';
import { ProfileuserComponent } from './module/manage-user/profileuser/profileuser.component';
import { RoyaltyComponent } from './module/royalty/royalty.component';
import { FooterNewComponent } from './shared/component/footer-new/footer-new.component';
import { HeaderNewComponent } from './shared/component/header-new/header-new.component';
import { SearchComponentComponent } from './shared/component/search-component/search-component.component';
import { SidebarComponent } from './shared/component/sidebar/sidebar.component';
import { Const, initialUserDetails } from './shared/const/const';
import { CopyDirective } from './shared/directives/copy.directive';
import { ErrorHandlerDirective } from './shared/directives/error-handler.directive';
import { ManageTransactionsDirective } from './shared/directives/manage-transactions.directive';
import { CommonHelper } from './shared/helpers/common-helper';
import { ContentSlicePipe } from './shared/pipes/content-slice.pipe';
import { LastseenPipe } from './shared/pipes/lastseen.pipe';
import { SearchPipe } from './shared/pipes/search.pipe';
import { UtcConverterPipe } from './shared/pipes/utc-converter.pipe';
import { UiModalComponent } from './shared/ui-modal/ui-modal.component';

import { NgxBugatlasModule } from 'ngx-bugatlas';
import { NgxEditorModule } from 'ngx-editor';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AnnouncementDetailComponent } from './module/announcement-detail/announcement-detail.component';
import { DeliveryDetailsComponent } from './module/delivery/delivery-details/delivery-details.component';
import { DeliveryHistoryComponent } from './module/delivery/delivery-history/delivery-history.component';
import { ManageDeliveryComponent } from './module/delivery/manage-delivery/manage-delivery.component';
import { ManageCategoryComponent } from './module/manage-category/manage-category.component';
import { SafeHtmlPipe } from './shared/pipes/safe-html.pipe';
import { SafePipe } from './shared/pipes/safe.pipe';
import { TrimWhitespacesDirective } from './shared/directives/trim-whitespaces.directive';


@NgModule({
    declarations: [
        AppComponent,
        HeaderNewComponent,
        FooterNewComponent,
        SidebarComponent,
        DashboardComponent,
        ManageCollectionComponent,
        EditcollectionComponent,
        ManageNftComponent,
        EditnftComponent,
        BurnnftComponent,
        ManageUserComponent,
        AllUserComponent,
        ProfileuserComponent,
        NftHoldingComponent,
        NftTransacationComponent,
        TransacationDetailComponent,
        RoyaltyComponent,
        UiModalComponent,
        SearchPipe,
        CopyDirective,
        ProfileComponent,
        ProfileHeaderComponent,
        ProfileNavComponent,
        TransactionsLogsListComponent,
        UtcConverterPipe,
        ErrorHandlerDirective,
        EditUserComponent,
        ConncetWalletComponent,
        ManageTransactionsDirective,
        LastseenPipe,
        CreateCollectionComponent,
        ContentSlicePipe,
        SearchComponentComponent,
        CreatenftComponent,
        UserLogsDetailsComponent,
        ManageDeliveryComponent,
        DeliveryHistoryComponent,
        CreateLazymintCollectionComponent,
        DeliveryDetailsComponent,
        SafePipe,
        AnnouncementDetailComponent,
        SafeHtmlPipe,
        ManageCategoryComponent,
        TrimWhitespacesDirective

    ],
    imports: [
        RouterModule,
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        }),
        BrowserAnimationsModule,
        TagInputModule,
        ClipboardModule,
        NgSelectModule,
        NgxSkeletonLoaderModule.forRoot({ animation: 'pulse' }),
        NgxEditorModule,
        InfiniteScrollModule,
        NgxBugatlasModule
    ],
    providers: [
        AppModule,
        CommonHelper,
        Const,
        initialUserDetails,
        CopyDirective,
        ErrorHandlerDirective,
        ManageTransactionsDirective,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true,
        }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {

}
