import { Component, Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Editor, Toolbar } from 'ngx-editor';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/services/storage.service';
import { IApiResponse } from 'src/app/shared/interface/interface';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

  
@Component({
  selector: 'app-announcement-detail',
  templateUrl: './announcement-detail.component.html',
  styleUrls: ['./announcement-detail.component.scss']
})
export class AnnouncementDetailComponent {
  announcements!:FormGroup;
  //submitted:boolean=false;
  editor: Editor;
  html = '';
  getAnnouncementData:any;
  statusValue:boolean=true;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  currentStatus: boolean;
  constructor(private fb:FormBuilder, private announceService:AnnouncementService, private toast:ToastrService,
    private storageService:StorageService,
    private domSanitizer:DomSanitizer

  ){
  }
  ngOnInit(){
    this.editor = new Editor();
    /**
     * formgroup
     */
    this.announcements = this.fb.group({
      background_color:['#006736', [Validators.required]],
      font_color:['#f5f5f5', [Validators.required]],
      announcement_title:['',[Validators.required]],
    });
    this.getUser()
    
  }
  announcementSubmit(){
    //this.submitted=true;
    if(this.announcements.valid){
      console.log('announcementsss', this.announcements.value);
      let apiCall ;
      let announcementId = this.storageService.getItem('announcement-id');
      if( announcementId != null ){
        apiCall = this.announceService.updateAnnouncemnet(this.announcements.value,announcementId);
      }else{
        apiCall = this.announceService.createAnnouncemnet(this.announcements.value);

      }
      apiCall.subscribe({
        next:(response:IApiResponse)=>{
          this.toast.success(response.message);
          this.getUser();
          this.storageService.removeItem('announcement-id');
          this.announcements.reset();
        },
        error:(response:IApiResponse)=>{
          this.toast.error(response.message);
        }
      })
    }
  }
  get f():{[key:string]:AbstractControl}{
    return this.announcements.controls;
  }
  /** 
     * get announcement
    */
 getUser(){
  this.announceService.getAnnouncement().subscribe({
    next:(response:IApiResponse)=>{
      this.getAnnouncementData=response.data.docs;
      // this.getAnnouncementData.forEach(element => {
      //   element.announcement_title  = this.domSanitizer.bypassSecurityTrustHtml(element.announcement_title);
      // }); 
    }
  })
 }
 /**
  * delete announcement
  */
 deleteAnnounce(data:any){
    this.announceService.removeAnnouncement(data).subscribe({
      next:(response:IApiResponse)=>{
        this.toast.success(response.message);
        this.storageService.removeItem('announcement-id');
      },
      error:(response:IApiResponse)=>{
        this.toast.error(response.message);
      }
     
    })
 }
 /**
  * edit announcement
  */
 editAnnouncemnet(data){
  this.storageService.setItem('announcement-id',data?._id);
     this.announcements.patchValue({
        background_color: data.background_color,
        font_color: data.font_color,
        announcement_title:data.announcement_title
      });
 }
 /**
  * enable announcement
  */
 setAnnouncement(data){
  let usedId = data?._id;
  let currentStatus = data?.enabled;
  if (currentStatus === true) {
    this.currentStatus = false;
  } else {
    this.currentStatus = true;
  }
  this.announceService.enableStatus(usedId, { enabled: this.currentStatus }).subscribe({
    next:(response:IApiResponse)=>{
      this.toast.success(response.message);
      this.getUser();
    },
    error:(error:any)=>{
      this.toast.error(error.error.message);
     this.getUser();
    }
  })
 }
}

