import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { CopyDirective } from 'src/app/shared/directives/copy.directive';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { allUsers, IApiResponse, IPagination, walletAccount } from '../../../shared/interface/interface';

import { HttpErrorResponse } from '@angular/common/http';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { CommonContractService } from 'src/app/services/common-contract.service';
import { ErrorHandlerDirective } from 'src/app/shared/directives/error-handler.directive';
import { ManageTransactionsDirective } from 'src/app/shared/directives/manage-transactions.directive';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';

let web3 = new Web3(window['ethereum'] as any);


@Component({
    selector: 'app-all-user',
    templateUrl: './all-user.component.html',
    styleUrls: ['./all-user.component.scss']
})
export class AllUserComponent implements OnInit {
    public allUserDetails: any;
    public searchValue: string;
    public isTableData: boolean = true;
    public currentPageLimit: number = 10;
    public allUserDetailsPaginations: IPagination;
    public isBlocked: boolean = false;
    public account: walletAccount;
    public isLoadingData: boolean = false;
    public isRegulated: any;
    isKycEnabledInAdmin = false;

    constructor(
        private userManagementService: UserManagementService,
        private accountService: AccountService,
        private copyDirective: CopyDirective,
        private storageService: StorageService,
        public clibboard: ClipboardService,
        public toastr: ToastrService,
        private manageTransactionsDirective: ManageTransactionsDirective,
        public commonservices: CommonContractService,
        private errorHandler: ErrorHandlerDirective
    ) { }

    /**
     * on init
     */
    ngOnInit(): void {
        this.isRegulated = JSON.parse(this.storageService.getItemSession('isRegulated'));
        if (this.isRegulated) this.getAdminKycStatus();
        this.commonservices.closeModalsObservable.subscribe((response: boolean) => {
            if (response) {
                Swal.close()
            }
        });

        this.account = this.storageService.getItem('wagmi.store') === null ?
            { address: '', network: '', chainId: '', provider: '' } :
            JSON.parse(this.storageService.getItem('wagmi.store') as any);;

        this.getAllUsers(1, this.currentPageLimit, this.isBlocked);

        this.accountService.searchPipeObserve.subscribe((response: any) => {
            if (this.searchValue == '' || this.searchValue == undefined) this.isTableData = true;

            if (this.searchValue != '' && this.searchValue != undefined) {
                this.isTableData = response;
                if (response) {
                    this.isTableData = response;
                    return
                }
            }

        })

        this.accountService.accountObserve.subscribe((response) => {
            if (response) {
                this.account = this.storageService.getItem('wagmi.store') === null ?
                    { address: '', network: '', chainId: '', provider: '' } :
                    JSON.parse(this.storageService.getItem('wagmi.store') as any);
                this.getAllUsers(1, this.currentPageLimit, this.isBlocked);
            }
        });

    }

    /**
     * Gets kyc status from admin
     */
    getAdminKycStatus() {
        this.userManagementService.getAdminKycStatus().subscribe({
            next: (response: IApiResponse) => {
                this.isKycEnabledInAdmin = response['data']['admin_kyc_status'];
            },
            error: (error: HttpErrorResponse) => {
                this.errorHandler.handleError(error);
            }
        });
    }


    /**
     * Gets all users
     * @param {number} page
     * @param {number} limit
     * @param {boolean} type
     * @param {string} item
     */
    public getAllUsers(page: number, limit: number, isBlocked: boolean, item?: string) {
        let searchKey = '';
        if (item) {
            searchKey = `&item=${item}`;
        }
        this.isLoadingData = true;
        this.userManagementService.getAllUsers(page, limit, isBlocked, searchKey).subscribe((response: allUsers) => {
            this.allUserDetails = response['data'].docs;
            this.allUserDetailsPaginations = response['data'];
            this.isLoadingData = false;

        })
    }

    /**
       * Track by function for ngFor loops
       *
       * @param index
       * @param item
       */
    public trackByFn(index: number, item: any): any {
        return item._id || index;
    }


    /**
   * Changes paginations
   * @param event
   */
    public changePaginations(event: any) {
        this.currentPageLimit = event.target.value;
        this.getAllUsers(1, event.target.value, this.isBlocked);

    }


    /**
     * Confirms alert
     * @param {string} id
     * @param {string} address
     * @param {boolean} isBlock
     */
    public confirmAlert(id: string, address: string, isBlock: boolean) {
        Swal.fire({
            title: `Are you sure want to ${isBlock ? 'block' : 'Delete'} this user?`,
            text: `Click OK to  ${isBlock ? 'block' : 'Delete'} this user.`,
            type: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'OK.',
            cancelButtonText: 'Cancel.',

        }).then((willDelete) => {
            Swal.fire({
                onBeforeOpen: () => {
                    Swal.showLoading()
                },
                allowOutsideClick: false,
                text: 'Processing, Please wait...',

            });
            if (willDelete.dismiss) {
                Swal.fire({ title: '', text: `Not ${isBlock ? 'block' : 'Delete'} this user.`, type: 'error', icon: 'error', confirmButtonText: 'OK.' });
            } else {
                if (isBlock) this.blockUser(id, address);
                else this.deleteUser(id);

            }
        });

    }

    /**
     * Deletes user
     * @param id
     */
    deleteUser(id: string) {
        try {
            this.userManagementService.deleteUser(id, this.account.state.data.account).subscribe({
                next: (_response) => {
                    Swal.fire({ title: '', text: 'Deleted Successfully.', icon: 'success', type: 'success', confirmButtonText: 'OK.' });
                    this.getAllUsers(1, this.currentPageLimit, this.isBlocked);
                },
                error:
                    ((error: any) => {
                        Swal.fire({ title: '', text: error.error.message ? error.error.message + '.' : 'Something went to wrong!. Please try again later.', type: 'error', icon: 'error', confirmButtonText: 'OK.' });

                    })
            })
        }
        catch (error) {
            Swal.fire({ title: '', text: error.error.message ? error.error.message + '.' : 'Something went to wrong!. Please try again later.', type: 'error', icon: 'error', confirmButtonText: 'OK.' });

        }
    }

    /**
     * Blocks user
     * @param {string} id
     * @param {string} address
     */
    public async blockUser(id: string, address: string) {
        try {
            const { blockUnblockAbi, requiredGas } = await this.userManagementService.blockUnblockUser(address, true, this.account.state.data.account);
            const message = {
                to: environment[this.account.state.data.chain.id].ACCESS_CONTROL_TOKEN,
                data: blockUnblockAbi,
                chainId: this.account.state.data.chain.id,
                gasPrice: await web3.utils.toHex(Number(await web3.eth.getGasPrice()) * 2),
                gasLimit: await web3.utils.toHex(Number(requiredGas) * 2)
            };
            this.manageTransactionsDirective.makeTransactions(message)
                .then(async (_receipt) => {
                    this.userManagementService.blockUser(id, true).subscribe((_response: any) => {
                        Swal.fire({ title: '', text: 'Blocked Successfully.', icon: 'success', type: 'success', confirmButtonText: 'OK.' });
                        this.getAllUsers(1, this.currentPageLimit, this.isBlocked);
                    },
                        (_error: any) => {
                            Swal.fire({ title: '', text: 'Something went to wrong!. Please try again later.', type: 'error', icon: 'error', confirmButtonText: 'OK.' });

                        }
                    )
                })
                .catch((error) => {
                    Swal.close()
                });
        }
        catch (error) {
            this.errorHandler.handleError(error);
            Swal.close()

        }
    }

    /**
       * Copys content
       * @param {string} content
       */
    public copy(content: string) {
        this.copyDirective.copy(content)
    }



    /**
   * Confirms alert
   * @param {string} id
   * @param {boolean} status
   */
    public kycUploadStatus(address: string, status: boolean) {
        let name = status ? 'enable' : 'disable';
        Swal.fire({
            title: `Are you sure you want to ${name} identity verification for this user?`,
            text: `This user will ${status ? '' : 'not'} be redirected to identity validation next time he tries to initiate a transaction.`,
            type: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'OK.',
            cancelButtonText: 'Cancel.',

        }).then((willDelete) => {
            Swal.fire({
                onBeforeOpen: () => {
                    Swal.showLoading()
                },
                allowOutsideClick: false,
                text: 'Processing, Please wait...',
            });
            if (willDelete.dismiss) {
                Swal.fire({ title: '', text: `Not ${name} this user.`, type: 'error', icon: 'success', confirmButtonText: 'OK.' });
                this.getAllUsers(1, this.currentPageLimit, this.isBlocked);
            } else {
                this.kycUploadStatusProcess(address, status);
            }
        });

    }

    /**
     * Kycs upload status process
     * @param {string} id
     * @param {string} status
     */
    kycUploadStatusProcess(id: string, status: boolean) {
        let name = status ? 'Enabled' : 'Disabled';
        const data = {
            status: status
        }
        this.userManagementService.updateKycStatus(id, data).subscribe((response) => {
            Swal.fire({ title: '', text: `${name} successfully.`, icon: 'success', type: 'success', confirmButtonText: 'OK.' });
            this.getAllUsers(1, this.currentPageLimit, this.isBlocked);
        },
            (error) => {
                Swal.fire({ title: '', text: `Not ${name} this user.`, icon: 'error', type: 'error', confirmButtonText: 'OK.' });
                this.getAllUsers(1, this.currentPageLimit, this.isBlocked);
            }
        )
    }


}
