// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    PINATA_KEY: '3816585697981d38d50d',
    CHAIN_ID: '0x13882',
    PINATA_SECRET: '4722d4967331aafb013d9dab2bc6e3534e654be9a1cbc8ce4aa4adaec3c13f27',
    BASE_URL: 'https://chocolate-magnificent-blackbird-410.mypinata.cloud/ipfs/',
    SOCKET_ENDPOINT: 'https://devapi.realworld.fi:3050',
    FILE_PATH: '',
    API_BASE_URL: 'https://devapi.realworld.fi/api/v1/',
    API_BASE_URL_V2: 'https://devapi.realworld.fi/api/v2/',
    SUPPORTED_CHAINS: ['80002'],
    MARKETPLACE_URL: 'https://devmarket.realworld.fi/',
    WEB_SITE_URL: 'https://devapp.realworld.fi',
    WALLET_CONNECT_PROJECT_ID: "b6c201630414d0fd586cfc6add82cde7",
    // BUG_ATLAS_API_KEY: 'CIEW9TJB9KBZE83I',
    BUG_ATLAS_API_KEY: 'WKOUJ7H2U999H8AA',
    // BUG_ATLAS_SECRET_KEY: 'HEIZT5FYN5SER86Z',
    BUG_ATLAS_SECRET_KEY: '4YOGEIZK3W5ATJ5P',
    COINGECKO_API: "https://api.coingecko.com/api/v3/simple",
    COINGECKO_API_KEY: "CG-eAbDVDxfBA1YYo4jncze1jrQ",
    SUB_ADMIN_EMAIL: 'partnerorders@realworld.fi',
    '80002': {
        EXPLORER: 'https://www.oklink.com/amoy',
        PROVIDER: ' https://rpc-amoy.polygon.technology',
        CHAIN_NETWORK: '0x13882',
        CHAINID: 80002,
        CURRENCY_NAME: 'MATIC',
        CURRENCY_SYMBOL: 'MATIC',
        CHAIN_NAME: 'Polygon Testnet',
        FACTORY_ADDRESS: '0xACe9bfF98cC113383a2535200A7CAD162B60BD0C',
        ACCESS_CONTROL_TOKEN: '0xDDE9256778596E949DEa33912c82312B1cd4066C',
    }

};
