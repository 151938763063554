import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LogDebugsService } from 'src/app/services/log-debugs.service';
import { LogsService } from 'src/app/services/logs.service';
import { SocketIoService } from 'src/app/services/socket-io.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { initialUserDetails } from 'src/app/shared/const/const';
import { CopyDirective } from 'src/app/shared/directives/copy.directive';
import { IPagination, IuserDetails, transhLogs } from 'src/app/shared/interface/interface';
import { environment } from 'src/environments/environment';

const apiUrl = environment.API_BASE_URL;

@Component({
    selector: 'app-nft-transacation',
    templateUrl: './nft-transacation.component.html',
    styleUrls: ['./nft-transacation.component.scss']
})
export class NftTransacationComponent implements OnInit {
    public userId: string = '';
    public walletAddress: string = '';
    public userData: IuserDetails;
    public allLogsDetails: transhLogs[] = [];
    public transhUrl: string = '';
    public isLoadingData: boolean = false;
    public allLogsDetailsPaginations: IPagination;
    public currentPageLimit: number = 10;
    public isRegulated: boolean;
    loader: boolean = false;
    userRouteData: IuserDetails; // This is used for menu navigation purpose

    constructor(
        private route: ActivatedRoute,
        private userManagementService: UserManagementService,
        private copyDirective: CopyDirective,
        private logsService: LogsService,
        private socketIoService: SocketIoService,
        private storageService: StorageService,
        private logDebgsService: LogDebugsService,
        private initialUserDetails: initialUserDetails
    ) { }


    /**
     * on init component
     */
    public ngOnInit(): void {
        this.userData = this.initialUserDetails.userDetails;
        this.userId = this.route.snapshot.paramMap.get('id');
        this.walletAddress = this.route.snapshot.paramMap.get('address') as any;
        this.getUserDetails();
        this.getAllLogs(1, this.currentPageLimit);
        this.isRegulated = JSON.parse(this.storageService.getItemSession('isRegulated'));
        this.socketIoService.getUserLogs();
        this.socketIoService.userLogs$.subscribe((response) => {
            if (response.regulated == this.isRegulated && this.userId == response.message.sender._id) {
                this.allLogsDetails.unshift(response.message);
            }
        });
        const account = this.storageService.getItem('wagmi.store') === null ?
            { address: '', network: '', chainId: '', provider: '' } :
            JSON.parse(this.storageService.getItem('wagmi.store') as any);

        this.transhUrl = environment[account.state.data.chain.id].EXPLORER
    }

    /**
     * Gets all logs
     * @param {number} page
     * @param {number} limit
     */
    public getAllLogs(page: number, limit: number) {
        this.isLoadingData = true;
        this.logsService.getUserLogs(page, limit, this.walletAddress).subscribe((response) => {
            this.allLogsDetails = response['data']['docs'];
            this.allLogsDetailsPaginations = response['data'];
            this.isLoadingData = false;
            this.logDebgsService.debugs(`${apiUrl}logs/list-user-logs?wallet_address=${this.walletAddress}&page${page}&limit=${limit}`, response, 'get', 'success');
        },
            (error) => {
                this.isLoadingData = false;
                this.logDebgsService.debugs(`${apiUrl}logs/list-user-logs?wallet_address=${this.walletAddress}&page${page}&limit=${limit}`, error, 'get', 'error');
            }
        )
    }

    /**
      * Track by function for ngFor loops
      * @param index
      * @param item
      */
    public trackByFn(index: number, item: any): any {
        return item._id || index;
    }

    /**
     * Gets user details
     */
    public getUserDetails() {
        this.loader = true;
        this.userManagementService.getUserByAddress(this.walletAddress).subscribe((response: any) => {
            this.userData = response.data;
            this.userRouteData = response.data;
            this.loader = false;
        }, (_error) => {
            this.loader = false;
        })
    }

    /**
      * Copys content
      * @param content
      */
    public copy(content: string) {
        this.copyDirective.copy(content)
    }

}
