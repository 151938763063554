import { Component, Input } from '@angular/core';
import { IuserDetails } from 'src/app/shared/interface/interface';

@Component({
  selector: 'app-profile-nav',
  templateUrl: './profile-nav.component.html',
  styleUrls: ['./profile-nav.component.scss']
})
export class ProfileNavComponent {
  @Input('userData') userData:IuserDetails;
  constructor() { }

  ngOnInit(): void {
  }

}
