import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccountService } from '../services/account.service';
import { environment } from 'src/environments/environment';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

constructor(
  public accountService:AccountService,
  public storageService:StorageService
){
  
}
public regulated:boolean;


  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.regulated = JSON.parse(this.storageService.getItemSession('isRegulated'));
    let account = this.storageService.getItem('wagmi.store') === null ?
    {"state":{"data": {"account": "", "chain": { "id":environment.SUPPORTED_CHAINS[0]}}}} :
    JSON.parse(this.storageService.getItem('wagmi.store') as any);
    let token = this.storageService.getItem('token');
   
    this.accountService.accountObserve.subscribe((response) => {
      if (response) {
        account = this.storageService.getItem('wagmi.store') === null ?
        {"state":{"data": {"account": "", "chain": { "id":environment.SUPPORTED_CHAINS[0]}}}} :
          JSON.parse(this.storageService.getItem('wagmi.store') as any);
        token = this.storageService.getItem('token');
      }
    });
    const bearerToken = `bearer ${token}`;
    if(this?.regulated == null){
      this.storageService.setItemSession('isRegulated',true);
      this.regulated = JSON.parse(this.storageService.getItemSession('isRegulated'));
    }
    
    // Clone the request and add a custom header
    const modifiedRequest = request.clone({
      setHeaders: {
        'Regulated' : this?.regulated.toString(),
        'Chain-id' : account.state.data.chain ? account.state.data.chain.id.toString() : environment.SUPPORTED_CHAINS[0],
        'Authorization': bearerToken
      },
    });
    
    // Pass the modified request to the next interceptor or the actual HTTP request
    return next.handle(modifiedRequest);
  }

}


