import { Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Editor, Toolbar } from 'ngx-editor';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { CommonContractService } from 'src/app/services/common-contract.service';
import { NftManagementService } from 'src/app/services/nft-management.service';
import { RoyaltyManagementService } from 'src/app/services/royalty-management.service';
import { StorageService } from 'src/app/services/storage.service';
import { Const } from 'src/app/shared/const/const';
import { ErrorHandlerDirective } from 'src/app/shared/directives/error-handler.directive';
import { ManageTransactionsDirective } from 'src/app/shared/directives/manage-transactions.directive';
import { walletAccount } from 'src/app/shared/interface/interface';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';

const ethereum = window['ethereum'] as any
let web3 = new Web3(window['ethereum'] as any);
const apiUrl = environment.API_BASE_URL;

@Component({
    selector: 'app-createnft',
    templateUrl: './createnft.component.html',
    styleUrls: ['./createnft.component.scss']
})
export class CreatenftComponent {

    public collectionLoader: boolean = false;
    public collectionFormSubmitted: boolean = false;
    public account: walletAccount;
    public displayNFT: boolean = false;
    public attributes: any = [];
    public attributeValue: any = [];
    public nftForm: FormGroup;
    public createNftLoader: boolean = false;
    public nftFormSubmitted: boolean = false;
    public nft: any;
    public attribute: any = [];
    public collectionName: string = '';
    public nftName: string = '';
    public baseUrl = environment.BASE_URL;
    public transactionHash: string = '';
    public ipfsNftHash: string = '';
    public IpfsHash: string = '';
    public transhUrl: any
    public fileErrorMsg: string = '';
    public multipleFileErrorMsg: string = '';
    public collectionAddress: string = '';
    @ViewChild('nftSuccessModal') nftSuccessModal;
    public secondaryImageLength: string;
    public IpfsHashSecSecImg: any = [];
    public primaryExtension: string = '';
    public isPreview: boolean = false;
    public previewImage: any;
    public previewImageName: string = '';
    public fileErrorMsgPreview: string = '';
    public IpfsPreviewHash: string = '';
    public tokenId: string = '';
    public nftDetails: any = [];
    public animationUrlLink: string = '';
    public isAttributeSet: boolean = false;
    public formNotChanged: boolean = false;
    public clientAddress: any;
    public attributesArray: any = [];
    public attributeValues: any = [];
    public selectedAttributeValues: any;
    public tokenURI: string = '';
    public collectionDetails: any;
    public IpfsHashSecSecImgVal: any[];
    @ViewChild('primaryMedia') primaryMedia!: ElementRef;
    @ViewChild('previewImageTag') previewImageTag!: ElementRef;
    @ViewChild('secondaryImage') secondaryImage!: ElementRef;
    public imageUrl: string = '';
    public primaryImageData: { url: string, fileType: string };
    public secondaryImagesUrl = [];
    public nftAllDetails: Object;
    updateFormError: boolean = false;
    previewImageUrl: string = '';
    currencyData: any;
    currencyError: string = '';
    appraisalError: string = '';
    loader: boolean = false;
    attributeSize: string = '';
    attributeMarkupFee: string = '';
    @ViewChild('goldApprisalValue') goldApprisalValue: ElementRef;
    @ViewChild('goldPrice') goldPrice: ElementRef;
    editor: Editor;
    html = '';
    toolbar: Toolbar = [
        ['bold', 'italic'],
        ['underline'],
        ['ordered_list', 'bullet_list'],
        [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
        ['align_left', 'align_center', 'align_right', 'align_justify'],
    ];
    @ViewChild('imageModal') imageModal: { show: () => void; hide: () => void; };
    selectedImage: string = '';
    isLazyMintCollection = false; // Flag variable to indicate whether the collection of the NFT being created or edited is lazy minted.
    isLazyMintNFT = false; // Flag variable to indicate whether the NFT being created or edited is lazy minted.
    isViewing3DImage = false;

    // Note: The variables below are used to decide whether to mint the lazy minted NFT or simply update its details in the database.
    // skipMinting - A flag variable.
    // skipMintingCategories - Categories for which the minting process should be skipped.
    skipMinting:boolean = false;
    skipMintingCategories:string[] = ['Gold']



    /**
     * Creates an instance of search collections component.
     */
    constructor(
        private formBuilder: FormBuilder,
        private nftManagementService: NftManagementService,
        private accountService: AccountService,
        private storageService: StorageService,
        private toastr: ToastrService,
        private constVal: Const,
        private zone: NgZone,
        private router: Router,
        private royaltyService: RoyaltyManagementService,
        private route: ActivatedRoute,
        private commonservice: CommonContractService,
        private manageTransactionsDirective: ManageTransactionsDirective,
        private errorHandler: ErrorHandlerDirective
    ) { }

    /**
     * on init
     */
    public ngOnInit(): void {
        this.editor = new Editor();
        this.commonservice.closeModalsObservable.subscribe((response: boolean) => {
            if (response) {
                this.nftSuccessModal.hide();
            }
        });

        this.account = this.storageService.getItem('wagmi.store') === null ?
            { address: '', network: '', chainId: '', provider: '' } :
            JSON.parse(this.storageService.getItem('wagmi.store') as any);;


        this.getCurrencyData();
        this.nftForm = this.formBuilder.group({
            collection: [''],
            platformFee: [''],
            royaltyFee: [''],
            secondaryImage: [''],
            name: ['', [Validators.required]],
            image: ['', [Validators.required]],
            description: ['', [Validators.required]],
            attribute: [[]],
            previewImage: [],
            currency: [],
            location: ['']
        });

        this.collectionAddress = this.route.snapshot.paramMap.get('address') as any;
        this.tokenId = this.route.snapshot.paramMap.get('tokenId') ? this.route.snapshot.paramMap.get('tokenId') : '';
        if (this.collectionAddress != '' && this.collectionAddress != null && this.tokenId == '') {

            setTimeout(() => {
                this.displayNFT = true;
                this.loader = true;
                this.collectionSearch();
            }, 1000);
        }
        if (this.tokenId != '') {
            this.displayNFT = true;
            this.loader = true;
            this.getNftDetails();
        }

        this.accountService.accountChange(false);
        this.accountService.accountObserve.subscribe((response) => {
            if (response) {
                this.account = this.storageService.getItem('wagmi.store') === null ?
                    { address: '', network: '', chainId: '', provider: '' } :
                    JSON.parse(this.storageService.getItem('wagmi.store') as any);
                this.transhUrl = environment[this.account.state.data.chain.id].EXPLORER
                if (this.tokenId != '') {
                    // this.displayNFT = false;
                    // this.router.navigate(['/manage-nft']);
                }
                else {
                    // this.displayNFT = false;
                    // this.router.navigate(['/manage-nft']);
                }
            }
        });

    }

    /**
     * Prepares the category attribute based on the presence of a token ID.
     *
     * @param {Object} categoryAttribute
     * @returns {Object} An object containing the category list, selected category, and read-only status.
     */
    prepareCategoryAttribute(categoryAttribute:Object):{categoryList:any[],selectedCategory:string,readOnly:boolean}{
        return {
            categoryList:!!this.tokenId ? [] : categoryAttribute?.['value'],
            selectedCategory:!!this.tokenId || this.isLazyMintCollection ? this.collectionDetails.category : '',
            readOnly: !!this.tokenId
        }
    }

    public getCollectionDetailsFromDB() {
        this.nftManagementService.getCollectionByAddress(this.collectionAddress).subscribe({
            next: (response: any) => {
                this.collectionDetails = response.data;
                this.isLazyMintCollection = this.collectionDetails?.isLazyMint;
                response.data['attributes'].map(async (attribute, i) => {
                    let value = '';
                    let readonly = false;
                    if (this.collectionDetails?.category?.toLowerCase() === 'gold') {
                        if (attribute?.key?.toLowerCase() == 'location' && this.collectionDetails?.isLazyMint) {
                            attribute.value = [];
                            value = this.collectionDetails.location;
                        }
                        // this.setAttributeValue(this.collectionDetails.location, { key: 'Location' })
                    }
                    if (attribute?.key?.toLowerCase() == 'category') {
                        const {categoryList,selectedCategory,readOnly} = this.prepareCategoryAttribute(attribute)
                        attribute.value = categoryList;
                        value = selectedCategory;
                        readonly = readOnly;
                        this.nftForm.addControl('attribute_options_' + i, this.formBuilder.control(value, attribute.mandatory ? [Validators.required] : []))
                    } else if (this.collectionDetails?.isLazyMint && this.collectionDetails?.category?.toLowerCase() === 'gold' && attribute?.key?.toLowerCase() == 'location') {
                        readonly = true;
                        this.nftForm.addControl('attribute_options_' + i, this.formBuilder.control(value, attribute.mandatory ? [Validators.required] : []))
                    }
                    else {
                        readonly = false;
                        const isMandatory:boolean = attribute.mandatory
                        const isMarkupFee =  attribute?.key?.toLowerCase() === 'markup fee';

                        const validators = [];
                        isMandatory && validators.push(Validators.required);
                        isMarkupFee && validators.push(Validators.pattern(/^\d+(\.\d+)?$/));
                        
                        this.nftForm.addControl('attribute_options_' + i, this.formBuilder.control(value, attribute.mandatory ? validators : []))
                    }
                    this.attributes.push({ key: attribute.key, value: '', defaultValue: attribute.value, options: `attribute_options_${i}`, readonly })
                })

                this.nftForm.patchValue({
                    collection: this.collectionDetails?.name,
                });
                if (this.collectionDetails.collection_owner_address != this.account.state.data.account) {
                    this.displayNFT = false;
                    this.collectionLoader = false;
                    this.toastr.error("You are not a owner of the collection.");
                    return
                }
                this.collectionLoader = false;
                this.loader = false;
            },
            error: (error: any) => {
                if (this.tokenId == '') this.router.navigate(['/manage-nft']);
                else this.router.navigate(['/manage-nft/edit-nft']);
                this.displayNFT = false;
                this.collectionLoader = false;
                this.loader = false;
                this.toastr.error(error.error.message);
            }
        })
    }


    /**
     * Collections search
     */
    public collectionSearch() {
        this.collectionFormSubmitted = true;
        try {
            this.collectionLoader = true;
            let web3 = new Web3(window['ethereum'] as any || environment[this.account.state.data.chain.id].PROVIDER);
            const inputAddress = this.collectionAddress;
            let collectionAddress = web3.utils.toChecksumAddress(inputAddress);
            this.nftManagementService.getCollectionOwner(collectionAddress).then(async (response: any) => {

                const collectionOwner = await web3.utils.toChecksumAddress(response);
                this.nftManagementService.isCollectionDestroyed(collectionAddress).then(async (response) => {
                    if (response) {
                        this.toastr.error("Collection destroyed.");
                        this.collectionLoader = false;
                        this.loader = false;
                        return;
                    }
                    else {
                        await this.getCollectionDetailsFromDB();
                        this.checkCollectionAddress(inputAddress).then(async (isAddressAvailable) => {
                            if (isAddressAvailable) {
                                if (collectionOwner === await web3.utils.toChecksumAddress(this.account.state.data.account)) {
                                    this.collectionLoader = false;
                                    this.collectionAddress = inputAddress;

                                    setTimeout(() => {
                                        this.displayNFT = true;
                                        this.createNftInit()
                                    }, 1000);
                                }
                                else {
                                    this.loader = false;
                                    this.displayNFT = false;
                                    this.collectionLoader = false;
                                    this.toastr.error("You are not a owner of the collection.");
                                }
                            }
                            else {
                                this.loader = false;
                            }
                        })
                    }
                })

            }).
                catch((error: any) => {
                    this.collectionLoader = false;
                    this.loader = false;
                    if ((error.toString()).includes('is not a valid Ethereum address') || (error.toString()).includes('invalid ethereum address')) {
                        this.toastr.error("Invalid collection address.");
                    }
                    else if ((error.toString()).includes('if you are not using the correct ABI for the contract you are retrieving data from')) {
                        this.toastr.error("Collection address not found.");
                    }
                    else if ((error.toString()).includes('returned no data')) {
                        this.toastr.error("Collection address not found.");
                    }
                })
        }
        catch (error) {
            this.loader = false;
            this.collectionLoader = false;
            if ((error.toString()).includes('is not a valid Ethereum address') || (error.toString()).includes('invalid ethereum address')) {
                this.toastr.error("Invalid collection address.");
            }
            else if ((error.toString()).includes('if you are not using the correct ABI for the contract you are retrieving data from')) {
                this.toastr.error("Collection address not found.");
            }
            else if ((error.toString()).includes('returned no data')) {
                this.toastr.error("Collection address not found.");
            }

        }
    }

    /**
     * Creates nft init
     */
    public createNftInit() {
        if (this.displayNFT) {
            this.transhUrl = environment[this.account.state.data.chain.id].EXPLORER
            this.nftManagementService.collectionRoyaltyFee(this.collectionAddress).then((royaltyFeeResponse: any) => {
                this.nftForm.patchValue({
                    royaltyFee: royaltyFeeResponse.toString() / 100,
                });
            });
            this.nftManagementService.collectionPlatformFee(this.collectionAddress).then((platformFeeResponse: any) => {
                this.nftForm.patchValue({
                    platformFee: platformFeeResponse.toString() / 100,
                });
            });
        }
        else {
            this.loader = false;
        }
    }


    /**
     * Checks collection address
     * @param inputAddress
     */
    public async checkCollectionAddress(inputAddress: string) {
        let status;
        await this.nftManagementService.getUserCreatedCollections(this.account.state.data.account).then(async (response) => {
            let web3 = new Web3(window['ethereum'] as any || environment[ethereum as any].chainId.PROVIDER);
            const collectionAddressList = await response;
            let collectionAddress = web3.utils.toChecksumAddress(inputAddress);
            await this.nftManagementService.isCollectionDestroyed(collectionAddress).then((response => {
                if (response) {
                    this.toastr.error("Collection destroyed.");
                    this.displayNFT = false;
                    status = false;
                }
                else {
                    if (!collectionAddressList.includes(collectionAddress)) {
                        this.toastr.error("Collection address not found.");
                        this.displayNFT = false;
                        status = false;
                        this.collectionLoader = false;
                    }
                    else {
                        status = true;
                        this.displayNFT = true;
                    }
                }
            }))
            return status;
        }).
            catch((error: any) => {
                status = false;
                this.displayNFT = false;
                this.collectionLoader = false;
                if ((error.toString()).includes('is not a valid Ethereum address')) {
                    this.toastr.error("Collection address not found.");
                }
            })
        return status;
    }

    // Create NFT Process Code here

    /**
    * Gets client address
    */
    public async getClientAddress() {
        const spliterContractAddress = await this.royaltyService.getSplitterContractAddress(this.collectionAddress) as any;
        this.clientAddress = await this.royaltyService.payee(spliterContractAddress, 1);
    }

    /**
     * Gets nft details
     */
    public async getNftDetails() {
        this.nftManagementService.getSingleNft(this.collectionAddress, this.tokenId).subscribe(async (response: any) => {
            this.nftAllDetails = response.data;
            this.collectionDetails = response.data['collections'];
            this.isLazyMintCollection = this.collectionDetails?.isLazyMint;
            this.isLazyMintNFT = this.nftAllDetails['lazy_mint']
            let collectionAttributes = response.data['collections']['attributes'];
            this.skipMinting = this.skipMintingCategories.includes(this.collectionDetails.category) && this.isLazyMintNFT
            const isLazyMintedGoldCollection:boolean = this.isLazyMintCollection && this.collectionDetails.category.toLowerCase() === 'gold'

            const uniqueKeys = new Set();
            let nftAttributes = response.data['attributes'].filter(attribute => {
                if (uniqueKeys.has(attribute.key)) {
                    return false;
                } else {
                    uniqueKeys.add(attribute.key);
                    return true;
                }
            });


            const allAttributes = [...nftAttributes, ...collectionAttributes];
            const uniqueAttributes = [];
            const seenKeys = new Set();
            let index = -1;
            allAttributes.forEach((attribute) => {
                if (!seenKeys.has(attribute.key)) {
                    index++;
                    if (attribute?.key?.toLowerCase() === 'size') {
                        this.setAttributeValue(attribute.value, { key: attribute.key });
                    }
                    if (attribute?.key?.toLowerCase() === 'markup fee') {
                        this.setAttributeValue(attribute.value, { key: attribute.key });
                    }

                    const collectionAttribute = collectionAttributes.find(attr => attr.key === attribute.key);

                    let defaultValue = [];
                    if (collectionAttribute && attribute?.key?.toLowerCase() != 'category') {
                        defaultValue = Array.isArray(collectionAttribute.value) && collectionAttribute.value.length === 0 ? '' : collectionAttribute.value;
                    }

                    if(collectionAttribute && attribute.key?.toLowerCase() === 'location' && isLazyMintedGoldCollection){
                        defaultValue = []
                    }
                    const controlName = 'attribute_options_' + index;

                    // Ensure control name is unique
                    if (!this.nftForm.contains(controlName)) {
                        // Adding form control for each attribute
                        const attributeValue = attribute.key.toLowerCase() === 'location' && isLazyMintedGoldCollection ? this.collectionDetails?.['location'] : attribute.value
                        this.nftForm.addControl(controlName, this.formBuilder.control(attribute.value.length > 0 ? attributeValue : '', attribute.mandatory ? [Validators.required] : []));

                    }
                    uniqueAttributes.push({
                        key: attribute.key,
                        value: attribute.value || '',
                        defaultValue,
                        options: controlName,
                        readonly: !collectionAttribute || attribute?.key?.toLowerCase() === 'category' || (attribute.key?.toLowerCase() === 'location'  && isLazyMintedGoldCollection)
                    });

                    seenKeys.add(attribute.key);
                }
            });

            // Update this.attributes with unique attributes
            this.attributes = uniqueAttributes;

            this.loader = false;
            this.animationUrlLink = response.data['animation_url'];
            this.imageUrl = response.data['primary_media'];
            this.secondaryImagesUrl = response.data['secondary_media'];
            this.isPreview = !!response.data['preview_image'];

            // Function to update a single image URL
            let updateSingleImageUrl = async (imageUrl) => {
                let splitUrl = imageUrl.split('.');
                let fileType = splitUrl[splitUrl.length - 1];

                if (fileType === 'html') {
                    return { url: imageUrl, fileType: fileType };
                } else {
                    let data = await this.nftManagementService.getImage(imageUrl);
                    if (data) {
                        fileType = data?.contentType?.split('/')[1];
                    }
                    return { url: imageUrl, fileType: fileType };
                }
            };

            // Function to update multiple image URLs
            let updateImageUrls = async (imageUrls) => {
                let updatedUrls = [];
                for (let imageUrl of imageUrls) {
                    updatedUrls.push(await updateSingleImageUrl(imageUrl));
                }
                return updatedUrls;
            };

            this.primaryImageData = await updateSingleImageUrl(this.imageUrl);
            this.secondaryImagesUrl = await updateImageUrls(this.secondaryImagesUrl);
            this.previewImageUrl = response.data['preview_image'];
            this.nftDetails = response.data;

            this.nftForm.patchValue({
                collection: response.data['collections']['name'],
                location: response.data['collections']['location'],
                name: response.data['name'],
                description: response.data['description'],
                currency: this.nftAllDetails['currency']['_id'],
                platformFee: response.data['minting_fee'],
                royaltyFee: response.data['royalty_fee'],
            });
            this.nftForm.patchValue({ 'attribute': this.attributes });

        });
    }

    /**
     * Fetchs default attr
     */
    fetchDefaultAttr() {
        //   this.attributes.forEach((element,index) => {
        //    if(element == 'Location' && this.collectionDetails.location){
        //     this.nftForm.addControl('attribute_options_' + index, this.formBuilder.control(this.collectionDetails.location));
        //     const obj = {
        //       "key": "Location",
        //       "value": this.collectionDetails.location
        //   }
        //   setTimeout(() => {
        //     this.setAttributeValue('Gold', obj)
        //   }, 500);
        //   }
        //   if(element == 'Category' && this.collectionDetails.category){
        //     this.nftForm.addControl('attribute_options_' + index, this.formBuilder.control(this.collectionDetails.category));
        //     const obj = {
        //       "key": "Category",
        //       "value": "Gold"
        //   }
        //   setTimeout(() => {
        //     this.setAttributeValue('Gold', obj)
        //   }, 500);
        //   }
        //   if((element == 'Appraisal value' || element == 'Appraisal Value') && this.collectionDetails.category == 'Gold'){
        //     const obj = {
        //       "key": "Appraisal value",
        //       "value": "2328"
        //   }
        //     setTimeout(() => {
        //     this.setAttributeValue('2328', obj)
        //   }, 500);

        //   }
        // })
    }
    /**
     * Gets collection details
     */
    public getCollectionDetails() {
        if (this.tokenId == '') {
            let attributes = [];
            // this.nftManagementService.getCollectionAttributes(this.collectionAddress).then((attributesResponse) => {
            //   attributesResponse.forEach((element, index) => {
            //     if(element == 'Location' && this.collectionDetails.location){
            //       // this.nftForm.addControl('attribute_options_' + index, this.formBuilder.control(this.collectionDetails.location));
            //       const obj = {
            //         "key": "Location",
            //         "value": this.collectionDetails.location
            //     }
            //     setTimeout(() => {
            //       this.setAttributeValue('Gold', obj)
            //     }, 500);
            //     }
            //     if(element == 'Category' && this.collectionDetails.category){
            //       this.nftForm.addControl('attribute_options_' + index, this.formBuilder.control(this.collectionDetails.category));
            //       const obj = {
            //         "key": "Category",
            //         "value": "Gold"
            //     }
            //     setTimeout(() => {
            //       this.setAttributeValue('Gold', obj)
            //     }, 500);
            //     }
            //     if((element == 'Appraisal value' || element == 'Appraisal Value') && this.collectionDetails.category == 'Gold'){
            //       const obj = {
            //         "key": "Appraisal value",
            //         "value": "2328"
            //     }
            //       setTimeout(() => {
            //       this.setAttributeValue('2328', obj)
            //     }, 500);

            //     }
            //     if (this.attributesArray.includes(element) && element != 'Appraisal value' && element != 'Appraisal Value') this.nftForm.addControl('attribute_options_' + index, this.formBuilder.control(element.value, Validators.required));
            //     else this.nftForm.addControl('attribute_options_' + index, this.formBuilder.control(element.value));
            //     attributes.push({ key: element, value: '' });
            //     if (index === attributesResponse.length - 1) {
            //       this.attributes = attributes;
            //     }
            //   });
            // })
        }

    }

    /**
     * Validate First letter start with Space
     * @param {string} event
    */
    public space(event) {
        if (event.target.selectionStart === 0 && event.code === this.constVal.CONST_VAR.space) {
            event.preventDefault();
        }
    }

    /**
     * Check Network
     */
    public checkNetwork() {
        if (this.account.state.data.chain.id && this.account.state.data.chain.id != environment[this.account.state.data.chain.id].CHAIN_NETWORK && this.account.state.data.chain.id != environment[this.account.state.data.chain.id].CHAINID) {
            this.toastr.error('Wrong network.');
        } else {
            this.zone.run(() => {
                this.router.navigate(['/nft-management']);
            });
        }
    }


    /**
     * Creates collections submit
     */
    public async createNftSubmit() {
        this.nftFormSubmitted = true;
        if (this.nftForm.invalid) {
            switch ('') {
                case this.nftForm.value.name: {
                    (document.getElementById('nameScrollEdit') as HTMLElement).scrollIntoView();
                    break;
                }
                case this.nftForm.value.image: {
                    (document.getElementById('imageScroll') as HTMLElement).scrollIntoView();
                    break;
                }
                case this.nftForm.value.description: {
                    (document.getElementById('descriptionScroll') as HTMLElement).scrollIntoView();
                    break;
                }
            }
            return false;
        }

        const selectedAttributes = this.attributesArray;
        for (let index = 0; index < selectedAttributes.length; index++) {
            const element = selectedAttributes[index];
            if ((element == 'Appraisal value' || element == 'Appraisal Value') && !this.nftForm.controls.currency.value) {
                return this.currencyError = 'Please select currency.';
            }
            else {
                this.currencyError = '';
            }
            if ((element == 'Appraisal value' || element == 'Appraisal Value') && this.nftForm.value.attribute && this.nftForm.value.attribute[index].value == '') {
                return this.appraisalError = 'Please enter  appraisal value.';
            }
            else {
                this.appraisalError = '';
            }
            if (this.nftForm.value.attribute && this.nftForm.value.attribute[index].key == element && this.nftForm.value.attribute[index].value == '' && element != 'Appraisal value' && element != 'Appraisal Value') {
                return this.toastr.error(`Error: ${element} is required`);
            }
        }
        this.nftName = this.nftForm.value.name

        this.fileErrorMsgPreview = '';
        if ((this.primaryExtension.toLowerCase() == ".glb") && this.previewImageName == '') {
            (document.getElementById('imageScroll') as HTMLElement).scrollIntoView();
            this.fileErrorMsgPreview = 'Please select preview image.';
            return false
        }
        this.createNftLoader = true;

        const secondaryImage = this.nftForm.controls['secondaryImage'].value;

        this.IpfsHashSecSecImg = [];
        this.IpfsHashSecSecImgVal = [];

        for (let i = 0; i < secondaryImage.length; i++) {
            this.nftManagementService.uploadFile(secondaryImage[i])
                .subscribe((response) => {
                    const resHash = response['IpfsHash']
                    this.IpfsHashSecSecImg.push({ resHash });
                    this.IpfsHashSecSecImgVal.push(this.baseUrl + '' + resHash);

                });
        }
        const file = this.nftForm.controls['image'].value;
        this.nftManagementService.uploadFile(file)
            .subscribe((response) => {
                this.IpfsHash = response['IpfsHash'];
                if (this.previewImageName != '') {
                    this.nftManagementService.uploadFile(this.previewImage)
                        .subscribe((previewResponse) => {
                            this.IpfsPreviewHash = previewResponse['IpfsHash'];
                            this.collectionDetails.category === 'Gold' ? this.createLazyMintNft() : this.ipfsNftProcess(response['IpfsHash'], this.nftForm.value.platformFee);
                        },
                            ((error) => {
                                this.createNftLoader = false;
                            })
                        );
                }
                else {
                    this.collectionDetails.category === 'Gold' ? this.createLazyMintNft() : this.ipfsNftProcess(response['IpfsHash'], this.nftForm.value.platformFee);
                }
            });
        // return false;
    }

    /**
     * Ipfs nft process
     * @param {string} ipfsImageHash
     * @param {number} mintFee
     */
    public ipfsNftProcess(ipfsImageHash, mintFee) {
        const { name, description, attribute, currency } = this.nftForm.controls;
        this.nftName = name.value;
        let obj;
        if ((this.primaryExtension.toLowerCase() == ".glb")) {
            obj = {
                collection: this.collectionDetails?.name,
                name: name.value,
                description: description.value,
                image: this.baseUrl + this.IpfsPreviewHash,
                animation_url: this.baseUrl + ipfsImageHash,
                secondaryImages: this.IpfsHashSecSecImg,
                date: Date.now(),
                attributes: attribute.value,
            };
        }
        else if (
            (this.primaryExtension.toLowerCase() == ".mpg") ||
            (this.primaryExtension.toLowerCase() == ".mp4") ||
            (this.primaryExtension.toLowerCase() == ".mp3")) {
            obj = {
                collection: this.collectionDetails?.name,
                name: name.value,
                description: description.value,
                animation_url: this.baseUrl + ipfsImageHash,
                secondaryImages: this.IpfsHashSecSecImg,
                date: Date.now(),
                attributes: attribute.value,
            };
        }
        else {
            obj = {
                collection: this.collectionDetails?.name,
                name: name.value,
                description: description.value,
                image: this.baseUrl + ipfsImageHash,
                secondaryImages: this.IpfsHashSecSecImg,
                date: Date.now(),
                attributes: attribute.value,
            };
        }
        if (currency.value) {
            obj['currency'] = currency.value;
        }
        this.nftManagementService.uploadJson(obj)
            .then((response) => {
                if (mintFee > 0) {
                    this.platformFeeProcess(response['IpfsHash']);
                }
                else {
                    this.mintProcess(response['IpfsHash']);
                }


            },
                ((error) => {
                    this.createNftLoader = false;
                })
            );
    }


    /**
     * Platforms fee process
     * @param {string} ipfsNftHash
     */
    public async platformFeeProcess(ipfsNftHash) {
        try {
            let ownerAddress;
            let platformFee;
            await this.nftManagementService.collectionPlatformFee(this.collectionAddress).then((platformFeeResponse: any) => {
                platformFee = this.convert((platformFeeResponse.toString() / 100) * 10 ** 18);
            });
            await this.nftManagementService.owner().then((ownerAddressResponse) => {
                ownerAddress = ownerAddressResponse;
            });
            this.mintProcess(ipfsNftHash, platformFee.toString());
        }
        catch (error) {
            this.createNftLoader = false;

        }
    }

    /**
     * Ipfs nft edit process
     */
    public async editNftSubmit() {
        this.nftFormSubmitted = true;
        this.updateFormError = false;
        if (this.nftForm.value.name == '') {
            (document.getElementById('nameScrollEdit') as HTMLElement).scrollIntoView();
            this.updateFormError = true;
            return
        }
        if (this.nftForm.value.description == '') {
            (document.getElementById('descriptionScroll') as HTMLElement).scrollIntoView();
            this.updateFormError = true;
            return
        }


        const { name } = this.nftForm.controls;
        this.nftName = name.value;
        this.fileErrorMsgPreview = '';
        if ((this.primaryExtension.toLowerCase() == ".glb") && ((this.previewImageUrl == undefined || this.previewImageUrl === '') && this.previewImageName == '') && this.isPreview) {
            (document.getElementById('imageScroll') as HTMLElement).scrollIntoView();
            this.fileErrorMsgPreview = 'Please select preview image.';
            return
        }
        this.createNftLoader = true;
        this.formNotChanged = false;
        let obj: any;

        if (this.nftForm.controls['secondaryImage'].value != '') {
            const secondaryImage = this.nftForm.controls['secondaryImage'].value;
            this.IpfsHashSecSecImg = [];
            this.IpfsHashSecSecImgVal = [];

            for (let i = 0; i < secondaryImage.length; i++) {
                await this.uploadFileToIPFS(secondaryImage[i]).then(async (response: any) => {
                    const resHash = await response.data['IpfsHash']
                    this.IpfsHashSecSecImg.push({ resHash });
                    this.IpfsHashSecSecImgVal.push(this.baseUrl + '' + resHash);
                }).catch((error) => {

                });
            }
            this.secondaryImagesUrl = this.IpfsHashSecSecImg;
        }

        if (this.nftForm.controls['image'].value != '') {
            const file = this.nftForm.controls['image'].value;
            await this.uploadFileToIPFS(file).then(async (response: any) => {
                this.IpfsHash = await response.data['IpfsHash'];
                if (this.previewImageName != '') {
                    await this.uploadFileToIPFS(this.previewImage).then(async (previewResponse: any) => {
                        this.IpfsPreviewHash = await previewResponse.data['IpfsHash'];
                        this.animationUrlLink = this.baseUrl + this.IpfsHash;
                        this.imageUrl = this.baseUrl + this.IpfsPreviewHash;
                    })
                } else {
                    this.imageUrl = this.baseUrl + this.IpfsHash;
                    this.animationUrlLink = '';
                }
            }).catch((error) => {
                this.createNftLoader = false;
            });
        }


        obj = await this.organizeEditNft();
        await this.nftManagementService.uploadJson(obj.data)
            .then((response) => {
                if(this.skipMinting){
                    const data = this.prepareLazyMintNFTPayload();
                    this.createNftApiProcess(data);

                }else{
                    this.mintProcess(response['IpfsHash']);
                }
            });
    }
    
    /**
     * Prepares the payload for updating a lazy minting an NFT based on the form data.
     * 
     * @returns {Object} The payload containing NFT details such as name, description, attributes, and media URLs.
     */
    prepareLazyMintNFTPayload(): Object {
        const { name, description, attribute } = this.nftForm.controls;
        let attributeValues;
        attributeValues = [...attribute.value, { key: 'Category', value: this.collectionDetails.category }];
        attributeValues = attributeValues.map((attr) => ({
          ...attr,
          value: Array.isArray(attr.value) ? '' : typeof attr.value === 'object' ? '' : attr.value?.toString() || '',
        }));
    
        let data = {
          name: name.value,
          description: description.value,
          attributes: attributeValues,
        };
    
        if (this.nftForm.controls['image'].value != '') {
          data['primary_media'] = this.baseUrl + '' + this.IpfsHash;
        }
        if (this.nftForm.controls['secondaryImage'].value.length > 0) {
          data['secondary_media'] = this.IpfsHashSecSecImgVal;
        }
        if (this.nftForm.controls['previewImage'].value) {
          data['preview_image'] = this.baseUrl + '' + this.IpfsPreviewHash;
        }
        return data;
      }

    /**
     * Uploads file to ipfs
     * @param {any} file
     * @returns
     */
    private async uploadFileToIPFS(file: any) {
        return new Promise((resolve, reject) => {
            this.nftManagementService.uploadFile(file).subscribe({
                next: (response: any) => {
                    resolve({ status: true, data: response });
                },
                error: (error) => {
                    reject({ status: false, data: error?.error });
                },
            });
        });
    }

    // private async unPinFileFromIPFS(cid: string) {
    //   return new Promise((resolve, reject) => {
    //     this.nftManagementService.unPinFile(cid).subscribe({
    //       next: (response: any) => {
    //         console.log('success', response);
    //         resolve({ status: true, data: response });
    //       },
    //       error: (error) => {
    //         reject({ status: false, data: error?.error });
    //       },
    //     });
    //   });
    // }

    /**
     * Organizes edit nft
     * @returns
     */
    private async organizeEditNft() {
        return new Promise((resolve) => {
            const { name, description, attribute, currency } = this.nftForm.controls;
            let obj: any;
            if (this.animationUrlLink != '' && this.imageUrl != '') {
                // this.IpfsHash = this.animationUrlLink;
                obj = {
                    collection: this.collectionDetails?.name,
                    name: name.value,
                    description: description.value,
                    image: this.imageUrl,
                    animation_url: this.animationUrlLink,
                    secondaryImages: this.secondaryImagesUrl,
                    date: Date.now(),
                    attributes: attribute.value,
                    currency: currency.value
                };
            }
            else if (this.animationUrlLink != '' && this.imageUrl == '') {
                // this.IpfsHash = this.animationUrlLink;
                obj = {
                    collection: this.collectionDetails?.name,
                    name: name.value,
                    description: description.value,
                    animation_url: this.animationUrlLink,
                    secondaryImages: this.secondaryImagesUrl,
                    date: Date.now(),
                    attributes: attribute.value,
                };
            }
            else {
                // this.IpfsHash = this.imageUrl;
                obj = {
                    collection: this.collectionDetails?.name,
                    name: name.value,
                    description: description.value,
                    image: this.imageUrl,
                    secondaryImages: this.secondaryImagesUrl,
                    date: Date.now(),
                    attributes: attribute.value,
                };
            }
            resolve({ status: true, data: obj });
        });
    }

    /**
     * Mints process
     * @param {string} ipfsNftHash
     * @param {string} platFormFee
     */
    public async mintProcess(ipfsNftHash: string, platFormFee?: string) {
        try {
            await this.getClientAddress();
            this.ipfsNftHash = ipfsNftHash;
            let abi;
            if (this.tokenId == '') {
                abi = await this.nftManagementService.createNft(this.collectionAddress, this.clientAddress, ipfsNftHash, this.account.state.data.account, platFormFee || 0);
            } else {
                abi = await this.nftManagementService.updateNft(this.collectionAddress, ipfsNftHash, this.tokenId, this.account.state.data.account);
            }
            const message = {
                to: this.collectionAddress,
                data: abi.createNftAbi,
                gasPrice: await web3.utils.toHex(Number(await web3.eth.getGasPrice()) * 2),
                gasLimit: await web3.utils.toHex(Number(abi.requiredGas * 2))
            };
            if (platFormFee) {
                message['value'] = platFormFee;
            }
            this.manageTransactionsDirective.makeTransactions(message)
                .then(async (receipt) => {
                    const { name, description, attribute, platformFee } = this.nftForm.controls;
                    const { owner_address, royalty_fee, _id } = this.collectionDetails;

                    await this.nftManagementService.getNextMintableToken(this.collectionAddress).then((response: any) => {
                        let tokenId;
                        if (Number(response) == 0) {
                            tokenId = 0;
                        }
                        else {
                            tokenId = Number(response) - 1;
                        }
                        let attributeValues = [...attribute.value];

                        attributeValues = attributeValues.map(attr => ({
                            ...attr,
                            value: Array.isArray(attr.value) ? '' :
                                typeof attr.value === 'object' ? '' :
                                    attr.value?.toString() || ''
                        }));


                        let data = {
                            name: name.value,
                            description: description.value,
                            attributes: attributeValues,
                        }
                        if (this.nftForm.controls['image'].value != '') {
                            data['primary_media'] = this.baseUrl + '' + this.IpfsHash;
                        }
                        if (this.nftForm.controls['secondaryImage'].value.length > 0) {
                            data['secondary_media'] = this.IpfsHashSecSecImgVal;
                        }
                        if (this.nftForm.controls['previewImage'].value) {
                            data['preview_image'] = this.baseUrl + '' + this.IpfsPreviewHash;
                        } else {
                            data['preview_image'] = '';
                        }
                        if (this.nftForm.controls['currency'].value && this.collectionDetails.category != 'Gold') {
                            data['currency'] = this.nftForm.controls['currency'].value;
                        }
                        if (this.tokenId == '') {
                            data['owner'] = owner_address;
                            data['collections'] = _id;
                            data['minting_fee'] = platformFee.value;
                            data['royalty_fee'] = royalty_fee;
                            data['token_id'] = tokenId;
                        }
                        this.createNftApiProcess(data, receipt['data']['transactionHash']);
                    })
                        .catch((error) => {
                            this.createNftLoader = false;
                        });

                })
                .catch((error) => {
                    this.createNftLoader = false;
                });
        }
        catch (error) {
            this.errorHandler.handleError(error);
            this.createNftLoader = false;

        }
    }

    createLazyMintNft() {
        const { name, description, attribute, platformFee } = this.nftForm.controls;
        const { owner_address, royalty_fee, _id } = this.collectionDetails;
        let attributeValues;
        attributeValues = [...attribute.value, { key: 'Category', value: this.collectionDetails.category }];
        let data = {
            name: name.value,
            description: description.value,
            attributes: attributeValues,
        }
        if (this.nftForm.controls['image'].value != '') {
            data['primary_media'] = this.baseUrl + '' + this.IpfsHash;
        }
        if (this.nftForm.controls['secondaryImage'].value.length > 0) {
            data['secondary_media'] = this.IpfsHashSecSecImgVal;
        }
        if (this.nftForm.controls['previewImage'].value) {
            data['preview_image'] = this.baseUrl + '' + this.IpfsPreviewHash;
        }
        if (this.nftForm.controls['currency'].value && this.collectionDetails.category != 'Gold') {
            data['currency'] = this.nftForm.controls['currency'].value;
        }
        if (this.tokenId == '') {
            data['owner'] = owner_address;
            data['collections'] = _id;
            data['minting_fee'] = platformFee.value;
            data['royalty_fee'] = royalty_fee;
        }
        // delete data?.['location'];
        this.createNftApiProcess(data);
    }

    /**
     * Creates nft api process
     * @param {object} data
     * @param {string} transactionHash
     */
    public createNftApiProcess(data: object, transactionHash: string = '') {
        let createNftSubscribe;
        let apiEndPoint;
        let apiMethod;
        if (this.tokenId == '') {
            createNftSubscribe = this.nftManagementService.createNftDb(data);
            apiEndPoint = `${apiUrl}admin/nft`;
            apiMethod = "post";
        }
        else {
            createNftSubscribe = this.nftManagementService.updateNftDb(data, this.tokenId, this.collectionAddress);
            apiEndPoint = `${apiUrl}admin/nft/update-by-token-id?token_id=${this.tokenId}&collection_address=${this.collectionAddress}`;
            apiMethod = "patch";
        }
        createNftSubscribe.subscribe((response: any) => {
            this.transactionHash = transactionHash;
            this.createNftLoader = false;
            this.nftSuccessModal.show();
            if (this.nftForm.controls['image'].value) this.primaryMedia.nativeElement.value = '';
            if (this.nftForm.controls['secondaryImage'].value) this.secondaryImage.nativeElement.value = '';
            if (this.previewImage) this.previewImageTag.nativeElement.value = '';
            this.tokenId != '' && this.getNftDetails();
            if (this.tokenURI != '') {
                this.nftManagementService.unPinFile(this.tokenURI).subscribe(async (data) => {
                })
            }

        },
            (error: any) => {
                this.createNftLoader = false;
                this.toastr.error('Something went wrong. Please try again');

            }
        )
    }

    /**
     * Handles the selection of secondary images, validating file formats and sizes.
     * 
     * @param {Event} event - The file input change event triggered when files are selected.
     * 
     */
    public selectSecImage(event) {
        this.secondaryImagesUrl = [];
        const files = event.target.files;
        const maxFileCount:number = 10;
        //NOTE: 1MB = 1000000 (6 Zeros)
        const maxPdfSize:number = 5000000; // 5 MB
        const maxOtherFileSize:number = 10000000; // 10 MB
        const supportedFormats:string[] = ['.png', '.jpeg', '.jpg', '.gif', '.pdf', '.webp'];

        /**
         * Sets an error message and clears the selected files.
         * 
         * @param {string} message - The error message to display.
         */
        const setFileError = (message: string) => {
            this.multipleFileErrorMsg = message;
            clearSelectedFiles();
        }

        /**
         * Clears the selected files from the form and resets the file input field.
         */
        const clearSelectedFiles = () => {
            this.nftForm.patchValue({ 'secondaryImage': '' });
            this.secondaryImage.nativeElement.value = '';
        }


        /**
         * Clears any existing error message.
         */
        const clearFileError = () => {
            this.multipleFileErrorMsg = '';
        }
    
        if (files.length > maxFileCount) {
            setFileError('Maximum allowed 10 files only.');
            return;
        }
    
        for (const file of files) {
            const fileExtension = file.name.substr(file.name.lastIndexOf('.')).toLowerCase();
            if (!supportedFormats.includes(fileExtension)) {
                setFileError(`${fileExtension} file format not supported.`);
                return;
            }
    
            const isPdf = fileExtension === '.pdf';
            const fileSizeLimit = isPdf ? maxPdfSize : maxOtherFileSize;
    
            if (file.size > fileSizeLimit) {
                this.toastr.error(`Secondary media (${isPdf ? 'PDF' : 'file'}) size should be ${fileSizeLimit / 1000000}MB or less.`);
                clearSelectedFiles();
                return;
            }
        }
    
        clearFileError();
        this.secondaryImageLength = `${files.length} Files Selected`;
        this.nftForm.patchValue({ 'secondaryImage': files });
    }

    /**
     * Selects image preview
     * @param {event} event
     */
    public selectImagePreview(event) {
        // if (event.target.files[0].size > 500000) {
        //   this.toastr.error("Preview image file size should be 500kb or lesser");
        //   return;
        // }
        if (this.tokenId != '') this.nftAllDetails['preview_image'] = '';
        let extension = event.target.files[0].name.substr(event.target.files[0].name.lastIndexOf('.'));
        if ((extension.toLowerCase() != ".png") &&
            (extension.toLowerCase() != ".jpeg") &&
            (extension.toLowerCase() != ".jpg") &&
            (extension.toLowerCase() != ".webp")) {
            this.fileErrorMsgPreview = extension.toLowerCase() + ' file format not supported.';
            this.previewImageName = '';
            this.previewImage = '';
            this.nftForm.patchValue({ 'previewImage': '' });
        }
        else {
            this.fileErrorMsgPreview = '';
            this.previewImageName = event.target.files[0].name;
            this.previewImage = event.target.files[0];
            this.nftForm.patchValue({ 'previewImage': event.target.files[0] });
        }

    }
    /**
     * Select Image
     * @param {event} event
     */
    public selectImage(event) {
        // not needed because glb files are bigger we can't determined

        // if (event.target.files[0].size > 5000000) {
        //   this.toastr.error("Primary media file size should be 5mb or lesser");
        //   return;
        // }
        this.primaryImageData = { url: '', fileType: '' };
        let extension = event.target.files[0]?.name.substr(event.target.files[0].name.lastIndexOf('.'));
        if ((extension.toLowerCase() == ".glb")) {
            this.isPreview = true;
        }
        else {
            this.isPreview = false;
            this.previewImage = '';
            this.previewImageName = '';
            this.nftForm.patchValue({ 'previewImage': '' });
        }
        if ((extension.toLowerCase() != ".png") &&
            (extension.toLowerCase() != ".jpeg") &&
            (extension.toLowerCase() != ".jpg") &&
            (extension.toLowerCase() != ".gif") &&
            (extension.toLowerCase() != ".glb") &&
            (extension.toLowerCase() != ".webp")) {
            this.fileErrorMsg = extension.toLowerCase() + ' file format not supported.';
            this.nftForm.patchValue({ 'image': '' });
            this.primaryExtension = '';
        }
        else {
            this.fileErrorMsg = '';
            this.primaryExtension = extension;
            this.nftForm.patchValue({ 'image': event.target.files[0] });
        }
    }
    /**
     * Set Attribute Value
     * @param {event} event
     * @param {array} attribute
     */
    async setAttributeValue(event, { key }) {

        let value = event;
        let keyIndex = this.nftForm.value.attribute.findIndex((item) => item.key?.toLowerCase() === key?.toLowerCase())
        if (keyIndex >= 0) this.nftForm.value.attribute[keyIndex].value = value;
        else this.nftForm.value.attribute.push({ key, value })
        this.nftForm.patchValue({ 'attribute': [...this.nftForm.value.attribute] });

        if (this.collectionDetails?.category && this.collectionDetails?.category?.toLowerCase() === 'gold') {
            let sizeAttribute = this.nftForm.value.attribute.find((attribute) => attribute?.key?.toLowerCase() === 'size')
            let markupFeeAttribute = this.nftForm.value.attribute.find((attribute) => attribute?.key?.toLowerCase() === 'markup fee')
            if (sizeAttribute && sizeAttribute?.value !== '' && markupFeeAttribute && markupFeeAttribute?.value !== '') {
                let params = {
                    "size": sizeAttribute?.value,
                    "markup_fee_percentage": markupFeeAttribute?.value
                }
                let response: any = await this.nftManagementService.getGoldValue(params);
                let appraisalValue = this.attributes.find((attribute) => attribute?.key?.toLowerCase() === 'appraisal value')
                let priceValue = this.attributes.find((attribute) => attribute?.key?.toLowerCase() === 'price')
                this.nftForm.patchValue({ [appraisalValue.options]: Math.ceil(response['data'].appraisal_value), [priceValue.options]: Math.ceil(response['data'].price) })

                let appraisalIndex = this.nftForm.value.attribute.findIndex((item) => item.key?.toLowerCase() === 'appraisal value')
                if (appraisalIndex >= 0) this.nftForm.value.attribute[appraisalIndex].value = Math.ceil(response['data'].appraisal_value);
                else this.nftForm.value.attribute.push({ key: "Appraisal value", value: Math.ceil(response['data'].appraisal_value) })

                let priceIndex = this.nftForm.value.attribute.findIndex((item) => item.key?.toLowerCase() === 'price')
                if (priceIndex >= 0) this.nftForm.value.attribute[priceIndex].value = Math.ceil(response['data'].price);
                else this.nftForm.value.attribute.push({ key: "Price", value: Math.ceil(response['data'].price) })

                this.nftForm.patchValue({ 'attribute': [...this.nftForm.value.attribute] });
            }
        }
    }

    /**
     * Set Attribute Value
     * @param {event} event
     * @param {array} attribute
     */
    public setNftAttributeValue(event, attribute) {
        const index = this.attributeValue.findIndex((item) => item.key === attribute);
        if (index >= 0) {
            this.attributeValue[index].value = event.target.value;
        } else {
            this.attributeValue.push({ key: attribute, value: event.target.value });
        }
        this.nftForm.patchValue({ 'attribute': this.attributeValue });
    }


    /**
     * Nft FormGet
     */
    get nftFormGet() {
        return this.nftForm.controls;
    }

    /**
     * Converts edit platform fee component
     * @param {any} n
     * @returns
     */
    public convert(n) {
        var sign = +n < 0 ? "-" : "",
            toStr = n.toString();
        if (!/e/i.test(toStr)) {
            return n;
        }
        var [lead, decimal, pow] = n.toString()
            .replace(/^-/, "")
            .replace(/^([0-9]+)(e.*)/, "$1.$2")
            .split(/e|\./);
        return +pow < 0
            ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
            : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
    }

    /**
     * Closes nftmodal
     */
    public closeNFTModal() {
        if (this.tokenId == '') {
            this.nftFormSubmitted = false;
            this.nftForm.patchValue({
                secondaryImage: '',
                name: '',
                image: '',
                description: '',
                attribute_options_0: '',
            });
            this.attributes.map((_data: any, index: number) => {
                let key = `attribute_options_${index}`
                this.nftForm.patchValue({ [key]: '' })
            });
        }
        this.nftSuccessModal.hide();
    }

    /**
     * Connects wallet
     */
    public connectWallet() {
        this.ngOnInit()
        const data = {
            status: true,
        };
        this.accountService.openWallet(data);
    }

    /**
     * Clears form
     */
    public clearForm() {
        if (this.nftForm.controls['image'].value) {
            this.primaryMedia.nativeElement.value = ''; this.nftForm.patchValue({ 'image': '' });
        }
        if (this.nftForm.controls['secondaryImage'].value) this.secondaryImage.nativeElement.value = '';
        if (this.previewImage) this.previewImageTag.nativeElement.value = '';

        if (!this.tokenId) {
            this.nftForm.patchValue({
                secondaryImage: '',
                name: '',
                image: '',
                description: '',
                previewImage: ''
            });
            this.attributes.map((_data: any, index: number) => {
                let key = `attribute_options_${index}`
                this.nftForm.patchValue({ [key]: '' })
            });
        }
        else {
            this.nftAllDetails['attributes'].map((element: any, index: number) => {
                let key = `attribute_options_${index}`;
                this.nftForm.patchValue({ [key]: element['value'] })
            });
            this.nftForm.patchValue({
                name: this.nftAllDetails['name'],
                description: this.nftAllDetails['description'],
            });
        }
    }

    /**
     * Gets currency data
     */
    public getCurrencyData() {
        this.royaltyService.getCurrencies().subscribe({
            next: (res: any) => {
                this.currencyData = res.data;
                const currencyDetails = this.currencyData.filter((item) => { if (item.symbol == 'USDC') { return item._id } })
                this.nftForm.patchValue({
                    currency: currencyDetails[0]._id
                })

            }
        })
    }

    /**
     * open image modal
     * @param{string}imageUrl
     * @param{boolean}is3D
     */
    openImageModal(imageUrl: string, is3D: boolean = false) {
        this.selectedImage = imageUrl;
        this.isViewing3DImage = is3D;
        this.imageModal.show();
    }

    /**
     * close image modal
     */
    closeImageModal() {
        this.imageModal.hide();
    }

    /**
     * whether to show the field or not
     * @param {{[key:string]:any}} attribute
     * @return {boolean}
     */
    shouldShowAttribute(attribute: any): boolean {
        if (attribute?.key?.toLowerCase() !== 'price') {
            // For non-price attributes
            if (attribute?.key?.toLowerCase() === 'quantity' && this.tokenId) {
                // Hide quantity if there's a tokenId (editing)
                return false;
            }
            return true;
        }

        // Price-specific logic
        // Always show price for creation (no tokenId)
        if (!this.tokenId) {
            return true;
        }

        // For editing (tokenId exists)
        if (this.tokenId) {
            // Hide price if it's a lazy mint NFT
            if (this.isLazyMintCollection) {
                return false;
            }
            // Show price if it's not a lazy mint NFT
            return true;
        }

        // Default case: show the attribute
        return true;
    }

}
