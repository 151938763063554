import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { fetchBalance } from '@wagmi/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { encodeFunctionData } from 'viem';
import Web3 from 'web3';
import { IApiResponse, walletAccount } from '../shared/interface/interface';
import { AccountService } from './account.service';
import { publicClientConfig } from './publicClientConfig.service';
import { StorageService } from './storage.service';

const accessAbi = require('../common/abis/access-token.json');

const apiUrl = environment.API_BASE_URL;
@Injectable({
    providedIn: 'root'
})
export class UserManagementService {
    public blockStatus = new BehaviorSubject(false);
    public blockStatusObser: Observable<boolean>;
    public contract: any;
    public accessContract: any;
    public account: walletAccount;
    public web3: any;
    private publicClient: any;


    constructor(
        private http: HttpClient,
        private storageService: StorageService,
        private accountService: AccountService,
        public publicClientConfig: publicClientConfig
    ) {
        this.blockStatusObser = this.blockStatus.asObservable();
        this.onInit();
    }

    public onInit() {

        this.account = this.storageService.getItem('wagmi.store') === null ?
            { address: '', network: '', chainId: '', provider: '' } :
            JSON.parse(this.storageService.getItem('wagmi.store') as any);

        setTimeout(() => {
            this.publicClient = this.publicClientConfig.publicClient(this.account.state.data.chain.id);
        }, 200);
        this.web3 = new Web3(window['ethereum'] as any || environment[this.account.state.data.chain.id].PROVIDER);
        setTimeout(() => {
            this.accessContract = new this.web3.eth.Contract(accessAbi, environment[this.account.state.data.chain.id].ACCESS_CONTROL_TOKEN);
        }, 200);
    }

    /**
  * Gets all users
  * @param {number} page
  * @param {number} limit
  * @param {boolean} isBlocked
  * @param {string} item
  * @returns
  */
    public getAllUsers(page: number, limit: number, isBlocked: boolean, item: string) {
        return this.http.get(`${apiUrl}admin/users?blocked=${isBlocked}&page=${page}&limit=${limit}${item}`);
    }

    /**
     * Updates users
     * @param {string} userId
     * @param {string} wallet_address
     * @param {object} data
     * @returns
     */
    public updateUsers(data: object, userId: string, wallet_address: string) {
        return this.http.patch(`${apiUrl}admin/user?id=${userId}&admin_address=${wallet_address}`, data);
    }

    /**
     * Blocks user
     * @param {string} id
     * @param {boolean} status
     */
    public blockUser(id: string, status: boolean) {
        const data = {
            is_blocked: status
        };
        return this.http.patch(`${apiUrl}admin/manage-user-access?id=${id}`, data);
    }

    /**
     * Delete user
     * @param {string} id
     * @param {string} wallet_address
     * @returns
     */
    public deleteUser(id: string, wallet_address: string) {
        return this.http.delete(`${apiUrl}admin/user-delete?id=${id}&admin_address=${wallet_address}`);
    }

    /**
    * Block or ubBlock user contract
    * @param {string} address
    */
    public async blockUnblockUser(address: string, status: boolean, walletAddress: string) {
        let requiredGas;
        let blockUnblockAbi;
        if (status) {
            const contractParams = {
                address: environment[this.account.state.data.chain.id].ACCESS_CONTROL_TOKEN,
                abi: accessAbi,
                functionName: 'blockUser',
                args: [address]
            };
            requiredGas = await this.publicClient.estimateContractGas({
                account: walletAddress,
                ...contractParams
            });

            blockUnblockAbi = await encodeFunctionData({
                ...contractParams
            })

        }
        else {
            const contractParams = {
                address: environment[this.account.state.data.chain.id].ACCESS_CONTROL_TOKEN,
                abi: accessAbi,
                functionName: 'unblockUser',
                args: [address]
            };
            requiredGas = await this.publicClient.estimateContractGas({
                account: walletAddress,
                ...contractParams
            });

            blockUnblockAbi = await encodeFunctionData({
                ...contractParams
            })

        }

        requiredGas = requiredGas.toString();

        return { blockUnblockAbi, requiredGas }
    }

    /**
     * Get users
     * @param {string} id
     * @returns
     */
    public getUser(id: string) {
        return this.http.get(`${apiUrl}/user?id=${id}`);
    }

    /**
     * Gets user by address
     * @param {string} walletAddress
     * @returns
     */
    public getUserByAddress(walletAddress: string) {
        return this.http.get(`${apiUrl}user/get-user?wallet_address=${walletAddress}`);
    }

    /**
     * Get user nft
     * @param {string} address
     * @param {number}page
     * @param {number}limit
     * @returns
     */
    public getUserNft(address: string, page = 1, limit = 9): Observable<IApiResponse> {
        return this.http.get<IApiResponse>(`${apiUrl}admin/nft-holdings-by-owner?address=${address}&page=${page}&limit=${limit}`);
    }

    /**
     * Updates block status
     * @param {boolean} status
     */
    public updateBlockStatus(status: boolean) {
        this.blockStatus.next(status);
    }

    /**
     * Profiles image upload
     * @param {File} file
     * @returns
     */
    profileImageUpload(file: File) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('service_type', 'image');
        return this.http.post(`${apiUrl}/file-upload/document`, formData)
    }

    /**
     * Updates kyc status
     * @param {string} id
     * @param {any} data
     */
    updateKycStatus(id: string, data: any) {
        return this.http.patch(`${apiUrl}/admin/kyc-enable-disable?id=${id}`, data);
    }

    /**
     * Gets kyc status from admin
     * @returns
     */
    getAdminKycStatus(): Observable<IApiResponse> {
        return this.http.get<IApiResponse>(`${environment.API_BASE_URL_V2}/admin/get-admin-kyc-status`);
    }

    /**
     * Gets currencies
     * @returns
     */
    getCurrencies() {
        return this.http.get(`${apiUrl}/user/currencies`);
    }

    /**
     * Gets matic price
     * @param network
     * @returns
     */
    getMaticPrice(network: string) {
        return this.http.get(`${environment.COINGECKO_API}/price?ids=${network}&vs_currencies=USD&x_cg_demo_api_key=${environment.COINGECKO_API_KEY}`);
    }

    /**
     * Gets token price
     * @param tokenAddress
     * @returns
     */
    getTokenPrice(tokenAddress: any) {
        return firstValueFrom(this.http.get(`${environment.COINGECKO_API}/token_price/polygon-pos?contract_addresses=${tokenAddress}&vs_currencies=USD&x_cg_demo_api_key=${environment.COINGECKO_API_KEY}`));
    }


    /**
     * Gets native balance
     * @param walletAddress
     * @param networkId
     * @returns
     */
    async getNativeBalance(walletAddress: any, networkId: any) {
        return await fetchBalance({
            address: walletAddress,
            chainId: networkId
        });
    }

    /**
     * Gets token balance
     * @param walletAddress
     * @param networkId
     * @param tokenAddress
     * @returns
     */
    async getTokenBalance(walletAddress: any, networkId: any, tokenAddress: any) {
        return await fetchBalance({
            address: walletAddress,
            chainId: networkId,
            token: tokenAddress
        });
    }

}
