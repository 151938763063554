<div class="main-wrap">
    <app-sidebar class="sidebarview"></app-sidebar>
    <section class="section">
        <div class="container-fluid">
            <div class="managecollection">
                <div class="row mb-4">
                    <div class="col-lg-12">
                        <ul class="manage-collection">
                            <li class="nav-item menuactivities cursor-pointer">{{ urlAddress != '' && urlAddress != null
                                ? 'Edit' :
                                'Create' }} collection.</li>
                            <li class="nav-item cursor-pointer" [routerLink]="['/manage-collection/create-lazymint']">
                                Create lazymint collection.</li>
                        </ul>
                    </div>
                </div>
                <!--start forms-->
                <form [formGroup]="collectionForm" (ngSubmit)="createCollectionsSubmit()">
                    <div class="row mb-lg-4 mb-sm-2">
                        <div class="col-lg-6 mb-4">
                            <label for="label">Collection Type. <span class="ms-2 text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="" readonly
                                formControlName="collectionType">
                        </div>
                        <div class="col-lg-6 mb-4">
                            <label for="label">Base url.<span class="ms-2 text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="" formControlName="baseUrl" readonly>
                            <div *ngIf="collectionFormSubmitted && collectionFormGet.baseUrl.errors"
                                class="invalid-feedback-custom">
                                <small class="text-danger" *ngIf="collectionFormGet.baseUrl.errors.required">Please
                                    enter base url.</small>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-4">
                            <label for="label">Name. <span class="ms-2 text-danger">*</span></label>
                            <input type="text" class="form-control" [readonly]="urlAddress != '' && urlAddress != null"
                                placeholder="Name." formControlName="name">
                            <div *ngIf="collectionFormSubmitted && collectionFormGet.name.errors"
                                class="invalid-feedback-custom">
                                <small class="text-danger" *ngIf="collectionFormGet.name.errors.required">Please enter
                                    collection Name.</small>
                            </div>
                        </div>

                        <div class="col-lg-6 mb-4">
                            <label for="label">Symbol. <span class="ms-2 text-danger">*</span></label>
                            <input type="text" class="form-control" [readonly]="urlAddress != '' && urlAddress != null"
                                placeholder="Symbol." formControlName="symbol">
                            <div *ngIf="collectionFormSubmitted && collectionFormGet.symbol.errors"
                                class="invalid-feedback-custom">
                                <small class="text-danger" *ngIf="collectionFormGet.symbol.errors.required">Please enter
                                    symbol.</small>
                            </div>
                        </div>

                        <div class="col-lg-6 mb-3">
                            <label for="label">Client Address.<span class="ms-2 text-danger">*</span></label>
                            <input type="text" class="form-control"
                                (change)="clientAddressChange($any($event.target).value)"
                                [readonly]="urlAddress != '' && urlAddress != null" formControlName="clientAddress"
                                placeholder="Client Address.">
                            <div *ngIf="collectionFormSubmitted && collectionFormGet.clientAddress.errors"
                                class="invalid-feedback-custom">
                                <small class="text-danger"
                                    *ngIf="collectionFormGet.clientAddress.errors.required">Please enter client address
                                    url.</small>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-4">
                            <label for="label">Royalty Fee(%).<span class="ms-2 text-danger">*</span> <span
                                    class="ms-2 warning-text">Add client address can get Royalty Fee.</span> </label>
                            <input type="text" class="form-control" placeholder="" value="2.5" readonly
                                formControlName="royaltyFee">
                        </div>

                        <div class="col-lg-6 mb-4">
                            <ng-container *ngIf="!isLazyMint">
                                <div class="form-group">
                                    <label>Attributes. <span class="text-danger"> * </span></label>
                                    <tag-input class="tag-chips" #tagInput [theme]="'minimal-danger'"
                                        [addOnPaste]="true" name="attribute" formControlName="attribute"
                                        placeholder="Enter collection attributes"></tag-input>
                                    <div *ngIf="collectionFormSubmitted && collectionFormGet.attribute.errors"
                                        class="invalid-feedback-custom">
                                        <small class="text-danger"
                                            *ngIf="collectionFormGet.attribute.errors.required">Please enter
                                            attributes.</small>
                                    </div>
                                </div>
                            </ng-container>

                        </div>
                        <div class="col-lg-6 mb-3">
                            <label for="label">Description.</label>
                            <div class="NgxEditor__Wrapper">
                                <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
                                <ngx-editor formControlName="description" [ngModel]="html" [editor]="editor"
                                    [placeholder]="'Description.'"></ngx-editor>
                            </div>
                        </div>
                    </div>
                    <!--end-->
                    <div class="row mt-4 mb-2">
                        <div class="col-lg-12">
                            <div class="d-flex align-items-center gap-4" *ngIf="account.state.data.account != ''">
                                <button class="submit-btn" type="submit" *ngIf="!createCollectionLoader"
                                    [disabled]="!collectionForm.valid">
                                    <span *ngIf="urlAddress != '' && urlAddress != null">Update.</span>
                                    <span *ngIf="urlAddress == '' || urlAddress == null">Submit.</span>
                                </button>
                                <button class="submit-btn" *ngIf="createCollectionLoader" disabled>
                                    Processing...
                                </button>

                                <button class="clear-btn" type="button" (click)="clearForm()"
                                    [disabled]="createCollectionLoader">Clear.</button>
                            </div>
                            <div class="d-flex align-items-center gap-4" *ngIf="account.state.data.account == ''">
                                <button class="submit-btn" type="button" (click)="connectWallet()">Connect
                                    Wallet.</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <app-ui-modal #collectionSuccessModal [hideHeader]="true" [hideFooter]="true">
            <div class="app-modal-body">
                <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
                    (click)="closeCollectionModal()"><span aria-hidden="true">&times;</span></button>
                <div class="modal-dialog" *ngIf="!urlAddress">
                    <h5 class="text-green text-center collection-success-title">Collection created successfully.</h5>
                    <div class="row">
                        <div class="col-12 d-flex gap-3 flex-wrap justify-content-center">
                            <div class="collection-name">
                                {{collectionName}}
                            </div>
                            <div class="collection-address" title="copy to clipboard">
                                <div class="d-flex gap-2">
                                    {{collectionAddress.slice(0,
                                    7)}}...{{collectionAddress.slice(account.state.data.account.length
                                    - 7)}}
                                    <img loading="lazy" src="assets/images/copy-icon.png" class="copy-icon"
                                        alt="copy-icon" (click)="copyCollectionAddress(collectionAddress)">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 view-link d-block">
                            <span class="d-flex justify-content-between"> ERC721 Collection. :
                                <a target="_blank" href="{{transhUrl}}/tx/{{transMintHash}}" target="_blank"> Click to
                                    view transactions. </a>
                            </span>
                        </div>
                        <div class="col-12 view-link" *ngIf="!isLazyMint">
                            <button class="btn btn-green" (click)="createNft(collectionAddress)">Create Item.</button>
                        </div>
                    </div>
                </div>
                <div class="modal-dialog" *ngIf="urlAddress">
                    <h5 class="text-green text-center collection-success-title">Updated successfully.</h5>
                    <div class="col-12 view-link">
                        <a target="_blank" href="{{transhUrl}}/tx/{{transHash}}" target="_blank"> Click to view
                            transactions.</a>
                    </div>
                    <div class="col-12 view-link" *ngIf="!isLazyMint">
                        <button class="btn btn-green" (click)="createNft(urlAddress)">Create Item.</button>
                    </div>
                </div>
            </div>
        </app-ui-modal>
    </section>
</div>