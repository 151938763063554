import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';
import { readContract } from '@wagmi/core';
import { getAddress, encodeFunctionData } from 'viem';
import { publicClientConfig } from './publicClientConfig.service';
import { StorageService } from './storage.service';
import { walletAccount } from '../shared/interface/interface';

const splitterContractAbi = require('../common/abis/payment-splitter-abi.json');
const collectionContractAbi = require('../common/abis/nftAbi.json');
const web3 = new Web3((window as any)['ethereum']);


@Injectable({
  providedIn: 'root'
})
export class RoyaltyManagementService {
  private publicClient:any;
  public account: walletAccount;

  constructor(
    private http: HttpClient,
    public publicClientConfig:publicClientConfig,
    private storageService:StorageService
  ) {
    this.onInit()
  }

  /**
   * init on
   */
  public onInit() {
    this.account = this.storageService.getItem('wagmi.store') === null ?
    { address: '', network: '', chainId: '', provider: '' } :
    JSON.parse(this.storageService.getItem('wagmi.store') as any);

    this.publicClient = this.publicClientConfig.publicClient(this.account.state.data.chain.id);
  }

  /**
   * Gets currencies
   * @returns  
   */
  public getCurrencies() {
    return this.http.get(`${environment.API_BASE_URL}admin/currencies`);
  }

  /**
   * Gets decimal
   * @param {string} contractAddress 
   * @returns  
   */
  async getDecimal(contractAddress: string) {   
    const chainId = this.account.state.data.chain.id; 
    return await readContract({
      address: await getAddress(contractAddress),
      abi: [{ "constant": true, "inputs": [], "name": "decimals", "outputs": [{ "name": "", "type": "uint8" }], "payable": false, "stateMutability": "view", "type": "function" }],
      chainId,
      functionName: 'decimals',
      args: []
    });
  }

/**
   * Get splitter contract address from collection contract
   * @param {string} collectionAddress
  */
public async getSplitterContractAddress(collectionAddress: string) {
  const chainId = this.account.state.data.chain.id;
  return await readContract({
    address: await getAddress(collectionAddress),
    abi: collectionContractAbi,
    chainId,
    functionName: 'paymentSplitter',
    args: []
  });
}

/**
   * To check if the address is payee or not (index -0 is creator, index -1 client)
   * @param {string} splitterContractAddress
   * @param {number} index
   */
public async payee(splitterContractAddress: string, index: number) {
  const chainId = this.account.state.data.chain.id;
  return await readContract({
    address: await getAddress(splitterContractAddress),
    abi: splitterContractAbi,
    chainId,
    functionName: 'payee',
    args: [index]
  });
}

 /**
   * Getter for the amount of shares held by an account
   * @param {string} splitterContractAddress
   * @param {string} address
   * @returns {number} percentage of shares
   */
 public async shares(splitterContractAddress: string, address: string) {
  const chainId = this.account.state.data.chain.id;
  return await readContract({
    address: await getAddress(splitterContractAddress),
    abi: splitterContractAbi,
    chainId,
    functionName: 'shares',
    args: [address]
  });
}

 /**
   * total royalty claimed by the account
   * @param {string} splitterContractAddress
   * @param {string} address
   * @returns {number} amount in ether
   */
 public async claimedRoyalty(splitterContractAddress: string, address: string) {
  const chainId = this.account.state.data.chain.id;
  const amount = await readContract({
    address: await getAddress(splitterContractAddress),
    abi: splitterContractAbi,
    chainId,
    functionName: 'released',
    args: [address]
  });
  return web3.utils.fromWei(Number(amount),'ether');
}

/**
   * total token royalty claimed by the account
   * @param {string} splitterContractAddress
   * @param {string} address
   * @param {string} tokenAddress
   * @returns {number} amount in ether
   */
public async userTokenRoyalty(splitterContractAddress: string, address: string, tokenAddress: any[]) {   
  const chainId = this.account.state.data.chain.id; 
  let userTokenRoyalty: any = []
  for (const element of tokenAddress){

    let _released = await readContract({
      address: await getAddress(splitterContractAddress),
      abi: splitterContractAbi,
      chainId,
      functionName: 'released',
      args: [element.address, address]
    });

    let _releasable = await readContract({
      address: await getAddress(splitterContractAddress),
      abi: splitterContractAbi,
      chainId,
      functionName: 'releasable',
      args: [element.address, address]
    });
    let decimal = await this.getDecimal(element.address) as any;
    element.released = Number(_released)/10**decimal
    element.releasable = Number(_releasable)/10**decimal
    userTokenRoyalty.push(element)
  }
  return userTokenRoyalty
}

/**
   * Getter for the amount of shares held by an account
   * @param {string} splitterContractAddress
   * @param {string} address
   * @returns {number} amount in ether
   */
public async releaseableAmount(splitterContractAddress: string, address: string) {
  const chainId = this.account.state.data.chain.id;
  const amount = await readContract({
    address: await getAddress(splitterContractAddress),
    abi: splitterContractAbi,
    chainId,
    functionName: 'releasable',
    args: [address]
  });
  return web3.utils.fromWei(Number(amount),'ether');
}

/**
   * Triggers a transfer to account of the amount of Ether they are owed, according to their percentage of the total shares and their previous withdrawals.
   * @param {string} splitterContractAddress
   * @param {string} address
   * @returns
   */
public async release(splitterContractAddress: string, address: string) {
  const chainId = this.account.state.data.chain.id;
  const contractParams = {
    address: await getAddress(splitterContractAddress),
    abi: splitterContractAbi,
    chainId,
    functionName: 'release',
    args: [address]
  };

  let requiredGas = await this.publicClient.estimateContractGas({
    address: await getAddress(splitterContractAddress),
    account:address,
    ...contractParams
  });

  let releaseAbi = await encodeFunctionData({
    ...contractParams
  })
 
  requiredGas = requiredGas.toString();

  return { releaseAbi, requiredGas }
}

/**
 * Triggers a transfer to account of the amount of tokens they are owed, according to their percentage of the total shares and their previous withdrawals.
 * @param {string} splitterContractAddress
 * @param {string} address
 * @param {string} tokenAddress
 * @returns
 */
public async releaseTokenRoyalty(splitterContractAddress: string, address: string, tokenAddress: string) {
  const chainId = this.account.state.data.chain.id;
  const contractParams = {
    address: await getAddress(splitterContractAddress),
    abi: splitterContractAbi,
    chainId,
    functionName: 'release',
    args: [tokenAddress, address]
  };

  let requiredGas = await this.publicClient.estimateContractGas({
    address: await getAddress(splitterContractAddress),
    account:address,
    ...contractParams
  });

  let releaseAbi = await encodeFunctionData({
    ...contractParams
  })
 
  requiredGas = requiredGas.toString();

  return { releaseAbi, requiredGas }
}

}
