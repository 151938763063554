import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SocketIoService } from 'src/app/services/socket-io.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { initialUserDetails } from 'src/app/shared/const/const';
import { CopyDirective } from 'src/app/shared/directives/copy.directive';
import { currency, IuserDetails, walletAccount } from 'src/app/shared/interface/interface';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-profileuser',
  templateUrl: './profileuser.component.html',
  styleUrls: ['./profileuser.component.scss']
})
export class ProfileuserComponent implements OnInit {
  public userId: string = '';
  public userData: IuserDetails;
  public isRegulated: any;
  kycStatus!:boolean;
  currencies: currency[];
  maticPrice: number = 0;
  regulatedBalance: number = 0;
  public account: walletAccount;
  loader:boolean = false
  userRouteData: IuserDetails; // This is using for menu navigation purpose


  constructor(
    private route: ActivatedRoute,
    private userManagementService: UserManagementService,
    private copyDirective: CopyDirective,
    private storageService: StorageService,
    private socketIoService:SocketIoService,
    private toastr: ToastrService,
    private initialUserDetails:initialUserDetails
  ) { }

  /**
   * on init component
   */
  public ngOnInit(): void {
    this.userData = this.initialUserDetails.userDetails;
    this.userId = this.route.snapshot.paramMap.get('address');
    this.account = this.storageService.getItem('wagmi.store') === null ?
      { address: '', network: '', chainId: '', provider: '' } :
      JSON.parse(this.storageService.getItem('wagmi.store') as any);
    this.isRegulated = JSON.parse(this.storageService.getItemSession('isRegulated'));
    this.getUserDetails();
    this.getCurrencies();
    this.getMaticPrice();
    this.getBalance();

    this.userManagementService.blockStatusObser.subscribe((response: boolean) => {
      if (response) {
        this.getUserDetails();
        this.getCurrencies();
        this.getMaticPrice();
        this.getBalance();
      }
    })

    this.socketIoService.checkUserStatus();
    this.socketIoService.checkUserStatus$.subscribe((response) => {
      if(response.regulated == this.isRegulated && this.userId == response.user_id) {
        this.getUserDetails();
        this.getCurrencies();
        this.getMaticPrice();
      }
    });


  }

  /**
   * Gets user details
   */
  public getUserDetails() {
    this.loader = true;
    this.userManagementService.getUserByAddress(this.userId).subscribe((response: any) => {
      this.userData = response.data;
      this.userRouteData = response.data;
      this.kycStatus = this.userData['kyc_enable'];
      this.loader = false;
    },(_error) => {
      this.loader = false;
    })
  }

  /**
    * Copys content
    * @param content
    */
  public copy(content: string) {
    this.copyDirective.copy(content)
  }

  /**
 * Confirms alert
 * @param {string} id
 * @param {boolean} status
 */
public kycUploadStatus(id:string,status:boolean) {
  this.kycStatus = status;
  let name = status ? 'enable' : 'disable';
  Swal.fire({
    title: `Are you sure you want to ${name} identity verification for this user?`,
    text: `This user will ${ status ? '' :'not' } be redirected to identity validation next time he tries to initiate a transaction.`,
    type: 'warning',
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonText: 'OK.',
    cancelButtonText: 'Cancel.',
  }).then((willDelete) => {
    Swal.fire({
      onBeforeOpen: () => {
        Swal.showLoading()
    },
      allowOutsideClick: false,
      text: 'Processing, Please wait...',
  });
    if (willDelete.dismiss) {
      Swal.fire({title:'',text: `Not ${name} this user.`,type: 'error',icon:'error',confirmButtonText:'OK.'});
      this.kycStatus = !status;
      this.getUserDetails();
    } else {
      this.kycUploadStatusProcess(id,status);
    }
  });

}

/**
 * Kycs upload status process
 * @param {string} id
 * @param {string} status
 */
kycUploadStatusProcess(id:string,status:boolean){
  let name = status ? 'Enabled' : 'Disabled';
  const data = {
    status:status
  }
    this.userManagementService.updateKycStatus(id,data).subscribe((response) => {
      Swal.fire({title:'', text:`${name} successfully.`,icon: 'success',type:'success',confirmButtonText:'OK.'});
      this.getUserDetails();
    },
    (error) => {
      Swal.fire({title:'',text: `Not ${name} this user.`,type: 'error',icon:'error',confirmButtonText:'OK.'});
     this.kycStatus = !status;
      this.getUserDetails();
    }
  )
}

/**
 * Gets currencies
 */
getCurrencies() {
  this.userManagementService.getCurrencies().subscribe({
    next: async (response: any) => {


      this.currencies = response.data;
      this.getTokenPrice();
      this.getTokenBalance();
    },
    error: (error) => {
      this.toastr.error(error?.data?.message || "Something went wrong, try again later.");
    }
  })
}

/**
 * Gets matic price
 */
getMaticPrice() {
  this.userManagementService.getMaticPrice('matic-network').subscribe((response:any) => {


    this.maticPrice = response['matic-network'].usd;
  });
}

/**
 * Gets token price
 */
async getTokenPrice() {
  this.currencies.map((currency) => {
    setTimeout(async () => {
      let response: any = await this.userManagementService.getTokenPrice(currency.address);

      currency.usdValue = response[currency.address.toLowerCase()]?.usd || 0;
    }, 100);
  });
}

/**
 * Gets balance
 */
async getBalance() {
    let balance = await this.userManagementService.getNativeBalance(this.userId,this.account.state.data.chain.id);
    this.regulatedBalance = Number(balance.formatted);
}

/**
 * Gets token balance
 */
getTokenBalance() {
  this.currencies.map(async (currency:any,index) => {
    let balance = await this.userManagementService.getTokenBalance(this.userId,this.account.state.data.chain.id, currency.address);
    currency.balance = Number(balance.formatted);
    if(this.currencies.length == index + 1){
      setTimeout(() => {
        this.currencies.sort((a, b) => b.balance - a.balance);
      }, 500);
    }
  });


}

}
