import { Component, Input } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { CopyDirective } from 'src/app/shared/directives/copy.directive';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserManagementService } from 'src/app/services/user-management.service';
import { AccountService } from 'src/app/services/account.service';
import { walletAccount } from 'src/app/shared/interface/interface';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  @Input('userData') userData: any;
  @Input('loader') loader: boolean = false;
  public isRegulated: boolean;
  public userId: string = '';
  profileLoader:boolean = false
  public account: walletAccount;
  

  constructor(
    private copyDirective: CopyDirective,
    private storageService: StorageService,
    private userManagementService: UserManagementService,
    public toastr:ToastrService,
    private route: ActivatedRoute,
    private accountService:AccountService
  ) { }

  /**
   * on init component
   */
  public ngOnInit(): void {
    this.isRegulated = JSON.parse(this.storageService.getItemSession('isRegulated')); 
    this.account = this.storageService.getItem('wagmi.store') === null ?
          { address: '', network: '', chainId: '', provider: '' } :
          JSON.parse(this.storageService.getItem('wagmi.store') as any);
    const userId = this.route.snapshot.paramMap.get('address');
    this.userManagementService.getUserByAddress(userId).subscribe((response) => {
      this.userId = response['data']._id
    })
    this.accountService.accountObserve.subscribe((response) => {
      if (response) {
        this.account = this.storageService.getItem('wagmi.store') === null ?
          { address: '', network: '', chainId: '', provider: '' } :
          JSON.parse(this.storageService.getItem('wagmi.store') as any);
      }
    });
  }

  /**
   * Copys content
   * @param {string} content 
   */
  public copy(content: string) {
    this.copyDirective.copy(content)
  }

  /**
   * Profiles image change
   * @param {any} event 
   */
  profileImageChange(event: any) {
    let extension = event.target.files[0].name.substr(event.target.files[0].name.lastIndexOf('.'));
    if ((extension.toLowerCase() != ".png") &&
      (extension.toLowerCase() != ".jpeg") &&
      (extension.toLowerCase() != ".jpg")) {
      this.toastr.error(extension.toLowerCase() + ' file format not supported.');
    }
    else {
      this.profileLoader = true;
      this.userManagementService.profileImageUpload(event.target.files[0]).subscribe((response) => {
        this.imageUploadProcess(response['data'].file[0].Url);
      },
        (error) => {
          this.profileLoader = false;
        }
      )
    }
  }

  /**
   * Images upload process
   * @param {string} url 
   */
  imageUploadProcess(url:string){
    let data = {
      profile_image:url
    }
    this.userManagementService.updateUsers(data,this.userId,this.account.state.data.account).subscribe((response) => {
      this.toastr.success(response['message']);
      this.profileLoader = false;
      this.userData.profile_image = url;
    },
    (error) => {
        this.profileLoader = false;
    }
    )
  }
}
