import { Component, Input, OnInit, NgZone, OnDestroy, ViewChild, HostListener, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { StorageService } from 'src/app/services/storage.service';
import { AccountService } from 'src/app/services/account.service';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';
import { ToastrService } from 'ngx-toastr';
import { NftManagementService } from 'src/app/services/nft-management.service';
import { CommonContractService } from 'src/app/services/common-contract.service';
import { polygon} from '@wagmi/core/chains';
import { polygonAmoy } from '../../const/polygonAmoy';
import { watchAccount, watchNetwork, disconnect, switchNetwork } from '@wagmi/core';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi';
import { LoginService } from 'src/app/services/login.service';
import { SearchService } from 'src/app/services/search.service';
import { walletAccount } from '../../interface/interface';
import { FormControl } from '@angular/forms';
import { Subject, debounceTime, takeUntil } from 'rxjs';

@Component({
  selector: 'app-header-new',
  templateUrl: './header-new.component.html',
  styleUrls: ['./header-new.component.scss']
})
export class HeaderNewComponent implements OnInit, OnDestroy {
  showMenu: any;
  regulated: any;
  public web3WalletConnect: any;
  public web3: any;
  public account: walletAccount;
  public connector: any;
  public chainId: any;
  public provider: any;
  public ethereum: any;
  public authSubscribe: any;
  @Input('collapseStyle') collapseStyle;
  public alertSuccess: boolean;
  public checkNetworkInterval: NodeJS.Timeout;
  public allowNetwork: boolean;
  public isRegulated:any;
  public currentRoute:string = '';
  public web3Modal: any;
  defaultNetwork = environment.SUPPORTED_CHAINS;
  @ViewChild('switchNetworkModal') switchNetworkModal: { show: () => void; hide: () => void; };
  @ViewChild('quickSearchBox') quickSearchBox:ElementRef
  walletProvider:string = '';
  serachDropdown:boolean=false;
  searchLoader:boolean = false;
  searchData
  searchKey: string = '';
  searchControl = new FormControl();
  private unsubscribeSearch$ = new Subject<void>();

  /**
   * Constructors
   */
  constructor(
    private accountService: AccountService,
    private storageService: StorageService,
    private router: Router,
    private toastr: ToastrService,
    private ngzone: NgZone,
    private nftManagementService: NftManagementService,
    private commonservices:CommonContractService,
    private loginService:LoginService,
    private _eref: ElementRef,
    private searchService:SearchService,
  ) { }

  /**
    * Initial Loader
    */
  public async ngOnInit() {
    this.wagmiConfig();
    this.router.events.subscribe(() => { 
      this.currentRoute = this.router.url;
  })
    
    this.isRegulated = JSON.parse(this.storageService.getItemSession('isRegulated'));
    if(this.isRegulated != false){
      this.storageService.setItemSession('isRegulated',true);
      this.isRegulated = JSON.parse(this.storageService.getItemSession('isRegulated'));
     } 
    this.web3 = new Web3(window['ethereum'] as any);
    this.ethereum = window['ethereum'] as any;
    this.accountService.authValidationsObserve.subscribe((response) => {
      if (response) {
        this.logout();
      }
    })
    this.accountService.walletObserve.subscribe((response) => {
      if (response.status) {
        this.connectWalletButton();
        const data = {
          status: false,
        };
        this.accountService.openWallet(data);
      }
    });

    /** common header hide on wallet connect */
    this.commonservices.sethederhide(true)
    this.getWalletAddress();

    this.searchControl.valueChanges
      .pipe(
        debounceTime(2000),
        takeUntil(this.unsubscribeSearch$)
      )
      .subscribe(value => {
        this.quickSearchProcess(value);
      });
  }

  /**
   * Gets wallet address
   */
  public getWalletAddress(){
    this.account = this.storageService.getItem('wagmi.store') === null ?
    { address: '', network: '', chainId: '', provider: '' } :
    JSON.parse(this.storageService.getItem('wagmi.store') as any);
    this.walletProvider = JSON.parse(this.storageService.getItem('wagmi.connectedRdns') || 'null');
    if(this.account.state.data.account){
      this.checkWhitelist();
    }
  }
  public wagmiConfig(){
    // wallet connect initialization


    const metadata = {
      name: 'Real World',
      description: 'Real World - Market',
      url: environment.WEB_SITE_URL,
      icons: ['https://avatars.githubusercontent.com/u/37784886']
    }
    const chains = [polygon, polygonAmoy]
    const projectId = environment.WALLET_CONNECT_PROJECT_ID;
    const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })

  this.web3Modal = createWeb3Modal({ wagmiConfig, projectId, chains, themeVariables: {'--w3m-accent': '#006736', '--w3m-z-index': 10000,'--w3m-font-family':'Source Code Pro' } })
    watchNetwork(async (network) => {
      if (network.chain != undefined) {
        if (!environment.SUPPORTED_CHAINS.includes(network.chain.id.toString())) {
          this.switchNetworkModal.show();
          this.commonservices.closeAllModals(true);
        }
        else {
          this.switchNetworkModal.hide();
          this.commonservices.closeAllModals(false);
          setTimeout(() => {
            this.getWalletAddress();
          }, 1000);
        }
      }
    })
    watchAccount((connection) => {
      if(connection.isConnected){
          setTimeout(() => {
            this.getWalletAddress();
          }, 500);
      }
      else{
        this.router.navigate(['/']);
        
      }
      })
    
  }
  public connectWalletButton(){
    // this.web3Modal?.open();
    this.connector.openModal();
  }
  /**
     * Destroy
     */
  public ngOnDestroy() {
    clearInterval(this.checkNetworkInterval);
    this.unsubscribeSearch$.next();
    this.unsubscribeSearch$.complete();
  } 
  /**
   * Checks whitelist
   */
  public checkWhitelist() {
    this.nftManagementService.whiteListed(this.account.state.data.account).then((async isWhitlisted => {
      if (!isWhitlisted) {
        this.toastr.error("Your address is not whitelisted. Please contact your admin.");
        await disconnect();
        if (this.router.url !== "/") {
          this.ngzone.run(() => {
            this.router.navigate(['/'])
          })
        }
        // setTimeout(() => window.location.reload(), 1500)
        return;
      }
      else{
        let data = {
          wallet_address:this.account.state.data.account
        }
        this.loginService.signin(data).subscribe((response) => {
          this.storageService.setItem('token',response['data'].token);
             this.accountService.accountChange(true);
          if(this.router.url == '/'){
            this.router.navigate(['/dashboard']);
          }
        })
        
      }
    })
  );
  }
  /**
   * Toggles menu
   */
  public toggleMenu() {
    this.showMenu = !this.showMenu;
  }
  /**
* Sets regulated
*/
public setRegulated(){
  this.storageService.setItemSession('isRegulated',!this.isRegulated);
  this.isRegulated = JSON.parse(this.storageService.getItemSession('isRegulated'));
  setTimeout(() => {
    if (this.router.url == "/") {
      window.location.reload();
    }
    else{
      this.router.navigate(['/']);
    }
  }, 500);
  }
  /**
   * Logout
   */
  async logout(){
    this.toastr.error('Authentication failed. Please login again');
    await disconnect();
    this.accountService.authValidationsUpdate(false);
    setTimeout(() => {
      this.router.navigate(['/']);
    }, 1000);
  }

  /**
   * Switchs network
   * @param {string} chainId 
   */
  switchNetwork(chainId: string) {
    switchNetwork({chainId: Number(chainId)});
  }
  /**
   * search dropdwon
   */
  showDropdown() {
    this.serachDropdown = !this.serachDropdown;
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    this.account = this.storageService.getItem('wagmi.store') === null ?
    {"state":{"data": {"account": "", "chain": { "id":environment.SUPPORTED_CHAINS[0]}}}} :
    JSON.parse(this.storageService.getItem('wagmi.store') as any);
    if (!this._eref.nativeElement.contains(event.target) && this.account.state.data.account) {
      this.serachDropdown = false;
      this.quickSearchBox.nativeElement.value = '';
    }
  }

  /**
   * Quicks search
   */
  quickSearch(){
      this.serachDropdown = true;
       this.searchLoader = true;
    
  }

  /**
   * Quicks search process
   * @param {string} keyWord 
   */
  quickSearchProcess(keyWord:string){
    this.searchKey = keyWord;
    this.searchService.searchItems(keyWord,this.account.state.data.account).subscribe((response) => {
      this.searchData = response;
      this.searchLoader = false;
    },
    (error) => {
      this.searchLoader = false;
    })
  }

  /**
   * Resets search
   */
  resetSearch(){
    this.quickSearchBox.nativeElement.value = '';
    this.serachDropdown = false;
  }
 
}
