import { Directive } from '@angular/core';
import { NgxBugatlasService } from 'ngx-bugatlas';
import { ToastrService } from 'ngx-toastr';

@Directive({
    selector: '[appErrorHandler]'
})
export class ErrorHandlerDirective {

    constructor(
        private toastr: ToastrService,
        private bugAtlasService: NgxBugatlasService,
    ) { }

    /**
     * Handles error
     * @param {any} error
     */
    async handleError(error: any) {
        this.bugAtlasService.appErrorPost({
            error_message: 'contract error',
        })
        let status = error?.data?.status || 500;
        error = await JSON.stringify(error, null, 2);
        error = await JSON.parse(error);

        // Handle api error and contract errors
        if (error?.data?.message) error.shortMessage = error?.data?.message
        else if (error?.name?.toLowerCase() === "contractfunctionexecutionerror") error.shortMessage = error?.cause?.reason

        switch (status) {
            case 4001: {
                this.toastr.error('User declined the transaction.');
                break;
            }
            case 404: {
                this.toastr.error('Requested route not found');
                break;
            }
            case 500:
            case 400: {
                if (error?.shortMessage?.includes('User rejected the request')) {
                    this.toastr.error("User declined the transaction.");
                } else this.toastr.error(error.shortMessage || "Something went wrong");
                break;
            }
            case 503: {
                this.toastr.error('Service Unavailable');
                break;
            }
            default: {
                this.toastr.error("Something went wrong");
                break;
            }
        }
    }

}

