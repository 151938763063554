import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { NftManagementService } from 'src/app/services/nft-management.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';
import { ManageTransactionsDirective } from 'src/app/shared/directives/manage-transactions.directive'
import { LogDebugsService } from 'src/app/services/log-debugs.service';
import { walletAccount } from 'src/app/shared/interface/interface';
import { ErrorHandlerDirective } from 'src/app/shared/directives/error-handler.directive';

let web3 = new Web3(window['ethereum'] as any);
const apiUrl = environment.API_BASE_URL;

@Component({
  selector: 'app-burnnft',
  templateUrl: './burnnft.component.html',
  styleUrls: ['./burnnft.component.scss']
})
export class BurnnftComponent implements OnInit {
  public nftBurnForm: UntypedFormGroup;
  public burnNftLoader: boolean = false;
  public burnNftFormSubmitted: boolean = false;
  public account: walletAccount;
  public transactionHash: string = '';
  public transhUrl = '';
  /**
   * Creates an instance of nft burn component.
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    private nftManagementService: NftManagementService,
    private accountService: AccountService,
    private storageService: StorageService,
    private toastr: ToastrService,
    private manageTransactionsDirective:ManageTransactionsDirective,
    private logDebgsService:LogDebugsService,
    private errorHandler: ErrorHandlerDirective
  ) { }

  /**
   * on init
   */
  public ngOnInit(): void {
    this.account = this.storageService.getItem('wagmi.store') === null ?
      { address: '', network: '', chainId: '', provider: '' } :
      JSON.parse(this.storageService.getItem('wagmi.store') as any);
    if (this.account.state.data.account) {
      this.transhUrl = environment[this.account.state.data.chain.id].EXPLORER;
    }
    this.nftBurnForm = this.formBuilder.group({
      address: ['', Validators.required],
      tokenId: ['', Validators.required],
    });

    this.accountService.accountObserve.subscribe((response) => {
      if (response) {
        this.account = this.storageService.getItem('wagmi.store') === null ?
          { address: '', network: '', chainId: '', provider: '' } :
          JSON.parse(this.storageService.getItem('wagmi.store') as any);
      }
    });
  }

   

  /**
   * Nfts burn submit
   * @return {httpresponse}
   */
  public async nftBurnSubmit() {
    if (this.nftBurnForm.invalid) {
      return;
    }
    this.burnNftFormSubmitted = true;
    this.burnNftLoader = true;
    
    this.nftManagementService.getUserCreatedCollections(this.account.state.data.account).then((response) => {

      const inputAddress = this.nftBurnForm.controls['address'].value.trim();
      let collectionAddress;
      try {
        collectionAddress = web3.utils.toChecksumAddress(inputAddress);
      }
      catch {
        this.toastr.error("Invalid collection address.");
        this.burnNftLoader = false;
        this.burnNftFormSubmitted = false;
        return false
      }
      
        this.nftManagementService.isCollectionDestroyed(collectionAddress).then(async (destroyedResponse) => {

          if (destroyedResponse) {
            this.toastr.error("Collection destroyed");
            this.burnNftLoader = false;
            this.burnNftFormSubmitted = false;
            return;
          }
          else {
            this.nftManagementService.getNextMintableToken(collectionAddress).then(async tokenId => {
              if (this.nftBurnForm.controls['tokenId'].value >= tokenId) {
                this.toastr.error("Invalid token ID.")
                this.burnNftLoader = false;
                this.burnNftFormSubmitted = false;
                return;
              } else {
                this.nftManagementService.getNftowner(this.nftBurnForm.controls['tokenId'].value, collectionAddress).then(((ownerAddress: any) => {
                  this.nftManagementService.checkBurnNft(collectionAddress,this.nftBurnForm.controls['tokenId'].value).subscribe((_response: any) => {
                  this.nftManagementService.owner().then((adminAddress:any) => {
                    if (web3.utils.toChecksumAddress(ownerAddress) != web3.utils.toChecksumAddress(this.account.state.data.account) && !response.includes(collectionAddress) && adminAddress != this.account.state.data.account) {
                      this.toastr.error("You are not a owner of the NFT.");
                      this.burnNftLoader = false;
                      this.burnNftFormSubmitted = false;
                      return;
                    } else {

                      this.nftManagementService.whiteListed(this.account.state.data.account).then((isWhitlisted => {
                        if (isWhitlisted) {
                          this.nftManagementService.getCreator(this.nftBurnForm.controls['tokenId'].value, collectionAddress).then(((creatorAddress: any) => {
                            if (web3.utils.toChecksumAddress(creatorAddress) == web3.utils.toChecksumAddress(this.account.state.data.account) || adminAddress == this.account.state.data.account) {
                              this.burnProcess();
                            }
                            else {
                              this.nftManagementService.getNftowner(this.nftBurnForm.controls['tokenId'].value, collectionAddress).then(((ownerAddress: any) => {
                                if (web3.utils.toChecksumAddress(ownerAddress) == web3.utils.toChecksumAddress(this.account.state.data.account)) {
                                  this.burnProcess();
                                }
                              }),
                                (error) => {
                                  error = error.toString().replace('Error: Returned error: execution reverted: ERC721: ', '');
                                  this.toastr.error(error);
                                  this.burnNftLoader = false;
                                  this.burnNftFormSubmitted = false;
                                })
                            }
                          }))
                        }
                        else {
                          this.toastr.error("Your address is not whitelisted. Please contact your admin.");
                          this.burnNftLoader = false;
                          this.burnNftFormSubmitted = false;
                        }
                      }),
                        (_error) => {
                          this.toastr.error("Transaction failed");
                          this.burnNftLoader = false;
                          this.burnNftFormSubmitted = false;
                        });
                    }
                  });
                },((error) => {
                  this.burnNftLoader = false;
                  this.burnNftFormSubmitted = false;
                  this.toastr.error(error.error.message);
                 
                  }));
                })).catch(() => {
                  this.toastr.error("Token ID already burned")
                  this.burnNftLoader = false;
                  this.burnNftFormSubmitted = false;
                  return
                  
                });
              }
            }),
              (error) => {
                this.burnNftLoader = false;
                this.burnNftFormSubmitted = false;
                if ((error.toString()).includes('is not a valid Ethereum address')) {
                  this.toastr.error("Invalid collection address.")
                }
                if ((error.toString()).includes('if you are not using the correct ABI for the contract you are retrieving data from')) {
                  this.toastr.error("Collection address not found.")
                }
              }

          }
        })

     
    }),
      (error: any) => {
        this.burnNftLoader = false;
        this.burnNftFormSubmitted = false;
        if ((error.toString()).includes('is not a valid Ethereum address')) {
          this.toastr.error("Invalid collection address.")
        }
        else if ((error.toString()).includes('if you are not using the correct ABI for the contract you are retrieving data from')) {
          this.toastr.error("Collection address not found.")
        }
        else if ((error.toString()).includes('returned no data')) {
          this.toastr.error("Collection address not found.")
        }
      }

  }

  /**
   * Burns process
   */
  public async burnProcess() {
    try{
      
      const { tokenId, address } = this.nftBurnForm.controls;
    const { burnAbi, requiredGas } = await this.nftManagementService.burnNft(tokenId.value, address.value, this.account.state.data.account);
    const message = {
      to: address.value,
      data: burnAbi,
      gasPrice: await web3.utils.toHex(Number(await web3.eth.getGasPrice()) * 2),
      gasLimit: await web3.utils.toHex(Number(requiredGas) * 2)
    };
    this.manageTransactionsDirective.makeTransactions(message)
      .then(async (receipt) => {
        this.deleteNft('',tokenId.value, address.value, receipt['data']['transactionHash']);
      }).
      catch((error) => {
        this.burnNftLoader = false;
        this.burnNftFormSubmitted = false;
      });
    }
    catch(error:any){
      this.errorHandler.handleError(error);
      this.burnNftLoader = false;
      this.burnNftFormSubmitted = false;
    }
  }

  /**
   * Gets get nft burn form
   */
  public get nftBurnFormGet() {
    return this.nftBurnForm.controls;
  }

  /**
  * Connects wallet
  */
  public connectWallet() {
    this.ngOnInit()
    const data = {
      status: true,
    };
    this.accountService.openWallet(data);
  }

  /**
   * Deletes nft
   * @param {string} tokenId 
   * @param {string} collectionAddress 
   * @param {string} transactionHash 
   */
  public deleteNft(deliveryId:string,tokenId: string, collectionAddress: string, transactionHash: string) {
    this.nftManagementService.deleteNft(deliveryId,tokenId, collectionAddress,this.account.state.data.account).subscribe((response) => {
      this.toastr.success('NFT burned successfully');
      this.transactionHash = transactionHash;
      this.burnNftLoader = false;
      this.burnNftFormSubmitted = false;
      this.nftBurnForm.reset();
      this.logDebgsService.debugs(`${apiUrl}admin/nft/delete-by-tokenid?token_id=${tokenId}&collection_address=${collectionAddress}`,response,'delete','success');
    },
      (error) => {
        this.burnNftLoader = false;
        this.burnNftFormSubmitted = false;
        this.logDebgsService.debugs(`${apiUrl}admin/nft/delete-by-tokenid?token_id=${tokenId}&collection_address=${collectionAddress}`,error,'delete','error');
      })
  }

}

