import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Socket, io } from 'socket.io-client';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class SocketIoService {
  private socket!: Socket;
  public getAllLogs$: BehaviorSubject<any> = new BehaviorSubject('');
  public userActivityLogs$: BehaviorSubject<any> = new BehaviorSubject('');
  public userLogs$: BehaviorSubject<any> = new BehaviorSubject('');
  public checkUserStatus$: BehaviorSubject<any> = new BehaviorSubject('');
  public regulated!:any;


  constructor(
    public storageService:StorageService
  ) {
    this.connectSocket();
   }

   /**
    * Connects socket
    */
   public connectSocket() {
    this.regulated = this.storageService.getItemSession('isRegulated');
    this.socket = io(environment.SOCKET_ENDPOINT, {query: {regulated: this.regulated}});
    this.socket.on('connection', () => {
    });

  }


  /**
   * Determines whether connected is
   * @returns true if connected 
   */
  public isConnected(): boolean {
   return this.socket && this.socket.connected;;;
  }

  /**
   * Disconnects socket
   */
  public disconnectSocket() {
    if (this.socket && this.socket.connected) {
      this.socket.disconnect();
    }
  }

  /**
   * Get all logs of socket io service
   */
  public getAllLogs = () => {
    this.socket.on('LOGS', (logs: any) => {
      this.getAllLogs$.next(logs);
    });

    return this.getAllLogs$.asObservable();
  };

  /**
   * Get user activity logs of socket io service
   */
  public getUserActivityLogs = () => {
    this.socket.on('USER_ACTIVITY_LOGS', (logs: any) => {
      this.userActivityLogs$.next(logs);
    });

    return this.userActivityLogs$.asObservable();
  };

  /**
   * Get user logs of socket io service
   */
  public getUserLogs = () => {
    this.socket.on('USER_LOGS', (logs: any) => {
      this.userLogs$.next(logs);
    });

    return this.userLogs$.asObservable();
  };
  
  /**
   * Check user status of socket io service
   */
  public checkUserStatus = () => {
    this.socket.on('USER_ONLINE', (logs: any) => {
      this.checkUserStatus$.next(logs);
    });

    return this.checkUserStatus$.asObservable();
  };

}
