import { Component } from '@angular/core';
import { LogDebugsService } from 'src/app/services/log-debugs.service';
import { LogsService } from 'src/app/services/logs.service';
import { SocketIoService } from 'src/app/services/socket-io.service';
import { StorageService } from 'src/app/services/storage.service';
import { CopyDirective } from 'src/app/shared/directives/copy.directive';
import { transhLogs } from 'src/app/shared/interface/interface';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';
let web3 = new Web3(window['ethereum'] as any);
const apiUrl = environment.API_BASE_URL;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  public allLogsDetails:transhLogs[] = [];
  public alluserActivityDetails: transhLogs[] = [];
  public transhUrl: string = '';
  public isLoadingData:boolean = false;
  public currentPageLimit: number = 10;
  public currentUserPageLimit: number = 7;
  public isRegulated: boolean;
  public isLoadingUserLogs:boolean = false;
  public alluserActivityDetailsLength:number;
  public allLogsDetailsLength:number;

  constructor(
    private socketIoService:SocketIoService,
    private logsService:LogsService,
    private copyDirective:CopyDirective,
    private storageService: StorageService,
    private logDebgsService:LogDebugsService
  ) { }
 
  /**
   * on init
   */
  public ngOnInit(): void {
    this.isRegulated = JSON.parse(this.storageService.getItemSession('isRegulated'));
    this.socketIoService.getAllLogs();
    this.socketIoService.getAllLogs$.subscribe((response) => {
      if(response.custodial == this.isRegulated) { 
        if(this.allLogsDetails.length >= this.currentPageLimit){
          this.allLogsDetails.pop();  
        }
        this.allLogsDetails.unshift(response.message);
      }
    });

    this.socketIoService.getUserActivityLogs();
    this.socketIoService.userActivityLogs$.subscribe((response) => {
      if(response.custodial == this.isRegulated) { 
        if(this.alluserActivityDetails.length >= this.currentUserPageLimit){
          this.alluserActivityDetails.pop();  
        }
        this.alluserActivityDetails.unshift(response.message);
      }
    });

    this.getAllLogs(1,this.currentPageLimit);
    this.getUserActivityLogs(1,this.currentUserPageLimit);
    const account = this.storageService.getItem('wagmi.store') === null ?
    { address: '', network: '', chainId: '', provider: '' } :
    JSON.parse(this.storageService.getItem('wagmi.store') as any);
    this.transhUrl = environment[account.state.data.chain.id].EXPLORER
  }

  
  /**
   * Gets all logs
   * @param {number} page 
   * @param {number} limit 
   */
  public getAllLogs(page:number,limit:number){
    this.isLoadingData = true;
    this.logsService.getLogs(page,limit).subscribe((response) => {
      this.allLogsDetails = response['data']['docs'];
      this.allLogsDetailsLength = response['data'].length;
      this.isLoadingData = false;
      this.logDebgsService.debugs(`${apiUrl}logs/list-logs?page=${page}&limit=${limit}`,response,'get','success');
    },
    (error) => {
      this.isLoadingData = false;
      this.logDebgsService.debugs(`${apiUrl}logs/list-logs?page=${page}&limit=${limit}`,error,'get','error');
    }
    )
  }

  /**
   * Gets user activity logs
   * @param {number} page 
   * @param {number} limit 
   */
  public getUserActivityLogs(page:number,limit:number){
    this.isLoadingUserLogs = true;
    this.logsService.getUserActivityLogs(page,limit).subscribe((response) => {
      this.alluserActivityDetails = response['data']['docs'];
      this.alluserActivityDetailsLength = response['data'].length;
      this.isLoadingUserLogs = false;
    },
    (error) => {
      this.isLoadingUserLogs = false;
    }
    )
  }
  /**
     * Track by function for ngFor loops
     * @param index
     * @param item
     */
  public trackByFn(index: number, item: any): any {
    return item._id || index;
  }

  /**
   * Copys content
   * @param content 
   */
public copy(content:string){
  this.copyDirective.copy(content)
}


/**
 * From wei convertion for gas fee
 * @param {number} value 
 * @returns  
 */
public fromWeiConvertion(value){
  const gasFee = +web3.utils.fromWei(value.toString(),'Gwei');
  return gasFee.toFixed(6)
}

}
