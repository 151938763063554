import { IuserDetails } from "../interface/interface";

/**
 * Const functions
 */
export class Const {
  CONST_VAR = {
    'invalidTknAuth': 'invalid token authorization',
    'typeZip': 'application/zip',
    'typeExcel': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'space': 'Space',
    'ERC721': 'ERC721',
    'ERC1155': 'ERC1155',
    'RENTAL': 'RENTAL',
    'attributes': 'attributes',
    'features': 'features',
    'Buy': 'Buy',
    'Bid': 'Bid',
    'TimedBid': 'TimedBid',
    'fullCard': 'full-card',
    'vertical': 'vertical',
    'menuLight': 'menu-light',
    'dark': 'dark',
    'reset': 'reset',
    'metamask': 'metamask',
    'horizontal': 'horizontal',
  }
}

/**
 * Set Initial user details
 */
export class initialUserDetails { 
  userDetails:IuserDetails = {
  apple_signin : false,
  createdAt : 'default-date',
  email : 'default@gmail.com',
  fire_block_address : 'default-address',
  google_signIn : false,
  is_blocked : false,
  kyc_enable : false,
  kyc_verified : 0,
  last_online : 'default-time',
  name : 'default-name',
  updatedAt : 'default-date',
  wallet_address : 'default-address',
  _id : 'default-id',
}
}
