<div class="main-wrap">
    <app-sidebar class="sidebarview"></app-sidebar>
    <section class="section">
        <div class="container-fluid">
            <div class="managecollection">
                <div class="row mb-4">
                    <div class="col-lg-6">
                        <ul class="manage-collection">
                            <li class="nav-item " [routerLink]="['/manage-nft']">Create NFT.</li>
                            <li class="nav-item" [routerLink]="['/manage-nft/edit-nft']" routerLinkActive="menuactivities"
                                [routerLinkActiveOptions]="{exact: true}">Edit NFT.</li>
                            <li class="nav-item" [routerLink]="['/manage-nft/burn-nft']">Burn NFT.</li>
                        </ul>
                    </div>
                </div>
                <!--start forms-->
                <form [formGroup]="nftBurnForm" (ngSubmit)="nftBurnSubmit()">
                    <div class="row mb-sm-2 mb-lg-0  align-items-center">
                        <div class="col-lg-3 col-sm-12 mb-3">
                            <label for="label">Token ID.</label>
                            <input type="text" class="form-control" placeholder="Enter Token ID"
                                formControlName="tokenId">
                            <div *ngIf="burnNftFormSubmitted && nftBurnFormGet.tokenId.errors">
                                <small class="text-danger" *ngIf="nftBurnFormGet.tokenId.errors.required">Please enter
                                    token ID.</small>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12 mb-3">
                            <label for="label">Collection Search.</label>
                            <input type="text" class="form-control" placeholder="Enter collection address"
                                formControlName="address">
                            <div *ngIf="burnNftFormSubmitted && nftBurnFormGet.address.errors">
                                <small class="text-danger" *ngIf="nftBurnFormGet.address.errors.required">Please enter
                                    collection address.</small>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-4 mt-sm-2 mt-md-3">

                            <ng-container *ngIf="account.state.data.account != ''">
                                <div class="text-center">
                                    <button class="submit-btn" type="submit" [disabled]="nftBurnForm.invalid"
                                        *ngIf="!burnNftLoader">Search.</button>
                                    <button class="submit-btn" *ngIf="burnNftLoader" disabled>
                                        Processing...
                                    </button>
                                </div>
                            </ng-container>
                            <div class="text-center">
                                <div class="d-flex align-items-center gap-4" *ngIf="account.state.data.account == ''">
                                    <button class="submit-btn wallet-btn" type="button"
                                        (click)="connectWallet()">Connect Wallet.</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
</div>