<div class="main-wrap">
    <app-sidebar class="sidebarview"></app-sidebar>
    <section class="section">
        <div class="container-fluid">
            <div class="managecollection" id="nameScrollEdit">
                <div class="row mb-4">
                    <div class="col-lg-6">
                        <ul class="manage-collection">
                            <li class="nav-item menuactivities">Manage items.</li>
                        </ul>
                    </div>
                </div>
                <!--start forms-->
                <div class="row  align-items-center " *ngIf="tokenId == ''">
                    <div class="col-lg-6 col-md-7 col-sm-12 mb-4">
                        <label for="label">Choose the collection to manage item's.</label>
                        <ng-select (change)="getNft($event,lazyMint,1,currentPageLimit)"
                            placeholder="Choose a collection">
                            <ng-option *ngFor="let data of collectionsAddressList" [value]="data._id">
                                {{data.name +' - '+data.collection_address }}</ng-option>

                        </ng-select>
                    </div>
                    <div class="col-lg-6 col-md-5 col-sm-12 mb-3"
                        *ngIf="nftDetails.length > 0 && nftDetails[0]['collections'].isLazyMint">
                        <div class="float-right">
                            <label for="label">Filter.</label>
                            <div class="d-block">
                                <button class="btn-light dropdown-toggle" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    {{sortName}}
                                </button>
                                <ul class="dropdown-menu sorting dropdown-menu-end">
                                    <li><a class="dropdown-item" (click)="sortNft('All items.','')">All items.</a></li>
                                    <li><a class="dropdown-item" (click)="sortNft('Normal mint item.','false')">Normal
                                            mint item.</a></li>
                                    <li><a class="dropdown-item" (click)="sortNft('Lazymint item.','true')">Lazymint
                                            item.</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="loading-notfound" *ngIf="collectionLoader">
                    <div class="loader"></div>
                    <p>Please wait while we load your items. </p>&nbsp;
                </div>
                <div class="table-responsive">
                    <table class="table table-hover" *ngIf="!collectionLoader && displayNFT">
                        <thead>
                            <th>S.no.</th>
                            <th>Name.</th>
                            <th>Image.</th>
                            <th>Token id.</th>
                            <th>Actions.</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of nftDetails; trackBy: trackByFn;let i = index;"
                                class="cursor-pointer">
                                <td (click)="clickRedirect(data)">
                                    {{i + 1}}
                                </td>
                                <td (click)="clickRedirect(data)">
                                    {{data.name}}
                                </td>
                                <td>
                                    <img *ngIf="data?.['fileType'] != 'html'" src="{{data['primary_media']}}"
                                        alt="nft img" width="50" height="50"
                                        onerror="this.src='assets/images/create-nft.png'">
                                    <img *ngIf="data?.['fileType'] == 'html'"
                                        [src]="data['preview_image'] ? data['preview_image'] : data['secondary_media'][0]"
                                        alt="nft img" width="50" height="50"
                                        onerror="this.src='assets/images/create-nft.png'">
                                </td>
                                <td (click)="clickRedirect(data)">{{data.token_id}}</td>
                                <td>
                                    <ng-container *ngIf="!data.lazy_mint">
                                        <div class="d-flex gap-2 position-relative">
                                            <button
                                                [routerLink]="['/manage-nft/edit-nft/', data.collections.collection_address,data.token_id]"
                                                type="button" class="btn btn-primary">Edit.</button>
                                            <button type="button"  [ngClass]="data.loan_status > 0 ? 'onboard-burn':'btn btn-danger '"
                                             
                                                (click)="confirmAlert('',data.token_id,data.collections.collection_address,data.name)">Burn.
                                                <div class="loan-details-tooltips">
                                                    Item is in collateral.
                                                </div></button>
                                               
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="data.lazy_mint">
                                        <div class="d-flex gap-2 position-relative">
                                            <button *ngIf="data.collections.category === 'Gold'"
                                                [routerLink]="['/manage-nft/edit-nft/', data.collections.collection_address,data.token_id]"
                                                type="button" class="btn btn-primary">Edit.</button>
                                                <button type="button" class="btn btn-green action-btn"
                                                disabled>Lazymint.</button>
                                               
                                        </div>
                                    </ng-container>
                                </td>
                            </tr>
                            <ng-container *ngIf="!collectionLoader">
                                <tr *ngIf="nftDetails.length == 0" class="text-center">
                                    <td colspan="5">
                                        <div class="data-not-found">
                                            <img src="assets/images/data-not-found.svg" alt="data not found" width="320"
                                                height="180" class="img-fluid mb-3">
                                            <h6>You do not have any item's. Can create a new Item by manage collections.
                                            </h6>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12" *ngIf="!collectionLoader && nftDetails.length != 0">
                    <div class="d-flex justify-content-end flex-wrap">
                        <div>
                            <button *ngIf="nftDetailsPagination?.page != 1" class="previous-btn"
                                (click)="getNft(collectionId,lazyMint,nftDetailsPagination?.page - 1, currentPageLimit)">
                                <span class="me-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                        fill="none">
                                        <path
                                            d="M10.5371 3.60462C10.3187 3.30815 9.90141 3.24483 9.60495 3.46317C9.37468 3.63277 9.15579 3.8024 8.96513 3.95118C8.58459 4.24819 8.07486 4.65707 7.56326 5.10131C7.05493 5.54267 6.52939 6.03202 6.12572 6.48888C5.92459 6.71652 5.73959 6.95185 5.60126 7.18132C5.47399 7.39245 5.33366 7.68259 5.33366 7.99999C5.33366 8.31739 5.47399 8.60759 5.60126 8.81872C5.73959 9.04819 5.92459 9.28345 6.12572 9.51112C6.52939 9.96799 7.05493 10.4573 7.56326 10.8987C8.07486 11.3429 8.58459 11.7518 8.96513 12.0489C9.15579 12.1976 9.37468 12.3673 9.60495 12.5369C9.90141 12.7552 10.3187 12.6919 10.5371 12.3954C10.6249 12.2762 10.6671 12.1375 10.667 12.0001L10.667 7.99999V3.99997C10.6671 3.8625 10.6249 3.72379 10.5371 3.60462Z"
                                            fill="currentColor" />
                                    </svg>
                                </span>Prev
                            </button>
                            <button *ngIf="nftDetailsPagination?.page == 1" disabled class="previous-btn disble-btn">
                                <span class="me-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                        fill="none">
                                        <path
                                            d="M10.5371 3.60462C10.3187 3.30815 9.90141 3.24483 9.60495 3.46317C9.37468 3.63277 9.15579 3.8024 8.96513 3.95118C8.58459 4.24819 8.07486 4.65707 7.56326 5.10131C7.05493 5.54267 6.52939 6.03202 6.12572 6.48888C5.92459 6.71652 5.73959 6.95185 5.60126 7.18132C5.47399 7.39245 5.33366 7.68259 5.33366 7.99999C5.33366 8.31739 5.47399 8.60759 5.60126 8.81872C5.73959 9.04819 5.92459 9.28345 6.12572 9.51112C6.52939 9.96799 7.05493 10.4573 7.56326 10.8987C8.07486 11.3429 8.58459 11.7518 8.96513 12.0489C9.15579 12.1976 9.37468 12.3673 9.60495 12.5369C9.90141 12.7552 10.3187 12.6919 10.5371 12.3954C10.6249 12.2762 10.6671 12.1375 10.667 12.0001L10.667 7.99999V3.99997C10.6671 3.8625 10.6249 3.72379 10.5371 3.60462Z"
                                            fill="currentColor" />
                                    </svg>
                                </span>Prev
                            </button>
                        </div>
                        <div>
                            <nav>
                                <ul class="pagination">
                                    <li class="page-item cursor-pointer"
                                        *ngIf="nftDetailsPagination.prevPage != null  && (nftDetailsPagination.prevPage - 1) != 0 && (nftDetailsPagination.prevPage - 1) != nftDetailsPagination.page"
                                        (click)="getNft(collectionId,lazyMint,1, currentPageLimit)">
                                        <a class="page-link">1</a>
                                    </li>
                                    <li class="page-item cursor-pointer">
                                        <a class="page-link" *ngIf="nftDetailsPagination.prevPage > 0"
                                            (click)="getNft(collectionId,lazyMint,nftDetailsPagination.prevPage, currentPageLimit)">{{nftDetailsPagination.prevPage}}</a>
                                    </li>
                                    <li class="page-item cursor-pointer"><a
                                            class="page-link active">{{nftDetailsPagination.page}}</a></li>
                                    <li class="page-item cursor-pointer"><a class="page-link"
                                            *ngIf="nftDetailsPagination.nextPage > 0"
                                            (click)="getNft(collectionId,lazyMint,nftDetailsPagination.nextPage, currentPageLimit)">{{nftDetailsPagination.nextPage}}</a>
                                    </li>
                                    <li class="page-item"
                                        *ngIf="nftDetailsPagination.totalPages > nftDetailsPagination.nextPage && nftDetailsPagination.nextPage != null && (nftDetailsPagination.nextPage + 1) != nftDetailsPagination.totalPages">
                                        <a class="page-link">..</a>
                                    </li>
                                    <li class="page-item cursor-pointer"
                                        *ngIf="nftDetailsPagination.totalPages > nftDetailsPagination.nextPage && nftDetailsPagination.nextPage != null"
                                        (click)="getNft(collectionId,lazyMint,nftDetailsPagination.totalPages, currentPageLimit)">
                                        <a class="page-link">{{nftDetailsPagination.totalPages}}</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div>
                            <button *ngIf="nftDetailsPagination?.page != nftDetailsPagination?.totalPages"
                                class="next-btn"
                                (click)="getNft(collectionId,lazyMint,nftDetailsPagination?.page + 1, currentPageLimit)">Next
                                <span class="ms-1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        viewBox="0 0 16 16" fill="none">
                                        <path
                                            d="M5.46292 3.60462C5.68126 3.30815 6.09859 3.24483 6.39505 3.46317C6.62532 3.63277 6.84421 3.8024 7.03487 3.95118C7.41541 4.24819 7.92514 4.65707 8.43674 5.10131C8.94507 5.54267 9.47061 6.03202 9.87428 6.48888C10.0754 6.71652 10.2604 6.95185 10.3987 7.18132C10.526 7.39245 10.6663 7.68259 10.6663 7.99999C10.6663 8.31739 10.526 8.60759 10.3987 8.81872C10.2604 9.04819 10.0754 9.28345 9.87428 9.51112C9.47061 9.96799 8.94507 10.4573 8.43674 10.8987C7.92514 11.3429 7.41541 11.7518 7.03487 12.0489C6.84421 12.1976 6.62532 12.3673 6.39505 12.5369C6.09859 12.7552 5.68126 12.6919 5.46292 12.3954C5.37514 12.2762 5.33289 12.1375 5.33301 12.0001L5.33301 7.99999V3.99997C5.33289 3.8625 5.37514 3.72379 5.46292 3.60462Z"
                                            fill="currentColor" />
                                    </svg></span>
                            </button>
                            <button *ngIf="nftDetailsPagination?.page == nftDetailsPagination?.totalPages" disabled
                                class="next-btn disble-btn">Next
                                <span class="ms-1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        viewBox="0 0 16 16" fill="none">
                                        <path
                                            d="M5.46292 3.60462C5.68126 3.30815 6.09859 3.24483 6.39505 3.46317C6.62532 3.63277 6.84421 3.8024 7.03487 3.95118C7.41541 4.24819 7.92514 4.65707 8.43674 5.10131C8.94507 5.54267 9.47061 6.03202 9.87428 6.48888C10.0754 6.71652 10.2604 6.95185 10.3987 7.18132C10.526 7.39245 10.6663 7.68259 10.6663 7.99999C10.6663 8.31739 10.526 8.60759 10.3987 8.81872C10.2604 9.04819 10.0754 9.28345 9.87428 9.51112C9.47061 9.96799 8.94507 10.4573 8.43674 10.8987C7.92514 11.3429 7.41541 11.7518 7.03487 12.0489C6.84421 12.1976 6.62532 12.3673 6.39505 12.5369C6.09859 12.7552 5.68126 12.6919 5.46292 12.3954C5.37514 12.2762 5.33289 12.1375 5.33301 12.0001L5.33301 7.99999V3.99997C5.33289 3.8625 5.37514 3.72379 5.46292 3.60462Z"
                                            fill="currentColor" />
                                    </svg></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>