import { HttpClient } from '@angular/common/http';
import { APP_ID, Injectable } from '@angular/core';
import { IApiResponse } from '../shared/interface/interface';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const apiUrl = environment.API_BASE_URL;
@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {

  constructor(private http:HttpClient) { }
  /** 
   * create announcement
  */
    createAnnouncemnet(createdata:any):Observable<IApiResponse>{
      return this.http.post<IApiResponse>(`${apiUrl}admin/announcement`,createdata);
    }


/** 
  * update announcement
  */
  updateAnnouncemnet(updateData:any,id:string):Observable<IApiResponse>{
      return this.http.patch<IApiResponse>(`${apiUrl}admin/announcement?id=${id}`,updateData);
  }

  /** 
 * get announcement details
 */
  getAnnouncement():Observable<IApiResponse>{
    return this.http.get<IApiResponse>(`${apiUrl}admin/announcement-list`);
  }
  /**
   * delete announcemnet
   */
  removeAnnouncement(id:string):Observable<IApiResponse>{
    return this.http.delete<IApiResponse>(`${apiUrl}admin/announcement?id=${id}`);
  }
  /**
   * enable Status
   */
  enableStatus(statusId:any,status):Observable<IApiResponse>{
    return this.http.patch<IApiResponse>(`${apiUrl}admin/announcement?id=${statusId}`, status);
  }
}
