import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { NftManagementService } from 'src/app/services/nft-management.service';
import { StorageService } from 'src/app/services/storage.service';
import { walletAccount } from 'src/app/shared/interface/interface';
import { environment } from 'src/environments/environment';
import Web3 from 'web3';

const ethereum = window['ethereum'] as any
let web3 = new Web3(window['ethereum'] as any);

@Component({
  selector: 'app-editnft',
  templateUrl: './editnft.component.html',
  styleUrls: ['./editnft.component.scss']
})
export class EditnftComponent implements OnInit {
  public nftBurnForm: UntypedFormGroup;
  public burnNftLoader: boolean = false;
  public burnNftFormSubmitted: boolean = false;
  public account: walletAccount;
  public transactionHash: string = '';
  public transhUrl = '';
  /**
   * Creates an instance of nft burn component.
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    private nftManagementService: NftManagementService,
    private accountService: AccountService,
    private storageService: StorageService,
    private toastr: ToastrService,
    private router: Router,
  ) { }

  /**
   * on init
   */
  public ngOnInit(): void {
    this.account = this.storageService.getItem('wagmi.store') === null ?
      { address: '', network: '', chainId: '', provider: '' } :
      JSON.parse(this.storageService.getItem('wagmi.store') as any);;

    this.nftBurnForm = this.formBuilder.group({
      address: ['', Validators.required],
      tokenId: ['', Validators.required],
    });
    setTimeout(() => {
      this.transhUrl = environment[this.account.state.data.chain.id].EXPLORER;
    }, 1000);

    this.accountService.accountObserve.subscribe((response) => {
      if (response) {
        this.account = this.storageService.getItem('wagmi.store') === null ?
          { address: '', network: '', chainId: '', provider: '' } :
          JSON.parse(this.storageService.getItem('wagmi.store') as any);
        if (this.nftBurnForm.controls['address'].value != '') {
          setTimeout(() => {
            this.nftBurnSubmit();
          }, 500);
        }
      }
    });

  }


  /**
   * Nfts burn submit
   * @return {httpresponse}
   */
  public async nftBurnSubmit() {
    this.burnNftFormSubmitted = true;
    if (this.nftBurnForm.invalid) {
      return;
    }
    this.burnNftLoader = true;
    const inputAddress = this.nftBurnForm.controls['address'].value.trim();
    const isValidColection = await this.nftManagementService.isValidCollection(inputAddress) as any;

    // Validation for collection address is valid
    if (web3.utils.toChecksumAddress(isValidColection) != web3.utils.toChecksumAddress(environment[this.account.state.data.chain.id].FACTORY_ADDRESS)) {
      this.toastr.error("Invalid collection address.");
      this.burnNftLoader = false;
      return;
    }
    // Validation for collection address available in contract
    this.nftManagementService.getUserCreatedCollections(this.account.state.data.account).then((response) => {
      let collectionAddress = web3.utils.toChecksumAddress(inputAddress);
      // Validation for collection address is destroyed
      this.nftManagementService.isCollectionDestroyed(collectionAddress).then(async (destroyedResponse) => {
        if (destroyedResponse) {
          this.toastr.error("Collection destroyed.");
          this.burnNftLoader = false;
          return;
        }
        else {
          // Validation for NFT token id
          this.nftManagementService.getNextMintableToken(collectionAddress).then(async tokenId => {
            if (this.nftBurnForm.controls['tokenId'].value >= tokenId) {
              this.toastr.error("Invalid token ID.")
              this.burnNftLoader = false;
              return;
            } else {
                const isOwnerAddress = await this.getAdminAddress(collectionAddress);
                // Validation for NFT owner
                this.nftManagementService.getNftowner(this.nftBurnForm.controls['tokenId'].value, collectionAddress).then(((ownerAddress:any) => {
                if (web3.utils.toChecksumAddress(ownerAddress) != web3.utils.toChecksumAddress(this.account.state.data.account) && !response.includes(collectionAddress) && !isOwnerAddress) {
                  this.toastr.error("You are not a owner of the NFT.");
                  this.burnNftLoader = false;
                  return;
                } else {
                  // Validation for address whitelisted
                  this.nftManagementService.whiteListed(this.account.state.data.account).then((isWhitlisted => {
                    if (isWhitlisted) {
                      this.nftManagementService.getCreator(this.nftBurnForm.controls['tokenId'].value, collectionAddress).then(((creatorAddress:any) => {
                        if (web3.utils.toChecksumAddress(creatorAddress) == web3.utils.toChecksumAddress(this.account.state.data.account)) {
                          this.router.navigate(['/manage-nft/edit-nft/', collectionAddress, this.nftBurnForm.controls['tokenId'].value]);
                        }
                        else if(isOwnerAddress){
                          this.router.navigate(['/manage-nft/edit-nft/', collectionAddress, this.nftBurnForm.controls['tokenId'].value]);
                        }
                        else {
                          this.nftManagementService.getNftowner(this.nftBurnForm.controls['tokenId'].value, collectionAddress).then(((ownerAddress:any) => {
                            if (web3.utils.toChecksumAddress(ownerAddress) == web3.utils.toChecksumAddress(this.account.state.data.account)) {
                              this.router.navigate(['/manage-nft/edit-nft/', collectionAddress, this.nftBurnForm.controls['tokenId'].value]);
                            }
                          }),
                            (error) => {
                              error = error.toString().replace('Error: Returned error: execution reverted: ERC721: ', '');
                              this.toastr.error(error);
                              this.burnNftLoader = false;
                            })
                        }
                      }))
                    }
                    else {
                      this.toastr.error("Your address is not whitelisted. Please contact your admin.");
                      this.burnNftLoader = false;
                    }
                  }),
                    (_error) => {
                      this.toastr.error("Transaction failed.");
                      this.burnNftLoader = false;
                    });
                }
              })).catch(() => {
                this.toastr.error("Token ID already burned.")
                this.burnNftLoader = false;
                return
              })
            }
          })
        }
      })
    })
      .catch((error: any) => {
        this.burnNftLoader = false;
        if ((error.toString()).includes('is not a valid Ethereum address')) {
          this.toastr.error("Collection address not found.")
        }
        else if((error.toString()).includes('returned no data')){
          this.toastr.error("Collection address not found.")
        }
      })
  }

  /**
   * Gets get nft burn form
   */
  public get nftBurnFormGet() {
    return this.nftBurnForm.controls;
  }

  /**
   * Connects wallet
   */
  public connectWallet() {
    this.ngOnInit()
    const data = {
      status: true,
    };
    this.accountService.openWallet(data);
  }



  /**
   * Gets admin address
   * @param {string} collectionAddress
   * @returns
   */
  public getAdminAddress(collectionAddress:string){
    return new Promise((resolve, reject) => {
      this.nftManagementService.getAdmin(collectionAddress).then((response: any) => {
        if(response == this.account.state.data.account){
          resolve(true);
        }
        else{
          resolve(false);
        }
      }).catch((error) => {

          reject(false);
      })
    })

  }

}
