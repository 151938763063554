<div class="main-wrap">
  <app-sidebar class="sidebarview"></app-sidebar>
  <section class="section">
    <div class="container-fluid">
      <div class="managecollection">
        <div class="row">
          <div class="col-lg-12">
            <div class="d-flex">
              <a class="backbtns">
                <svg xmlns="http://www.w3.org/2000/svg" (click)="goBack()" width="24" height="24" viewBox="0 0 24 24"
                  fill="none" [ngClass]="loader?' back_btn cursor-notallowed':' back_btn cursor-pointer'">
                  <path d="M5 12L20 11.9998" stroke="#111827" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path
                    d="M8.99992 7L4.70703 11.2929C4.37369 11.6262 4.20703 11.7929 4.20703 12C4.20703 12.2071 4.37369 12.3738 4.70703 12.7071L8.99992 17"
                    stroke="#111827" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span *ngIf="!loader">Order Details: {{deliveryDetails.order_id}}</span>
                <ngx-skeleton-loader
                  [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '200px', height: '15px','border-radius':'5px'}"
                  *ngIf="loader"></ngx-skeleton-loader>
              </a>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-3 col-sm-6 mb-18">
            <div class="order-status h-100">
              <h3>Order Status.</h3>
              <hr>
              <div class="status">
                <h4>Order ID.</h4>
                <h5 *ngIf="!loader">{{deliveryDetails.order_id}}</h5>
                <ngx-skeleton-loader
                  [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '100%', height: '15px','border-radius':'5px'}"
                  *ngIf="loader"></ngx-skeleton-loader>
              </div>
              <div class="status">
                <h4>Order Status.</h4>
                <ng-container *ngIf="!loader">
                  <select
                    *ngIf="deliveryDetails.status['status'] != 6 && deliveryDetails.status['status'] != 5 && deliveryDetails.status['status'] != 7; else OrderStatus"
                    class="form-select btn status{{deliveryDetails.status['status']}}"
                    aria-label="Default select example" id="status-update"
                    (change)="confirmAlert(deliveryDetails._id,deliveryDetails.status['status'])">
                    <option *ngFor="let deliveryData of deliveryStatus"
                      [selected]="deliveryData.status == deliveryDetails.status['status']"
                      value={{deliveryData.status}},{{deliveryData._id}}>{{deliveryData.name}}</option>
                  </select>
                  <ng-template #OrderStatus>
                    <a class="" *ngIf="deliveryDetails.status['status'] != 7" [ngClass]="{
                                                    'cancel': deliveryDetails.status['status'] == 6,
                                                    'deliver': deliveryDetails.status['status'] != 6 && deliveryDetails.status['status'] != 7
                                                  }">{{deliveryDetails.status['status'] == 6 ? 'Order Cancelled.' :
                      'Order delivered.'}}</a>
                    <a class="burned" *ngIf="deliveryDetails.status['status'] == 7">Item burned.</a>
                  </ng-template>
                </ng-container>
                <ngx-skeleton-loader *ngIf="loader"
                  [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '100%', height: '15px','border-radius':'5px'}"></ngx-skeleton-loader>
              </div>

            </div>
          </div>
          <div class="col-lg-9 col-sm-6 mb-18">
            <div class="order-status">
              <div class="d-flex align-items-center justify-content-between">
                <h3>Item Details.</h3>
                <a [ngClass]="loader?'cursor-notallowed':''"
                  [href]="deliveryDetails.nft_id['lazy_mint'] ? marketPlaceUrl+'lazy-mint/'+deliveryDetails.nft_id['_id']+'?regulated='+isRegulated : marketPlaceUrl+'nft-detail/'+deliveryDetails.nft_id['collections'].collection_address+'/'+deliveryDetails.nft_id['token_id']+'?regulated='+isRegulated"
                  target="_blank"><img src="assets/images/external-black.svg" alt=""></a>
              </div>
              <hr>
              <div class="status">
                <div class="d-flex align-items-center gap-4 flex-wrap">

                  <ng-container *ngIf="!loader">
                    <img *ngIf="deliveryDetails?.nft_id['fileType'] != 'html'"
                      src="{{deliveryDetails.nft_id['primary_media']}}" alt="nft img" width="115" height="112"
                      onerror="this.src='assets/images/create-nft.png'">

                    <div class="gemsView" *ngIf="deliveryDetails?.nft_id['fileType'] === 'html'">
                      <iframe
                        [src]="deliveryDetails.nft_id['preview_image'] ? deliveryDetails.nft_id['preview_image'] : deliveryDetails.nft_id['primary_media'] | safe"
                        class="img-fluid" frameborder="0" scrolling="no"></iframe>
                    </div>
                  </ng-container>
                  <ngx-skeleton-loader *ngIf="loader"
                    [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '115px', height: '112px','border-radius':'5px'}"></ngx-skeleton-loader>

                  <div>
                    <h4>Item Name.</h4>
                    <h5 class="mb-3" *ngIf="!loader">{{deliveryDetails.nft_id['name']}}</h5>
                    <ngx-skeleton-loader *ngIf="loader"
                      [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '200px', height: '15px','border-radius':'5px'}"></ngx-skeleton-loader>
                    <h4>Quantity.</h4>
                    <h5 *ngIf="!loader">{{deliveryDetails.quantity}}</h5>
                    <ngx-skeleton-loader *ngIf="loader"
                      [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '200px', height: '15px','border-radius':'5px'}"></ngx-skeleton-loader>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 col-sm-12 mb-18">
            <div class="order-status mb-3">
              <h3>Delivery Address.</h3>
              <hr>
              <div class="status d-flex align-items-center flex-wrap">
                <h4 class="mb-0 w-30">House Number.</h4>
                <h5 *ngIf="!loader">{{deliveryDetails.house_number}}</h5>
                <h5 *ngIf="loader"><ngx-skeleton-loader
                    [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '200px', height: '15px','border-radius':'5px'}"></ngx-skeleton-loader>
                </h5>
              </div>
              <div class="status d-flex align-items-center flex-wrap">
                <h4 class="mb-0 w-30">Street.</h4>
                <h5 *ngIf="!loader">{{deliveryDetails.street}}</h5>
                <h5 *ngIf="loader"><ngx-skeleton-loader
                    [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '200px', height: '15px','border-radius':'5px'}"></ngx-skeleton-loader>
                </h5>
              </div>
              <div class="status d-flex align-items-center flex-wrap">
                <h4 class="mb-0 w-30">City.</h4>
                <h5 *ngIf="!loader">{{deliveryDetails.city}}</h5>
                <h5 *ngIf="loader"><ngx-skeleton-loader
                    [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '200px', height: '15px','border-radius':'5px'}"></ngx-skeleton-loader>
                </h5>
              </div>
              <div class="status d-flex align-items-center flex-wrap">
                <h4 class="mb-0 w-30">Country.</h4>
                <h5 *ngIf="!loader">{{deliveryDetails.country}}</h5>
                <h5 *ngIf="loader"><ngx-skeleton-loader
                    [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '200px', height: '15px','border-radius':'5px'}"></ngx-skeleton-loader>
                </h5>
              </div>
              <div class="status d-flex align-items-center flex-wrap">
                <h4 class="mb-0 w-30">Postal Code.</h4>
                <h5 *ngIf="!loader">{{deliveryDetails.postal_code}}</h5>
                <h5 *ngIf="loader"><ngx-skeleton-loader
                    [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '200px', height: '15px','border-radius':'5px'}"></ngx-skeleton-loader>
                </h5>
              </div>
            </div>
            <div class="order-status">
              <div class="d-flex  justify-content-between flex-wrap">
                <h3>User Details.</h3>
                <a [ngClass]="loader?'cursor-notallowed':''"
                  [routerLink]="['/manage-user/user-profile/',deliveryDetails.user_id['wallet_address']]"><img
                    src="assets/images/external-black.svg" alt=""></a>
              </div>
              <hr>
              <div class="status d-flex flex-wrap">
                <h4 class="mb-0 w-30">Full Name.</h4>
                <h5 *ngIf="!loader">{{deliveryDetails.user_id['name']}}</h5>
                <h5 *ngIf="loader"><ngx-skeleton-loader
                    [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '200px', height: '15px','border-radius':'5px'}"></ngx-skeleton-loader>
                </h5>
              </div>
              <div class="status d-flex mb-2 flex-wrap">
                <h4 class="mb-0 w-30">Profile ID.</h4>
                <div class="d-flex gap-2">
                  <h6 *ngIf="!loader" class="blue cursor-pointer"
                    [routerLink]="['/manage-user/user-profile/',deliveryDetails.user_id['wallet_address']]">
                    {{deliveryDetails.user_id['_id']}}</h6>
                  <a *ngIf="!loader" class="cursor-pointer" (click)="copy(deliveryDetails.user_id['_id'])"><img
                      src="assets/images/copy.svg" alt="copy"></a>
                  <h6 *ngIf="loader"><ngx-skeleton-loader
                      [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '200px', height: '15px','border-radius':'5px'}"></ngx-skeleton-loader>
                  </h6>
                </div>
              </div>
              <div class="status d-flex  mb-2 flex-wrap">
                <h4 class="mb-0 w-30">Mail ID.</h4>
                <div class="d-flex gap-2">
                  <h6 *ngIf="!loader" class="blue cursor-pointer"
                    [routerLink]="['/manage-user/user-profile/',deliveryDetails.user_id['wallet_address']]">
                    {{deliveryDetails.user_id['email']}}</h6>
                  <a *ngIf="!loader" class="cursor-pointer" (click)="copy(deliveryDetails.user_id['email'])"><img
                      src="assets/images/copy.svg" alt="copy"></a>
                  <h6 *ngIf="loader"><ngx-skeleton-loader
                      [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '200px', height: '15px','border-radius':'5px'}"></ngx-skeleton-loader>
                  </h6>
                </div>
              </div>
              <div class="status d-flex  mb-2 flex-wrap">
                <h4 class="mb-0 w-30">Wallet address.</h4>
                <div class="d-flex gap-2">
                  <h6 *ngIf="!loader" class="blue cursor-pointer"
                    [routerLink]="['/manage-user/user-profile/',deliveryDetails.user_id['wallet_address']]">
                    {{deliveryDetails.user_id['wallet_address']}}</h6>
                  <a *ngIf="!loader" class="cursor-pointer"
                    (click)="copy(deliveryDetails.user_id['wallet_address'])"><img src="assets/images/copy.svg"
                      alt="copy"></a>
                  <h6 *ngIf="loader"><ngx-skeleton-loader
                      [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '200px', height: '15px','border-radius':'5px'}"></ngx-skeleton-loader>
                  </h6>
                </div>
              </div>
              <div class="status d-flex  flex-wrap">
                <h4 class="mb-0 w-30">Order Initiated.</h4>
                <h5 *ngIf="!loader">{{deliveryDetails.createdAt | date:"dd/MM/YYYY hh:mm:ss a"}}</h5>
                <h5 *ngIf="loader"><ngx-skeleton-loader
                    [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '200px', height: '15px','border-radius':'5px'}"></ngx-skeleton-loader>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12 mb-18">

            <div class="order-status">
              <h3>Shipping activity.</h3>
              <div class="pt-4" *ngIf="shippingLoader">
                <ngx-skeleton-loader
                  [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '100%', height: '15px','border-radius':'5px'}"></ngx-skeleton-loader>
                <ngx-skeleton-loader
                  [theme]="{ background: 'rgba(0, 103, 54, .15)', width: '100%', height: '15px','border-radius':'5px'}"></ngx-skeleton-loader>
              </div>
              <hr>
              <div class="ordertracking-wrap" *ngIf="!shippingLoader">
                <div class="order-track">
                  <div class="order-track-step" *ngFor="let data of deliveryHistory">
                    <div class="order-track-status">
                      <span class=" order-track-status-dot" [ngClass]="data.is_completed ? 'is-complete' : ''"></span>
                      <span class="order-track-status-line"></span>
                    </div>
                    <div class="order-track-text">
                      <p class="order-track-text-stat">{{data.status.name}}.</p>
                      <span class="order-track-text-sub" *ngIf="data.is_completed">Placed on {{data.updatedAt |
                        date:"dd/MM/YYYY hh:mm:ss a"}}</span>
                    </div>
                  </div>

                </div>
              </div>
              <!--end-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>