import { environment } from 'src/environments/environment';

/**
 * Common helper functions
 */
export class CommonHelper {
  /**
   * File Download
   * @param {string} path
   * @param {string} fileName
   */
  public fileDownload(path: string, fileName: string) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', environment.FILE_PATH + path);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

   /**
     * 
     * @param data 
     * @returns 
     */
   public decimalToHex(data: number | string) {
    let hex = Number(data).toString(16);
    while (hex.length < 2) {
        hex = '0' + hex;
    }
    return '0x'+hex;
} 
}
