import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { NftManagementService } from 'src/app/services/nft-management.service';
import { StorageService } from 'src/app/services/storage.service';
import { IApiResponse, IPagination, walletAccount } from 'src/app/shared/interface/interface';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';


@Component({
    selector: 'app-manage-category',
    templateUrl: './manage-category.component.html',
    styleUrls: ['./manage-category.component.scss']
})
export class ManageCategoryComponent implements OnInit {
    categories: string[] = [];
    currentPageLimit = 10;
    currentPage = 1;
    submitLoader = false;
    selectedCategoryName = '';
    nftList: { [key: string]: any }[] = [];
    nftListPagination: IPagination;
    sortType:nftSortByType = nftSortByType.ALL_ITEMS
    categoriesList: { [key: string]: any }[] = [];
    isRegulated = false;
    private marketPlaceUrl = environment.MARKETPLACE_URL;
    account: walletAccount;
    sortName = 'All items.';
    totalNftsToBeSelected = 5;

    selectedNftsByCategory:any [] = [];
    selectedNftsId:string [] = [];
    selectedNftsRetrieved:boolean = false;

    showTableView:{[key:string]:boolean}={
      'allNft':false,
      'selectedNft':false
    }

    isLoading:{[key:string]:boolean}={
      'allNft':false,
      'selectedNft':false
    }

    /**
     * constructor
     */
    constructor(
        private nftService: NftManagementService,
        private toastr: ToastrService,
        private storageService: StorageService,
    ) {

    }


    /**
     * loads on component initialization
     */
    ngOnInit(): void {
        this.isRegulated = JSON.parse(this.storageService.getItemSession('isRegulated'));
        this.account = this.storageService.getItem('wagmi.store') === null ?
            { address: '', network: '', chainId: '', provider: '' } :
            JSON.parse(this.storageService.getItem('wagmi.store') as any);
        this.getCategories();
    }

    /**
     * get categories list
     */
    getCategories(): void {
        this.nftService.getCategories().subscribe({
            next: (response: any) => {
                this.categories = response.data || []
            },
            error: (error) => {
                this.toastr.error(error.error.message);
            }
        })
    }

  /**
   * Gets nft
   * @param {string} selectedCategoryName
   * @param {number} page
   * @param {number} limit
   * @param {nftSortByType} sortType
   */
  getNfts(selectedCategoryName: string, sortType: nftSortByType, page?: number, limit?: number) {
    let getAllNftApiCall = this.nftService.getNftsbyCategory(selectedCategoryName, sortType, page, limit);
    
    // NOTE: sortType passed to getSelectedNftApiCall is hardcoded to nftSortByType.ALL_ITEMS  
    let getSelectedNftApiCall = this.nftService.getSelectedNftsInCategory(selectedCategoryName, nftSortByType.ALL_ITEMS, 1, limit);

    // API calls configuration
    let apiCalls = [getAllNftApiCall];
    if (!this.selectedNftsRetrieved) {
      apiCalls.push(getSelectedNftApiCall);
      this.showTableView['selectedNft'] = false;
      this.isLoading['selectedNft'] = true;
    }

    const resetAll = () =>{
      this.selectedCategoryName = ''
      this.nftList = [];
      this.selectedNftsId = [];
      this.selectedNftsByCategory = [];
      Object.keys(this.showTableView).map((state:string)=>{
        this.showTableView[state] = false
      })
      Object.keys(this.isLoading).map((loadingState:string)=>{
        this.isLoading[loadingState] = false
      })
    }

    if (selectedCategoryName) {
      this.showTableView['allNft'] = false;
      this.isLoading['allNft'] = true;
      
      this.selectedCategoryName = selectedCategoryName;

      forkJoin(apiCalls).subscribe({
        next: ([allNFTResponse, selectedNFTResponse]) => {
          // Extract file type logic into a function
          const extractFileType = (nft: any) => {
            return nft?.preview_image ? nft.preview_image.split('.').pop() : nft?.primary_media?.split('.').pop();
          };

          const handleAllNftResponse = () => {
            this.nftList = allNFTResponse.data.docs;
            this.nftListPagination = allNFTResponse.data;
            // Update nftList with file types
            this.nftList.forEach((nft: any) => {
              nft.fileType = extractFileType(nft);
            });

            this.showTableView['allNft'] = true;
            this.isLoading['allNft'] = false;
          };

          const handleSelectedNftResponse = () => {
            this.selectedNftsRetrieved = true;
            this.selectedNftsByCategory = selectedNFTResponse?.data?.docs || [];

            // Update selectedNftsByCategory with file types
            this.selectedNftsByCategory.forEach((nft: any) => {
              nft.fileType = extractFileType(nft);
            });

            // Extract selected NFT IDs
            this.selectedNftsId = this.selectedNftsByCategory.map((nft: any) => nft._id);
            this.showTableView['selectedNft'] = true;
            this.isLoading['selectedNft'] = false;
          };

          handleAllNftResponse();
          if(selectedNFTResponse) handleSelectedNftResponse();
        },
        error: (error: any) => {
          resetAll();
          this.toastr.error(error.error.message);
        },
      });
    } else {
      //Resets All.
      resetAll();
    }
  }
    /**
     * Sorts nft
     * @param {string} sortName
     * @param {nftSortByType} type
     */
    sortNft(sortName: string, type: nftSortByType) {
        this.sortName = sortName;
        this.sortType = type;
        this.getNfts(this.selectedCategoryName, type, this.currentPage, this.currentPageLimit);
    }

    /**
 * Track by function for ngFor loops
 * @param index
 * @param item
 */
    public trackByFn(index: number, item: any): any {
        return item._id || index;
    }

    /**
     * Clicks redirect
     * @param {object} data
     */
    clickRedirect(data: object) {
        window.open(data['lazy_mint'] ? this.marketPlaceUrl + 'lazy-mint/' + data['_id'] + '?regulated=' + this.isRegulated : this.marketPlaceUrl + 'nft-detail/' + data['collections'].collection_address + '/' + data['token_id'] + '?regulated=' + this.isRegulated, '_blank');
    }

    /**
     * store selected nfts
     */
    storeSelectedNfts() {
        this.submitLoader = true;
        this.nftService.storeDefaultNftsinCategory(this.selectedNftsId, this.selectedCategoryName).subscribe({
            next: (response: IApiResponse) => {
                this.submitLoader = false;
                this.toastr.success(response.message + '.');
                this.getNfts(this.selectedCategoryName, nftSortByType.ALL_ITEMS, this.currentPage, this.currentPageLimit);
            },
            error: (error: HttpErrorResponse) => {
                this.submitLoader = false;
                this.toastr.error(error.error.message);
            }
        })
    }
    
  /**
   * Checks if the given NFT item is selected.
   *
   * @param {string} nftItemId - The ID of the NFT item to check.
   * @returns {boolean} - Returns `true` if the NFT item is selected, otherwise `false`.
   */
  isCheckedNftItem(nftItemId: string) {
    return this.selectedNftsId.includes(nftItemId);
  }

  /**
   * Toggles the selection of an NFT item.
   * If the item is checked, it is added to the selected list; otherwise, it is removed.
   *
   * @param {boolean} isChecked - Indicates whether the NFT item is selected.
   * @param {any} nftItem - The NFT item object to be toggled.
   */
  toggleNftItem(isChecked: boolean, nftItem: any) {
    if (isChecked) {
      this.selectedNftsByCategory.push(nftItem);
      this.selectedNftsId.push(nftItem._id);
    } else {
      this.selectedNftsByCategory = this.selectedNftsByCategory.filter((selectedNft) => selectedNft._id !== nftItem._id);
      this.selectedNftsId = this.selectedNftsId.filter((selectedNftId: string) => selectedNftId !== nftItem._id);
    }
  }

  /**
   * Checks if the NFT item should be disabled.
   *
   * @param {string} nftId - The ID of the NFT item to check.
   * @returns {boolean} - Returns `true` if the NFT item should be disabled, otherwise `false`.
   */
  isDisabledNftItem(nftId: string): boolean {
    // Condition 1: Check if the NFT item is selected
    const isChecked = this.selectedNftsByCategory.some((nft: any) => nft._id === nftId);
    // Condition 2: Total selected NFTs have reached the limit
    const hasReachedLimit = this.selectedNftsByCategory.length >= this.totalNftsToBeSelected;
    return !isChecked && hasReachedLimit;
  }

  /**
   * Getter for accessing the `_nftSortBy`.
   *
   * @returns {typeof nftSortByType} The `nftSortByType` object.
   */
  get _nftSortBy():typeof nftSortByType {
    return nftSortByType
  }
}

enum nftSortByType{
  ALL_ITEMS = '',
  NORMAL_MINT_ITEM = 'false',
  LAZY_MINT_ITEM = 'true'
}