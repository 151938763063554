import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/services/account.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { walletAccount } from 'src/app/shared/interface/interface';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  public userId: string = '';
  public userData: any;
  public isRegulated: any;
  public editForm: UntypedFormGroup;
  public editFormSubmitted:boolean = false;
  public editFormLoader:boolean = false;
  public account: walletAccount;
  

  constructor(
    private route: ActivatedRoute,
    private userManagementService: UserManagementService,
    private storageService: StorageService,
    public formBuilder:UntypedFormBuilder,
    private toastr:ToastrService,
    private accountService:AccountService
  ) { }

  /**
   * on init component
   */
  public ngOnInit(): void {
    this.account = this.storageService.getItem('wagmi.store') === null ?
    { address: '', network: '', chainId: '', provider: '' } :
    JSON.parse(this.storageService.getItem('wagmi.store') as any);
    this.userId = this.route.snapshot.paramMap.get('id');
    this.isRegulated = JSON.parse(this.storageService.getItemSession('isRegulated'));
    this.getUserDetails();
    this.userManagementService.blockStatusObser.subscribe((response: boolean) => {
      if (response) {
        this.getUserDetails();
      }
    })

    this.editForm = this.formBuilder.group({
      name:['',[Validators.required]],
      email:['',[Validators.required]],
    })
    this.accountService.accountObserve.subscribe((response) => {
      if (response) {
        this.account = this.storageService.getItem('wagmi.store') === null ?
          { address: '', network: '', chainId: '', provider: '' } :
          JSON.parse(this.storageService.getItem('wagmi.store') as any);
      }
    });
  }

  /**
   * Gets user details
   */
  public getUserDetails() {
    this.userManagementService.getUser(this.userId).subscribe((response: any) => {
      this.userData = response.data;
      this.editForm.patchValue({
        name:this.userData.name,
        email:this.userData.email,
      })
    })
  }

  /**
   * Gets edit form controls
   */
  public get editFormControls(){
    return this.editForm.controls;
  }

  /**
   * Edits form submit
   */
  public editFormSubmit(){
    this.editFormSubmitted = true;
    if(this.editForm.invalid){
      return
    }
    this.editFormLoader = true;
    const {name, email} = this.editForm.controls;
    let data = {
      name:name.value,
      email:email.value
    }

    this.userManagementService.updateUsers(data,this.userId,this.account.state.data.account).subscribe((response) => {
      this.toastr.success(response['message']);
      this.editFormLoader = false;
    },
    (error) => {
      this.editFormLoader = false;
    }
    )
  }

}
