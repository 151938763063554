import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-new',
  templateUrl: './footer-new.component.html',
  styleUrls: ['./footer-new.component.scss']
})
export class FooterNewComponent implements OnInit {

  year:any
constructor(){

}
ngOnInit():void{
  this.year = new Date().getFullYear();
}

}
