<div class="main-wrap">
    <app-sidebar class="sidebarview"></app-sidebar>
    <section class="section">
        <div class="container-fluid">
            <div class="managecollection" id="nameScrollEdit">
                <div class="row mb-4">
                    <div class="col-lg-6">
                        <ul class="manage-collection">
                            <li class="nav-item menuactivities">
                                {{tokenId ? 'Update' : 'Create'}} item.</li>
                        </ul>
                    </div>
                </div>
                <!--start forms-->
                <div class="loading-notfound" *ngIf="loader">
                    <div class="loader"></div>
                    <p>Please wait while we load your items. </p>&nbsp;
                </div>
                <form [formGroup]="nftForm" *ngIf="!loader">
                    <div class="row mb-2" *ngIf="displayNFT">
                        <div class="col-lg-6 mb-4">
                            <label for="label">Collection <span class="ms-2 text-danger">*</span></label>
                            <input type="text" class="form-control" readonly value="{{collectionDetails?.name}}"
                                formControlName="collection">
                        </div>
                        <div class="col-lg-6 mb-4" id="imageScroll">
                            <label for="label">Name <span class="ms-2 text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="Name" formControlName="name">

                            <div *ngIf="nftFormSubmitted && nftFormGet.name.errors" class="invalid-feedback-custom">
                                <small class="text-danger" *ngIf="nftFormGet.name.errors.required">Please enter Item
                                    name.</small>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-4">
                            <label for="label">Upload primary media <span class="ms-2 text-danger">* <span
                                        class="ms-1"><small>(png,
                                            jpeg, jpg, gif,glb, webp)</small></span></span></label>
                            <div class="row" *ngIf="tokenId && primaryImageData?.fileType">
                                <div class="col-lg-12">
                                    <div class="mediaimage">
                                        <img *ngIf="primaryImageData?.fileType?.toLowerCase() !=='html' && primaryImageData?.fileType?.toLowerCase() !=='gltf-binary'"
                                            src="{{primaryImageData?.url}}"
                                            (click)="openImageModal(primaryImageData?.url)" class="cursor-pointer"
                                            alt="primary-media" onerror="this.src='assets/images/default-nft.svg'">
                                        <img [src]="previewImageUrl || secondaryImagesUrl[0]?.url"
                                            *ngIf="primaryImageData?.fileType?.toLowerCase() == 'gltf-binary' || primaryImageData?.fileType?.toLowerCase() == 'html'"
                                            (click)="openImageModal(previewImageUrl ||  secondaryImagesUrl[0]?.url)"
                                            class="cursor-pointer" onerror="this.src='assets/images/default-nft.svg'">

                                        <a class="submit-btn cursor-pointer"
                                            (click)="openImageModal(primaryImageData?.url,true)"
                                            [ngClass]="primaryImageData?.fileType?.toLowerCase() == 'html'|| primaryImageData?.fileType?.toLowerCase()  == 'gltf-binary'?'d-block':'d-none'">View
                                            3D</a>
                                    </div>
                                </div>
                            </div>
                            <input type="file" class="form-control" id="validatedCustomFile" #primaryMedia
                                (change)="selectImage($event)">
                            <div *ngIf="tokenId == '' && nftFormSubmitted && nftFormGet.image.errors"
                                class="invalid-feedback-custom pt-3">
                                <small class="text-danger " *ngIf="nftFormGet.image.errors.required">Please select
                                    image.</small>
                            </div>
                            <div class="pt-2">
                                <small class="text-danger">{{fileErrorMsg}}</small>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-4" *ngIf="isPreview">
                            <label for="label">Upload preview image <span class="ms-2 text-danger">* <span
                                        class="ms-1"><small>(png,
                                            jpeg, jpg, webp)</small></span></span></label>
                            <div class="row" *ngIf="tokenId && nftAllDetails?.['preview_image']">
                                <div class="col-lg-12">
                                    <div class="mediaimage">
                                        <img [src]="nftAllDetails?.['preview_image']"
                                            onerror="this.src='assets/images/default-nft.svg'"
                                            (click)="openImageModal(nftAllDetails?.['preview_image'])"
                                            class="cursor-pointer">
                                    </div>
                                </div>
                            </div>
                            <input type="file" class="form-control" id="validatedCustomFile" #previewImageTag
                                (change)="selectImagePreview($event)">
                            <div class="pt-2">
                                <small class="text-danger">{{fileErrorMsgPreview}}</small>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-4">
                            <label for="label">Upload secondary media <span class="ms-2 text-danger"><span
                                        class="ms-1"><small>(png,
                                            jpeg, jpg, gif, webp, pdf)</small></span></span></label>
                            <ng-container *ngIf="tokenId && secondaryImagesUrl.length > 0">
                                <div class="mediaimage">
                                    <div *ngFor="let secondaryImage of secondaryImagesUrl;let i = index">
                                        <img src="{{secondaryImage?.['url']}}" alt="i"
                                            *ngIf="secondaryImage?.fileType?.toLowerCase() != 'pdf' && secondaryImage?.['url']"
                                            (click)="openImageModal(secondaryImage?.['url'])" class="cursor-pointer"
                                            onerror="this.src='assets/images/default-nft.svg'">
                                        <a [href]="secondaryImage?.url" target="_blank"
                                            *ngIf="secondaryImage?.fileType?.toLowerCase() == 'pdf'">
                                            <img src="assets/images/document-preview.png" class="actives"
                                                style="width:100px;height:75px;object-fit:cover;">
                                        </a>
                                    </div>
                                </div>
                            </ng-container>
                            <input type="file" class="form-control" multiple id="validatedCustomFile" #secondaryImage
                                (change)="selectSecImage($event)">
                            <div class="pt-2">
                                <small class="text-danger">{{multipleFileErrorMsg}}</small>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-4" id="descriptionScroll">
                            <label for="label">Minting fee (MATIC) <span class="ms-2 text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="Minting fee" readonly
                                formControlName="platformFee">
                        </div>
                        <div class="col-lg-6 mb-4">
                            <label for="label">Royalty fee (%)<span class="ms-2 text-danger">*</span></label>
                            <input type="text" class="form-control" readonly formControlName="royaltyFee">
                        </div>
                        <div class="col-lg-12 mb-4">
                            <label for="label">Description<span class="ms-2 text-danger">*</span></label>
                            <div class="NgxEditor__Wrapper">
                                <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
                                <ngx-editor formControlName="description" [editor]="editor"
                                    [placeholder]="'Description.'"></ngx-editor>
                            </div>

                            <div *ngIf="nftFormSubmitted && nftFormGet.description.errors"
                                class="invalid-feedback-custom">
                                <small class="text-danger" *ngIf="nftFormGet.description.errors.required">Please enter
                                    description.</small>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6">

                            <div class="form-group" *ngIf=" attributes.length > 0">
                                <label>Attributes <span class="text-danger"> *</span></label>
                                <div *ngFor="let attribute of attributes; let i = index">
                                    <ng-container *ngIf="shouldShowAttribute(attribute)">
                                        <div class="d-flex align-items-center justify-content-between gap-2 mb-2">
                                            <span class="w-50">{{attribute.key}}
                                                <span
                                                    *ngIf="(attribute?.key?.toLowerCase() === 'markup fee' || attribute?.key?.toLowerCase() === 'purity') && collectionDetails.category.toLowerCase() === 'gold'">(%)</span>
                                            </span>
                                            <span class="w-50">
                                                <ng-select [clearable]="false"
                                                    *ngIf="attribute?.defaultValue?.length > 0"
                                                    [readonly]="attribute?.key?.toLowerCase() === 'size' && collectionDetails?.category?.toLowerCase() === 'gold' && !!this.tokenId"
                                                    [formControlName]="'attribute_options_' + i"
                                                    (change)="setAttributeValue($event, attribute)">
                                                    <ng-option *ngFor="let data of attribute?.defaultValue"
                                                        [value]="data">{{data}}</ng-option>
                                                </ng-select>
                                                <div class="d-flex align-items-center">
                                                    <input *ngIf="attribute?.defaultValue?.length === 0"
                                                        [type]="attribute?.key?.toLowerCase() === 'appraisal value' || attribute?.key?.toLowerCase() === 'markup fee' ? 'number' : 'text'"
                                                        (keydown)="space($event)" class="form-control" 
                                                        placeholder="Attribute value"
                                                        [formControlName]="'attribute_options_' + i"
                                                        (change)="setAttributeValue($any($event.target).value, attribute)"
                                                        [readonly]="attribute.readonly || ((attribute?.key?.toLowerCase() === 'price' || attribute?.key?.toLowerCase() === 'size' || attribute?.key?.toLowerCase() === 'appraisal value') && collectionDetails?.category?.toLowerCase() === 'gold') || attribute?.key?.toLowerCase() === 'category' || (attribute?.key?.toLowerCase() === 'appraisal value' && collectionDetails?.category?.toLowerCase() !== 'gold' && !!this.tokenId ) ">
                                                    <ng-container
                                                        *ngIf="attribute?.key?.toLowerCase() === 'appraisal value' && collectionDetails?.category?.toLowerCase() !== 'gold'">
                                                        <ng-select [clearable]="false" formControlName="currency"
                                                            [readonly]="!!tokenId || attribute.readonly"
                                                            class="w-50 currency-select">
                                                            <ng-option *ngFor="let data of currencyData"
                                                                [selected]="data.symbol == 'USDC'"
                                                                [value]="data._id">{{data.symbol}}</ng-option>
                                                        </ng-select>
                                                    </ng-container>
                                                    <span class="mx-1"
                                                        *ngIf="(attribute?.key?.toLowerCase() === 'appraisal value' || attribute?.key?.toLowerCase() === 'price') && collectionDetails?.category?.toLowerCase() === 'gold'">USD</span>
                                                </div>
                                            </span>
                                        </div>
                                        <div *ngIf="nftFormSubmitted && nftFormGet['attribute_options_' + i]?.errors"
                                            class="invalid-feedback-custom">
                                            <small class="text-danger"
                                                *ngIf="nftFormGet['attribute_options_' + i]?.errors?.required">Please
                                                enter attribute value.</small>
                                                <small class="text-danger"
                                                *ngIf="nftFormGet['attribute_options_' + i]?.errors?.pattern">Please
                                                enter a valid attribute value.</small>
                                        </div>
                                    </ng-container>
                                </div>

                                <!-- <ng-container *ngIf="collectionDetails?.category?.toLowerCase() === 'gold'">
                  <div class="d-flex align-items-center justify-content-between gap-2 mb-2"
                    *ngIf="collectionDetails.category">
                    <span class="w-50">Category</span>
                    <span class="w-50"> <input type="text" class="form-control" readonly
                        value="{{collectionDetails.category}}"> </span>
                  </div>
                  <div class="d-flex align-items-center justify-content-between gap-2 mb-2"
                    *ngIf="collectionDetails.location">
                    <span class="w-50">Location</span>
                    <span class="w-50"> <input type="text" class="form-control" formControlName="location"
                        value="{{collectionDetails.location}}">
                    </span>
                  </div>
                </ng-container> -->
                            </div>


                        </div>
                        <div class="row mt-lg-3 mt-sm-1 ">
                            <div class="col-lg-12">
                                <div class="d-flex align-items-center gap-4" *ngIf="account.state.data.account != ''">
                                    <button class="submit-btn" type="submit" [disabled]="createNftLoader"
                                        (click)="createNftSubmit()" *ngIf="tokenId == ''">
                                        <span *ngIf="!createNftLoader"> Submit. </span>
                                        <span *ngIf="createNftLoader">Processing...</span>
                                    </button>
                                    <button class="submit-btn" type="submit"
                                        [disabled]="createNftLoader && !updateFormError" (click)="editNftSubmit()"
                                        *ngIf="tokenId != ''">
                                        <span *ngIf="!createNftLoader"> Update. </span>
                                        <span *ngIf="createNftLoader">Processing...</span>
                                    </button>
                                    <button class="clear-btn" type="button" (click)="clearForm()"
                                        [disabled]="createNftLoader">Clear.</button>
                                </div>
                                <div class="d-flex align-items-center gap-4" *ngIf="account.state.data.account == ''">
                                    <button class="submit-btn" type="button" (click)="connectWallet()">Connect
                                        Wallet.</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        </div>
        <app-ui-modal #nftSuccessModal [hideHeader]="true" [hideFooter]="true">
            <div class="app-modal-body">
                <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
                    (click)="closeNFTModal()"><span aria-hidden="true">&times;</span></button>
                <div class="modal-dialog" *ngIf="!formNotChanged">
                    <h5 class="text-green text-center nft-success-title" *ngIf="tokenId == ''">Item created
                        successfully.</h5>
                    <h5 class="text-green text-center nft-success-title" *ngIf="tokenId">Item updated successfully.</h5>
                    <div class="row">
                        <div class="col-12 mt-4 text-center"> Collection Name :
                            {{collectionDetails?.name}}
                        </div>
                        <div class="col-12 mt-2 text-center"> Item Name:
                            {{nftName}}
                        </div>
                        <ng-container *ngIf="this.collectionDetails && this.collectionDetails.category != 'Gold'">
                            <div class="col-12 mt-2 text-center">
                                <a href="{{transhUrl}}/tx/{{transactionHash}}" target="_blank" class="">Click to view
                                    transaction.</a>
                            </div>
                            <div class="col-12 mt-2 text-center">
                                <a href="{{baseUrl}}{{IpfsHash}}" *ngIf="tokenId == ''" target="_blank" class="">Click
                                    to view Item
                                    file.</a>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="modal-dialog" *ngIf="formNotChanged">
                    <h5 class="text-green mb-4 text-center nft-success-title">Changes not detected.</h5>
                    <div class="d-flex justify-content-around">
                        <a class="mt-4 text-center cursor-pointer" (click)="nftSuccessModal.hide()"> Edit again. </a>
                        <a class="mt-4 text-center cursor-pointer" [routerLink]="['/']"> Go to home page.</a>
                    </div>
                </div>
            </div>
        </app-ui-modal>
        <app-ui-modal #imageModal [hideHeader]="true" [hideFooter]="true">
            <div class="app-modal-body">
                <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
                    (click)="closeImageModal()"><span aria-hidden="true">&times;</span></button>
                <div class="modal-dialog">
                    <div class="row three-d-image">
                        <div class="col-lg-12 text-center">
                            <img src="{{selectedImage}}" alt="image" onerror="this.src='assets/images/default-nft.svg'"
                                *ngIf="!isViewing3DImage" class="img-fluid mx-auto">
                            <ng-container *ngIf="isViewing3DImage">
                                <iframe *ngIf="primaryImageData?.fileType?.toLowerCase() == 'html'"
                                    [src]="primaryImageData.url | safe" class="img-fluid maginifypopup" frameborder="0"
                                    scrolling="no"></iframe>
                                <model-viewer *ngIf="primaryImageData?.fileType?.toLowerCase()  == 'gltf-binary'"
                                    id="my-model-viewer" camera-controls camera-orbit="45deg 55deg 2.5m"
                                    [src]="primaryImageData?.url" alt="A 3D model of an astronaut"
                                    data-js-focus-visible="" ar-status="not-presenting">
                                </model-viewer>
                            </ng-container>
                        </div>
                    </div>
                </div>

            </div>
        </app-ui-modal>
    </section>
</div>